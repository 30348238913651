import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RestClient } from "@@intelease/web/utils";
import { map, tap } from "rxjs/operators";
import {
  DriveListResponseModel,
  FileRenameRequestModel,
  FileSearchRequestModel,
  FullNodeModel,
  LightNodeModel,
  MediumNodeModel,
  NodeAdvancedSearchResultModel,
  NodeSearchResultModel,
  OffsetInSearchResultModel,
} from "@@intelease/web/common/models";
import { FileApiService } from "@@intelease/web/intelease/services";
import {
  ExportMultiNodeModel,
  ListResponseModel,
  PaginationModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import { INode } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive-tree.service";
import { cloneDeep } from "lodash";
import { OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel } from "@@intelease/api-models/adex-api-model-src";

export interface DriveSort {
  fieldName: string;
  sort: string;
}

export interface FullNodeModelWithParentDirectoryUid {
  node: FullNodeModel;
  parentDirectoryUid: string;
  refreshDirectories: boolean;
}

export type NavigationType = string | "OWNED" | "SHARED" | "DELETED" | "ALL";

class QueryParams {
  page: string;
  perPage: string;
  sort: string;
  sorts: any;

  constructor(page: PaginationModel, sortsParam?: any) {
    this.perPage = page.size.toString();
    this.page = page.page.toString();
    if (sortsParam) {
      if (typeof sortsParam === "string") {
        // single sort
      } else {
        // multi sort
        this.sorts = [];
        for (const key of Object.keys(sortsParam)) {
          this.sorts.push(sortsParam[key]);
        }
      }
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class ItlsDriveService {
  public static readonly ROOT_NODE_ID = "root";
  public static readonly ROOT_NODE_LEVEL = 1;
  private static readonly API_VERSION_1 = "/v1";

  loadAdvancedSearchRecords = new Subject<Array<string>>();

  navigationCategory$ = new Subject<NavigationType>();

  newFolderCreated$: Subject<FullNodeModelWithParentDirectoryUid> =
    new Subject<FullNodeModelWithParentDirectoryUid>();

  renameFolder$: Subject<{ name: string; uid: string }> = new Subject<{
    name: string;
    uid: string;
  }>();

  deleteFolders$: Subject<string[]> = new Subject<string[]>();

  constructor(
    private restClient: RestClient,
    private fileApiService: FileApiService
  ) {}

  public static getNonRootDirectoryUid(directoryUid: string) {
    return directoryUid === ItlsDriveService.ROOT_NODE_ID
      ? undefined
      : directoryUid;
  }

  public static getInitialNodes() {
    const INITIAL_NODES: INode[] = [
      {
        value: "",
        id: ItlsDriveService.ROOT_NODE_ID,
        title: "My Drive",
        level: ItlsDriveService.ROOT_NODE_LEVEL,
      },
    ];
    return cloneDeep(INITIAL_NODES);
  }

  getRecordsAdvancedSearch(payload): Observable<ServerResponseModel> {
    const _payload = {
      ...payload,
      ...{
        page: 1,
        perPage: 9000,
      },
    };
    return this.restClient.sendPostRequest<ServerResponseModel>(
      "/v1",
      "/files/advanced-search",
      { data: _payload },
      ServerResponseModel
    );
  }

  getRootDirectories(
    category: "favorite" | "shared" | "owned" | "deleted" | "all",
    page: PaginationModel,
    sorts: string[]
  ): Observable<any> {
    return this.restClient
      .sendGetRequest<OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel>(
        ItlsDriveService.API_VERSION_1,
        "/rootDirectories?category=" + this.getRootCategory(category),
        OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel,
        {
          params: new QueryParams(page, sorts),
        }
      )
      .pipe(map((res) => res.data));
  }

  search(
    fileSearchRequest: FileSearchRequestModel
  ): Observable<ListResponseModel<NodeSearchResultModel>> {
    return this.restClient.sendPostListRequest(
      ItlsDriveService.API_VERSION_1,
      `/files/search`,
      NodeSearchResultModel,
      {
        data: fileSearchRequest,
      }
    );
  }

  getOffsetInSearchResult(
    parentDirectoryUid: string,
    targetNode: LightNodeModel
  ): Observable<OffsetInSearchResultModel> {
    return this.restClient.sendPostRequest(
      ItlsDriveService.API_VERSION_1,
      "/files/search/offset",
      {
        data: {
          parentDirectoryUid,
          targetNode,
        },
      },
      OffsetInSearchResultModel,
      {},
      (resp) => resp.data
    );
  }

  getChildren(
    directoryUid: string,
    page: PaginationModel,
    sorts: string[]
  ): Observable<any> {
    return this.restClient
      .sendGetRequest<OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel>(
        ItlsDriveService.API_VERSION_1,
        "/directories/" + directoryUid,
        OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel,
        {
          params: new QueryParams(page, sorts),
        }
      )
      .pipe(map((resp: any) => resp.data));
  }

  getChildrenDirectories(
    directoryUid?: string
  ): Observable<ListResponseModel<FullNodeModel>> {
    const options = directoryUid
      ? {
          params: {
            directoryUid,
          },
        }
      : undefined;
    return this.restClient
      .sendGetRequestNoView(
        ItlsDriveService.API_VERSION_1,
        "/directories/owned/children/directories",
        options
      )
      .pipe(map((resp: any) => resp.data));
  }

  rename(
    fileNodeUid: string,
    fileRenameRequestModel: FileRenameRequestModel
  ): Observable<MediumNodeModel> {
    return this.restClient.sendPutRequest(
      ItlsDriveService.API_VERSION_1,
      `/files/${fileNodeUid}/name`,
      {
        data: fileRenameRequestModel,
      },
      MediumNodeModel,
      {},
      (resp: any) => resp.data
    );
  }

  deleteDirectory(directoryUid: string): Observable<any> {
    return this.restClient.sendDeleteRequestNoView(
      ItlsDriveService.API_VERSION_1,
      `/directories/${directoryUid}`
    );
  }

  deleteDirectories(directoryUid: string[]): Observable<any> {
    return this.restClient.sendPostRequestNoView(
      ItlsDriveService.API_VERSION_1,
      `/directories/deleteBatch`,
      {
        data: {
          objectUids: directoryUid,
          selectAll: false,
          affectAllVersions: false,
        },
      }
    );
  }

  deleteNodes(deletedNodes: LightNodeModel[]): Observable<any> {
    return this.restClient.sendPostRequestNoView(
      ItlsDriveService.API_VERSION_1,
      "/files/deleteBatch",
      {
        data: {
          deletedNodes: deletedNodes,
        },
      }
    );
  }

  moveNodes(
    sourceNodes: LightNodeModel[],
    destDirectoryUid?: string
  ): Observable<any> {
    const destinationDirectoryUid =
      ItlsDriveService.getNonRootDirectoryUid(destDirectoryUid);
    return this.restClient.sendPutRequestNoView(
      ItlsDriveService.API_VERSION_1,
      "/files/move",
      {
        data: {
          sourceNodes,
          destinationDirectoryUid,
        },
      }
    );
  }

  createDirectory(
    name: string,
    parentDirectoryUid?: string
  ): Observable<FullNodeModel> {
    return this.restClient
      .sendPostRequest(
        ItlsDriveService.API_VERSION_1,
        "/directories",
        {
          data: {
            name,
            parentUid: parentDirectoryUid,
          },
        },
        FullNodeModel,
        {},
        (resp) => resp.data
      )
      .pipe(
        tap((item) =>
          this.newFolderCreated$.next({
            node: item,
            parentDirectoryUid,
            refreshDirectories: true,
          })
        )
      );
  }

  exportNodes(
    fileType: any,
    nodes: LightNodeModel[],
    includeNotes?: boolean,
    includeDocumentChronology?: boolean,
    includeSourceAttribution?: boolean,
    includeAnnotatedDocs?: boolean,
    excludeEmptyProvisions?: boolean,
    includeSectionHeaders?: boolean,
    emptyProvisionText?: string
  ) {
    this.fileApiService.export(
      new ExportMultiNodeModel(
        nodes,
        fileType,
        includeNotes,
        includeDocumentChronology,
        includeSourceAttribution,
        includeAnnotatedDocs,
        excludeEmptyProvisions,
        includeSectionHeaders,
        emptyProvisionText,
        undefined
      )
    );
  }

  /**
   * possible values: ALL ,DELETED ,FAVORITES ,OWNED ,SHARED
   */
  private getRootCategory(
    category: "favorite" | "shared" | "owned" | "deleted" | "all"
  ) {
    switch (category) {
      case "favorite":
        return "FAVORITES";
      case "shared":
        return "SHARED";
      case "owned":
        return "OWNED";
      case "deleted":
        return "DELETED";
      case "all":
        return "ALL";
    }
    throw new Error(
      `unrecognized category for finding it's root category: ` + category
    );
  }
}
