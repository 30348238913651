import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { transition, trigger, useAnimation } from "@angular/animations";
import { bounceIn, zoomIn, zoomOut } from "ng-animate";
import { Observable, of, Subscription } from "rxjs";
import {
  debounceTime,
  finalize,
  map,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { Router } from "@angular/router";
import { NodeSearchResultModel } from "@@intelease/web/common/models";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-drive.service";
import { findLastIndex } from "lodash";
import { FormControl } from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { DriveFacade, DriveNodeType } from "@intelease/app-state/drive-v2";
import { Location } from "@angular/common";
import { DriveNodeStatus } from "@intelease/app-state/drive-v2";
import { AdexRouterService } from "@@intelease/web/intelease/utils";
import { SEARCH_BAR_ANIMATION } from "./constants/search-bar-animation.const";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

interface EnrichedNodeSearchResultModel {
  tooltip: string;
  node: NodeSearchResultModel;
}

interface SearchModel {
  isLoading: boolean;
  result: Observable<EnrichedNodeSearchResultModel[]>;
  totalSize: number;
  resultSize: number;
  noResult: boolean;
  failed: boolean;
  scrollCount: number;
}

@Component({
  selector: "il-drive-search",
  templateUrl: "./itls-drive-search.component.html",
  styleUrls: ["./itls-drive-search.component.scss"],
  animations: [
    trigger("fadeIn", [
      transition(
        "void => *",
        useAnimation(zoomIn, {
          params: {
            timing: 0.3,
          },
        })
      ),
      transition(
        "* => void",
        useAnimation(zoomOut, {
          params: {
            timing: 0.2,
          },
        })
      ),
    ]),
    trigger("bounceIn", [
      transition(
        "void => *",
        useAnimation(bounceIn, {
          params: {
            timing: 0.5,
          },
        })
      ),
    ]),
    ...SEARCH_BAR_ANIMATION,
  ],
})
export class ItlsDriveSearchComponent implements OnInit {
  search: SearchModel = {
    isLoading: false,
    noResult: false,
    totalSize: 0,
    failed: false,
    result: of([]),
    resultSize: 0,
    scrollCount: 10 - 2,
  };
  isAdvancedSearchActive: boolean;
  advancedSearchFilterNumber: string;
  @Output()
  searchItemClicked: EventEmitter<NodeSearchResultModel> = new EventEmitter<NodeSearchResultModel>();
  indexOfLastDirectoryNode = undefined;
  allSearchResultDirectoryNodes = false;
  nameQueryCtrl: FormControl = new FormControl();
  category: string;
  isAdvancedSearchOpen = false;

  @Input()
  fromDashboard = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  isShowingSearch = false;

  constructor(
    private router: Router,
    private adexRouter: AdexRouterService,
    private driveService: ItlsDriveService,
    public driveFacade: DriveFacade,
    private location: Location,
    private destroyRef: DestroyRef
  ) {
    this.initAction();
  }

  ngOnInit() {
    this.search.result = this.nameQueryCtrl.valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      switchMap((searchText) => this.getSearchResult(searchText))
    );

    this.setCategory();
  }

  initAction() {
    this.driveFacade.getNumberOfFilters$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((numberOfFilters) => {
        this.advancedSearchFilterNumber = `${numberOfFilters} search filters`;
      });
  }

  setCategory() {
    this.location.onUrlChange((path) => {
      this.category = this.extractCategoryFromPath(path);
    });
    this.category = this.extractCategoryFromPath(location.pathname);
  }

  private extractCategoryFromPath(path: string): "OWNED" | "ALL" | string {
    const category = path.split("/")[2];

    if (!category) {
      return "RECENT";
    }

    return category.toUpperCase();
  }

  changeSearchLoading(isLoading: boolean): void {
    this.search.isLoading = isLoading;
  }

  onSearchNoResults(noResult): void {
    this.search.noResult = noResult;
  }

  getSearchResult(term: string): Observable<EnrichedNodeSearchResultModel[]> {
    if (term) {
      this.changeSearchLoading(true);
      return this.driveService
        .search({
          name: term,
          pageData: {
            page: 1,
            perPage: 6,
          },
          view: "LIGHT",
          sorts: undefined,
        })
        .pipe(
          tap((resp) => {
            const items = resp.items;
            const lastIdx = findLastIndex(
              items,
              (item) => item.type === DriveNodeType.DIRECTORY
            );
            this.indexOfLastDirectoryNode =
              lastIdx === -1 ? undefined : lastIdx;
            this.allSearchResultDirectoryNodes = items.every(
              (item) => item.type === DriveNodeType.DIRECTORY
            );
            this.search.totalSize = resp.pagination.totalResults;
            this.search.resultSize = items.length;
          }),
          map((resp) => resp.items),
          map((items) =>
            items.filter((item) => item.status !== DriveNodeStatus.UI_DELETED)
          ),
          map((items) =>
            items.map((item) => this.createEnrichedNodeSearchResultModel(item))
          ),
          tap((items) => this.onSearchNoResults(items.length === 0)),
          finalize(() => this.changeSearchLoading(false))
        );
    } else {
      this.onSearchNoResults(true);
      return of([]);
    }
  }

  private createEnrichedNodeSearchResultModel(
    node: NodeSearchResultModel
  ): EnrichedNodeSearchResultModel {
    return {
      node,
      tooltip:
        node.navigation.categoryTitle +
        " > " +
        node.navigation.items.map((item) => item.name + " > ").join("") +
        node.name,
    };
  }

  onSearchItemSelect(item: EnrichedNodeSearchResultModel): void {
    const selectedNode = item.node;
    switch (selectedNode.type) {
      case DriveNodeType.DOC_SET:
        if (selectedNode.viewer === "DEFAULT") {
          this.router.navigate([`/documents/${selectedNode.uid}`]);
        } else if (selectedNode.viewer === "GOOGLE_DRIVE_PREVIEW") {
          this.adexRouter.openGoogleDriveFilePreview(
            selectedNode.googleDriveFileId
          );
        }
        break;
      case DriveNodeType.DIRECTORY:
        this.router.navigate([`/drive/folders/${selectedNode.uid}`]);
        break;
    }
    this.nameQueryCtrl.setValue("");
    this.searchItemClicked.emit(selectedNode);
  }

  onSeeAllResultsClicked() {
    this.router.navigate(["/drive/search"], {
      queryParams: {
        name: this.nameQueryCtrl.value,
      },
    });
  }

  toggleSearch() {
    this.isShowingSearch = !this.isShowingSearch;
    this.nameQueryCtrl.reset();
  }
}
