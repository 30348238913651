import {
  FullValMultiPdfProvViewModel,
  OApiReqEditMentionValueDtoModel,
  ProvisionMentionService,
} from "@@intelease/api-models/adex-api-model-src";
import { Location } from "@angular/common";
import { Injectable, OnInit } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { Observable, Observer, of, Subject } from "rxjs";
import { HEADER_ARRAY, DEFAULT_OPTIONS } from "../constants/itls-table.const";
import {
  InputValueModel,
  TableDataModel,
} from "../interfaces/input-value-model";
import { ItlsTableComponent } from "../itls-table.component";
import {
  TableCreateEditModel,
  TableValue,
} from "@@intelease/web/ui/src/lib/itls-table/interfaces/table-create-edit-model";
import { isEqual } from "lodash";
import { filter, share, shareReplay, switchMap, take } from "rxjs/operators";
import { ProvisionReviewUIFacade } from "@@intelease/web/abstract-review/src/lib/store/provision-review.facade";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class ItlsTableService {
  public static setTableWidth: Subject<void> = new Subject<void>();
  headerArray = HEADER_ARRAY;
  currentPath: string;

  private onDismiss = new Subject();
  public onDismiss$ = this.onDismiss.asObservable();

  constructor(
    private _bottomTable: MatBottomSheet,
    private location: Location,
    private provisionMentionService: ProvisionMentionService,
    private readonly provisionReviewUIFacade: ProvisionReviewUIFacade
  ) {
    this.location.onUrlChange((path) => {
      if (path !== this.currentPath) {
        this.dismissTable();
      }
    });

    ItlsTableService.setTableWidth.pipe(takeUntilDestroyed()).subscribe(() => {
      setTimeout(() => {
        const menuWidth = document.querySelector("left-menu")?.clientWidth;
        const table: any = document.querySelector(".itls-table");
        const sidebarWidth: number = document
          .querySelector("il-provision-review-sidebar .content")
          ?.getBoundingClientRect().width;
        table.style.width = `calc(100vw - ${
          (sidebarWidth || 0) + (menuWidth || 0)
        }px)`;
      });
    });
  }

  get instanceMentionUid() {
    return this.instance?.data?.mentionUid;
  }

  public isDataChanged(
    current: TableValue,
    previous: TableValue,
    createModel: boolean = false
  ): boolean {
    if (createModel) {
      return current.headers.some((header) => header);
    }

    return !isEqual(current, previous);
  }

  setTableWidth() {
    setTimeout(
      () => {
        ItlsTableService.setTableWidth.next();
      },
      document.querySelector(".itls-table") ? 450 : 1
    );
  }

  openTable(modalData: InputValueModel, options?: {}): Observable<any> {
    this.setCurrentPath();

    this.setTableWidth();

    const { mentionUid, provisionUid, recordUid, mentionPosition } =
      modalData.serviceData;
    let createDataModel;
    if (modalData.createMentionData) {
      createDataModel = {
        docAbstractUid: modalData.createMentionData.docAbstractUid,
        page: modalData.createMentionData.page,
        notes: modalData.createMentionData.notes,
        sectionHeader: modalData.createMentionData.sectionHeader,
        provisionUid,
        subProvisionsPath: modalData.createMentionData.subProvisionsPath,
        defaultHeadersExists: modalData.createMentionData.defaultHeadersExists,
      } as TableCreateEditModel["createDataModel"];
    }
    const data: TableCreateEditModel = {
      tableValue: {
        headers: this.setHeadersValue(modalData.tableData),
        rows: this.setRowsValue(modalData.tableData),
      },
      createDataModel,
      mentionUid,
      readOnly: modalData.readOnly,
    };
    const _bottomSheet = this._bottomTable.open(ItlsTableComponent, {
      ...options,
      ...DEFAULT_OPTIONS,
      data,
    });
    return _bottomSheet.afterDismissed().pipe(
      take(1),
      filter((response) => !!response),
      switchMap((res: { body: any; isChanged: boolean }) => {
        const { body } = res;
        if (res.isChanged) {
          this.provisionReviewUIFacade.savingProvisionOption(
            provisionUid,
            mentionUid
          );

          if (data.createDataModel) {
            return this.provisionMentionService.manualCreateProvisionMentionV3({
              recordUid,
              body: {
                data: {
                  ...body.data,
                  ...mentionPosition,
                },
              },
            });
          } else {
            return this.provisionMentionService.updateRecordProvisionValueV2({
              mentionUid,
              provisionUid,
              recordUid,
              body,
            });
          }
        }

        return of();
      })
    );
  }

  private setHeadersValue(_inputValue: TableDataModel) {
    if (!_inputValue) {
      return this.headerArray.slice(0, 5);
    }
    const value = {
      rows: _inputValue.rows.map((_row) => {
        return Object.keys(_row).map((_key) => {
          return _row[_key];
        });
      }),
      headers: _inputValue.headers.map((_header) => {
        return _header.name;
      }),
    };
    return this.headerArray.slice(0, value.headers.length + 1);
  }

  private setRowsValue(_inputValue: TableDataModel) {
    let value;
    if (_inputValue) {
      value = {
        rows: _inputValue.rows.map((_row) => {
          return Object.keys(_row).map((_key) => {
            return _row[_key];
          });
        }),
        headers: _inputValue.headers.map((_header) => {
          return _header.name || _header;
        }),
      };
    } else {
      value = {
        rows: [
          ["", "", "", ""],
          ["", "", "", ""],
          ["", "", "", ""],
          ["", "", "", ""],
        ],
        headers: ["", "", "", ""],
      };
    }
    return [value.headers, ...value.rows].map((row, index) => {
      row.splice(0, 0, `${index + 1}`);
      return row;
    });
  }

  private get instance(): ItlsTableComponent {
    return this._bottomTable?._openedBottomSheetRef?.instance;
  }

  dismissTable() {
    if (this.instance) {
      this.instance.onBottomTableDismiss(
        this.instance.tableDetailComponentRef.tableValue
      );
    } else {
      setTimeout(() => {
        this.onDismiss.next(true);
        this.dismiss();
      });
    }
    return this.onDismiss$;
  }

  private dismiss(): void {
    /**
     *  when user try to close the table this codes make table invisable because we don't need the animation of closing the table.
     *  but when user try to navigate to another pages at sidebar or use'go to record' button this function run
     *  and there is no element with #bottom-table-container id so it throw a error and stoke at this line.
     *  the 'if' check if there is a table make it hidden first and then close it.
     **/
    if (document.getElementById("bottom-table-container"))
      document.getElementById("bottom-table-container").classList.add("hidden");
    this._bottomTable.dismiss();
  }

  isTableOpen(): boolean {
    return !!this._bottomTable._openedBottomSheetRef;
  }

  private setCurrentPath() {
    this.currentPath = this.location.path();
  }
}
