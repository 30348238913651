import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, Injector, NgModule } from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoaderFactory } from "@@intelease/web/common/utils";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { InteleaseModule } from "@@intelease/web/intelease/intelease.module";
import {
  AuthInterceptor,
  BaseUrlInterceptor,
  HttpContextRemoverInterceptor,
  HttpHeadersInterceptor,
  NotificationInterceptor,
  TokenInterceptor,
} from "@@intelease/web/intelease/interceptors";
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthGuardService } from "@@intelease/web/intelease/guards";
import { NgScrollbarModule } from "ngx-scrollbar";
import { PagesComponent } from "./pages.component";
import { TaskModalModule } from "@@intelease/web/tasks";
import { FormModalModule } from "@@intelease/web/ui";
import { TableProvisionModalModule } from "@@intelease/web/common/modals";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NgxPermissionsModule } from "ngx-permissions";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { storeFreeze } from "ngrx-store-freeze";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { AppStateCommonModule } from "@@intelease/app-state/common";
import { MatNativeDateModule } from "@angular/material/core";
import { ProvisionBoxModalModule } from "@@intelease/web/abstraction-page/src/lib/modals/provision-box";
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "@@intelease/web/ui/src/lib/angularrx-social-login/public-api";
import { AngularResizedEventModule } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";
import { MultiWindowModule } from "ngx-multi-window";
import { ServiceInjectorHelper } from "@@intelease/web/utils";
import { UcWordsPipe } from "ngx-pipes";
import "hammerjs";
import { GlobalErrorHandler } from "./global-error-handler";
import { matTooltipOtherOptions } from "../../../../libs/app-services/common/src";
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from "@angular/material/tooltip";

const SHARED_MODALS = [
  TaskModalModule,
  TableProvisionModalModule,
  FormModalModule,
];

const MATERIAL_MODULES = [MatSnackBarModule];

@NgModule({
  declarations: [AppComponent, PagesComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    NgxSpinnerModule,
    NgScrollbarModule,
    InteleaseCommonModule,
    InteleaseModule,
    ...SHARED_MODALS,
    ...MATERIAL_MODULES,
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [storeFreeze] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
    AppStateCommonModule,
    MatNativeDateModule,
    ProvisionBoxModalModule,
    SocialLoginModule,
    AngularResizedEventModule,
    MultiWindowModule,
  ],
  providers: [
    AuthGuardService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true,
    },
    // order is IMPORTANT, do not put any other HttpInterceptor after this
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpContextRemoverInterceptor,
      multi: true,
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    UcWordsPipe,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: matTooltipOtherOptions,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private readonly _injector: Injector) {
    ServiceInjectorHelper.instance = _injector;
  }
}
