import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonModalService } from "@common/services";
import { ModalInputModel } from "@intelease/models";
import { finalize } from "rxjs/operators";
import { ModalsResponseTypeEnum } from "@intelease/enums";
import { Router } from "@angular/router";
import { LightNodeModel } from "@common/models";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-drive.service";
import {
  UploadingDocSetBoxService,
  UploadDocSetDataStatus,
  UploadDocSetData,
  UploadDocData,
} from "@@intelease/web/ui/src/lib/itls-new-upload/services/uploading-doc-set-box.service";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import { QueueFacade } from "@@intelease/app-state/queue/src";
import { QUEUE_STATUS } from "@@intelease/app-state/queue/src/lib/models/queue.types";
import {
  DriveFacade,
  Permission,
  User,
} from "@@intelease/app-state/drive-v2/src";

@Component({
  selector: "intelease-uploading-doc-set-box",
  templateUrl: "./uploading-box.component.html",
  styleUrls: ["./uploading-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadingDocSetBoxComponent implements OnDestroy {
  subscription: Subscription = new Subscription();
  isMinimized = false;
  isLoading = true;
  items: UploadDocSetData[] = [];
  UploadDocSetDataStatus = UploadDocSetDataStatus;
  lastIndexToUpload = 0;
  private isUploading = false;
  private isClosingDialog = false;
  private currentUploadingDocSetUid: string;
  private currentSubscription: Subscription;
  private isCancelingUpload = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private modalData: UploadDocSetData,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private commonModalService: CommonModalService,
    private itlsDriveService: ItlsDriveService,
    private uploadingBoxService: UploadingDocSetBoxService,
    private notificationService: MessageService,
    private queueFacade: QueueFacade,
    private driveFacade: DriveFacade
  ) {
    this.items.push(this.modalData);
    this.subscription.add(
      this.uploadingBoxService.$onNewUpload.subscribe((newUploadData) => {
        this.items.push(newUploadData);
        this.uploadNextRecord();
        this.cdr.detectChanges();
      })
    );
    this.uploadNextRecord();
  }

  @HostListener("window:beforeunload", ["$event"])
  doSomething($event) {
    if (this.isUploading) {
      $event.returnValue =
        "Some docSets are still uploading. This action may cancel these uploads.";
    }
  }

  private uploadNextRecord() {
    if (this.isUploading || this.isClosingDialog) {
      return;
    }

    // check to see if we have any upload in QUEUED
    if (this.lastIndexToUpload !== this.items.length) {
      // start uploading docSets 1 by 1 (change this later so we can upload X docSets at the same time)
      const item = this.items[this.lastIndexToUpload];
      let uploadDocData: UploadDocData;
      if (item.isFolder) {
        uploadDocData = item.docSets.find(
          (docSet) => docSet.status === UploadDocSetDataStatus.UPLOADING
        );
        if (!uploadDocData) {
          console.log(
            `ERROR: why we couldn't find a docSet with UPLOADING status!\n\t` +
              JSON.stringify(item)
          );
          this.lastIndexToUpload += 1;
          return this.uploadNextRecord();
        }
      } else {
        uploadDocData = item.docSet;
      }
      uploadDocData.queueUid = item.queueUid;
      // the docSet is cancelled (it shouldn't be in other states), continue to next item in the queue
      if (uploadDocData.status !== UploadDocSetDataStatus.UPLOADING) {
        this.lastIndexToUpload += 1;
        return this.uploadNextRecord();
      }

      this.isUploading = true;
      this.currentUploadingDocSetUid = uploadDocData.uid;
      this.currentSubscription = this.uploadingBoxService
        .uploadDocSetFiles(
          uploadDocData.uid,
          uploadDocData.files,
          uploadDocData.queueUid
        )
        .pipe(
          finalize(() => {
            this.isUploading = false;
            this.currentUploadingDocSetUid = undefined;
            this.currentSubscription = undefined;
            if (this.isCancelingUpload) {
              this.isCancelingUpload = false;
            } else {
              this.uploadNextRecord();
            }
            this.cdr.detectChanges();
          })
        )
        .subscribe(
          (resp) => {
            if (item.isFolder) {
              uploadDocData.parentFolderUid = item.parentFolderUid;
            }
            this.updateStatus(
              uploadDocData.uid,
              item,
              UploadDocSetDataStatus.COMPLETED
            );

            if (
              resp.queueSelected &&
              resp.queueIngestionResult?.ingestionFailed
            ) {
              this.notificationService.error(
                resp.queueIngestionResult.ingestionFailureReason,
                {
                  Style: "flip",
                  Duration: 3000,
                }
              );
            }
            const queueIngestionResult = resp.queueIngestionResult;
            this.driveFacade.addDocSetPartial({
              docSetName: resp.name,
              docSetUid: resp.uid,
              directoryUid: resp.directoryUid,
              documentCount: resp.documentCount,
              permissions: resp.permissions as Permission[],
              recordUid: queueIngestionResult?.recordUid,
              queueName: queueIngestionResult?.queueName,
              queueUid: queueIngestionResult?.queueUid,
              queueStage: queueIngestionResult?.queueUid
                ? queueIngestionResult?.queueStage || QUEUE_STATUS.PROCESSING
                : undefined,
              owner: resp.owner as User,
            });

            //only valid if the queue was selected while uploading
            if (queueIngestionResult) {
              this.queueFacade.addRecordToQueueTabs({
                recordName: queueIngestionResult.recordName,
                recordUid: queueIngestionResult.recordUid,
                queueUid: queueIngestionResult.queueUid,
                queueStatus: QUEUE_STATUS.PROCESSING,
              });
            }
          },
          (error) => {
            this.updateStatus(
              uploadDocData.uid,
              item,
              UploadDocSetDataStatus.CANCELED
            );
          }
        );
      this.subscription.add(this.currentSubscription);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onToggleMinimized() {
    this.isMinimized = !this.isMinimized;
    this.cdr.detectChanges();
  }

  onCloseClicked() {
    // do not close the dialog if we have a docSet in UPLOADING state
    if (
      this.items.every((item) =>
        item.isFolder
          ? item.folderStatus !== UploadDocSetDataStatus.UPLOADING
          : item.docSet.status !== UploadDocSetDataStatus.UPLOADING
      )
    ) {
      this.closeDialog();
    } else {
      const modalData = new ModalInputModel();
      modalData.payload = {
        customMessage: false,
        message:
          "Some docSets are still uploading, do you want to cancel them?",
        title: "Cancel Record Uploading",
      };
      // confirm with User to stop docSet(s) in UPLOADING state
      this.commonModalService
        .openGenericOkCancelModal(modalData)
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed?.type === ModalsResponseTypeEnum.CLOSE) {
            this.closeDialog();
          }
        });
    }
  }

  private closeDialog() {
    this.isClosingDialog = true;
    this.uploadingBoxService.close();
  }

  private updateStatus(
    docSetUid: string,
    item: UploadDocSetData,
    newStatus: UploadDocSetDataStatus,
    shouldContinueToNextTimeInTheQueue = true
  ) {
    if (item.isFolder) {
      const foundedRecord = item.docSets.find(
        (docSet) => docSet.uid === docSetUid
      );
      foundedRecord.status = newStatus;
      if (
        !item.docSets.every(
          (docSet) => docSet.status !== UploadDocSetDataStatus.UPLOADING
        )
      ) {
        // if we still have at-least 1 docSet in UPLOADING
        item.folderStatus = UploadDocSetDataStatus.UPLOADING;
      } else if (
        item.docSets.every(
          (docSet) => docSet.status === UploadDocSetDataStatus.CANCELED
        )
      ) {
        // if all docSets are canceled
        item.folderStatus = UploadDocSetDataStatus.CANCELED;
        this.lastIndexToUpload += 1;
      } else if (
        item.docSets.every(
          (docSet) => docSet.status === UploadDocSetDataStatus.COMPLETED
        )
      ) {
        // if all docSets are canceled
        item.folderStatus = UploadDocSetDataStatus.COMPLETED;
        if (shouldContinueToNextTimeInTheQueue) {
          this.lastIndexToUpload += 1;
        }
      } else {
        // if at-least 1 docSet is completed
        item.folderStatus = UploadDocSetDataStatus.PARTIALLY_COMPLETED;
        if (shouldContinueToNextTimeInTheQueue) {
          this.lastIndexToUpload += 1;
        }
      }
    } else {
      const docSet = item.docSet;
      docSet.status = newStatus;
      if (shouldContinueToNextTimeInTheQueue) {
        this.lastIndexToUpload += 1;
      }
    }
  }

  onUploadedDataChildRecordClicked(docSet: UploadDocData) {
    if (docSet.status === UploadDocSetDataStatus.COMPLETED) {
      const url = `/documents/${docSet.uid}`;
      this.router.navigateByUrl(url);
    }
  }

  onUploadedDataClicked(item: UploadDocSetData) {
    const isCompleted = item.isFolder
      ? item.folderStatus === UploadDocSetDataStatus.COMPLETED ||
        item.folderStatus === UploadDocSetDataStatus.PARTIALLY_COMPLETED
      : item.docSet.status === UploadDocSetDataStatus.COMPLETED;
    if (isCompleted) {
      if (item.isFolder) {
        const targetNode: LightNodeModel = {
          uid: item.folderUid,
          type: "DIRECTORY",
        };
        this.navigateToDrivePanel(item.parentFolderUid, targetNode);
      } else {
        const url = `/documents/${item.docSet.uid}`;
        this.router.navigateByUrl(url);
      }
    }
  }

  private navigateToDrivePanel(
    parentFolderUid: string,
    targetNode: LightNodeModel
  ) {
    this.subscription.add(
      this.itlsDriveService
        .getOffsetInSearchResult(parentFolderUid, targetNode)
        .subscribe((resp) => {
          const queryParams = {
            targetNodeOffset: resp.offset,
          };
          if (parentFolderUid) {
            this.router.navigate(["/drive/folders/" + parentFolderUid], {
              queryParams,
            });
          } else {
            this.router.navigate(["/drive/owned"], {
              queryParams,
            });
          }
        })
    );
  }

  onCancelUpload(item: UploadDocSetData, docSets: UploadDocData[]) {
    // confirm cancellation
    const modalData = new ModalInputModel();
    modalData.payload = {
      isHtmlMessage: true,
      message:
        "Are you sure you want to cancel this upload?<br>" +
        "<br>" +
        "  Note: The docSet(s) may still show up as Uploading status for a few minutes.",
      title: "Cancel Record(s) Uploading",
    };
    this.commonModalService
      .openGenericOkCancelModal(modalData, {})
      .afterClosed()
      .subscribe((result) => {
        if (result?.type === ModalsResponseTypeEnum.CLOSE) {
          // User could have been wait a long time and some of the selected docSets might not be with UPLOADING status
          const stillUploadingRecords = docSets.filter(
            (docSet) => docSet.status === UploadDocSetDataStatus.UPLOADING
          );
          if (stillUploadingRecords.length > 0) {
            console.error("NOT_IMPLEMENTED!");
            //this.cancelUpload(item, stillUploadingRecords)
          }
        }
      });
  }

  cancelUpload(item: UploadDocSetData, docSets: UploadDocData[]) {
    // check and possibly cancel current upload
    let isCancellingCurrentUpload;
    let timeoutToCallCancelApi;
    if (
      !docSets.every((docSet) => docSet.uid !== this.currentUploadingDocSetUid)
    ) {
      timeoutToCallCancelApi = 100;
      isCancellingCurrentUpload = true;
      this.isCancelingUpload = true;
      this.currentSubscription.unsubscribe();
    } else {
      timeoutToCallCancelApi = 0;
      isCancellingCurrentUpload = false;
    }

    // use timeout to avoid concurrency issue (we don't want to call "this.uploadNextRecord()" at the same time)
    setTimeout(() => {
      this.subscription.add(
        this.uploadingBoxService
          .cancelUploadingLeaseFiles(docSets.map((docSet) => docSet.uid))
          .pipe(
            finalize(() => {
              this.uploadNextRecord();
              this.cdr.detectChanges();
            })
          )
          .subscribe((resp) => {
            docSets.forEach((docSet) => {
              if (resp?.recordUids?.includes(docSet.uid)) {
                this.updateStatus(
                  docSet.uid,
                  item,
                  UploadDocSetDataStatus.CANCELED,
                  isCancellingCurrentUpload
                );
              }
            });
          })
      );
    }, timeoutToCallCancelApi);
  }
}
