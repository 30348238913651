import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import {
  MergeDocSetModalComponent,
  MergeDocSetModalInputData,
} from "../merge-doc-set-modal/merge-doc-set-modal.component";

@Injectable()
export class MergeDocSetModalService {
  constructor(private dialog: MatDialog) {}

  openMergeDocSetsModal(docSetIds: string[]): Observable<any> {
    const dialogRef = this.dialog.open<
      MergeDocSetModalComponent,
      MergeDocSetModalInputData,
      any
    >(MergeDocSetModalComponent, {
      width: "400px",
      height: "600px",
      panelClass: "no-padding-modal-panel",
      data: {
        docSetIds,
      },
    });
    return dialogRef.afterClosed();
  }
}
