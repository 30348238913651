import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { fetch, pessimisticUpdate } from "@ngrx/router-store/data-persistence";
import { DatePipe } from "@angular/common";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { cloneDeep, keyBy, floor } from "lodash";

import { RecordsPartialState } from "./drive.reducer";
import {
  RecordsActionTypes,
  UpdateRecordStatus,
  UpdateRecordStatusSucceeded,
  UpdateRecordStatusFailed,
  UpdateRecord,
  UpdateRecordSucceeded,
  UpdateRecordFailed,
  LoadAllRecords,
  LoadAllRecordsSucceeded,
  LoadAllRecordsFailed,
  AddRecord,
  AddRecordSucceeded,
  AddRecordFailed,
  AddDirectory,
  AddDirectorySucceeded,
  AddDirectoryFailed,
  LoadAdvancedSearchFields,
  AdvancedSearchFieldsLoaded,
  AdvancedSearchFieldsError,
  LoadAdvancedSearchRecords,
  AdvancedSearchRecordsError,
  AdvancedSearchRecordsLoaded,
  ChangePage,
  ChangePageSucceeded,
  ChangePageError,
  SortsRecords,
  SortsRecordsError,
  SortsRecordsSucceeded,
  RenameRecordOrDirectorySucceeded,
  RenameRecordOrDirectoryError,
  RenameRecordOrDirectory,
  UpdateCustomTags,
  UpdateCustomTagsSucceeded,
  UpdateCustomTagsFailed,
} from "./drive.actions";

import {
  DriveService,
  FolderStructureNodeDtoModel,
  OApiListWithNavigationAndPaginationResultDocSetNodeDtoModel,
  OApiReqRenameNodeDtoModel,
  OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel,
  OApiRespSearchFieldsDtoModel,
  RecordService,
  ReportService,
} from "@@intelease/api-models/adex-api-model-src";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { ReportsService } from "@@intelease/app-services/reports/src";
import { InteleaseCommonService } from "@@intelease/app-services/common/src";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";
import { RecordNodeDtoModel } from "../models/record-node-dto-model";
import { Permission, RecordStatus, RecordType } from "../models/record.types";
import { LocalStorageKey } from "@@intelease/web/common/enums/local-storage.keys";
import { Router } from "@angular/router";
import { OwnerModel } from "@@intelease/web/common/models";
import { DriveNodeType } from "@intelease/app-state/drive-v2";

@Injectable()
export class RecordsEffects {
  updateRecordStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.UpdateRecordStatus),
      pessimisticUpdate({
        run: (action: UpdateRecordStatus, state: RecordsPartialState) => {
          const { payload } = action;
          return new UpdateRecordStatusSucceeded(payload);
        },
        onError(action: UpdateRecordStatus, error: any) {
          return new UpdateRecordStatusFailed(error);
        },
      })
    )
  );

  updateRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.UpdateRecord),
      pessimisticUpdate({
        run: (action: UpdateRecord, state: RecordsPartialState) => {
          const { recordUid } = action.payload;
          // this.recordService.getRecord({})
        },
        onError(action: UpdateRecord, error) {
          return new UpdateRecordFailed(error);
        },
      })
    )
  );

  addRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.AddRecord),
      pessimisticUpdate({
        run: (action: AddRecord, state: RecordsPartialState) => {
          const { category, data, recordUid, directoryUid } = action.payload;
          const name = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_FULLNAME)
          );
          const email = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_EMAIL)
          );
          const userUid = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_UID)
          );
          const currentDate = this.convertDateToTimeZone(
            "America/Los_Angeles",
            new Date(),
            -7
          );
          const createdAt = data?.createdAt
            ? new Date(
                (data?.createdAt.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.createdAt}`)
              )
            : currentDate;
          const lastModifiedDate = data?.lastModifiedDate
            ? new Date(
                (data?.lastModifiedDate.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.lastModifiedDate}`)
              )
            : currentDate;
          return new AddRecordSucceeded({
            category: category,
            directoryUid: directoryUid,
            data: Json2TypescriptHelper.convertToEntity(
              {
                createdAt,
                lastModifiedDate,
                name: data?.name,
                owner: {
                  name: data?.owner?.name || name,
                  email: data?.owner?.email || email,
                  uid: data?.owner?.uid || userUid,
                },
                permissions: data?.permissions || [
                  Permission.READ,
                  Permission.MOVE,
                  Permission.COMMENT,
                  Permission.EXPORT,
                  Permission.DELETE,
                  Permission.EDIT,
                  Permission.SHARE,
                ],
                shared: data?.shared || false,
                type: data?.type || RecordType.RECORD,
                uid: recordUid,
              },
              RecordNodeDtoModel
            ),
          });
        },
        onError(action: AddRecord, error) {
          return new AddRecordFailed(error);
        },
      })
    )
  );

  addDirectory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.AddDirectory),
      pessimisticUpdate({
        run: (action: AddDirectory, state: RecordsPartialState) => {
          const { category, data, directoryUid } = action.payload;
          const dateNow = new Date(
            (new Date().getTimezoneOffset() - 420) * 60000 + Date.now()
          );
          const name = cloneDeep(localStorage.getItem(LocalStorageKey.NAME));
          const email = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_EMAIL)
          );
          const userUid = cloneDeep(
            localStorage.getItem(LocalStorageKey.USER_UID)
          );
          const currentDate = `${this.datePipe.transform(
            dateNow,
            "YYYY-MM-ddThh:mm:ss.s-07:00"
          )}[America/Los_Angeles]`;
          const createdAt = data?.createdAt
            ? new Date(
                (data?.createdAt.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.createdAt}`)
              )
            : currentDate;
          const lastModifiedDate = data?.lastModifiedDate
            ? new Date(
                (data?.lastModifiedDate.getTimezoneOffset() - 420) * 60000 +
                  Date.parse(`${data?.lastModifiedDate}`)
              )
            : currentDate;

          const payload = {
            category: category,
            directoryUid: directoryUid,
            data: Json2TypescriptHelper.convertToEntity(
              {
                createdAt,
                lastModifiedDate,
                name: data?.name,
                owner: {
                  name: data?.owner?.name || name,
                  email: data?.owner?.email || email,
                  uid: data?.owner?.uid || userUid,
                },
                permissions: data?.permissions || [
                  "READ",
                  "MOVE",
                  "COMMENT",
                  "EXPORT",
                  "DELETE",
                  "EDIT",
                  "SHARE",
                ],
                shared: data?.shared || false,
                type: data?.type || DriveNodeType.DIRECTORY,
                uid: data?.uid,
              },
              FolderStructureNodeDtoModel
            ),
          };

          this.itlsDriveService.newFolderCreated$.next({
            node: {
              createdAt: null as any,
              lastModifiedDate: payload.data.lastModifiedDate as Date,
              name: payload.data.name as string,
              owner: payload.data.owner as OwnerModel,
              permissions: payload.data.permissions as (
                | "READ"
                | "EDIT"
                | "DELETE"
                | "EXPORT"
                | "MOVE"
                | "COMMENT"
                | "SHARE"
              )[],
              shared: false,
              size: 0,
              type: DriveNodeType.DIRECTORY,
              uid: payload.data.uid as string,
              status: "",
            },
            parentDirectoryUid: directoryUid as string,
            refreshDirectories: false,
          });

          return new AddDirectorySucceeded(payload);
        },
        onError(action: AddDirectory, error) {
          return new AddDirectoryFailed(error);
        },
      })
    )
  );

  loadAdvancedSearchFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.LoadAdvancedSearchFields),
      fetch({
        run: (
          action: LoadAdvancedSearchFields,
          state?: RecordsPartialState
        ) => {
          return this.driveService.getSearchFields().pipe(
            map((_res) => {
              const res: any /* todo: fix type */ = cloneDeep(_res);
              const operatorsTitleMap =
                this.inteleaseCommonService.getOperatorsTitleMap();
              const aggregationsTitleMap =
                this.reportsService.getAggregationsTitleMap();
              const { columnFields, filterFields, fieldTypeToRelations } =
                res.data;
              for (const key in fieldTypeToRelations) {
                if (fieldTypeToRelations[key]) {
                  fieldTypeToRelations[key] = fieldTypeToRelations[key].map(
                    (
                      item:
                        | "FIELD_NEQ"
                        | "FIELD_EQ"
                        | "FIELD_NOT_CONTAIN"
                        | "FIELD_CONTAIN"
                        | "FIELD_NOT_EXISTS"
                        | "FIELD_EXISTS"
                        | "NOT_EXISTS"
                        | "EXISTS"
                        | "IN"
                        | "GEQ"
                        | "LEQ"
                        | "GT"
                        | "LT"
                        | "NOT_CONTAIN"
                        | "CONTAIN"
                        | "NEQ"
                        | "EQ"
                    ) => ({
                      label: operatorsTitleMap[item] || item,
                      value: item,
                    })
                  );
                }
              }
              const _generalFields = filterFields.map(
                (item: {
                  allowedTypes: any;
                  fieldType:
                    | "STRING"
                    | "DATE"
                    | "UID"
                    | "SINGLE_CAT"
                    | "MULTIPLE_CAT"
                    | "PHONE"
                    | "EMAIL"
                    | "ADDRESS"
                    | "YEAR"
                    | "LOCAL_DATE"
                    | "PROPER_NOUN"
                    | "COMMON_NOUN"
                    | "TABLE"
                    | "MONEY"
                    | "DOUBLE"
                    | "NUMBER";
                }) => ({
                  ...item,
                  docSetCategories: [
                    ReportsService.GENERAL_DOC_SET_CATEGORY__VALUE,
                  ],
                  allowedTypes:
                    item.allowedTypes && item.allowedTypes.length
                      ? item.allowedTypes.map(
                          (
                            allowedType:
                              | "STRING"
                              | "DATE"
                              | "UID"
                              | "SINGLE_CAT"
                              | "MULTIPLE_CAT"
                              | "PHONE"
                              | "EMAIL"
                              | "ADDRESS"
                              | "YEAR"
                              | "LOCAL_DATE"
                              | "PROPER_NOUN"
                              | "COMMON_NOUN"
                              | "TABLE"
                              | "MONEY"
                              | "DOUBLE"
                              | "NUMBER"
                          ) => ({
                            label: PROVISIONS_DATA_CONST[allowedType].uiName,
                            value: allowedType,
                          })
                        )
                      : [
                          {
                            label: PROVISIONS_DATA_CONST[item.fieldType].uiName,
                            value: item.fieldType,
                          },
                        ],
                })
              );
              const _columnFields = columnFields.map(
                (item: {
                  allowedTypes: any;
                  fieldType:
                    | "STRING"
                    | "DATE"
                    | "UID"
                    | "SINGLE_CAT"
                    | "MULTIPLE_CAT"
                    | "PHONE"
                    | "EMAIL"
                    | "ADDRESS"
                    | "YEAR"
                    | "LOCAL_DATE"
                    | "PROPER_NOUN"
                    | "COMMON_NOUN"
                    | "TABLE"
                    | "MONEY"
                    | "DOUBLE"
                    | "NUMBER";
                }) => ({
                  ...item,
                  docSetCategories: [
                    ReportsService.GENERAL_DOC_SET_CATEGORY__VALUE,
                  ],
                  allowedTypes:
                    item.allowedTypes && item.allowedTypes.length
                      ? item.allowedTypes.map(
                          (
                            allowedType:
                              | "STRING"
                              | "DATE"
                              | "UID"
                              | "SINGLE_CAT"
                              | "MULTIPLE_CAT"
                              | "PHONE"
                              | "EMAIL"
                              | "ADDRESS"
                              | "YEAR"
                              | "LOCAL_DATE"
                              | "PROPER_NOUN"
                              | "COMMON_NOUN"
                              | "TABLE"
                              | "MONEY"
                              | "DOUBLE"
                              | "NUMBER"
                          ) => ({
                            label: PROVISIONS_DATA_CONST[allowedType].uiName,
                            value: allowedType,
                          })
                        )
                      : [
                          {
                            label: PROVISIONS_DATA_CONST[item.fieldType].uiName,
                            value: item.fieldType,
                          },
                        ],
                })
              );
              const payload = {
                columnFields: _columnFields,
                filterFields: keyBy(_generalFields, "fieldName"),
                fieldTypeToRelations,
              };
              return new AdvancedSearchFieldsLoaded(payload);
            })
          );
        },
        onError: (action: LoadAdvancedSearchFields, error) => {
          return new AdvancedSearchFieldsError(error);
        },
      })
    )
  );

  loadAdvancedSearchRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.LoadAdvancedSearchRecords),
      switchMap((action: LoadAdvancedSearchRecords) =>
        this.itlsDriveService.getRecordsAdvancedSearch(action.payload).pipe(
          map((res) => {
            const data: any = Json2TypescriptHelper.convertToEntity(
              res.data,
              OApiListWithNavigationAndPaginationResultDocSetNodeDtoModel
            );
            const _data = {
              ...data,
              items: data.items.map((item: RecordNodeDtoModel) => {
                return {
                  ...item,
                  createdAt: item.creationDate,
                };
              }),
            };
            const _items = [];
            for (
              let counter = 0;
              counter < floor(_data?.pagination?.totalResults / 20) + 1;
              counter++
            ) {
              _items.push(_data?.items.slice(counter * 20, (counter + 1) * 20));
            }
            return new AdvancedSearchRecordsLoaded({
              ..._data,
              items: _items,
              numberOfFilters: action.payload.filters.value.length,
            });
          }),
          catchError(() => of(new AdvancedSearchRecordsError()))
        )
      )
    )
  );

  changePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.ChangePage),
      pessimisticUpdate({
        run: (action: ChangePage, state: RecordsPartialState) => {
          return new ChangePageSucceeded(action.payload);
        },
        onError: (action: ChangePage, error) => {
          return new ChangePageError(error);
        },
      })
    )
  );

  loadAllRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.LoadAllRecords),
      switchMap((action: LoadAllRecords) => {
        const { directoryUid, category } = action.payload;
        if (category) {
          return this.driveService.getRootDirectories(action.payload).pipe(
            map(
              (res) =>
                new LoadAllRecordsSucceeded(
                  Json2TypescriptHelper.convertToEntity(
                    res,
                    OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
                  ).data
                )
            ),
            catchError((err) => {
              this.router.navigateByUrl("/dashboard");
              return of(new LoadAllRecordsFailed(err));
            })
          );
        } else {
          return this.driveService
            .getChildren({
              ...action.payload,
              directoryUid: directoryUid as string,
            })
            .pipe(
              map(
                (res) =>
                  new LoadAllRecordsSucceeded(
                    Json2TypescriptHelper.convertToEntity(
                      res,
                      OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
                    ).data
                  )
              ),
              catchError((err) => {
                this.router.navigateByUrl("/dashboards");
                return of(new LoadAllRecordsFailed(err));
              })
            );
        }
      })
    )
  );

  sortsRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.SortsRecords),
      switchMap((action: SortsRecords) => {
        const { category, directoryUid, sorts, page, perPage } = action.payload;
        if (category) {
          return this.driveService
            .getRootDirectories({
              category,
              page,
              perPage,
              sorts,
            })
            .pipe(
              map((res) => {
                return new SortsRecordsSucceeded({
                  items: Json2TypescriptHelper.convertToEntity(
                    res,
                    OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
                  ).data?.items,
                });
              }),
              catchError(() => of(new SortsRecordsError()))
            );
        } else {
          return this.driveService
            .getChildren({
              directoryUid: directoryUid as string,
              page,
              perPage,
              sorts,
            })
            .pipe(
              map((res) => {
                return new SortsRecordsSucceeded({
                  items: Json2TypescriptHelper.convertToEntity(
                    res,
                    OApiRespListWithNavigationAndPaginationResultDocSetNodeDtoModel
                  ).data?.items,
                });
              }),
              catchError(() => of(new SortsRecordsError()))
            );
        }
      })
    )
  );

  renameRecordOrDirectory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.RenameRecordOrDirectory),
      fetch({
        run: (action: RenameRecordOrDirectory) => {
          const {
            newName,
            row: { type, uid, name },
          } = action.payload;
          return this.itlsDriveService
            .rename(uid, { name: newName, type })
            .pipe(
              map((resp) => new RenameRecordOrDirectorySucceeded(resp)),
              catchError(() =>
                of(
                  new RenameRecordOrDirectoryError({
                    oldName: name,
                    uid,
                  })
                )
              )
            );
        },
      })
    )
  );

  updateCustomTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecordsActionTypes.UpdateCustomTags),
      fetch({
        run: (action: UpdateCustomTags) => {
          return this.recordService.editRecordCustomTags(action.payload).pipe(
            map(
              (res) =>
                new UpdateCustomTagsSucceeded({
                  recordUid: action.payload.recordUid,
                  body: {
                    data: {
                      customTags: res.data?.customTags,
                    },
                  },
                })
            )
          );
        },
        onError: () => {
          return new UpdateCustomTagsFailed();
        },
      })
    )
  );

  convertDateToTimeZone = (city: string, date: Date, offset: number) => {
    const utc = date.getTime() + date.getTimezoneOffset() * 60000;
    const nd = new Date(utc + 3600000 * offset);
    return `${this.datePipe.transform(
      nd,
      `YYYY-MM-ddThh:mm:ss.s${Math.sign(offset) === -1 ? "-" : "+"}${
        Math.abs(offset) < 10 ? `0${Math.abs(offset)}` : Math.abs(offset)
      }:00`
    )}[${city}]`;
  };

  constructor(
    private actions$: Actions,
    private driveService: DriveService,
    private datePipe: DatePipe,
    private reportService: ReportService,
    private reportsService: ReportsService,
    private inteleaseCommonService: InteleaseCommonService,
    private itlsDriveService: ItlsDriveService,
    private router: Router,
    private recordService: RecordService
  ) {}
}
