import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";
import {
  RECORD_ACTION__ASSIGN,
  RECORD_ACTION__EDIT,
  RECORD_ACTION__EXPORT,
  RECORD_ACTION__FULL_EXPORT,
  RECORD_ACTION__HISTORY_EXPORT,
  RECORD_ACTION__MOVE,
  RECORD_ACTION__OPEN_IN_NEW_TAB,
  RECORD_ACTION__RENAME,
} from "@@intelease/web/ui/src/lib/itls-drive/constants/record-action-buttons.const";

export const FOLDER_ACTION__RENAME = {
  title: "Rename",
  actionKey: "RENAME",
  icon: "icon-rename",
  children: [],
};
export const FOLDER_ACTION__SHARE = {
  title: "Share",
  actionKey: "SHARE",
  icon: "icon-share",
  children: [],
};
export const FOLDER_ACTION__MOVE = {
  title: "Move",
  actionKey: "MOVE",
  icon: "icon-move",
  children: [],
};
export const FOLDER_ACTION__ADD_TO_QUEUE = {
  title: "Add to Queue",
  actionKey: "ADD_TO_QUEUE",
  icon: "icon-move",
  children: [],
};
export const FOLDER_ACTION__DOWNLOAD = {
  title: "Download",
  actionKey: "DOWNLOAD",
  icon: "icon-download",
};
export const FOLDER_ACTION__DELETE = {
  title: "Delete",
  actionKey: "DELETE",
  icon: "icon-remove",
  children: [],
};

export const FOLDER_ACTION_BUTTONS: ActionButtonInterface[] = [
  FOLDER_ACTION__SHARE,
  FOLDER_ACTION__MOVE,
  FOLDER_ACTION__ADD_TO_QUEUE,
  FOLDER_ACTION__DOWNLOAD,
  FOLDER_ACTION__RENAME,
  FOLDER_ACTION__DELETE,
];

export const BATCH_FOLDER_ACTION_BUTTONS: ActionButtonInterface[] = [
  FOLDER_ACTION__SHARE,
  FOLDER_ACTION__MOVE,
  FOLDER_ACTION__ADD_TO_QUEUE,
  FOLDER_ACTION__DOWNLOAD,
  FOLDER_ACTION__DELETE,
];

export const FOLDER_ACTION_BUTTONS_KEYS: string[] = FOLDER_ACTION_BUTTONS.map(
  (actionButton) => actionButton.actionKey
);

export const BATCH_FOLDER_ACTION_BUTTONS_KEYS: string[] =
  BATCH_FOLDER_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);

export const EXPORT_ACTION_BUTTONS: ActionButtonInterface[] = [
  RECORD_ACTION__FULL_EXPORT,
  RECORD_ACTION__EXPORT,
  RECORD_ACTION__HISTORY_EXPORT,
];

export const EXPORT_ACTION_BUTTONS_KEYS: string[] = EXPORT_ACTION_BUTTONS.map(
  (actionButton) => actionButton.actionKey
);

export const EDIT_ACTION_BUTTONS: ActionButtonInterface[] = [
  RECORD_ACTION__RENAME,
  RECORD_ACTION__ASSIGN,
  RECORD_ACTION__MOVE,
];

export const EDIT_ACTION_BUTTONS_KEYS: string[] = EDIT_ACTION_BUTTONS.map(
  (actionButton) => actionButton.actionKey
);

export const READ_ACTION_BUTTONS: ActionButtonInterface[] = [
  RECORD_ACTION__OPEN_IN_NEW_TAB,
  RECORD_ACTION__EDIT,
];

export const READ_ACTION_BUTTONS_KEYS: string[] = READ_ACTION_BUTTONS.map(
  (actionButton) => actionButton.actionKey
);
