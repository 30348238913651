import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  Injector,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { isEqual } from "lodash";
import {
  OApiReqEditMentionValueDtoModel,
  OApiReqSaveManualMentionV2DtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import {
  TableCreateEditModel,
  TableValue,
} from "@@intelease/web/ui/src/lib/itls-table/interfaces/table-create-edit-model";
import { ProvisionTypeEnum } from "@@intelease/app-models/provision";
import { fromEvent, Observable, Subject } from "rxjs";
import { map, take, takeUntil, throttleTime } from "rxjs/operators";
import { CommonModalService } from "@@intelease/web/common/services";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-table",
  templateUrl: "./itls-table.component.html",
  styleUrls: ["./itls-table.component.scss"],
})
export class ItlsTableComponent implements OnInit, AfterViewInit {
  private readonly TABLE_MAX_SIZE_IN_PERCENT = 0.8;
  private readonly MIN_HEIGHT_OF_TABLE_IN_PX = 15;
  tableHeightInit = 200;
  tableDetail: TableValue;
  isUnfold = true;
  prevY: any;
  newY: any;
  oldTable: TableValue;
  renderCounter = 0;
  tableElement: any;
  readOnly: boolean;
  isDismissed = false;

  @ViewChild("resizable")
  resizable: ElementRef;

  @ViewChild("fullBody")
  body: ElementRef;

  @ViewChild("tableDetailComponent", { read: ElementRef })
  tableDetailComponent: ElementRef;

  @ViewChild("tableDetailComponent")
  tableDetailComponentRef;

  private tableDismiss = new Subject<void>();

  destroyRef = inject(DestroyRef);

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: TableCreateEditModel,
    private _bottomSheet: MatBottomSheetRef,
    private matBottomSheetRef: MatBottomSheetRef,
    private readonly renderer: Renderer2,
    private readonly ngZone: NgZone,
    private readonly injector: Injector,
    private readonly commonModalService: CommonModalService
  ) {}

  private get fullBodyElement(): HTMLElement {
    return this.body.nativeElement;
  }

  ngOnInit(): void {
    if (this.data) {
      this.readOnly = this.data.readOnly;
      this.tableDetail = {
        headers: this.data.tableValue.headers,
        rows: this.data.tableValue.rows,
      };
      this.oldTable = JSON.parse(JSON.stringify(this.data.tableValue));
    }

    this.tableDismiss
      .asObservable()
      .pipe(throttleTime(1000))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.onBottomTableDismiss(
          this.tableDetailComponentRef.tableValue,
          true
        );
      });

    this.matBottomSheetRef
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => (this.isDismissed = true));
  }

  ngAfterViewInit(): void {
    this.initTableHeight();
  }

  private initTableHeight() {
    this.tableElement =
      this.tableDetailComponent.nativeElement.querySelector("table");
    // setTimeout(() => {
    if (this.tableElement.clientHeight < 300) {
      this.resizable.nativeElement.style.height = `${this.tableElement.clientHeight}px`;
    } else {
      this.resizable.nativeElement.style.height = `${this.tableHeightInit}px`;
    }
    // }, 20)
  }

  private revertTableSize() {
    this.resizable.nativeElement.style.height = `${this.tableHeightInit}px`;
  }

  public openNoHeadersConfirmModal(): Observable<boolean> {
    const modalData = new ModalInputModel();
    modalData.payload = {
      message: `You didn't add any headers. Are you sure you want to save the changes?`,
      title: "Unsaved Changes",
      confirmButtonTitle: "Ok",
      cancelButtonTitle: "Cancel",
    };
    return this.commonModalService
      .openGenericOkCancelModal(modalData)
      .afterClosed()
      .pipe(
        take(1),
        map((res) => res?.data?.exitType === ModalsResponseTypeEnum.CLOSE)
      ) as Observable<boolean>;
  }

  private prepareDataForCreateManualMention(
    currentValue: TableValue,
    isChanged: boolean
  ): void {
    const body: OApiReqSaveManualMentionV2DtoModel = {
      data: {
        docAbstractUid: this.data.createDataModel.docAbstractUid,
        notes: this.data.createDataModel.notes,
        page: this.data.createDataModel.page,
        provisionUid: this.data.createDataModel.provisionUid,
        provisionValue: {
          headers: currentValue.headers,
          types: currentValue.rows[0].map((item) => {
            return "COMMON_NOUN";
          }),
          rows: currentValue.rows,
        },
        sectionHeader: this.data.createDataModel.sectionHeader,
        type: ProvisionTypeEnum.TABLE,
      },
    };
    this.matBottomSheetRef.dismiss({
      body,
      isChanged: isChanged,
    });
  }

  public shouldOpenConfirmNoHeadersModal(tableValue: TableValue): boolean {
    return (
      !this.hasHeader(tableValue.headers) &&
      !this.isDismissed &&
      (this.areRowsModified(tableValue.rows) ||
        this.headersWasRemoved(tableValue))
    );
  }

  private headersWasRemoved(tableValue: TableValue): boolean {
    return (
      !this.hasHeader(tableValue.headers) &&
      this.hasHeader(this.previousValue.headers)
    );
  }

  private hasHeader(headers: TableValue["headers"]): boolean {
    return headers.some((h) => h);
  }

  private get previousValue(): TableValue {
    return {
      headers: this.oldTable.rows[0].slice(1),
      rows: this.oldTable.rows.slice(1).map((row) => {
        return row.slice(1);
      }),
    };
  }

  onBottomTableDismiss(
    evt: TableValue,
    shouldRevertTableHeight: boolean = false
  ) {
    const currentValue: TableValue = {
      headers: evt.headers,
      rows: evt.rows,
    };
    const previousValue = this.previousValue;

    if (this.data.createDataModel) {
      const isChanged = !isEqual(currentValue, previousValue);

      setTimeout(() => {
        if (this.shouldOpenConfirmNoHeadersModal(currentValue)) {
          this.openNoHeadersConfirmModal().subscribe((confirm) => {
            if (confirm) {
              this.prepareDataForCreateManualMention(currentValue, isChanged);
            } else if (shouldRevertTableHeight) {
              this.revertTableSize();
            }
          });
        } else {
          this.prepareDataForCreateManualMention(currentValue, isChanged);
        }
      });
    } else {
      const body: OApiReqEditMentionValueDtoModel = {
        data: {
          provisionValue: {
            headers: evt.headers,
            types: evt.rows[0].map((item) => {
              return "COMMON_NOUN";
            }),
            rows: evt.rows,
          },
          type: "TABLE",
          formatValues: {
            TABLE: {
              headers: evt.headers,
              types: evt.rows[0].map((item) => {
                return "COMMON_NOUN";
              }),
              rows: evt.rows,
            },
          },
        },
        returnParams: {
          view: "PARTIAL_VAL",
        },
      };

      setTimeout(() => {
        if (this.shouldOpenConfirmNoHeadersModal(currentValue)) {
          this.openNoHeadersConfirmModal().subscribe((confirm) => {
            if (confirm) {
              this.matBottomSheetRef.dismiss({
                body,
                isChanged: !isEqual(currentValue, previousValue),
              });
            } else if (shouldRevertTableHeight) {
              this.revertTableSize();
            }
          });
        } else {
          this.matBottomSheetRef.dismiss({
            body,
            isChanged: !isEqual(currentValue, previousValue),
          });
        }
      });
    }
  }

  private areRowsModified(rows: (string | number)[][]): boolean {
    return rows.some((row) => !row.every((column) => column === ""));
  }

  private getTableHeight(event: MouseEvent): number {
    const hegiht = window.innerHeight - event.clientY - 10;

    if (hegiht < this.TABLE_MAX_SIZE_IN_PERCENT * window.innerHeight) {
      return hegiht;
    }

    return this.TABLE_MAX_SIZE_IN_PERCENT * window.innerHeight;
  }

  private isTableTooSmall(): boolean {
    return (
      parseInt(this.resizable.nativeElement.style.height, 10) <
      this.MIN_HEIGHT_OF_TABLE_IN_PX
    );
  }

  public mouseDown(): void {
    // this.resizable.nativeElement.style.maxHeight = `${this.tableElement.clientHeight}px`

    this.renderer.addClass(this.fullBodyElement, "full-body");

    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, "mousemove")
        .pipe(takeUntil(fromEvent(window, "mouseup")))
        .subscribe(
          (event: MouseEvent) => this.onMouseMove(event),
          () => {
            //
          },
          () => this.onFinishResize()
        );
    });
  }

  private onFinishResize(): void {
    this.renderer.removeClass(this.fullBodyElement, "full-body");
  }

  private onMouseMove(event: MouseEvent): void {
    this.resizable.nativeElement.style.height = `${this.getTableHeight(
      event
    )}px`;

    if (this.isTableTooSmall()) {
      this.tableDismiss.next();
    }
  }

  // reduceHeight() {
  //     setTimeout(() => {
  //         this.resizable.nativeElement.style.maxHeight = `${this.tableElement.clientHeight}px`
  //     }, 10)
  // }
}
