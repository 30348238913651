import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { cloneDeep } from "lodash";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { NewUploadService } from "@@intelease/web/ui/src/lib/itls-new-upload";
import { DocDataFiles, MergedDocSetModel } from "../models";

@Component({
  selector: "intelease-doc-set-doc-data-item",
  templateUrl: "./doc-set-doc-data-item.component.html",
  styleUrls: ["./doc-set-doc-data-item.component.scss"],
})
export class DocSetDocDataItemComponent {
  @Input()
  verticalView = true;
  @Input()
  mergedDocSet: MergedDocSetModel;
  @Output()
  allFilesRemoved: EventEmitter<any> = new EventEmitter<any>();

  onRemoveFile(index: number) {
    this.mergedDocSet.docDataFiles.splice(index, 1);
    if (this.mergedDocSet.docDataFiles.length) {
      this.updateUploadEvents();
    } else {
      this.allFilesRemoved.emit();
    }
  }

  onDropDocument(event: CdkDragDrop<any, any>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.updateUploadEvents();
  }

  onDocumentNameChanged(item: DocDataFiles) {
    if (!item.name.length) {
      item.name = NewUploadService.getFilenameWithoutExtension(item.file);
    }
  }

  private updateUploadEvents() {
    const items = this.mergedDocSet.docDataFiles;
    const obj = {
      pre: [],
      post: items,
    };
    obj.pre =
      this.mergedDocSet.lastDocumentReorderingEvent.pre.length > 0
        ? this.mergedDocSet.lastDocumentReorderingEvent.post
        : items;
    this.mergedDocSet.lastDocumentReorderingEvent = cloneDeep(obj);
    this.mergedDocSet.documentReorderingEvents.push(cloneDeep(obj));
  }

  onDocSetNameChanged() {
    this.mergedDocSet.pickDefaultNameIfEmpty();
  }
}
