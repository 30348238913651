<div id="drive-search-results">
  <div class="loading-container" *ngIf="!(columns.length && isTableLoaded)">
    <mat-progress-spinner diameter="35" color="accent" mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div
    class="table-responsive folder-list"
    (resized)="onResized($event)"
    [style.border]="
      columns.length && isTableLoaded ? 'solid 1px #c9ced5' : 'unset'
    "
  >
    <ngx-datatable
      #table
      *ngIf="columns.length && isTableLoaded"
      class="table table-hover"
      [columns]="columns"
      [columnMode]="columnModeSetting"
      [scrollbarH]="scrollBarHorizontal"
      [headerHeight]="50"
      [footerHeight]="40"
      [rowHeight]="'auto'"
      [externalPaging]="true"
      [count]="pagination.totalResults"
      [offset]="pagination.page - 1"
      [limit]="pagination.size"
      [externalSorting]="true"
      [selectAllRowsOnPage]="false"
      [virtualization]="true"
      [selected]="selectedItems"
      [rowIdentity]="getUid"
      [selectionType]="SelectionType.multi"
      [sortType]="sortType.single"
      (sort)="onSortClick($event)"
      [rows]="basicRows"
      (tableContextmenu)="onTableContextMenu($event)"
      (page)="changePage($event)"
    >
      <ng-container *ngFor="let col of columns">
        <ngx-datatable-column
          [name]="col.name"
          [prop]="col.prop"
          [sortable]="col.sortable"
          [maxWidth]="180"
          *ngIf="col.prop === 'status'"
          [headerClass]="'pl-4'"
        >
          <ng-template let-column="column" ngx-datatable-header-template>
            {{ column.name }}
          </ng-template>
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <div
              *ngIf="col.prop === 'status'"
              class="row-label {{ value }}"
              (click)="onRowClick(row, $event)"
              (dblclick)="onRowDbClick(row, $event)"
            >
              <span>
                {{ value | enums: "DOCUMENT_STATUS" }}
              </span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="col.name"
          [prop]="col.prop"
          *ngIf="
            col.type !== 'ACTION' &&
            col.type !== 'CHECKBOX' &&
            col.prop !== 'status'
          "
          [sortable]="col.sortable"
          [headerClass]="'pl-4'"
        >
          <ng-template let-column="column" ngx-datatable-header-template>
            {{ column.name }}
          </ng-template>
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <div
              class="row-label"
              (click)="onRowClick(row, $event)"
              (dblclick)="onRowDbClick(row, $event)"
            >
              <span *ngIf="col.type !== 'DATE' && col.prop !== 'name'">
                {{ value || "-" }}
              </span>
              <div
                *ngIf="col.prop === 'name'"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <mat-icon
                  *ngIf="row.type === 'DIRECTORY'"
                  [svgIcon]="
                    row.shared ? 'icon-folder-share' : 'icon-documents'
                  "
                  class="folder-icon"
                ></mat-icon>
                <mat-icon
                  *ngIf="row.type === 'RECORD'"
                  [svgIcon]="row.shared ? 'icon-documents-share' : 'icon-note'"
                  class="record-icon"
                ></mat-icon>
                <span style="line-height: normal">{{ value }}</span>
              </div>
              <span *ngIf="col.type === 'DATE'">
                {{ (value | date: "medium") || "-" }}
              </span>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ng-container>
      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
          let-isVisible="isVisible"
        >
          <span class="pl-3" *ngIf="pagination.totalResults > pageSize"
            >{{ (curPage - 1) * pageSize + 1 }} -
            {{ rowCount < curPage * pageSize ? rowCount : curPage * pageSize }}
            of {{ rowCount | number }}</span
          >
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!(rowCount / pageSize > 1)"
            (change)="table.onFooterPage($event)"
            [style.marginRight]="
              uploadingBoxDialogService.uploadingBoxDialogOpened
                ? '400px'
                : '48px'
            "
          >
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
    <div
      class="context-menu-container"
      [matMenuTriggerFor]="contextMenu"
      #contextMenuTrigger="matMenuTrigger"
      [hidden]="!contextMenuPos"
      [ngStyle]="{
        'left.px': contextMenuPos?.x,
        'top.px': contextMenuPos?.y
      }"
    >
      <mat-menu #contextMenu="matMenu" hasBackdrop="false">
        <ng-container *ngFor="let menu of contextMenuActionButtons">
          <button
            mat-menu-item
            (click)="onButtonActionClick(menu.actionKey, contextMenuContent)"
          >
            <mat-icon [svgIcon]="menu.icon"></mat-icon>
            <span class="title">{{ menu.title }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</div>
