import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import { NavigationItemService } from "@@intelease/web/intelease/constants/navigation-item.service";
import { NodeSearchResultModel } from "@@intelease/web/common/models";
import { DriveFacade } from "@intelease/app-state/drive-v2";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-drive-v2",
  templateUrl: "./itls-drive.component.html",
  styleUrls: ["./itls-drive.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsDriveComponent {
  canAddFolder = false;
  isShowingSearch = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private driveFacade: DriveFacade
  ) {
    this.driveFacade.getAllRecordsList$
      .pipe(takeUntilDestroyed())
      .subscribe((resp: any) => {
        this.canAddFolder = resp?.nodeNavigation?.category === "OWNED";
      });
  }

  onCreateNewFolderClicked() {
    NavigationItemService.openAddFolder$.next();
  }

  onSearchItemClicked(nodeSearchResultModel: NodeSearchResultModel) {
    this.isShowingSearch = false;
    this.cdr.detectChanges();
  }

  onShowSearchBarClicked() {
    this.isShowingSearch = true;
    this.cdr.detectChanges();
  }

  onCloseSearchBox() {
    this.driveFacade.closeAdvancedSearch();
    this.isShowingSearch = false;
  }
}
