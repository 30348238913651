import {
  ChangeCustomTagsRequestDtoModel,
  CustomTagApiDtoModel,
  FolderStructureNodeDtoModel,
  OApiReqUpdateCustomTagsRequestDtoModel,
  DocSetNodeDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { LocalStorageKey } from "@@intelease/web/common/enums/local-storage.keys";
import { SortDirectionLowcaseEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import { LocalStorageService } from "@@intelease/web/common/services";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import { isEmpty } from "lodash";
import {
  SelectedFilterModel,
  DocSetCategory,
  IRecord,
  Paginated,
  RecordCategory,
  RecordStatus,
  RecordType,
  User,
  SelectedColumnFieldsModel,
  RecordCategoryUppercaseEnum,
} from "../models/record.types";

import {
  LoadAllRecords,
  LoadAdvancedSearchFields,
  UpdateRecordStatus,
  AddRecord,
  AddDirectory,
  LoadAdvancedSearchRecords,
  ChangePage,
  SetSelectedFilter,
  SetSelectedColumnFields,
  CurrentSelectedColumnFields,
  CancelAdvancedSearch,
  ApplyAdvancedSearch,
  CloseAdvancedSearch,
  SortsRecords,
  RenameRecordOrDirectory,
  UpdateCustomTags,
  UpdateShareRecordWithUid,
} from "./drive.actions";
import { RecordsPartialState } from "./drive.reducer";
import { recordsQuery } from "./drive.selectors";
import { DatePipe } from "@angular/common";
import { DateUtil } from "@@intelease/web/utils";

@Injectable()
export class DriveFacade {
  loadAllRecordsSucceeded$ = this.store.pipe(
    select(recordsQuery.loadAllRecordsSucceeded)
  );

  loadAllRecordsError$ = this.store.pipe(
    select(recordsQuery.loadAllRecordsError)
  );

  getAdvancedSearchFieldsList$: Observable<any[]> = this.store.pipe(
    select(recordsQuery.getAdvancedSearchFieldsList)
  );

  advancedSearchFieldsOperators$ = this.store.pipe(
    select(recordsQuery.getProvisionsOperator)
  );

  isLoadAllRecordsLoading$ = this.store.pipe(
    select(recordsQuery.isLoadAllRecordsLoading)
  );

  getAllRecordsList$ = this.store.pipe(select(recordsQuery.getAllRecordsList));

  getAppliedSelectedColumnFields$ = this.store.pipe(
    select(recordsQuery.getAppliedSelectedColumnFields)
  );

  getCurrentSelectedColumnFields$ = this.store.pipe(
    select(recordsQuery.getCurrentSelectedColumnFields)
  );

  advancedSearchPossibleColumns$ = this.store.pipe(
    select(recordsQuery.advancedSearchPossibleColumns)
  );

  isAdvancedSearchActive$ = this.store.pipe(
    select(recordsQuery.isAdvancedSearchActive)
  );

  getPagination$ = this.store.pipe(select(recordsQuery.getPagination));

  getNumberOfFilters$ = this.store.pipe(
    select(recordsQuery.getNumberOfFilters)
  );

  getSelectedFilter$ = this.store.pipe(
    select(recordsQuery.getCurrentSelectedFilter)
  );

  getAppliedSelectedFilter$ = this.store.pipe(
    select(recordsQuery.getAppliedSelectedFilter)
  );

  isSortRecordFailed$ = this.store.pipe(
    select(recordsQuery.isSortRecordFailed)
  );

  getRecords$(category?: RecordCategory): Observable<Paginated<IRecord>> {
    return this.getAllRecordsList$.pipe(
      map(
        (records) =>
          ({
            ...records,
            items: ((records?.items || []) as IRecord[])
              .map((item) => {
                return {
                  ...item,
                  owner: (item?.owner as User)?.name,
                  ownerUid: (item.owner as User)?.uid,
                  docSetCategory: item?.docSetCategory
                    ? this.convertDocSetCategory(item?.docSetCategory)
                    : item?.docSetCategory,
                };
              })
              .filter((item) =>
                !category ? true : this.filterRecordByCategory(item, category)
              ),
          } as Paginated<IRecord>)
      )
    );
  }

  private filterRecordByCategory(
    record: IRecord,
    category: RecordCategory
  ): boolean {
    if (category === RecordCategory.deleted) {
      return record?.type === RecordType.RECORD
        ? record.status === RecordStatus.UI_DELETED
        : true;
    }

    //TODO: add else categories, but i don't have knowledge
    return true;
  }

  private get docSetCategories(): DocSetCategory[] {
    return this.localStorageService.get(LocalStorageKey.DOC_SET_CATEGORIES);
  }

  private convertDocSetCategory(
    docSetCategory: DocSetCategory["value"]
  ): DocSetCategory["uiName"] {
    return (
      this.docSetCategories.find(
        (category) => category.value === docSetCategory
      ) as DocSetCategory
    ).uiName;
  }

  constructor(
    private store: Store<RecordsPartialState>,
    private readonly localStorageService: LocalStorageService,
    private datePipe: DatePipe
  ) {}

  loadAllRecords(payload: {
    category?: RecordCategoryUppercaseEnum;
    directoryUid?: string;
    page?: number;
    perPage?: number;
    view?: string;
    sorts?: string;
  }) {
    this.store.dispatch(new LoadAllRecords(payload));
  }

  sortsRecords(payload: {
    category?: RecordCategoryUppercaseEnum;
    directoryUid?: string;
    page?: number;
    perPage?: number;
    view?: string;
    sorts?: string;
  }) {
    this.store.dispatch(new SortsRecords(payload));
  }

  loadAdvancedSearchFields() {
    this.store
      .pipe(select(recordsQuery.getAdvancedSearchFieldsList))
      .pipe(
        first(),
        filter((fieldsList) => isEmpty(fieldsList))
      )
      .subscribe((value) => {
        this.store.dispatch(new LoadAdvancedSearchFields());
      });
  }

  loadAdvancedSearchRecords(payload: { sorts: string[] }) {
    combineLatest([
      this.store.pipe(select(recordsQuery.getAppliedSelectedColumnFields)),
      this.store.pipe(select(recordsQuery.getAppliedSelectedFilter)),
    ])
      .pipe(first())
      .subscribe(([columns, filters]) => {
        this.store.dispatch(
          new LoadAdvancedSearchRecords({
            directoryUid: location.pathname.split("/")[3]
              ? location.pathname.split("/")[3]
              : undefined,
            category:
              location.pathname.split("/")[2] !== "folders"
                ? location.pathname.split("/")[2].toUpperCase()
                : "OWNED",
            filters: this._filter(filters),
            columns: columns.map(
              (column) => `${column?.prop}::${column?.name}::${column?.type}`
            ),
            sorts: payload.sorts,
          })
        );
      });
  }

  setSelectedColumnFields(payload: SelectedColumnFieldsModel[]) {
    this.store.dispatch(new SetSelectedColumnFields(payload));
  }

  setCurrentSelectedColumnFields(payload: SelectedColumnFieldsModel[]) {
    this.store.dispatch(new CurrentSelectedColumnFields(payload));
  }

  addRecord(payload: {
    recordUid: string;
    category: "ALL" | "DELETED" | "FAVORITES" | "OWNED" | "SHARED";
    directoryUid?: string;
    data: DocSetNodeDtoModel;
  }) {
    this.store.dispatch(new AddRecord(payload));
  }

  addDirectory(payload: {
    category: "ALL" | "DELETED" | "FAVORITES" | "OWNED" | "SHARED";
    directoryUid?: string;
    data: FolderStructureNodeDtoModel;
  }) {
    this.store.dispatch(new AddDirectory(payload));
  }

  changePage(payload: number) {
    this.store.dispatch(new ChangePage(payload));
  }

  updateRecordStatus(payload: {
    recordUid: string;
    status:
      | "UPLOADING"
      | "QUEUED"
      | "PROCESSING"
      | "PROCESSING_COMPLETED"
      | "UNDER_REVIEW"
      | "COMPLETED"
      | "ERROR"
      | "UI_DELETED";
  }) {
    this.store.dispatch(new UpdateRecordStatus(payload));
  }

  setSelectedFilter(payload: SelectedFilterModel) {
    this.store.dispatch(new SetSelectedFilter(payload));
  }

  applyAdvancedSearch() {
    this.store.dispatch(new ApplyAdvancedSearch());
  }

  cancelAdvancedSearch() {
    this.store.dispatch(new CancelAdvancedSearch());
  }

  closeAdvancedSearch() {
    this.store.dispatch(new CloseAdvancedSearch());
  }

  renameRecordOrDirectory(payload: { row: IRecord; newName: string }) {
    this.store.dispatch(new RenameRecordOrDirectory(payload));
  }

  updateCustomTags(recordUid: string, customTags: CustomTagApiDtoModel[]) {
    const data: ChangeCustomTagsRequestDtoModel = {
      customTags,
    };
    const updateCustomTagsDTO: OApiReqUpdateCustomTagsRequestDtoModel = {
      data,
    };
    const params = {
      recordUid,
      body: updateCustomTagsDTO,
    };
    this.store.dispatch(new UpdateCustomTags(params));
  }

  updateShareRecordWithUid(recordUid: string, shared: boolean) {
    this.store.dispatch(new UpdateShareRecordWithUid(recordUid, shared));
  }

  private _filter(value: any) {
    const _value = {
      ...value,
      value: value.value.map((item: any) => {
        if (item.field.fieldType === "LOCAL_DATE") {
          return {
            ...item,
            field: `${item?.field?.fieldName}::${item?.field?.uiName}::${item?.field?.fieldType}`,
            category: item?.field?.category,
            value: item.value
              ? DateUtil.serializeToExactDate(item.value)
              : item.value,
          };
        } else {
          return {
            ...item,
            field: `${item?.field?.fieldName}::${item?.field?.uiName}::${item?.field?.fieldType}`,
            category: item?.field?.category,
          };
        }
      }),
    };
    return _value;
  }
}
