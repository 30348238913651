<div class="add-calendar-container">
  <h1 mat-dialog-title>{{ title || "Select a folder" }}</h1>
  <div mat-dialog-content style="max-height: 495px; height: 495px">
    <il-drive-tree
      [selectedNodeUid]="selectedFolder?.id"
      [nonSelectableNodeUids]="nonSelectableNodeUids"
      [disabledNodeUids]="disabledNodeUids"
      (nodeClicked)="onNodeClicked($event)"
      [showContextMenu]="true"
    ></il-drive-tree>
  </div>
  <div
    mat-dialog-actions
    style="display: flex; align-items: center; justify-content: space-around"
  >
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="onSelectClicked()"
      [disabled]="!selectedFolder"
    >
      Select
    </button>
  </div>
</div>
