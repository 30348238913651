<div class="add-project-container">
  <h1 mat-dialog-title>
    {{ modalTitle }}
  </h1>
  <div mat-dialog-content>
    <div itlsPgFormGroupDefault style="border: none">
      <label> Custom Tags for this Record </label>
      <ng-select
        [items]="accountCustomTags"
        [addTag]="true"
        [multiple]="true"
        appendTo="body"
        bindLabel="name"
        [closeOnSelect]="true"
        [(ngModel)]="customTags"
        style="width: 185px"
      >
        <ng-option
          *ngFor="let item of possibleCustomTags"
          [value]="item.index"
          >{{ item.name }}</ng-option
        >
      </ng-select>
    </div>
  </div>
  <div
    mat-dialog-actions
    style="display: flex; align-items: center; justify-content: space-around"
  >
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" (click)="onSaveCustomTags()">
      Save
    </button>
  </div>
</div>

<!-- <div class="add-project-container">
  <h1 mat-dialog-title>
    {{modalTitle}}
  </h1>
  <div mat-dialog-content>
    <div itlsPgFormGroupDefault style="border: none">
      <label> Custom Tags for this Record </label>
      <ng-select
              [items]="accountCustomTags"
              [addTag]="true"
              [multiple]="true"
              appendTo="body"
              bindLabel="name"
              [closeOnSelect]="false"
              [(ngModel)]="customTags"
          >
              <ng-template
                  ng-optgroup-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
              >
                  <input
                      id="item-{{ index }}"
                      type="checkbox"
                      [ngModel]="item$.selected"
                  />
                  {{ item.name }}
              </ng-template>
          </ng-select>
    </div>
  </div>
  <div
      mat-dialog-actions
      style="
          display: flex;
          align-items: center;
          justify-content: space-around;
      "
  >
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button color="primary" (click)="onSaveCustomTags()">
          Save
      </button>
  </div>
</div> -->
