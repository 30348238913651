import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";
import {
  DOC_SET_ACTION__DELETE,
  DOC_SET_ACTION__DOWNLOAD,
  DOC_SET_ACTION__MOVE,
} from "./doc-set-action-buttons.const";

export const MULTI_SELECTION_ACTION_BUTTONS: ActionButtonInterface[] = [
  DOC_SET_ACTION__MOVE,
  DOC_SET_ACTION__DOWNLOAD,
  DOC_SET_ACTION__DELETE,
];

export const MULTI_SELECTION_ACTION_BUTTONS_KEYS: string[] =
  MULTI_SELECTION_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);
