import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  EntityFieldModel,
  ModalInputModel,
  PaginationModel,
} from "@@intelease/web/intelease/models";
import {
  ColumnMode,
  ContextmenuType,
  DatatableComponent,
  SelectionType,
  SortType,
} from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CommonModalService,
  UploadingBoxDialogService,
} from "@@intelease/web/common/services";
import {
  InteleaseNotificationService,
  MainDrawerService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { MatDialog } from "@angular/material/dialog";
import {
  AdexRouterService,
  QueueFeatureService,
} from "@@intelease/web/intelease/utils";
import { NewShareEntityComponent } from "@@intelease/web/ui/src/lib/new-share-entity/new-share-entity.component";
import { ResizedEvent } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";
import {
  DriveSort,
  ItlsDriveService,
} from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-drive.service";
import {
  BATCH_FOLDER_ACTION_BUTTONS_KEYS,
  DRIVE_SEARCH_CONST,
  FOLDER_ACTION_BUTTONS_KEYS,
  MULTI_SELECTION_ACTION_BUTTONS_KEYS,
  DOC_SET_ACTION__ADD_TO_QUEUE,
  RECORD_ACTION__ASSIGN,
  RECORD_ACTION__OPEN_IN_NEW_TAB,
  DOC_SET_ACTION__SHARE,
  BATCH_DOC_SET_ACTION_BUTTONS_KEYS,
  ALL_DOC_SET_ACTION_BUTTONS_KEYS,
  DOC_SET_ACTION__MOVE,
  DOC_SET_ACTION__RENAME,
  DOC_SET_ACTION__DOWNLOAD,
  DOC_SET_ACTION__DELETE,
  COMMON_ACTION_BUTTONS,
  DOC_SET_ACTION__REVIEW,
  DOC_SET_ACTION__VIEW,
} from "@@intelease/web/ui/src/lib/itls-drive-v2/constants";
import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";
import { MatMenuTrigger } from "@angular/material/menu";
import { SelectFolderComponent } from "@@intelease/web/ui/src/lib/itls-drive-v2/modals";
import {
  LightNodeNameV2Model,
  NodeSearchResultModel,
} from "@@intelease/web/common/models";
import { AbstractExportModalService } from "@@intelease/web/intelease/modal";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { TaskModalService } from "@@intelease/web/tasks";
import { SortDirectionEnum } from "@@intelease/web/common/enums/sort-direction.enum";
import {
  APP_ENUMS_CONST,
  ENTITY_FORM_SCHEMA_ONLY_NAME_CONST,
} from "@@intelease/web/intelease/constants";
import { FormModalService } from "@@intelease/web/ui";
import * as _ from "lodash";

import { ItlsShareDriveService } from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-share-drive.service";
import { forEach, remove } from "lodash";
import {
  DriveNodeStatus,
  DriveNodeType,
  IDriveNode,
} from "@@intelease/app-state/drive-v2/src";
import { EditLbxRolesDtoModel } from "@@intelease/api-models/adex-api-model-src";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

interface TableRowModel {
  uid: string;
  recordUid?: string;
  queueUid?: string;
  queueName?: string;
  queueStage?: string;
  name: string;
  status: string;
  owner: string;
  ownerUid: string;
  lastModifiedDate: Date;
  createdAt: Date;
  size: number;
  type: "RECORD" | "DIRECTORY" | "DOC_SET";
  permissions: (
    | "SHARE"
    | "DELETE"
    | "COMMENT"
    | "MOVE"
    | "EXPORT"
    | "EDIT"
    | "READ"
  )[];
  shared: boolean;

  viewer: NodeSearchResultModel["viewer"];
  googleDriveFileId: NodeSearchResultModel["googleDriveFileId"];
}

const PAGINATION_SIZE = 20;

@Component({
  selector: "intelease-drive-search-results",
  templateUrl: "./itls-drive-search-results.component.html",
  styleUrls: ["./itls-drive-search-results.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsDriveSearchResultsComponent implements OnInit {
  @Output() selectOutput: EventEmitter<any> = new EventEmitter();
  @Output() newTask: EventEmitter<any> = new EventEmitter();
  @Input() tableColumns: EntityFieldModel[] = [];
  @ViewChild("table") table: DatatableComponent;
  actionMaps: { [key: string]: any };
  tableUpdated = false;
  columnModeSetting: ColumnMode | keyof typeof ColumnMode =
    window.innerWidth < 960 ? "standard" : "force";
  scrollBarHorizontal = window.innerWidth < 960;
  SelectionType = SelectionType;
  pagination: PaginationModel = new PaginationModel(1, PAGINATION_SIZE);
  gotData = false;
  basicRows: TableRowModel[] = [];
  columns: any[] = [];
  selectedItems: any[] = [];
  isTableLoaded = false;
  nodes: NodeSearchResultModel[] = [];
  @ViewChild("contextMenuTrigger", { static: false })
  contextMenu: MatMenuTrigger;
  contextMenuContent: any;
  contextMenuEvent: MouseEvent;
  contextMenuPos: { x: number; y: number };
  searchTerm: string;
  sorts: DriveSort[] = [];
  contextMenuActionButtons: ActionButtonInterface[] = [];
  sortType = SortType;

  isQueueEnabled = false;

  lbxRoles: EditLbxRolesDtoModel["lbxRoles"];

  constructor(
    private route: ActivatedRoute,
    private adexRouter: AdexRouterService,
    private commonModalService: CommonModalService,
    private inteleaseNotificationService: InteleaseNotificationService,
    public mainDrawerService: MainDrawerService,
    private itlsShareDriveService: ItlsShareDriveService,
    private dialog: MatDialog,
    private driveService: ItlsDriveService,
    private cdr: ChangeDetectorRef,
    private taskModalService: TaskModalService,
    private formModalService: FormModalService,
    public uploadingBoxDialogService: UploadingBoxDialogService,
    private queueFeatureService: QueueFeatureService,
    private destroyRef: DestroyRef
  ) {
    this.initTableColumns();
    this.initActions();
  }

  ngOnInit() {
    this.isQueueEnabled = this.queueFeatureService.isFeatureEnabled();
    this.route.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        this.searchTerm = params.name;
        this.refresh();
        this.getSearchResult(true);
        this.cdr.detectChanges();
      });
    this.lbxRoles = UserInfoService.getLbxRoles();
  }

  initTableColumns() {
    this.columns = DRIVE_SEARCH_CONST.columns;
  }

  onButtonActionClick(actionKey: string, row) {
    this.actionMaps[actionKey](row);
  }

  changePage(pageInfo): void {
    this.pagination.page = ++pageInfo.offset;
    this.getSearchResult();
  }

  canIngestQueueLbxUser() {
    return (
      !UserInfoService.isLbxAccountUser() ||
      this.lbxRoles.includes("FORMS_ADMIN")
    );
  }

  /**
   * This is used by the html table.
   */
  getUid(row: TableRowModel): string {
    return row.uid;
  }

  onSortClick(evt: any) {
    const column = this.columns.find(
      (inColumn) => inColumn.prop === evt.column.prop
    );
    const existingIndex = this.sorts.findIndex(
      (item) => item.fieldName === column.prop
    );
    if (existingIndex !== -1) {
      this.sorts.splice(existingIndex, 1);
    }
    if (evt.newValue) {
      const direction = evt.newValue.toUpperCase();
      this.sorts.splice(0, 0, {
        fieldName: column.prop,
        sort:
          column.prop +
          "::" +
          column.name +
          "::" +
          column.type +
          "::" +
          SortDirectionEnum[direction],
      });
    }
    this.getSearchResult(true);
  }

  initActions() {
    this.actionMaps = {
      OPEN_IN_NEW_TAB: () => this.onOpenInNewTAbClick(this.selectedItems[0]),
      DOC_SET_VIEW: () => this.onDocSetView(this.selectedItems[0]),
      DOC_SET_REVIEW: () => this.onDocSetReview(this.selectedItems[0]),
      MOVE: () =>
        this.selectedItems.length > 1
          ? this.onBatchMove(this.selectedItems)
          : this.onMove(this.selectedItems[0]),
      DOWNLOAD: () =>
        this.selectedItems.length > 1
          ? this.onBatchDownload(this.selectedItems)
          : this.onDownload(this.selectedItems[0]),
      FULL_EXPORT: () =>
        this.selectedItems.length > 1
          ? this.onBatchFullExport(this.selectedItems)
          : this.onFullExport(this.selectedItems[0]),
      HISTORY_EXPORT: () =>
        this.selectedItems.length > 1
          ? this.onBatchHistoryExport(this.selectedItems)
          : this.onHistoryExport(this.selectedItems[0]),
      SHARE: () =>
        this.selectedItems.length > 1
          ? this.onBatchShare(this.selectedItems)
          : this.onShare(this.selectedItems[0]),
      RENAME: () => this.onRename(this.selectedItems[0]),
      DELETE: () =>
        this.selectedItems.length > 1
          ? this.onBatchDelete(this.selectedItems)
          : this.onDelete(this.selectedItems[0]),
      RESTORE: () => this.onRestore(this.selectedItems[0]),
      ASSIGN: () =>
        this.selectedItems.length > 1
          ? this.onBatchAssign(this.selectedItems)
          : this.onAssign(this.selectedItems[0]),
    };
  }

  private onDownload(thisRow: TableRowModel) {
    this.onBatchDownload([thisRow]);
  }

  private onBatchDownload(rows: TableRowModel[]) {
    const nodes = rows.map(
      (row) =>
        new LightNodeNameV2Model({
          name: row.name,
          type: row.type,
          uid: row.uid,
        })
    );
    this.driveService.exportDriveNodes(nodes);
  }

  private onFullExport(thisRow: IDriveNode) {
    console.log(thisRow);
    this.onBatchFullExport([thisRow]);
  }

  private onHistoryExport(thisRow: IDriveNode) {
    console.log(thisRow);
    this.onBatchHistoryExport([thisRow]);
  }

  private onBatchFullExport(rows: IDriveNode[]) {
    const nodes = rows.map(
      (row) =>
        new LightNodeNameV2Model({
          name: row.name,
          type: row.type,
          uid: row.uid,
        })
    );
    this.driveService.specialExportDriveNodes(nodes, "FULL");
  }

  private onBatchHistoryExport(rows: IDriveNode[]) {
    const nodes = rows.map(
      (row) =>
        new LightNodeNameV2Model({
          name: row.name,
          type: row.type,
          uid: row.uid,
        })
    );
    this.driveService.specialExportDriveNodes(nodes, "HISTORY");
  }

  private onOpenInNewTAbClick(selectedItem: TableRowModel) {
    if (selectedItem.type === DriveNodeType.DOC_SET) {
      this.onDocSetView(selectedItem as any);
    } else if (selectedItem.type === DriveNodeType.DIRECTORY) {
      const foldersUrl = `/drive/folders/${selectedItem.uid}`;
      this.adexRouter.navigate([foldersUrl]);
    }
  }

  onRowClick(row: TableRowModel, evt): void {
    //
  }

  onRowDbClick(row: TableRowModel, evt): void {
    if (row.status === DriveNodeStatus.UI_DELETED) {
      return;
    }

    if (evt.ctrlKey || evt.shiftKey || evt.metaKey) return;

    if (row.type === DriveNodeType.DIRECTORY) {
      const foldersUrl = `/drive/folders/${row.uid}`;
      this.adexRouter.navigate([foldersUrl]);
    } else if (row.type === DriveNodeType.DOC_SET) {
      this.onDocSetView(row as any);
    }
  }

  private equalsAnyIgnoreCase(status: string, ...expectedStatuses: string[]) {
    return (
      expectedStatuses?.findIndex(
        (expectedStatus) =>
          status?.toLowerCase() === expectedStatus.toLowerCase()
      ) !== -1
    );
  }

  private createDeleteModal(rows: TableRowModel[]) {
    const distinctTypes = new Set(rows.map((row) => row.type));
    const deleteModalInput = new ModalInputModel();
    deleteModalInput.mode = ComponentModeEnum.REMOVE;
    deleteModalInput.payload = {
      customMessage: true,
    };
    if (distinctTypes.size === 1 && distinctTypes.has(DriveNodeType.DOC_SET)) {
      // only docSets
      deleteModalInput.payload.title = "Delete DocSet(s)";
      deleteModalInput.payload.message = `This will permanently delete ${rows.length} documents(s). Are you sure?`;
      deleteModalInput.payload.params = {
        deletedRecordsNum: rows.length,
      };
    } else if (
      distinctTypes.size === 1 &&
      distinctTypes.has(DriveNodeType.DIRECTORY)
    ) {
      // only folders
      deleteModalInput.payload.title = "Delete Folder(s)";
      deleteModalInput.payload.message = `This will permanently delete ${rows.length} folder(s). Are you sure?`;
      deleteModalInput.payload.params = {
        deletedFoldersNum: rows.length,
      };
    } else {
      const deletedRecordsNum = rows.filter(
        (row) => row.type === DriveNodeType.DOC_SET
      ).length;
      const deletedFoldersNum = rows.filter(
        (row) => row.type === DriveNodeType.DIRECTORY
      ).length;
      deleteModalInput.payload.title = "Delete Folder(s) / DocSet(s)";
      deleteModalInput.payload.message = `This will permanently delete ${deletedFoldersNum} folder(s) and ${deletedRecordsNum} documents(s). Are you sure?`;
      deleteModalInput.payload.params = {
        deletedRecordsNum,
        deletedFoldersNum,
      };
    }
    return deleteModalInput;
  }

  private onRestore(row: TableRowModel) {
    //
  }

  private onDelete(row: TableRowModel) {
    this.onBatchDelete([row]);
  }

  private onBatchDelete(rows: TableRowModel[]) {
    this.commonModalService
      .openDeleteConfirmModal(this.createDeleteModal(rows))
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse?.type === ModalsResponseTypeEnum.CLOSE) {
          this.driveService
            .deleteNodes(
              rows.map((row) => ({
                type: row.type,
                uid: row.uid,
              }))
            )
            .subscribe((resp) => {
              this.getSearchResult();
            });
        }
      });
  }

  private onShare(row: TableRowModel): void {
    this.dialog
      .open(NewShareEntityComponent, {
        width: "350px",
        height: "420px",
        panelClass: "no-padding-modal-panel",
        data: {
          shareEntityService: this.itlsShareDriveService,
          entity: {
            entityUid: row.uid,
            entityType: row.type,
          },
        },
      })
      .afterClosed()
      .subscribe((shared) => {
        if (shared) {
          this.getSearchResult();
        }
      });
  }

  private onBatchShare(rows: TableRowModel[]): void {
    this.dialog
      .open(NewShareEntityComponent, {
        width: "350px",
        height: "420px",
        panelClass: "no-padding-modal-panel",
        data: {
          shareEntityService: this.itlsShareDriveService,
          entities: rows.map((row) => ({
            entityUid: row.uid,
            entityType: row.type,
          })),
        },
      })
      .afterClosed()
      .subscribe((shared) => {
        if (shared) {
          this.getSearchResult();
        }
      });
  }

  private onRename(row: TableRowModel): void {
    let editTitle;
    let saveButtonTooltip;
    switch (row.type) {
      case DriveNodeType.RECORD:
        editTitle = "Edit Record";
        break;
      case DriveNodeType.DIRECTORY:
        editTitle = "Edit Folder";
        break;
      default:
        throw new Error("unexpected value: " + row.type);
    }
    const modalData = new ModalInputModel();
    modalData.mode = ComponentModeEnum.EDIT;
    modalData.payload = {
      editTitle,
      formSchema: { ...ENTITY_FORM_SCHEMA_ONLY_NAME_CONST },
      model: { name: row.name },
    };
    switch (modalData.payload.editTitle) {
      case "Edit Folder":
        saveButtonTooltip = {
          emptyField: "You must enter a folder name!",
          notValidField: "No change detected in the folder name!",
          firstMessage: "No change detected in the folder name!",
        };
        break;
      case "Edit Record":
        saveButtonTooltip = {
          emptyField: "You must enter a record name!",
          notValidField: "No change detected in the record name!",
          firstMessage: "No change detected in the record name!",
        };
        break;

      default:
        break;
    }
    const modalRef = this.formModalService.openNewFormModal(
      modalData,
      {
        minWidth: 340,
      },
      saveButtonTooltip
    );
    modalRef.afterClosed().subscribe((res) => {
      if (res && res.data) {
        const { name } = res.data;
        if (name) {
          this.driveService
            .rename(row.uid, {
              name,
              type: row.type,
            })
            .subscribe((resp) => {
              this.getSearchResult();
            });
        }
      }
    });
  }

  private onDocSetView(row: IDriveNode): void {
    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UI_DELETED
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this document, as it is deleted."
      );
      return;
    }

    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UPLOADING
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this document, as its files are still uploading."
      );
      return;
    }

    if (row.viewer === "DEFAULT") {
      const previewUrl = `${"/documents"}/${row.uid}`;
      this.adexRouter.openInNewTab(previewUrl);
    } else if (row.viewer === "GOOGLE_DRIVE_PREVIEW") {
      this.adexRouter.openGoogleDriveFilePreview(row.googleDriveFileId);
    }
  }

  private onDocSetReview(row: IDriveNode): void {
    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UI_DELETED
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this document, as it is deleted."
      );
      return;
    }

    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UPLOADING
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this document, as its files are still uploading."
      );
      return;
    }

    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.PROCESSING,
        APP_ENUMS_CONST.DOCUMENT_STATUS.ERROR,
        APP_ENUMS_CONST.DOCUMENT_STATUS.QUEUED
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this record, as it is processing. Please wait and refresh the page, till the processing is complete."
      );
      return;
    }

    this.adexRouter.navigateToRecordReviewPage(row.recordUid, true);
  }

  onResized(event: ResizedEvent) {
    if (!this.tableUpdated) {
      this.tableUpdated = true;
      setTimeout(() => {
        this.basicRows = [...this.basicRows];
        try {
          this.table.ngDoCheck();
        } catch (e) {
          //
        }
        this.tableUpdated = false;
      }, 150);
    }
  }

  getPosition(e) {
    let posx = 0;
    let posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }

    return {
      x: this.mainDrawerService.isOpen ? posx - 270 : posx - 25,
      y: posy - 55,
    };
  }

  private reloadContextMenuActionButtons() {
    this.contextMenuActionButtons = this.getContextMenuActionButtons();
  }

  private getContextMenuActionButtons(): ActionButtonInterface[] {
    if (this.selectedItems.length === 0) {
      return [];
    }

    const allActionButtons = [...COMMON_ACTION_BUTTONS];

    // filter action buttons based on app environment
    this.filterActionButtonsBasedOnAppEnv(allActionButtons, this.selectedItems);

    // filter action buttons based on permissions
    this.filterActionButtonsBasedOnSelectedItemsPermissions(
      allActionButtons,
      this.selectedItems
    );

    // filter action buttons based on directories / records details e.g: for records in ERROR state we only allow DELETE action
    this.filterActionButtonsBasedOnRowDetails(
      allActionButtons,
      this.selectedItems
    );

    // filter action buttons based on mixed selection of folders and records
    this.filterActionButtonsBasedOnMixedSelectionOfFoldersAndRecords(
      allActionButtons,
      this.selectedItems
    );

    //filter action buttons based on status
    this.filterActionButtonsBasedOnRecordStatus(
      allActionButtons,
      this.selectedItems
    );

    // filter action buttons for Google Drive files
    this.filterActionButtonsForGoogleDriveFiles(
      allActionButtons,
      this.selectedItems
    );

    return allActionButtons;
  }

  private filterActionButtonsBasedOnAppEnv(
    allActionButtons: ActionButtonInterface[],
    selectedItems: TableRowModel[]
  ) {
    if (allActionButtons.length === 0) {
      return;
    }
    if (!this.isQueueEnabled || !this.canIngestQueueLbxUser()) {
      _.remove(
        allActionButtons,
        (actionButton) =>
          actionButton.actionKey === DOC_SET_ACTION__ADD_TO_QUEUE.actionKey
      );
    }
  }

  private filterActionButtonsBasedOnSelectedItemsPermissions(
    allActionButtons: ActionButtonInterface[],
    selectedItems: TableRowModel[]
  ) {
    for (const selectedItem of selectedItems) {
      // stop if we don't have any actions to filter
      if (allActionButtons.length === 0) {
        return;
      }
      if (!selectedItem.permissions?.includes("READ")) {
        _.remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey ===
              RECORD_ACTION__OPEN_IN_NEW_TAB.actionKey ||
            actionButton.actionKey === DOC_SET_ACTION__REVIEW.actionKey
        );
      }
      if (!selectedItem.permissions?.includes("EDIT")) {
        _.remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__RENAME.actionKey ||
            actionButton.actionKey === RECORD_ACTION__ASSIGN.actionKey ||
            actionButton.actionKey === DOC_SET_ACTION__MOVE.actionKey
        );
      }
      if (!selectedItem.permissions?.includes("SHARE")) {
        _.remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__SHARE.actionKey
        );
      }
      if (!selectedItem.permissions?.includes("EXPORT")) {
        _.remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__DOWNLOAD.actionKey
        );
      }
      if (!selectedItem.permissions?.includes("DELETE")) {
        _.remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__DELETE.actionKey
        );
      }
    }
  }

  private filterActionButtonsBasedOnRowDetails(
    allActionButtons: ActionButtonInterface[],
    selectedItems: TableRowModel[]
  ) {
    for (const selectedItem of selectedItems) {
      // stop if we don't have any actions to filter
      if (allActionButtons.length === 0) {
        return;
      }
      // only allow DELETE action for records with ERROR status
      if (selectedItem.status === "ERROR") {
        _.remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey !== DOC_SET_ACTION__DELETE.actionKey
        );
      }
      // remove MOVE action if there is a folder/record that is NOT owned by the current user
      if (selectedItem.ownerUid !== UserInfoService.getUserUid()) {
        _.remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__MOVE.actionKey
        );
      }

      // remove REVIEW action if there is no recordUid associated with this docSet
      if (!selectedItem?.recordUid) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__REVIEW.actionKey
        );
      }

      // remove DOWNLOAD action if the docSet is not viewable internally
      if (selectedItem?.viewer !== "DEFAULT") {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__DOWNLOAD.actionKey
        );
      }

      // remove ADD_TO_QUEUE menu if the docSet is already in a queue
      if (selectedItem?.queueUid) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__ADD_TO_QUEUE.actionKey
        );
      }
    }
  }

  private filterActionButtonsBasedOnMixedSelectionOfFoldersAndRecords(
    allActionButtons: ActionButtonInterface[],
    selectedItems: TableRowModel[]
  ) {
    if (allActionButtons.length === 0) {
      return;
    }
    const isBatchSelection = selectedItems.length > 1;
    const allFolders = selectedItems.every(
      (item) => item.type === DriveNodeType.DIRECTORY
    );
    const allDocSets = selectedItems.every(
      (item) => item.type === DriveNodeType.DOC_SET
    );
    if (allFolders) {
      // only folders
      if (isBatchSelection) {
        _.remove(
          allActionButtons,
          (actionButton) =>
            !BATCH_FOLDER_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      } else {
        _.remove(
          allActionButtons,
          (actionButton) =>
            !FOLDER_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
    } else if (allDocSets) {
      // only DocSets
      if (isBatchSelection) {
        _.remove(
          allActionButtons,
          (actionButton) =>
            !BATCH_DOC_SET_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      } else {
        _.remove(
          allActionButtons,
          (actionButton) =>
            !ALL_DOC_SET_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
    } else {
      // mixed of folders and records
      _.remove(
        allActionButtons,
        (actionButton) =>
          !MULTI_SELECTION_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
      );
    }
  }

  private filterActionButtonsBasedOnRecordStatus(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IDriveNode[]
  ) {
    selectedItems.forEach((selectedItem) => {
      if (selectedItem.status === DriveNodeStatus.UPLOADING) {
        const actionsShouldRemove = [
          RECORD_ACTION__ASSIGN,
          DOC_SET_ACTION__DELETE,
          DOC_SET_ACTION__REVIEW,
          DOC_SET_ACTION__VIEW,
          DOC_SET_ACTION__DOWNLOAD,
          RECORD_ACTION__OPEN_IN_NEW_TAB,
        ];
        forEach(actionsShouldRemove, (action) => {
          remove(
            allActionButtons,
            (actionButton) => actionButton.actionKey === action.actionKey
          );
        });
      } else if (selectedItem.status === DriveNodeStatus.UI_DELETED) {
        forEach(allActionButtons, () => {
          remove(allActionButtons, () => true);
        });
      }
    });
  }

  private filterActionButtonsForGoogleDriveFiles(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IDriveNode[]
  ) {
    const actionsShouldRemove = [
      DOC_SET_ACTION__RENAME,
      DOC_SET_ACTION__SHARE,
      DOC_SET_ACTION__MOVE,
      DOC_SET_ACTION__DELETE,
      DOC_SET_ACTION__ADD_TO_QUEUE,
    ];
    selectedItems.forEach((selectedItem) => {
      if (selectedItem.googleDriveFileId) {
        forEach(actionsShouldRemove, (action) => {
          remove(
            allActionButtons,
            (actionButton) => actionButton.actionKey === action.actionKey
          );
        });
      }
    });
  }

  onTableContextMenu(contextMenuEvent: {
    event: MouseEvent;
    type: ContextmenuType;
    content: any;
  }) {
    this.contextMenu.closeMenu();
    if (contextMenuEvent.type === "body") {
      if (!this.isSelectedBefore(contextMenuEvent.content)) {
        this.selectedItems = [contextMenuEvent.content];
      }
      this.contextMenuContent = contextMenuEvent.content;
      this.contextMenuEvent = contextMenuEvent.event;
      this.contextMenuPos = this.getPosition(this.contextMenuEvent);
    } else {
      this.contextMenuContent = undefined;
      this.contextMenuEvent = undefined;
      this.contextMenuPos = undefined;
      this.selectedItems = [];
    }

    this.reloadContextMenuActionButtons();
    if (this.contextMenuActionButtons.length === 0) {
      this.contextMenu.closeMenu();
    } else {
      setTimeout(() => {
        this.contextMenu.openMenu();
      }, 150);
    }

    contextMenuEvent.event.preventDefault();
    contextMenuEvent.event.stopPropagation();
  }

  private isSelectedBefore(item): boolean {
    return (
      this.selectedItems.length > 0 &&
      this.selectedItems.find((selectedItem) => selectedItem.uid === item.uid)
    );
  }

  private onResetSelection() {
    this.selectedItems = [];
  }

  private getSearchResult(resetPage = false): void {
    this.gotData = false;
    if (resetPage) {
      this.pagination.page = 1;
    }

    this.driveService
      .search({
        name: this.searchTerm,
        pageData: {
          page: this.pagination.page,
          perPage: this.pagination.size,
        },
        view: "FULL",
        sorts: this.sorts.map((item) => item.sort),
      })
      .subscribe((resp) => {
        this.nodes = resp.items;
        this.pagination = new PaginationModel(
          this.pagination.page,
          this.pagination.size,
          resp.pagination.totalResults
        );

        this.basicRows = resp.items.map((item) => ({
          uid: item.uid,
          name: item.name,
          status: item.status,
          owner: item.owner.name,
          ownerUid: item.owner.uid,
          lastModifiedDate: item.lastModifiedDate,
          //TODO(reza) FIX ME
          size: undefined,
          type: item.type,
          permissions: item.permissions,
          shared: item.shared,
          createdAt: item.createdAt,
          viewer: item.viewer,
          googleDriveFileId: item.googleDriveFileId,
          queueUid: item.queueUid,
          queueName: item.queueName,
          queueStage: item.queueStage,
          recordUid: item.recordUid,
        }));
        this.selectedItems = [];
        this.gotData = true;
        this.isTableLoaded = true;
        this.cdr.detectChanges();
      });
  }

  @HostListener("document:click", ["$event"])
  clickedOutside($event) {
    this.contextMenu?.closeMenu();
    this.contextMenuEvent = undefined;
    this.contextMenuPos = undefined;
    this.contextMenuContent = undefined;
  }

  private onMove(row: TableRowModel) {
    this.onBatchMove([row]);
  }

  private onBatchMove(rows: TableRowModel[]) {
    const dialogRef = this.dialog.open(SelectFolderComponent, {
      height: "640px",
      width: "480px",
      data: {
        source: {
          uids: rows.map((row) => row.uid),
        },
        title: "Select destination folder",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.selectedFolder) {
        this.driveService
          .moveNodes(
            rows.map((row) => ({
              uid: row.uid,
              type: row.type,
            })),
            result.selectedFolder.id
          )
          .subscribe((resp) => {
            this.getSearchResult();
          });
      }
    });
  }

  private onAssign(row: TableRowModel) {
    const taskData = new ModalInputModel();
    taskData.mode = ComponentModeEnum.ADD;
    taskData.payload = { abstractUid: row.uid };
    this.taskModalService
      .openNewTaskModal(taskData)
      .afterClosed()
      .subscribe((res) => {
        //
      });
  }

  private onBatchAssign(rows: TableRowModel[]) {
    const taskData = new ModalInputModel();
    taskData.mode = ComponentModeEnum.ADD;
    taskData.payload = { abstractUids: rows.map((row) => row.uid) };
    this.taskModalService
      .openNewTaskModal(taskData)
      .afterClosed()
      .subscribe((res) => {
        //
      });
  }

  private refresh() {
    this.isTableLoaded = false;
    this.onResetSelection();
    this.sorts = [];
  }
}
