import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ItlsDriveService as ItlsDriveService2 } from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-drive.service";
import { InteleaseNotificationService } from "@@intelease/web/intelease/services";
import { finalize } from "rxjs/operators";

@Component({
  selector: "il-add-folder",
  templateUrl: "./add-folder.component.html",
  styleUrls: ["./add-folder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFolderComponent {
  name: string;
  parentFolderUid: string;
  isCreatingNewFolder = false;
  isValidateFields = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dialogRef: MatDialogRef<AddFolderComponent>,
    private driveService: ItlsDriveService2,
    private inteleaseNotificationService: InteleaseNotificationService,
    private cdr: ChangeDetectorRef
  ) {
    this.parentFolderUid = modalData?.parentFolderUid;
  }

  onSaveFolderClicked() {
    if (this.areValidInputs()) {
      this.isCreatingNewFolder = true;
      this.driveService
        .createDirectory(this.name, this.parentFolderUid)
        .pipe(
          finalize(() => {
            this.isCreatingNewFolder = false;
            this.cdr.detectChanges();
          })
        )
        .subscribe(() => {
          this.dialogRef.close({ name: this.name });
        });
    }
  }

  private areValidInputs() {
    if (!this.name || !this.name.length) {
      this.inteleaseNotificationService.openSnackBar(
        "Please enter name for new folder"
      );
      return false;
    }
    return true;
  }
}
