<ng-container *ngIf="!item.hidden">
  <ng-container *ngxPermissionsOnly="expectedAuthorities">
    <!-- normal collapsable -->
    <a
      class="nav-link"
      style="padding-left: 8px"
      [ngClass]="item.classes"
      *ngIf="!item.url && !item.function"
      (click)="toggleOpen($event)"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.url -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && !item.externalUrl && !item.function"
      (click)="toggleOpen($event)"
      [routerLink]="item.url"
      [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && item.externalUrl && !item.function"
      (click)="toggleOpen($event)"
      [href]="item.url"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="!item.url && item.function"
      (click)="toggleOpen($event); item.function()"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && !item.externalUrl && item.function"
      (click)="toggleOpen($event); item.function()"
      [routerLink]="item.url"
      [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a
      class="nav-link"
      [ngClass]="item.classes"
      *ngIf="item.url && item.externalUrl && item.function"
      (click)="toggleOpen($event); item.function()"
      [href]="item.url"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
      <mat-icon class="collapsable-arrow mr-1">keyboard_arrow_right</mat-icon>
      <span class="nav-link-title">{{ item.title }}</span>
      <span
        class="nav-link-badge"
        *ngIf="item.badge"
        [ngStyle]="{
          'background-color': item.badge.bg,
          color: item.badge.fg
        }"
      >
        {{ item.badge.title }}
      </span>
      <ng-container *ngxPermissionsOnly="expectedIconAuthorities">
        <mat-icon
          class="nav-link-icon"
          *ngIf="item.icon && item.iconFunction"
          (click)="item.iconFunction($event)"
          >{{ item.icon }}</mat-icon
        >
        <mat-icon
          class="nav-link-icon"
          *ngIf="item.icon && !item.iconFunction"
          (click)="item.iconFunction($event)"
          >{{ item.icon }}</mat-icon
        >
      </ng-container>
    </ng-template>

    <div class="children" [@slideInOut]="isOpen">
      <il-drive-tree
        [class.active]="driveTreeActive"
        (nodeClicked)="onNodeClicked($event)"
        [showContextMenu]="true"
      ></il-drive-tree>
      <ng-container *ngFor="let item of item.children">
        <itls-nav-vertical-item
          *ngIf="item.type == 'item'"
          [item]="item"
        ></itls-nav-vertical-item>
        <itls-nav-vertical-collapsable
          *ngIf="item.type == 'collapsable'"
          [item]="item"
        ></itls-nav-vertical-collapsable>
        <itls-nav-vertical-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></itls-nav-vertical-group>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
