<div class="add-calendar-container">
  <form (submit)="onAdd()">
    <h1 mat-dialog-title>Add document set into a queue</h1>

    <il-choose-queue-dropdown (select)="onSelectQueue($event)">
    </il-choose-queue-dropdown>

    <div
      mat-dialog-actions
      style="display: flex; align-items: center; justify-content: space-around"
    >
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="!selectedQueue"
      >
        Add
      </button>
    </div>
  </form>
</div>
