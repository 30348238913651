/* tslint:disable */
/* eslint-disable */

/**
 * the request body for appending a value to an existing mention
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("NestedProvisionSubfieldDtoModel")
export class NestedProvisionSubfieldDtoModel {
  /**
   * the key used by the model for finding a value
   */

  @JsonProperty("modelKey", String, true)
  modelKey?: string = "";

  /**
   * the UI-facing name for this key
   */

  @JsonProperty("uiName", String, true)
  uiName?: string = "";
}
