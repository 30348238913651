<div class="advanced-search-container" (click)="$event.stopPropagation()">
  <button
    class="close-btn"
    matTooltip="Close search box"
    mat-icon-button
    (click)="closeModal()"
  >
    <itls-icon svgIcon="icon-cancel" [height]="14"></itls-icon>
  </button>
  <mat-tab-group
    animationDuration="0ms"
    [(selectedIndex)]="selectedTabIndex"
    (selectedTabChange)="selectedTabChange($event)"
  >
    <mat-tab label="Filters">
      <il-advanced-search-filters
        (nextStep)="onChangeTab()"
        (cancelAdvancedSearch)="onCancelAdvancedSearch()"
      ></il-advanced-search-filters>
    </mat-tab>
    <mat-tab label="Columns">
      <il-advanced-search-columns
        (cancelAdvancedSearch)="onCancelAdvancedSearch()"
        (applyAdvancedSearch)="onApplyAdvancedSearch()"
      ></il-advanced-search-columns>
    </mat-tab>
  </mat-tab-group>
</div>
