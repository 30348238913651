import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexModule } from "@angular/flex-layout";
import { CommonModule } from "@angular/common";

import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatTreeModule } from "@angular/material/tree";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatTabsModule } from "@angular/material/tabs";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatListModule } from "@angular/material/list";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgScrollbarModule } from "ngx-scrollbar";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { InteleasePipesModule } from "@@intelease/web/intelease/pipes";
import { AngularResizedEventModule } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";
import {
  ActionButtonModule,
  PgSelectModule,
  ProgressModule,
  SideMenuModule,
} from "@@intelease/web/intelease/components";
import { PgCardModule } from "@@intelease/web/intelease/components/card/card.module";
import { PgPaginationModule } from "@@intelease/web/intelease/components/pagination/pagination.module";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { ItlsDriveComponent } from "@@intelease/web/ui/src/lib/itls-drive/drive/itls-drive.component";
import {
  AddFolderComponent,
  SelectFolderComponent,
} from "@@intelease/web/ui/src/lib/itls-drive/modals";
import { ItlsFolderComponent } from "@@intelease/web/ui/src/lib/itls-drive/folders/itls-folder.component";
import { ItlsDriveTreeComponent } from "@@intelease/web/ui/src/lib/itls-drive/tree/itls-drive-tree.component";
import { ItlsDriveSearchComponent } from "@@intelease/web/ui/src/lib/itls-drive/search/text-search/itls-drive-search.component";
import { ItlsDriveSearchResultsComponent } from "@@intelease/web/ui/src/lib/itls-drive/search-results/itls-drive-search-results.component";
import { AppStateDriveModule } from "@intelease/app-state/drive";
import { AdvancedSearchFiltersComponent } from "./search/advanced-search/filters/itls-advanced-search-filters.component";
import { ItlsFilterModule } from "../itls-filter";
import { AdvancedSearchComponent } from "./search/advanced-search/itls-advanced-search.component";
import { AdvancedSearchColumnsComponent } from "./search/advanced-search/columns/itls-advanced-search-columns.component";
import { ItlsModifyCustomTagModule } from "../itls-modify-custom-tag/itls-modify-custom-tag.module";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";

const MATERIAL_MODULES = [
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTreeModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatProgressBarModule,
  MatTabsModule,
  MatListModule,
  MatAutocompleteModule,
  MatTooltipModule,
  DragDropModule,
];

const INTELEASE_MODULES = [
  ActionButtonModule.forRoot(),
  ProgressModule.forRoot(),
  PgSelectModule,
  PgCardModule.forRoot(),
  PgPaginationModule,
  SideMenuModule.forRoot(),
];

const NGX_BOOTSTRAP = [
  BsDropdownModule.forRoot(),
  ModalModule.forRoot(),
  TooltipModule.forRoot(),
  PopoverModule.forRoot(),
  PaginationModule.forRoot(),
  TypeaheadModule.forRoot(),
];

const COMPONENTS = [
  AdvancedSearchComponent,
  AdvancedSearchColumnsComponent,
  AdvancedSearchFiltersComponent,
  ItlsDriveComponent,
  ItlsFolderComponent,
  ItlsDriveTreeComponent,
  ItlsDriveSearchComponent,
  ItlsDriveSearchResultsComponent,
];

const MODALS = [AddFolderComponent, SelectFolderComponent];

@NgModule({
  declarations: [...COMPONENTS, ...MODALS],
  imports: [
    ...INTELEASE_MODULES,
    ...NGX_BOOTSTRAP,
    ...MATERIAL_MODULES,
    InteleaseCommonModule,
    ItlsCustomIconModule,
    NgxDatatableModule,
    InteleasePipesModule,
    AngularResizedEventModule,
    FormsModule,
    CommonModule,
    RouterModule,
    FlexModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AppStateDriveModule,
    ItlsFilterModule,
    NgScrollbarModule,
    ItlsModifyCustomTagModule,
  ],
  exports: [ItlsDriveTreeComponent, ItlsDriveSearchComponent],
  //entryComponents: [...MODALS],
  providers: [],
})
export class ItlsDriveNoRoutingModule {}
