import { Component, Pipe, PipeTransform } from "@angular/core";
import { ControlWidget } from "../../widget";
import {
  AbstractReviewFacade,
  MentionEditTrackerFacade,
} from "@@intelease/app-state/abstract-review/src";
import { Observable } from "rxjs";
import { DocumentsMapModel } from "@@intelease/app-state/abstract-review/src/lib/models/abstract-review.model";
import { NESTED_MENTION_ATTRIBUTIONS_FIELD_KEY } from "@@intelease/web/intelease/constants";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "sf-textarea-widget",
  template: `
    <div class="widget form-group">
      <div
        [class]="
          (subfieldModelKey$ | async) === schema.modelKey
            ? 'label-header highlight-selected-subfield'
            : 'label-header'
        "
      >
        <div
          class="label-title"
          style="cursor: default;"
          [matTooltip]="schema.description"
          [matTooltipShowDelay]="1000"
        >
          <label
            *ngIf="schema.title"
            [attr.for]="id"
            class="horizontal control-label"
          >
            {{ schema.title }}
          </label>
          <span *ngIf="schema.description" class="formHelp">{{
            schema.description
          }}</span>
        </div>
        <div class="label-actions">
          <mat-icon
            *ngIf="schema.onApproveOrReject"
            [matTooltip]="
              schema?.approvalStatus === 'REJECTED'
                ? 'Undo subfield Rejection'
                : 'Reject the subfield'
            "
            matTooltipShowDelay="1000"
            (click)="onApproveOrReject('REJECTED')"
            [ngStyle]="{
              fontSize: '10px',
              height: '10px',
              width: '10px',
              paddingTop: '8px',
              marginRight: '-4px',
              color: schema?.approvalStatus === 'REJECTED' ? 'red' : 'black',
              cursor: 'pointer'
            }"
          >
            thumb_down
          </mat-icon>
          <mat-icon
            *ngIf="schema.onApproveOrReject"
            [matTooltip]="
              schema?.approvalStatus === 'APPROVED'
                ? 'Undo subfield Approval'
                : 'Approve the subfield'
            "
            matTooltipShowDelay="1000"
            (click)="onApproveOrReject('APPROVED')"
            [ngStyle]="{
              fontSize: '10px',
              height: '10px',
              width: '10px',
              paddingTop: '8px',
              marginRight: '-4px',
              color:
                schema?.approvalStatus === 'APPROVED' ? '#5ae410' : 'black',
              cursor: 'pointer'
            }"
          >
            thumb_up
          </mat-icon>
          <itls-icon
            *ngIf="schema.onLocate"
            matRipple
            svgIcon="icon-location"
            [matTooltip]="schema | locateSubfieldButtonTooltip: docUidToMeta"
            [matTooltipShowDelay]="1000"
            (click)="onLocate(schema.modelKey)"
          ></itls-icon>
          <itls-icon
            *ngIf="schema.onDelete"
            matRipple
            svgIcon="icon-cancel"
            matTooltip="Delete the subfield value"
            [matTooltipShowDelay]="1000"
            (click)="onDelete(schema.modelKey)"
          ></itls-icon>
          <itls-icon
            *ngIf="schema.onOpenDetails"
            matRipple
            svgIcon="icon-edit"
            matTooltip="Open the attributions details for edit"
            [matTooltipShowDelay]="2000"
            (click)="onOpenDetails(schema.modelKey)"
          ></itls-icon>
        </div>
      </div>

      <textarea
        *ngIf="schema.modelKey === ATTRIBUTIONS_FIELD_KEY"
        [readonly]="schema.readOnly"
        [name]="name"
        [value]="control.value"
        class="text-widget textarea-widget form-control form-widget"
        [attr.placeholder]="schema.placeholder"
        [rows]="schema.rows || 2"
        [cols]="schema.cols || 2"
        [attr.maxLength]="schema.maxLength || null"
        [attr.minLength]="schema.minLength || null"
        style="cursor: pointer !important;"
        click-stop-propagation
        (click)="onFocus($event, schema.modelKey)"
      ></textarea>

      <textarea
        *ngIf="schema.modelKey !== ATTRIBUTIONS_FIELD_KEY"
        [readonly]="schema.readOnly"
        [name]="name"
        class="text-widget textarea-widget form-control form-widget"
        [attr.placeholder]="schema.placeholder"
        [rows]="schema.rows || 2"
        [cols]="schema.cols || 2"
        [attr.maxLength]="schema.maxLength || null"
        [attr.minLength]="schema.minLength || null"
        [formControl]="control"
        (focus)="onFocus($event, schema.modelKey)"
      ></textarea>
    </div>
  `,
  styleUrls: ["./textarea.widget.scss"],
})
export class TextAreaWidgetComponent extends ControlWidget {
  subfieldModelKey$: Observable<string | undefined>;

  ATTRIBUTIONS_FIELD_KEY = NESTED_MENTION_ATTRIBUTIONS_FIELD_KEY;
  docUidToMeta: { [documentUid: string]: DocumentsMapModel };
  constructor(
    private readonly mentionEditTrackerFacade: MentionEditTrackerFacade,
    private readonly abstractReviewFacade: AbstractReviewFacade
  ) {
    super();
    this.subfieldModelKey$ =
      this.mentionEditTrackerFacade.getLastTouchedNestedSubfield$;

    this.abstractReviewFacade.getDocumentsShortNameMap$
      .pipe(takeUntilDestroyed())
      .subscribe((documents) => {
        this.docUidToMeta = documents;
      });
  }

  onFocus(ev: FocusEvent, modelKey: string) {
    ev.preventDefault();
    this.schema.onTouched && this.schema.onTouched(modelKey);
  }

  onLocate(modelKey: string) {
    this.schema.onLocate && this.schema.onLocate(modelKey);
  }

  onDelete(modelKey: string) {
    this.schema.onDelete && this.schema.onDelete(modelKey);
  }

  onOpenDetails(modelKey: string) {
    this.schema.onOpenDetails && this.schema.onOpenDetails(modelKey);
  }

  onApproveOrReject(approvalStatus: "APPROVED" | "REJECTED") {
    this.schema.onApproveOrReject &&
      this.schema.onApproveOrReject(this.schema.modelKey, approvalStatus);
  }
}

@Pipe({
  name: "locateSubfieldButtonTooltip",
})
export class LocateSubfieldButtonTooltipPipe implements PipeTransform {
  transform(schema: any, docUidToMeta: any): string {
    return this.getLocationTooltip(schema, docUidToMeta);
  }

  getLocationTooltip(schema, docUidToMeta) {
    if (
      schema?.locationDocAbstractUid &&
      schema?.locationPage &&
      docUidToMeta
    ) {
      if (Object.values(docUidToMeta).length > 1) {
        return `Jump to doc ${
          docUidToMeta[schema.locationDocAbstractUid].shortLabel
        }, page ${schema.locationPage}`;
      } else {
        return `Jump to page ${schema.locationPage}`;
      }
    }
  }
}
