import { Component } from "@angular/core";
import { ControlWidget } from "../../widget";

@Component({
  selector: "sf-ck-text-ckeditor-widget",
  template: `
    <div class="widget form-group">
      <label
        *ngIf="schema.title"
        [attr.for]="id"
        class="horizontal control-label"
      >
        {{ schema.title }}
      </label>
      <il-ck-editor-cp-component
        [model]="control.value"
        (modelChange)="onModelChange($event)"
      >
      </il-ck-editor-cp-component>
    </div>
  `,
})
export class CkTextEditorWidgetComponent extends ControlWidget {
  onModelChange(content: string) {
    this.control.setValue(content);
  }
}
