import {
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { DriveFacade } from "@@intelease/app-state/drive/src";
import { ReportLogicalOperatorEnum } from "@intelease/constants";
import { cloneDeep, isEqual } from "lodash";
import { filter, first } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-advanced-search-filters",
  templateUrl: "./itls-advanced-search-filters.component.html",
  styleUrls: ["./itls-advanced-search-filters.component.scss"],
})
export class AdvancedSearchFiltersComponent implements OnInit {
  selectedFilter: {
    operator: ReportLogicalOperatorEnum;
    value: any[];
  } = {
    operator: ReportLogicalOperatorEnum.AND,
    value: [],
  };
  filterFields: {
    fieldName: string;
    fieldType: string;
    uiName: string;
    category: "GENERAL" | "PROVISION";
    docSetCategories: string[];
  }[] = [];

  @Output() nextStep = new EventEmitter();
  @Output() cancelAdvancedSearch = new EventEmitter();

  constructor(
    public driveFacade: DriveFacade,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.initActions();
  }

  initActions() {
    this.driveFacade.getAdvancedSearchFieldsList$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((advancedSearchFields) => {
        if (advancedSearchFields) {
          this.filterFields = advancedSearchFields;
        }
      });

    this.driveFacade.getSelectedFilter$
      .pipe(
        filter(
          (advancedSearchFilterModel) =>
            !isEqual(advancedSearchFilterModel, {
              operator: ReportLogicalOperatorEnum.AND,
              value: [],
            })
        ),
        first()
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((advancedSearchFilterModel) => {
        this.selectedFilter = cloneDeep(advancedSearchFilterModel);
      });
  }

  onFilterModelChange(evt) {
    this.driveFacade.setSelectedFilter(cloneDeep(evt));
  }

  onNextBtnClick() {
    this.nextStep.emit();
  }

  onCancelBtnClick() {
    this.cancelAdvancedSearch.emit();
  }
}
