import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  EntityFieldModel,
  ModalInputModel,
  ModalResponseModel,
  PaginationModel,
} from "@@intelease/web/intelease/models";
import {
  ColumnMode,
  ContextmenuType,
  DatatableComponent,
  SelectionType,
  SortType,
} from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CommonModalService,
  UploadingBoxDialogService,
} from "@@intelease/web/common/services";
import {
  InteleaseNotificationService,
  MainDrawerService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { MatDialog } from "@angular/material/dialog";
import {
  AdexRouterService,
  QueueFeatureService,
} from "@@intelease/web/intelease/utils";
import { NewShareEntityComponent } from "@@intelease/web/ui/src/lib/new-share-entity/new-share-entity.component";
import { ResizedEvent } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";
import { combineLatest, Subject, Subscription } from "rxjs";
import {
  ItlsDriveService as ItlsDriveService2,
  NavigationType,
} from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-drive.service";
import {
  ALL_DOC_SET_ACTION_BUTTONS_KEYS,
  BATCH_DOC_SET_ACTION_BUTTONS_KEYS,
  BATCH_FOLDER_ACTION_BUTTONS_KEYS,
  COMMON_ACTION_BUTTONS,
  DELETE_DOC_SET_ACTION_BUTTONS_KEYS,
  DOC_SET_ACTION__ADD_TO_QUEUE,
  DOC_SET_ACTION__DELETE,
  DOC_SET_ACTION__DOWNLOAD,
  DOC_SET_ACTION__FULL_EXPORT,
  DOC_SET_ACTION__MERGE,
  DOC_SET_ACTION__MOVE,
  DOC_SET_ACTION__RENAME,
  DOC_SET_ACTION__REVIEW,
  DOC_SET_ACTION__SHARE,
  DOC_SET_ACTION__VIEW,
  EXPORT_DOC_SET_ACTION_BUTTONS_KEYS,
  FOLDER_ACTION_BUTTONS_KEYS,
  FOLDER_TABLE,
  MULTI_SELECTION_ACTION_BUTTONS_KEYS,
  RECORD_ACTION__ASSIGN,
  RECORD_ACTION__OPEN_IN_NEW_TAB,
} from "@@intelease/web/ui/src/lib/itls-drive-v2/constants";
import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";
import { MatMenuTrigger } from "@angular/material/menu";
import { NavigationItemService } from "@@intelease/web/intelease/constants/navigation-item.service";
import {
  AddFolderComponent,
  AddToQueueComponent,
  AddToQueueModalInput,
  SelectFolderComponent,
} from "@@intelease/web/ui/src/lib/itls-drive-v2/modals";
import { ItlsBreadcrumbService } from "@@intelease/web/intelease/components/toolbar/breadcrumb";
import {
  FullNodeModel,
  LightNodeNameV2Model,
} from "@@intelease/web/common/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { TaskModalService } from "@@intelease/web/tasks";
import {
  SortDirectionEnum,
  SortDirectionLowcaseEnum,
} from "@@intelease/web/common/enums/sort-direction.enum";
import { FormModalService } from "@@intelease/web/ui";
import {
  APP_ENUMS_CONST,
  ENTITY_FORM_SCHEMA_ONLY_NAME_CONST,
} from "@@intelease/web/intelease/constants";
import { cloneDeep, forEach, isEqual, remove } from "lodash";
import { ItlsShareDriveService } from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-share-drive.service";
import { CentrifugeService } from "@@intelease/app-services/notifications/src";
import { NotificationTypesEnum } from "@@intelease/app-models/notifications/src";
import {
  DriveFacade,
  DriveNodeCategory,
  DriveNodeCategoryUppercaseEnum,
  DriveNodeStatus,
  DriveNodeType,
  IDriveNode,
  Permission,
  SelectedColumnFieldsModel,
} from "@intelease/app-state/drive-v2";
import { ItlsModifyCustomTagComponent } from "../../itls-modify-custom-tag/itls-modify-custom-tag.component";
import { filter, take } from "rxjs/operators";
import { QUEUE_STATUS } from "@@intelease/app-state/queue/src";
import { MergeDocSetModalService } from "../../itls-merge-doc-sets";
import {
  DeleteMultipleNodesResponseModel,
  EditLbxRolesDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { SettingItemKeys } from "@@intelease/app-models/settings";
import { CommonFacade } from "@@intelease/app-state/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

interface LoadDirectoriesOptions {
  resetPage?: boolean;
  targetNodeOffset?: number;
}

export const PAGINATION_SIZE = 20;

@Component({
  selector: "intelease-folder-v2",
  templateUrl: "./itls-folder.component.html",
  styleUrls: ["./itls-folder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsFolderComponent implements OnInit {
  @Output() newTask: EventEmitter<any> = new EventEmitter();
  @Input() tableColumns: EntityFieldModel[] = [];
  @ViewChild("table") table: DatatableComponent;
  actionMaps: { [key: string]: any };
  tableUpdated = false;

  // custom fields (remove anything above this)
  private subscriptions: Subscription = new Subscription();
  columnModeSetting: ColumnMode | keyof typeof ColumnMode =
    window.innerWidth < 960 ? "standard" : "force";
  scrollBarHorizontal = window.innerWidth < 960;
  SelectionType = SelectionType;
  pagination: PaginationModel = new PaginationModel(1, PAGINATION_SIZE);
  basicRows: IDriveNode[] = [];
  columns: any[] = [];
  selectedItems: any[] = [];
  isTableLoaded = false;
  isRecordsLoaded = false;
  isAdvancedSearchActive = false;
  nodes: FullNodeModel[] = [];
  @ViewChild("contextMenuTrigger", { static: false })
  contextMenu: MatMenuTrigger;
  contextMenuContent: any;
  contextMenuEvent: MouseEvent;
  contextMenuPos: { x: number; y: number };
  folderUid: string;
  category:
    | "favorite"
    | "shared"
    | "owned"
    | "deleted"
    | "all"
    | "documents"
    | "google_drive";
  navigationType: NavigationType;
  sorts: {
    sorts: {
      prop: string;
      dir: SortDirectionLowcaseEnum;
    }[];
    sort: string;
  };
  contextMenuActionButtons: ActionButtonInterface[] = [];
  isShiftHold: boolean;
  sortType = SortType;
  isQueueEnabled = false;
  allowFullExport = false;

  lbxRoles: EditLbxRolesDtoModel["lbxRoles"];

  destroyRef = inject(DestroyRef);

  constructor(
    private adexRouter: AdexRouterService,
    private route: ActivatedRoute,
    private router: Router,
    private commonModalService: CommonModalService,
    private inteleaseNotificationService: InteleaseNotificationService,
    public mainDrawerService: MainDrawerService,
    private itlsShareDriveService: ItlsShareDriveService,
    private dialog: MatDialog,
    private driveService: ItlsDriveService2,
    private cdr: ChangeDetectorRef,
    private itlsBreadcrumbService: ItlsBreadcrumbService,
    private taskModalService: TaskModalService,
    private formModalService: FormModalService,
    public uploadingBoxDialogService: UploadingBoxDialogService,
    private driveFacade: DriveFacade,
    private queueFeatureService: QueueFeatureService,
    private mergeDocSetsModalService: MergeDocSetModalService,
    private commonFacade: CommonFacade
  ) {
    this.isQueueEnabled = this.queueFeatureService.isFeatureEnabled();
    this.commonFacade
      .getAccountSettingValueByKey$(SettingItemKeys.ALLOW_FULL_EXPORT)
      .pipe(take(1))
      .pipe(takeUntilDestroyed())
      .subscribe((value) => (this.allowFullExport = value));
    this.initTableColumns();
    this.initActions();
  }

  ngOnInit() {
    this.initListener();
    this.lbxRoles = UserInfoService.getLbxRoles();
  }

  initListener() {
    this.subscriptions.add(
      this.route.params.subscribe((params) => {
        if (params["category"]?.includes("_")) {
          this.router.navigate(["drive", params["category"].replace("_", "-")]);
        }

        const queryParams = this.route.snapshot.queryParams;
        if (
          params.category !== this.category ||
          params.folderUid !== this.folderUid
        ) {
          this.driveFacade.closeAdvancedSearch();
        }
        if (params.category) {
          this.setSorts();
          this.category = params.category.replace("-", "_");
          this.folderUid = undefined;
          this.loadDirectories({
            resetPage: true,
            targetNodeOffset: queryParams?.targetNodeOffset,
          });
        } else if (params.folderUid) {
          this.setSorts();
          this.category = undefined;
          this.folderUid = params.folderUid;
          this.loadDirectories({
            resetPage: true,
            targetNodeOffset: queryParams?.targetNodeOffset,
          });
        }
      })
    );

    this.subscriptions.add(
      this.driveFacade.getAppliedSelectedColumnFields$.subscribe(
        (selectedColumnFields) => {
          this.columns = cloneDeep(selectedColumnFields);
        }
      )
    );

    this.subscriptions.add(
      NavigationItemService.openAddFolder$.subscribe(() =>
        this.openCreateFolderDialog()
      )
    );

    this.subscriptions.add(
      this.driveService.newFolderCreated$.subscribe((item) => {
        if (item.refreshDirectories) this.loadDirectories();
      })
    );

    this.subscriptions.add(
      this.driveFacade.getPagination$.subscribe((pagination) => {
        this.pagination = {
          page: pagination?.page,
          size: PAGINATION_SIZE,
          totalResults: pagination?.totalResults,
        };
      })
    );

    combineLatest([
      this.driveFacade.isAllDriveNodesLoading$,
      this.driveFacade.loadAllDriveNodesSucceeded$,
      this.driveFacade.isAdvancedSearchActive$,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([
          isLoadAllRecordsLoading,
          loadAllRecordsSucceeded,
          isAdvancedSearchActive,
        ]) => {
          this.isTableLoaded = loadAllRecordsSucceeded;
          this.isRecordsLoaded = !isLoadAllRecordsLoading;
          this.isAdvancedSearchActive = isAdvancedSearchActive;
          this.cdr.markForCheck();
        }
      );

    this.driveService.loadAdvancedSearchRecords
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((sort) => {
        if (sort)
          this.driveFacade.loadAdvancedSearchRecords({
            sorts: sort,
          });
      });

    combineLatest([this.driveFacade.isAdvancedSearchActive$])
      .pipe(filter(([isAdvancedSearchActive]) => !isAdvancedSearchActive))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isAdvancedSearchActive]) => {
        if (this.isTableLoaded) {
          this.setSorts();
          this.loadDirectories();
        }
      });

    this.driveFacade.isAllDriveNodesLoading$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((isLoadAllRecordsLoading) => !isLoadAllRecordsLoading)
      )
      .subscribe((isLoadAllRecordsLoading) => {
        this.driveFacade
          .getRecords$(this.category as DriveNodeCategory)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((resp) => {
            this.basicRows = resp.items;
            this.cdr.markForCheck();
          });
      });

    this.onRecordStatusUpdate();

    this.driveFacade.isSortRecordFailed$
      .pipe(filter(Boolean))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.setSorts();
      });
  }

  initTableColumns() {
    this.subscriptions.add(
      this.driveFacade.isAdvancedSearchActive$
        .pipe(filter((value) => !value))
        .subscribe(() => {
          if (this.navigationType === "OWNED") {
            this.driveFacade.setSelectedColumnFields(
              cloneDeep(
                FOLDER_TABLE.ownedColumns
              ) as SelectedColumnFieldsModel[]
            );
          } else {
            this.driveFacade.setSelectedColumnFields(
              cloneDeep(
                FOLDER_TABLE.defaultColumns
              ) as SelectedColumnFieldsModel[]
            );
          }
        })
    );
    // this.columns.unshift(_.first(ROOT_FOLDER_TABLE_CONST.fixedColumns))
    // this.columns.push(_.last(ROOT_FOLDER_TABLE_CONST.fixedColumns))
  }

  onPageChange(evt) {
    this.driveFacade.isAdvancedSearchActive$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isAdvancedSearchActive) => {
        isAdvancedSearchActive
          ? this.driveFacade.changePage(evt?.page)
          : this.table.onFooterPage(evt);
      });
    // this.driveFacade.changePage(evt?.page)
    // this.table.onFooterPage(evt)
  }

  onButtonActionClick(actionKey: string, row) {
    this.actionMaps[actionKey](row);
  }

  changePage(pageInfo): void {
    this.pagination.page = ++pageInfo.offset;
    this.loadDirectories();
  }

  canIngestQueueLbxUser() {
    return (
      !UserInfoService.isLbxAccountUser() ||
      this.lbxRoles.includes("FORMS_ADMIN")
    );
  }

  /**
   * This is used by the html table.
   */
  getUid(row: IDriveNode): string {
    return row?.uid;
  }

  private getCurrentPageSelectedItemsLen() {
    const currentPageUidList = this.basicRows.map((row: any) => row.uid);
    return this.selectedItems.filter(
      (selectedItem: any) => currentPageUidList.indexOf(selectedItem.uid) !== -1
    ).length;
  }

  onSortClick(evt: any) {
    if (evt.newValue) {
      this.setSorts(evt);
      if (this.sorts.sort) {
        const payload = {
          category: this.category?.toUpperCase() as
            | DriveNodeCategoryUppercaseEnum
            | undefined,
          directoryUid: this.folderUid,
          page: this.pagination.page,
          perPage: PAGINATION_SIZE,
          sorts: this.sorts.sort,
        };
        this.isAdvancedSearchActive
          ? this.driveService.loadAdvancedSearchRecords.next([this.sorts.sort])
          : this.driveFacade.sortDriveNodes(payload);
      }
    }
  }

  initActions() {
    this.actionMaps = {
      OPEN_IN_NEW_TAB: () => this.onOpenInNewTabClick(this.selectedItems[0]),
      DOC_SET_VIEW: () => this.onDocSetView(this.selectedItems[0]),
      DOC_SET_REVIEW: () => this.onDocSetReview(this.selectedItems[0]),
      MOVE: () =>
        this.selectedItems.length > 1
          ? this.onBatchMove(this.selectedItems)
          : this.onMove(this.selectedItems[0]),
      DOWNLOAD: () =>
        this.selectedItems.length > 1
          ? this.onBatchDownload(this.selectedItems)
          : this.onDownload(this.selectedItems[0]),
      FULL_EXPORT: () =>
        this.selectedItems.length > 1
          ? this.onBatchFullExport(this.selectedItems)
          : this.onFullExport(this.selectedItems[0]),
      HISTORY_EXPORT: () =>
        this.selectedItems.length > 1
          ? this.onBatchHistoryExport(this.selectedItems)
          : this.onHistoryExport(this.selectedItems[0]),
      SHARE: () =>
        this.selectedItems.length > 1
          ? this.onBatchShare(this.selectedItems)
          : this.onShare(this.selectedItems[0]),
      RENAME: () => this.onRename(this.selectedItems[0]),
      DELETE: () =>
        this.selectedItems.length > 1
          ? this.onBatchDelete(this.selectedItems)
          : this.onDelete(this.selectedItems[0]),
      RESTORE: () => this.onRestore(this.selectedItems[0]),
      ASSIGN: () =>
        this.selectedItems.length > 1
          ? this.onBatchAssign(this.selectedItems)
          : this.onAssign(this.selectedItems[0]),
      ADD_TO_QUEUE: () =>
        this.selectedItems.length > 1
          ? this.onBatchAddToQueue(this.selectedItems)
          : this.onAddToQueue(this.selectedItems[0]),
      DOC_SET_MERGE: () => this.onMergeDocSets(this.selectedItems),
    };
  }

  private onMergeDocSets(rows: IDriveNode[]) {
    this.mergeDocSetsModalService
      .openMergeDocSetsModal(rows.map((row) => row.uid))
      .subscribe(() => {
        this.loadDirectories();
      });
  }

  private onAddToQueue(row: IDriveNode) {
    this.onBatchAddToQueue([row]);
  }

  private onBatchAddToQueue(rows: IDriveNode[]) {
    const docSetUids = rows.map((row) => row.uid);
    const modalInputData = new AddToQueueModalInput();
    modalInputData.payload = { docSetUids: docSetUids };
    this.dialog.open<
      AddToQueueComponent,
      AddToQueueModalInput,
      ModalResponseModel
    >(AddToQueueComponent, {
      data: modalInputData,
    });
  }

  private onDownload(thisRow: IDriveNode) {
    this.onBatchDownload([thisRow]);
  }

  private onBatchDownload(rows: IDriveNode[]) {
    const nodes = rows.map(
      (row) =>
        new LightNodeNameV2Model({
          name: row.name,
          type: row.type,
          uid: row.uid,
        })
    );
    this.driveService.exportDriveNodes(nodes);
  }

  private onFullExport(thisRow: IDriveNode) {
    this.onBatchFullExport([thisRow]);
  }

  private onHistoryExport(thisRow: IDriveNode) {
    this.onBatchHistoryExport([thisRow]);
  }

  private onBatchFullExport(rows: IDriveNode[]) {
    const nodes = rows.map(
      (row) =>
        new LightNodeNameV2Model({
          name: row.name,
          type: row.type,
          uid: row.uid,
        })
    );
    this.driveService.specialExportDriveNodes(nodes, "FULL");
  }

  private onBatchHistoryExport(rows: IDriveNode[]) {
    const nodes = rows.map(
      (row) =>
        new LightNodeNameV2Model({
          name: row.name,
          type: row.type,
          uid: row.uid,
        })
    );
    this.driveService.specialExportDriveNodes(nodes, "HISTORY");
  }

  private onOpenInNewTabClick(selectedItem: IDriveNode) {
    if (selectedItem.type === DriveNodeType.DOC_SET) {
      this.onDocSetView(selectedItem);
    } else if (selectedItem.type === DriveNodeType.DIRECTORY) {
      const foldersUrl = `/drive/folders/${selectedItem.uid}`;
      this.adexRouter.navigate([foldersUrl]);
    }
  }

  modifyCustomTag(row: IDriveNode, value, actionType: string) {
    const { uid } = row;
    const modalInput = new ModalInputModel();
    modalInput.mode = ComponentModeEnum.ADD;
    modalInput.payload = {
      customMessage: false,
      customTags: value,
    };
    this.dialog
      .open(ItlsModifyCustomTagComponent, {
        data: {
          abstractUid: uid,
          ...modalInput,
        },
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse.data.customTags) {
          this.driveFacade.updateCustomTags(uid, modalResponse.data.customTags);
        }
      });
  }

  onRowClick(row: IDriveNode, value, evt) {
    //
  }

  onRowDbClick(row: IDriveNode, evt): void {
    this.driveFacade.getNodeNavigation$
      .pipe(take(1))
      .subscribe((nodeNavigation) => {
        if (
          nodeNavigation.category === "DELETED" &&
          row.type !== DriveNodeType.DIRECTORY
        ) {
          return;
        }

        if (evt.ctrlKey || evt.shiftKey || evt.metaKey) return;
        if (row.type === DriveNodeType.DIRECTORY) {
          const foldersUrl = `/drive/folders/${row.uid}`;
          this.adexRouter.navigate([foldersUrl]);
        } else if (row.type === DriveNodeType.DOC_SET) {
          this.onDocSetView(row);
        }
      });
  }

  private equalsAnyIgnoreCase(status: string, ...expectedStatuses: string[]) {
    return (
      expectedStatuses?.findIndex(
        (expectedStatus) =>
          status?.toLowerCase() === expectedStatus.toLowerCase()
      ) !== -1
    );
  }

  private createDeleteModal(rows: IDriveNode[]) {
    const distinctTypes = new Set(rows.map((row) => row.type));
    const deleteModalInput = new ModalInputModel();
    deleteModalInput.mode = ComponentModeEnum.REMOVE;
    deleteModalInput.payload = {
      customMessage: true,
    };
    if (distinctTypes.size === 1 && distinctTypes.has(DriveNodeType.DOC_SET)) {
      // only docSets
      deleteModalInput.payload.title = "Delete DocSet(s)";
      deleteModalInput.payload.message = `This will permanently delete ${rows.length} document(s). Are you sure?`;
      deleteModalInput.payload.params = {
        deletedRecordsNum: rows.length,
      };
    } else if (
      distinctTypes.size === 1 &&
      distinctTypes.has(DriveNodeType.DIRECTORY)
    ) {
      // only folders
      deleteModalInput.payload.title = "Delete Folder(s)";
      deleteModalInput.payload.message = `This will permanently delete ${rows.length} folder(s). Are you sure?`;
      deleteModalInput.payload.params = {
        deletedFoldersNum: rows.length,
      };
    } else {
      const deletedRecordsNum = rows.filter(
        (row) => row.type === DriveNodeType.DOC_SET
      ).length;
      const deletedFoldersNum = rows.filter(
        (row) => row.type === DriveNodeType.DIRECTORY
      ).length;
      deleteModalInput.payload.title = "Delete Folder(s) / DocSet(s)";
      deleteModalInput.payload.message = `This will permanently delete ${deletedFoldersNum} folder(s) and ${deletedRecordsNum} document(s). Are you sure?`;
      deleteModalInput.payload.params = {
        deletedRecordsNum,
        deletedFoldersNum,
      };
    }
    return deleteModalInput;
  }

  private onRestore(row: IDriveNode) {
    //
  }

  private onDelete(row: IDriveNode) {
    this.onBatchDelete([row]);
  }

  private onBatchDelete(rows: IDriveNode[]) {
    this.commonModalService
      .openDeleteConfirmModal(this.createDeleteModal(rows))
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse?.type === ModalsResponseTypeEnum.CLOSE) {
          const payload = rows.map((row) => ({
            type: row.type,
            uid: row.uid,
          }));
          this.subscriptions.add(
            this.driveService.deleteNodes(payload).subscribe((res) => {
              if (!res?.error) {
                this.driveService.deleteFolders$.next(
                  payload.map(
                    (item) => item.type === DriveNodeType.DIRECTORY && item.uid
                  )
                );
                this.loadDirectories();
              } else {
                this.onBatchDeleteFailedAndRetry(rows, res);
              }
            })
          );
        }
      });
  }

  private onBatchDeleteFailedAndRetry(
    rows: IDriveNode[],
    previousErrorResp: DeleteMultipleNodesResponseModel
  ) {
    let modalMessage = `<span> ${previousErrorResp.errorMessage} </span>
        <br/>
        <br/>
        <span>
            The following folders are currently configured to store record summary output from relevant queues
        </span>
        <br/>
        <ul>
        `;

    for (let i = 0; i < previousErrorResp?.nonRemovableFolders?.length; i++) {
      modalMessage += ` <li> ${previousErrorResp?.nonRemovableFolders[i]} connected to ${previousErrorResp?.connectedQueues[i]} </li>`;
    }
    modalMessage += `</ul>`;

    const modalInputData = new ModalInputModel(ComponentModeEnum.REMOVE, {
      isHtmlMessage: true,
      message: modalMessage,
      title: "Confirm Delete Queue Connected Folders?",
    });

    this.commonModalService
      .openGenericOkCancelModal(modalInputData)
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse?.type === ModalsResponseTypeEnum.CLOSE) {
          const payload = rows.map((row) => ({
            type: row.type,
            uid: row.uid,
          }));
          this.subscriptions.add(
            this.driveService.deleteNodes(payload, true).subscribe((res) => {
              if (!res?.error) {
                this.driveService.deleteFolders$.next(
                  payload.map(
                    (item) => item.type === DriveNodeType.DIRECTORY && item.uid
                  )
                );
                this.loadDirectories();
              } else {
                this.inteleaseNotificationService.openSnackBar(
                  "Something went wrong!"
                );
              }
            })
          );
        }
      });
  }

  private onShare(row: IDriveNode): void {
    this.dialog.open(NewShareEntityComponent, {
      width: "350px",
      height: "420px",
      panelClass: "no-padding-modal-panel",
      data: {
        shareEntityService: this.itlsShareDriveService,
        entity: {
          entityUid: row.uid,
          entityType: row.type,
        },
      },
    });
  }

  private onBatchShare(rows: IDriveNode[]): void {
    this.dialog.open(NewShareEntityComponent, {
      width: "350px",
      height: "420px",
      panelClass: "no-padding-modal-panel",
      data: {
        shareEntityService: this.itlsShareDriveService,
        entities: rows.map((row) => ({
          entityUid: row.uid,
          entityType: row.type,
        })),
      },
    });
  }

  private onRename(row: IDriveNode): void {
    let saveButtonTooltip;
    let editTitle;
    switch (row.type) {
      case DriveNodeType.RECORD:
        editTitle = "Edit Record";
        break;
      case DriveNodeType.DIRECTORY:
        editTitle = "Edit Folder";
        break;
      case DriveNodeType.DOC_SET:
        editTitle = "Edit Document Set";
        break;
      default:
        throw new Error("unexpected value: " + row.type);
    }
    const modalData = new ModalInputModel();
    modalData.mode = ComponentModeEnum.EDIT;
    modalData.payload = {
      editTitle,
      formSchema: { ...ENTITY_FORM_SCHEMA_ONLY_NAME_CONST },
      model: { name: row.name },
    };
    switch (modalData.payload.editTitle) {
      case "Edit Folder":
        saveButtonTooltip = {
          emptyField: "You must enter a folder name!",
          notValidField: "No change detected in the folder name!",
          firstMessage: "No change detected in the folder name!",
        };
        break;
      case "Edit Record":
        saveButtonTooltip = {
          emptyField: "You must enter a record name!",
          notValidField: "No change detected in the record name!",
          firstMessage: "No change detected in the record name!",
        };
        break;
      case "Edit Document Set":
        saveButtonTooltip = {
          emptyField: "You must enter a document set name!",
          notValidField: "No change detected in the document set name!",
          firstMessage: "No change detected in the document set name!",
        };
        break;
      default:
        break;
    }
    const modalRef = this.formModalService.openNewFormModal(
      modalData,
      {
        minWidth: 340,
      },
      saveButtonTooltip
    );
    modalRef.afterClosed().subscribe((res) => {
      if (res && res?.data && res?.data?.name) {
        const { name } = res.data;
        this.driveService.renameFolder$.next({
          uid: row.uid,
          name: name,
        });
        this.driveFacade.renameDriveNode({ newName: name, row });
      }
    });
  }

  private onDocSetView(row: IDriveNode): void {
    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UI_DELETED
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this document, as it is deleted."
      );
      return;
    }

    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UPLOADING
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this document, as its files are still uploading."
      );
      return;
    }
    if (row.viewer === "DEFAULT") {
      const previewUrl = `${"/documents"}/${row.uid}`;
      this.adexRouter.openInNewTab(previewUrl);
    } else if (row.viewer === "GOOGLE_DRIVE_PREVIEW") {
      this.adexRouter.openGoogleDriveFilePreview(row.googleDriveFileId);
    }
  }

  private onDocSetReview(row: IDriveNode): void {
    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UI_DELETED
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this document, as it is deleted."
      );
      return;
    }

    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UPLOADING
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this document, as its files are still uploading."
      );
      return;
    }

    if (
      this.equalsAnyIgnoreCase(
        row.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.PROCESSING,
        APP_ENUMS_CONST.DOCUMENT_STATUS.ERROR,
        APP_ENUMS_CONST.DOCUMENT_STATUS.QUEUED
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this record, as it is processing. Please wait and refresh the page, till the processing is complete."
      );
      return;
    }

    this.adexRouter.navigateToRecordReviewPage(row.recordUid, true);
  }

  onResized(event: ResizedEvent) {
    if (!this.tableUpdated) {
      this.tableUpdated = true;
      setTimeout(() => {
        combineLatest([
          this.driveFacade.getRecords$(this.category as DriveNodeCategory),
          this.driveFacade.loadAllDriveNodesSucceeded$,
        ])
          .pipe(
            filter(([resp, isLoaded]) => isLoaded && !isEqual(resp.items, [])),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe(([resp, isLoaded]) => {
            this.basicRows = resp.items;
            this.cdr.detectChanges();
          });
        try {
          this.table.ngDoCheck();
        } catch (e) {
          //
        }
        this.tableUpdated = false;
      }, 150);
    }
  }

  getPosition(e: MouseEvent) {
    const leftMenu = document.querySelector("left-menu")?.clientWidth || 0;
    let posx = 0;
    let posy = 0;

    if (e.pageX || e.pageY) {
      posx = e.pageX - leftMenu;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }

    return {
      x: this.mainDrawerService.isOpen ? posx - 270 : posx - 25,
      y: posy - 55,
    };
  }

  private reloadContextMenuActionButtons() {
    this.contextMenuActionButtons = this.getContextMenuActionButtons();
  }

  private getContextMenuActionButtons(): ActionButtonInterface[] {
    const allActionButtons = [...COMMON_ACTION_BUTTONS];

    if (this.selectedItems.length === 0) {
      return [];
    }

    const userUid = UserInfoService.getUserUid();
    this.driveFacade.getNodeNavigation$
      .pipe(take(1))
      .subscribe((nodeNavigation) => {
        if (nodeNavigation.category === "DELETED") {
          allActionButtons.splice(0, allActionButtons.length);
        }
      });

    this.filterActionButtonsBasedOnAppEnv(allActionButtons);

    this.filterActionButtonsBasedOnAccountSettings(
      allActionButtons,
      this.selectedItems
    );

    this.filterActionButtonsBasedOnSelectedItemsPermissions(
      allActionButtons,
      this.selectedItems
    );

    this.filterActionButtonsBasedOnRowDetails(
      allActionButtons,
      this.selectedItems,
      userUid
    );

    this.filterActionButtonsBasedOnMixedSelectionOfFoldersAndRecords(
      allActionButtons,
      this.selectedItems
    );

    this.filterActionButtonsBasedOnRecordOwner(
      allActionButtons,
      this.selectedItems,
      userUid
    );

    this.filterActionButtonsBasedOnRecordStatus(
      allActionButtons,
      this.selectedItems
    );

    this.filterActionButtonsForGoogleDriveFiles(
      allActionButtons,
      this.selectedItems
    );

    return allActionButtons;
  }

  private filterActionButtonsBasedOnAppEnv(
    allActionButtons: ActionButtonInterface[]
  ) {
    if (!this.isQueueEnabled || !this.canIngestQueueLbxUser()) {
      remove(
        allActionButtons,
        (actionButton) =>
          actionButton.actionKey === DOC_SET_ACTION__ADD_TO_QUEUE.actionKey
      );
    }
  }

  private filterActionButtonsBasedOnAccountSettings(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IDriveNode[]
  ) {
    if (
      !this.allowFullExport ||
      selectedItems.length > 1 ||
      (selectedItems.length > 0 &&
        selectedItems[0].type === DriveNodeType.DIRECTORY)
    ) {
      remove(
        allActionButtons,
        (actionButton) =>
          actionButton.actionKey === DOC_SET_ACTION__FULL_EXPORT.actionKey
      );
    }
  }

  private filterActionButtonsBasedOnSelectedItemsPermissions(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IDriveNode[]
  ) {
    for (const selectedItem of selectedItems) {
      // stop if we don't have any actions to filter
      if (allActionButtons.length === 0) {
        return;
      }
      if (!selectedItem.permissions?.includes(Permission.READ)) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey ===
              RECORD_ACTION__OPEN_IN_NEW_TAB.actionKey ||
            actionButton.actionKey === DOC_SET_ACTION__REVIEW.actionKey
        );
      }
      if (!selectedItem.permissions?.includes(Permission.EDIT)) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__RENAME.actionKey ||
            actionButton.actionKey === RECORD_ACTION__ASSIGN.actionKey ||
            actionButton.actionKey === DOC_SET_ACTION__ADD_TO_QUEUE.actionKey ||
            actionButton.actionKey === DOC_SET_ACTION__MOVE.actionKey
        );
      }
      if (!selectedItem.permissions?.includes(Permission.SHARE)) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__SHARE.actionKey
        );
      }
      if (!selectedItem.permissions?.includes(Permission.EXPORT)) {
        remove(allActionButtons, (actionButton) =>
          EXPORT_DOC_SET_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
      if (!selectedItem.permissions?.includes(Permission.DELETE)) {
        remove(allActionButtons, (actionButton) =>
          DELETE_DOC_SET_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
    }
  }

  private filterActionButtonsBasedOnRowDetails(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IDriveNode[],
    userUid: string
  ) {
    for (const selectedItem of selectedItems) {
      // stop if we don't have any actions to filter
      if (allActionButtons.length === 0) {
        return;
      }
      // only allow DELETE action for records with ERROR status
      if (selectedItem.status === "ERROR") {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey !== DOC_SET_ACTION__DELETE.actionKey
        );
      }
      // remove MOVE action if there is a folder/record that is NOT owned by the current user
      if (selectedItem.ownerUid !== userUid) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__MOVE.actionKey
        );
      }
      // remove REVIEW action if there is no recordUid associated with this docSet
      if (!selectedItem.recordUid) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__REVIEW.actionKey ||
            actionButton.actionKey === DOC_SET_ACTION__FULL_EXPORT.actionKey
        );
      }
      // remove ADD_TO_QUEUE menu if the docSet is already in a queue
      if (selectedItem.queueUid) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__ADD_TO_QUEUE.actionKey
        );
      }
      // remove DOWNLOAD action if the docSet is not viewable internally
      if (selectedItem?.viewer !== "DEFAULT") {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === DOC_SET_ACTION__DOWNLOAD.actionKey
        );
      }
    }
  }

  private filterActionButtonsBasedOnMixedSelectionOfFoldersAndRecords(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IDriveNode[]
  ) {
    if (allActionButtons.length === 0) {
      return;
    }
    const isBatchSelection = selectedItems.length > 1;
    const allFolders = selectedItems.every(
      (item) => item.type === DriveNodeType.DIRECTORY
    );
    const allDocSets = selectedItems.every(
      (item) => item.type === DriveNodeType.DOC_SET
    );
    if (allFolders) {
      // only folders
      if (isBatchSelection) {
        remove(
          allActionButtons,
          (actionButton) =>
            !BATCH_FOLDER_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      } else {
        remove(
          allActionButtons,
          (actionButton) =>
            !FOLDER_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
    } else if (allDocSets) {
      // only doc sets
      if (isBatchSelection) {
        remove(
          allActionButtons,
          (actionButton) =>
            !BATCH_DOC_SET_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      } else {
        remove(
          allActionButtons,
          (actionButton) =>
            !ALL_DOC_SET_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
    } else {
      // mixed of folders and records
      remove(
        allActionButtons,
        (actionButton) =>
          !MULTI_SELECTION_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
      );
    }
  }

  private filterActionButtonsBasedOnRecordStatus(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IDriveNode[]
  ) {
    const actionsShouldRemoveForUploading = [
      RECORD_ACTION__ASSIGN,
      DOC_SET_ACTION__MERGE,
      DOC_SET_ACTION__DELETE,
      DOC_SET_ACTION__REVIEW,
      DOC_SET_ACTION__VIEW,
      DOC_SET_ACTION__DOWNLOAD,
      RECORD_ACTION__OPEN_IN_NEW_TAB,
    ];
    const actionsShouldRemoveForProcessing = [
      RECORD_ACTION__ASSIGN,
      DOC_SET_ACTION__DELETE,
      DOC_SET_ACTION__REVIEW,
      DOC_SET_ACTION__DOWNLOAD,
      DOC_SET_ACTION__MERGE,
      RECORD_ACTION__OPEN_IN_NEW_TAB,
    ];
    for (const selectedItem of selectedItems) {
      if (allActionButtons.length === 0) {
        return;
      }
      if (selectedItem.status === DriveNodeStatus.UPLOADING) {
        forEach(actionsShouldRemoveForUploading, (action) => {
          remove(
            allActionButtons,
            (actionButton) => actionButton.actionKey === action.actionKey
          );
        });
      } else if (selectedItem.status === DriveNodeStatus.PROCESSING) {
        forEach(actionsShouldRemoveForProcessing, (action) => {
          remove(
            allActionButtons,
            (actionButton) => actionButton.actionKey === action.actionKey
          );
        });
      }
    }
  }

  private filterActionButtonsBasedOnRecordOwner(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IDriveNode[],
    userUid: string
  ) {
    selectedItems.forEach((selectedItem) => {
      if (selectedItem.ownerUid !== userUid) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === RECORD_ACTION__ASSIGN.actionKey
        );
      }
    });
  }

  private filterActionButtonsForGoogleDriveFiles(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IDriveNode[]
  ) {
    const actionsShouldRemove = [
      DOC_SET_ACTION__RENAME,
      DOC_SET_ACTION__SHARE,
      DOC_SET_ACTION__MOVE,
      DOC_SET_ACTION__DELETE,
      DOC_SET_ACTION__ADD_TO_QUEUE,
      DOC_SET_ACTION__MERGE,
    ];
    for (const selectedItem of selectedItems) {
      if (allActionButtons.length === 0) {
        return;
      }
      if (selectedItem.googleDriveFileId) {
        forEach(actionsShouldRemove, (action) => {
          remove(
            allActionButtons,
            (actionButton) => actionButton.actionKey === action.actionKey
          );
        });
      }
    }
  }

  onTableContextMenu(contextMenuEvent: {
    event: MouseEvent;
    type: ContextmenuType;
    content: any;
  }) {
    this.contextMenu.closeMenu();
    if (contextMenuEvent.type === "body") {
      if (!this.isSelectedBefore(contextMenuEvent.content)) {
        this.selectedItems = [contextMenuEvent.content];
      }
      this.contextMenuContent = contextMenuEvent.content;
      this.contextMenuEvent = contextMenuEvent.event;
      this.contextMenuPos = this.getPosition(this.contextMenuEvent);
    } else {
      this.contextMenuContent = undefined;
      this.contextMenuEvent = undefined;
      this.contextMenuPos = undefined;
      this.selectedItems = [];
    }

    this.reloadContextMenuActionButtons();
    if (this.contextMenuActionButtons.length === 0) {
      this.contextMenu.closeMenu();
    } else {
      setTimeout(() => {
        this.contextMenu.openMenu();
      }, 150);
    }

    contextMenuEvent.event.preventDefault();
    contextMenuEvent.event.stopPropagation();
  }

  private isSelectedBefore(item): boolean {
    return (
      this.selectedItems.length > 0 &&
      this.selectedItems.find((selectedItem) => selectedItem.uid === item.uid)
    );
  }

  private onResetSelection() {
    this.selectedItems = [];
  }

  private loadDirectories(options?: LoadDirectoriesOptions) {
    let shouldHighlightTargetNode = false;

    if (options?.resetPage) {
      if (options?.targetNodeOffset > 0) {
        this.pagination.page =
          options.targetNodeOffset % PAGINATION_SIZE === 0
            ? Math.floor(options.targetNodeOffset / PAGINATION_SIZE)
            : Math.floor(options.targetNodeOffset / PAGINATION_SIZE) + 1;
        shouldHighlightTargetNode = true;
      } else {
        this.pagination.page = 1;
      }
    }

    if (!this.isAdvancedSearchActive) {
      const payload = {
        category:
          this.category?.toUpperCase() as DriveNodeCategoryUppercaseEnum,
        directoryUid: this.folderUid,
        page: this.pagination.page,
        perPage: this.pagination.size,
        sorts: this.sorts.sort,
      };
      this.driveFacade.loadAllRecords(payload);
    }

    combineLatest([
      this.driveFacade.loadAllDriveNodesSucceeded$,
      this.driveFacade.getRecords$(this.category as DriveNodeCategory),
    ])
      .pipe(filter(([loadAllRecordsSucceeded]) => loadAllRecordsSucceeded))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([loadAllRecordsSucceeded, resp]) => {
        if (
          this.isNavigationTypeChanged(
            resp?.nodeNavigation?.category
              ? resp?.nodeNavigation?.category
              : "OWNED"
          )
        ) {
          this.navigationType = resp?.nodeNavigation?.category
            ? resp?.nodeNavigation?.category
            : "OWNED";
          this.initTableColumns();
        } else {
          this.selectedItems = [];
        }
        this.pagination = new PaginationModel(
          this.pagination.page,
          PAGINATION_SIZE,
          resp?.pagination?.totalResults
        );
        this.itlsBreadcrumbService.recreateBreadcrumbByNodeNavigation(
          resp?.nodeNavigation
        );
        // this.basicRows = resp.items
        if (shouldHighlightTargetNode) {
          const highlightRowIndex =
            options.targetNodeOffset -
            (this.pagination.page - 1) * PAGINATION_SIZE;
          this.selectedItems = [this.basicRows[highlightRowIndex - 1]];
        }
        this.cdr.detectChanges();
      });
  }

  onRecordStatusUpdate() {
    CentrifugeService.onRecordStatusUpdate
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        const { type, abstractUid, abstractTitle, queueUid } = data;
        if (type === NotificationTypesEnum.ABSTRACTION_FINISHED) {
          if (window.location.pathname.includes("/drive/")) {
            this.driveFacade.updateDocument({
              recordUid: abstractUid,
              queueStage: QUEUE_STATUS.READY,
              queueUid: queueUid,
            });
            this.cdr.detectChanges();
          }
        }
      });
  }

  private openCreateFolderDialog() {
    this.dialog.open(AddFolderComponent, {
      width: "275px",
      data: {
        parentFolderUid: this.folderUid,
      },
    });
  }

  @HostListener("document:click", ["$event"])
  clickedOutside($event) {
    this.contextMenu?.closeMenu();
    this.contextMenuEvent = undefined;
    this.contextMenuPos = undefined;
    this.contextMenuContent = undefined;
  }

  private onMove(row: IDriveNode) {
    this.onBatchMove([row]);
  }

  private onBatchMove(rows: IDriveNode[]) {
    const dialogRef = this.dialog.open(SelectFolderComponent, {
      height: "640px",
      width: "480px",
      data: {
        source: {
          uids: rows.map((row) => row.uid),
          parentDirectoryUid: this.folderUid,
        },
        title: "Select destination folder",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.selectedFolder) {
        this.subscriptions.add(
          this.driveService
            .moveNodes(
              rows.map((row) => ({
                uid: row.uid,
                type: row.type,
              })),
              result.selectedFolder.id
            )
            .subscribe((resp) => {
              this.loadDirectories();
            })
        );
      }
    });
  }

  private onAssign(row: IDriveNode) {
    const taskData = new ModalInputModel();
    taskData.mode = ComponentModeEnum.ADD;
    taskData.payload = { abstractUid: row.uid };
    this.taskModalService
      .openNewTaskModal(taskData)
      .afterClosed()
      .subscribe((res) => {
        //
      });
  }

  private onBatchAssign(rows: IDriveNode[]) {
    const taskData = new ModalInputModel();
    taskData.mode = ComponentModeEnum.ADD;
    taskData.payload = { abstractUids: rows.map((row) => row.uid) };
    this.taskModalService
      .openNewTaskModal(taskData)
      .afterClosed()
      .subscribe((res) => {
        //
      });
  }

  private isNavigationTypeChanged(navigationType: NavigationType): boolean {
    return !this.navigationType || this.navigationType !== navigationType;
  }

  private refresh() {
    // this.isTableLoaded = false
    setTimeout(() => {
      // this.isTableLoaded = true
      this.initTableColumns();
      this.cdr.detectChanges();
    }, 1);
    this.onResetSelection();
    this.setSorts();
  }

  private setSorts(evt?) {
    if (!evt) {
      const columnProp = "createdAt";
      const columnName = "Created At";
      const columnType = "DATE";
      this.sorts = {
        sort: `${columnProp}::${columnName}::${columnType}::${SortDirectionEnum.DESC}`,
        sorts: [
          {
            dir: SortDirectionLowcaseEnum.DESC,
            prop: columnProp,
          },
        ],
      };
    } else {
      const column = this.columns.find(
        (inColumn) => inColumn.prop === evt.column.prop
      );
      const direction = evt.newValue.toUpperCase();
      this.sorts = {
        sort: `${column.prop}::${column.name}::${column.type}::${SortDirectionEnum[direction]}`,
        sorts: evt.sorts,
      };
    }
    this.cdr.detectChanges();
  }

  checkShiftHold(event) {
    this.isShiftHold = event.shiftKey;
  }
}
