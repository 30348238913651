import { NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { ExtendedModule, FlexModule } from "@angular/flex-layout";
import { InteleaseCommonModule } from "@@intelease/web/common/common.module";
import { ProgressModule } from "@@intelease/web/intelease/components";
import { NgPipesModule } from "ngx-pipes";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";
import { AppStateDriveV2Module } from "@@intelease/app-state/drive-v2/src";
import { MergeDocSetModalComponent } from "./merge-doc-set-modal/merge-doc-set-modal.component";
import { ItlsChooseQueueModule } from "../itls-choose-queue/choose-queue.module";
import { MergeDocSetModalService } from "./services/merge-doc-set-modal.service";
import { DocSetDocDataItemComponent } from "./doc-set-doc-data-item/doc-set-doc-data-item.component";
import { SelectFilesFromDocSetsComponent } from "./select-files-from-doc-sets/select-files.component";
import { ItlsDriveNoRoutingModule } from "../itls-drive";

const MATERIAL_MODULES = [
  MatExpansionModule,
  MatIconModule,
  MatProgressBarModule,
  MatListModule,
  MatRadioModule,
  MatTooltipModule,
  MatButtonModule,
];

@NgModule({
  declarations: [
    MergeDocSetModalComponent,
    SelectFilesFromDocSetsComponent,
    DocSetDocDataItemComponent,
  ],
  exports: [MergeDocSetModalComponent],
  providers: [MergeDocSetModalService],
  imports: [
    InteleaseCommonModule,
    ItlsCustomIconModule,
    ...MATERIAL_MODULES,
    FlexModule,
    ExtendedModule,
    ProgressModule,
    NgPipesModule,
    TooltipModule,
    DragDropModule,
    ItlsDriveNoRoutingModule, // can't import the V2 module because of circular dependency, //can't import the v2 module because of circular dependencies, we need to move out the relevant component into a base component
    AppStateDriveV2Module,
    ItlsChooseQueueModule,
  ],
})
export class ItlsMergeDocSetModule {}
