import { Component, Inject, ViewChild } from "@angular/core";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { InteleaseNotificationService } from "@@intelease/web/intelease/services";
import { Observable, of, Subject } from "rxjs";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { CommonModalService } from "@@intelease/web/common/services";
import { finalize, map } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-drive.service";
import { DriveFacade, User } from "@@intelease/app-state/drive-v2/src";
import {
  DocSetFullViewModel,
  DocumentSetService,
  QueueDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { CommonFacade } from "@@intelease/app-state/common/src";
import { SettingItemKeys } from "@@intelease/app-models/settings/src";
import { QueueFacade, QUEUE_STATUS } from "@@intelease/app-state/queue/src";
import { SelectFilesFromDocSetsComponent } from "../select-files-from-doc-sets/select-files.component";
import { DocDataFiles, MergedDocSetModel } from "../models";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import { Permission } from "@@intelease/app-state/drive-v2/src/";

const ALLOWED_FILE_EXTENSIONS = [
  ".pdf",
  ".docx",
  ".doc",
  ".odt",
  ".ppt",
  ".pptx",
  ".odp",
  ".png",
  ".jpeg",
  ".jpg",
  ".svg",
  ".bmp",
  ".tif",
  ".tiff",
];

const INVALID_FILE_NAMES_CHARACTERS = ['"', "/", "%"];
const MAX_DOCS_ALLOWED = 5;

export enum PanelViewEnum {
  SELECT_FILES = "SELECT_FILES",
  SELECT_DEST_FOLDER = "SELECT_DEST_FOLDER",
  SELECT_QUEUE = "SELECT_QUEUE",
}

export interface MergeDocSetModalInputData {
  docSetIds: string[];
}

@Component({
  selector: "intelease-merge-doc-set-modal",
  templateUrl: "./merge-doc-set-modal.component.html",
  styleUrls: ["./merge-doc-set-modal.component.scss"],
})
export class MergeDocSetModalComponent {
  isUploading = false;
  docSetIds: string[];
  docSets: DocSetFullViewModel[];
  isLoaded = false;
  @ViewChild("selectFilesPanel") selectFilesPanel: MatExpansionPanel;
  @ViewChild("selectDestFolderPanel", { static: false })
  selectDestFolderPanel: MatExpansionPanel;
  @ViewChild("selectQueuePanel", { static: false })
  selectQueuePanel: MatExpansionPanel;
  @ViewChild("filesUploadComponent")
  selectFilesFromDocSetsComponent: SelectFilesFromDocSetsComponent;
  PanelView = PanelViewEnum;
  selectedView: PanelViewEnum = PanelViewEnum.SELECT_FILES;
  selectedQueueUid: string = undefined;
  selectedFolderUid: string;
  mergedDocSets: MergedDocSetModel[];
  disallowedFiles: string[] = [];
  isQueueRequired$: Observable<boolean> = this.commonFacade
    .getAccountSettingByKey$(SettingItemKeys.FORCE_QUEUE_FOR_UPLOAD)
    .pipe(map((setting) => setting.value));

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: MergeDocSetModalInputData,
    private inputValidationService: InputValidationService,
    private inteleaseNotificationService: InteleaseNotificationService,
    private commonModalService: CommonModalService,
    private dialogRef: MatDialogRef<MergeDocSetModalComponent>,
    private readonly docSetService: DocumentSetService,
    private notificationService: MessageService,
    private driveFacade: DriveFacade,
    private commonFacade: CommonFacade,
    private queueFacade: QueueFacade
  ) {
    this.docSetIds = dialogData.docSetIds;
    this.loadDocSets();
  }

  private loadDocSets() {
    Promise.all(
      this.docSetIds.map((docSetUid) =>
        this.docSetService
          .get({ docSetUid: docSetUid })
          .pipe(map((docSet) => docSet.data))
          .toPromise()
      )
    ).then((docSets) => {
      this.docSets = docSets;
      const newDocSetName = this.docSets[0].name;
      const docDataFiles = this.docSets
        .map((docSet) =>
          docSet.documents.map((docData) => {
            const lastIndexOfDot = docData.fileName.lastIndexOf(".");
            const docDataName =
              lastIndexOfDot === -1
                ? docData.fileName
                : docData.fileName.substring(0, lastIndexOfDot);
            return {
              file: new File([], docData.fileName),
              name: docDataName,
              docSetUid: docSet.uid,
              docUid: docData.uid,
            } as DocDataFiles;
          })
        )
        .flatMap((files) => files);
      this.mergedDocSets = [
        new MergedDocSetModel(docDataFiles, newDocSetName, [], {
          pre: [],
          post: [],
        }),
      ];
      this.isLoaded = true;
    });
  }

  onModelChanged(mergedDocSetModels: MergedDocSetModel[]) {
    const filteredUploadedDocSetModels = [];
    for (const mergedDocSet of mergedDocSetModels) {
      mergedDocSet.docDataFiles = mergedDocSet.docDataFiles.filter((myFile) => {
        const filename = myFile.name;
        const isValid = this.inputValidationService.validTextSuffixesIgnoreCase(
          filename,
          ALLOWED_FILE_EXTENSIONS
        );
        if (!isValid) {
          this.disallowedFiles.push(filename);
        }
        return isValid;
      });
      if (mergedDocSet.docDataFiles.length > 0) {
        filteredUploadedDocSetModels.push(mergedDocSet);
      }
    }
    this.mergedDocSets = filteredUploadedDocSetModels;
  }

  onMerge() {
    if (!this.areFilesValid()) {
      return;
    }
    this.confirmWithUserCreditsUsage().subscribe((confirmed) => {
      if (confirmed) {
        this.mergeDocSets();
      }
    });
  }

  onSelectQueue(queue: QueueDtoModel) {
    this.selectedQueueUid = queue?.uid || undefined;
  }

  private confirmWithUserCreditsUsage(): Observable<boolean> {
    if (!this.selectedQueueUid) {
      return of(true);
    }

    const numCredits = this.mergedDocSets
      .map((item) => Math.ceil(item.docDataFiles.length / MAX_DOCS_ALLOWED))
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    if (numCredits === 1) {
      return of(true);
    }
    const numDocSets = new Set(
      this.mergedDocSets
        .flatMap((docSet) => docSet.docDataFiles)
        .map((docDataFile) => docDataFile.docSetUid)
    ).size;
    const numFiles = this.mergedDocSets
      .map((item) => item.docDataFiles.length)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const modalData = new ModalInputModel();

    modalData.payload = {
      customMessage: true,
      params: {
        numCredits: numCredits,
        numDocSets: numDocSets,
        numFiles: numFiles,
      },
      message: "GeneralMessage.MergeDocSetsConfirm",
      title: "Confirm Merge",
    };

    const userConfirmedCreditsUsage = new Subject<boolean>();
    this.commonModalService
      .openGenericOkCancelModal(modalData)
      .afterClosed()
      .subscribe((res) => {
        userConfirmedCreditsUsage.next(
          res.data.exitType === ModalsResponseTypeEnum.CLOSE
        );
      });
    return userConfirmedCreditsUsage;
  }

  private areFilesValid(): boolean {
    for (const mergedDocSet of this.mergedDocSets) {
      const originalFileNames = mergedDocSet.docDataFiles.map(
        (myFile) => myFile.file.name
      );
      const isValid = this.inputValidationService.validTextsSuffixesIgnoreCase(
        originalFileNames,
        ALLOWED_FILE_EXTENSIONS
      );
      if (!isValid) {
        this.inteleaseNotificationService.openSnackBar(
          `One or more files are not supported! (allowed file extensions: '` +
            ALLOWED_FILE_EXTENSIONS +
            `')`
        );
        return false;
      }
      const fileNames = mergedDocSet.docDataFiles.map((myFile) => myFile.name);
      for (const fileName of fileNames) {
        if (
          !INVALID_FILE_NAMES_CHARACTERS.every(
            (invalidFileNameChar) => !fileName.includes(invalidFileNameChar)
          )
        ) {
          this.inteleaseNotificationService.openSnackBar(
            "Found a file with invalid name: " +
              fileName +
              " (these characters are not allowed: " +
              INVALID_FILE_NAMES_CHARACTERS.join(", ") +
              " )"
          );
          return false;
        }
      }
      const fileNameSet = new Set(fileNames);
      if (fileNameSet.size !== fileNames.length) {
        this.inteleaseNotificationService.openSnackBar(
          "Can not have duplicate file names, all file names must be unique"
        );
        return false;
      }
    }
    return true;
  }

  private mergeDocSets() {
    this.isUploading = true;
    const parentDirectoryUid = ItlsDriveService.getNonRootDirectoryUid(
      this.selectedFolderUid
    );

    this.docSetService
      .mergeDocSets({
        body: {
          data: {
            directoryUid: parentDirectoryUid,
            docSetName: this.mergedDocSets[0].name,
            queueUid: this.selectedQueueUid,
            selectedDocDatas: this.mergedDocSets[0].docDataFiles.map(
              (docData) => {
                return {
                  docSetUid: docData.docSetUid,
                  docUid: docData.docUid,
                  newFileName: docData.name,
                };
              }
            ),
          },
        },
      })
      .pipe(
        finalize(() => {
          this.isUploading = false;
        })
      )
      .subscribe((resp) => {
        if (
          resp.data.queueSelected &&
          resp.data.queueIngestionResult?.ingestionFailed
        ) {
          this.notificationService.error(
            resp.data.queueIngestionResult.ingestionFailureReason,
            {
              Style: "flip",
              Duration: 3000,
            }
          );
        }
        const queueIngestionResult = resp.data.queueIngestionResult;
        this.driveFacade.addDocSetPartial({
          docSetName: resp.data.name,
          docSetUid: resp.data.uid,
          directoryUid: resp.data.directoryUid,
          documentCount: resp.data.documentCount,
          permissions: resp.data.permissions as Permission[],
          recordUid: queueIngestionResult?.recordUid,
          queueName: queueIngestionResult?.queueName,
          queueUid: queueIngestionResult?.queueUid,
          queueStage: queueIngestionResult?.queueUid
            ? queueIngestionResult?.queueStage || QUEUE_STATUS.PROCESSING
            : undefined,
          owner: resp.data.owner as User,
        });

        //only valid if the queue was selected while uploading
        if (queueIngestionResult) {
          this.queueFacade.addRecordToQueueTabs({
            recordName: queueIngestionResult.recordName,
            recordUid: queueIngestionResult.recordUid,
            queueUid: queueIngestionResult.queueUid,
            queueStatus: QUEUE_STATUS.PROCESSING,
          });
        }
        this.dialogRef.close({});
      });
  }

  private changeCurrentView(newView: PanelViewEnum) {
    this.selectedView = newView;
    switch (this.selectedView) {
      case PanelViewEnum.SELECT_FILES:
        this.selectFilesPanel.expanded = true;
        break;
      case PanelViewEnum.SELECT_DEST_FOLDER:
        this.selectDestFolderPanel.expanded = true;
        break;
      case PanelViewEnum.SELECT_QUEUE:
        this.selectQueuePanel.expanded = true;
        break;
    }
  }

  onPanelClosed(closedView: PanelViewEnum) {
    if (closedView === this.selectedView) {
      switch (this.selectedView) {
        case PanelViewEnum.SELECT_FILES:
          this.changeCurrentView(PanelViewEnum.SELECT_DEST_FOLDER);
          break;
        case PanelViewEnum.SELECT_DEST_FOLDER:
          this.changeCurrentView(PanelViewEnum.SELECT_QUEUE);
          break;
        case PanelViewEnum.SELECT_QUEUE:
          this.changeCurrentView(PanelViewEnum.SELECT_DEST_FOLDER);
          break;
      }
    }
  }

  onPanelOpened(openedView: PanelViewEnum) {
    this.selectedView = openedView;
  }

  onBackClicked() {
    switch (this.selectedView) {
      case PanelViewEnum.SELECT_DEST_FOLDER:
        this.changeCurrentView(PanelViewEnum.SELECT_FILES);
        break;
      case PanelViewEnum.SELECT_QUEUE:
        this.changeCurrentView(PanelViewEnum.SELECT_DEST_FOLDER);
        break;
    }
  }

  onNextClicked() {
    switch (this.selectedView) {
      case PanelViewEnum.SELECT_FILES:
        if (this.mergedDocSets.length) {
          this.changeCurrentView(PanelViewEnum.SELECT_DEST_FOLDER);
        }
        break;
      case PanelViewEnum.SELECT_DEST_FOLDER:
        if (this.selectedFolderUid) {
          this.changeCurrentView(PanelViewEnum.SELECT_QUEUE);
        }
        break;
    }
  }
}
