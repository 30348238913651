import { CustomTagApiDtoModel } from "@@intelease/api-models/adex-api-model-src";
import { CommonFacade } from "@@intelease/app-state/common/src";
import { WebAbstractionPageModifyCustomTagsModalComponent } from "@@intelease/web/abstraction-page/src/lib/modals/modify-custom-tags";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { ModalResponseModel } from "@@intelease/web/intelease/models";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { Component, DestroyRef, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";

@Component({
  selector: "il-modify-custom-tag",
  templateUrl: "./itls-modify-custom-tag.component.html",
  styleUrls: ["./itls-modify-custom-tag.component.scss"],
})
export class ItlsModifyCustomTagComponent implements OnInit {
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  modalTitle = "Modify Custom Tags";
  possibleCustomTags: any[];

  // user selections
  customTags: CustomTagApiDtoModel[] = [];

  // choices for user
  accountCustomTags: CustomTagApiDtoModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<WebAbstractionPageModifyCustomTagsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private inputValidationService: InputValidationService,
    private destroyRef: DestroyRef,
    private commonFacade: CommonFacade
  ) {}

  ngOnInit() {
    const { customTags } = this.dialogData.payload;
    this.customTags = customTags;

    this.commonFacade
      .getAccountCustomTags()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((customTagsData) => {
        this.accountCustomTags = customTagsData || [];
      });

    this.commonFacade.getAccountCustomTags$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((customTagsData) => {
        this.accountCustomTags = customTagsData || [];
      });
  }

  onSaveCustomTags() {
    if (!this.validateOnSaveCustomTags(this.customTags)) {
      return;
    }
    this.modalRes.data = {
      ...{
        customTags: this.customTags,
      },
    };
    this.dialogRef.close(this.modalRes);
  }

  onDismissModal() {
    this.dialogRef.close();
  }

  private validateOnSaveCustomTags(
    customTags: CustomTagApiDtoModel[]
  ): boolean {
    for (const customTag of customTags) {
      if (
        !this.inputValidationService.usefulObjectTextFieldElseAlert(
          customTag,
          "name",
          "Each custom tag must have a name!"
        )
      ) {
        return false;
      }
    }
    return true;
  }
}
