import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { FetchApiService, FetchBatchApiService } from "../../../index";

@Injectable({
  providedIn: "root",
})
export class FetchProvisionService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly PROVISIONS_URL = "/provisions";

  constructor(private fetchApiService: FetchApiService) {}

  getProvisionByUid<T extends object>(
    view: string,
    uid: string,
    classRef: new () => T
  ): Observable<T> {
    return this.fetchApiService.sendRequest(
      FetchProvisionService.API_VERSION_1,
      FetchProvisionService.PROVISIONS_URL,
      uid,
      view,
      classRef
    );
  }
}
