import {
  FolderStructureNodeDtoModel,
  MediumNodeDtoModel,
  OApiReqUpdateCustomTagsRequestDtoModel,
  DocSetNodeDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { Action } from "@ngrx/store";
import {
  IRecord,
  RecordCategoryUppercaseEnum,
  SelectedColumnFieldsModel,
  SelectedFilterModel,
} from "../models/record.types";

export enum RecordsActionTypes {
  UpdateRecordStatus = "[Records] Update Record Status",
  UpdateRecordStatusSucceeded = "[Records] Update Record Status Succeeded",
  UpdateRecordStatusFailed = "[Records] Update Record STatus Failed",

  UpdateRecord = "[Records] Update Record",
  UpdateRecordSucceeded = "[Records] Update Record Succeeded",
  UpdateRecordFailed = "[Records] Update Record Failed",

  AddRecord = "[Records] Add Record",
  AddRecordSucceeded = "[Records] Add Record Succeeded",
  AddRecordFailed = "[Records] Add Record Failed",

  AddDirectory = "[Records] Add Directory",
  AddDirectorySucceeded = "[Records] Add Directory Succeeded",
  AddDirectoryFailed = "[Records] Add Directory Failed",

  LoadAllRecords = "[Records] Load All Records",
  LoadAllRecordsSucceeded = "[Records] Load All Records Succeeded",
  LoadAllRecordsFailed = "[Records] Load All Records Failed",

  LoadMyRecords = "[Records] Load All Records",
  LoadMyRecordsSucceeded = "[Records] Load All Records Succeeded",
  LoadMyRecordsFailed = "[Records] Load All Records Failed",

  LoadAdvancedSearchFields = "[Reocords] Load Advanced Search Fields",
  AdvancedSearchFieldsLoaded = "[Reocords] Advanced Search Fields Loaded",
  AdvancedSearchFieldsError = "[Reocords] Advanced Search Fields Error",

  LoadAdvancedSearchRecords = "[Reocords] Load Advanced Search Records",
  AdvancedSearchRecordsLoaded = "[Reocords] Advanced Search Records Loaded",
  AdvancedSearchRecordsError = "[Reocords] Advanced Search Records Error",

  SetSelectedColumnFields = "[Records] Set Selected Column Fields",
  ApplyAdvancedSearch = "[Records] Apply Advanced Search",
  CancelAdvancedSearch = "[Records] Cancel Advanced Search",
  CloseAdvancedSearch = "[Records] Close Advanced Search",

  ChangePage = "[Records] Change Page",
  ChangePageSucceeded = "[Records] Change Page Succeeded",
  ChangePageError = "[Records] Change Page Error",

  CurrentSelectedColumnFields = "[Records] Current Selected Column Fields",

  SetSelectedFilter = "[Records] Set Selected Filter",

  SortsRecords = "[Records] Sorts Records",
  SortsRecordsSucceeded = "[Records] Sorts Records Succeeded",
  SortsRecordsError = "[Records] Sorts Records Error",

  RenameRecordOrDirectory = "[Records] Rename Record Or Directory",
  RenameRecordOrDirectorySucceeded = "[Records] Rename Record Or Directory Succeeded",
  RenameRecordOrDirectoryError = "[Records] Rename Record Or Directory Error",

  UpdateCustomTags = "[Records] Update Custom Tags",
  UpdateCustomTagsSucceeded = "[Records] Update Custom Tags Succeeded",
  UpdateCustomTagsFailed = "[Records] Update Custom Tags Failed",

  UpdateShareRecordWithUid = "[Records] Update Share Record With Record Uid",
}

export class AddDirectory implements Action {
  readonly type = RecordsActionTypes.AddDirectory;

  constructor(
    public payload: {
      category: "ALL" | "DELETED" | "FAVORITES" | "OWNED" | "SHARED";
      directoryUid?: string;
      data: FolderStructureNodeDtoModel;
    }
  ) {}
}

export class AddDirectorySucceeded implements Action {
  readonly type = RecordsActionTypes.AddDirectorySucceeded;

  constructor(
    public payload: {
      category: "ALL" | "DELETED" | "FAVORITES" | "OWNED" | "SHARED";
      directoryUid?: string;
      data: FolderStructureNodeDtoModel;
    }
  ) {}
}

export class AddDirectoryFailed implements Action {
  readonly type = RecordsActionTypes.AddDirectoryFailed;

  constructor(public payload: any) {}
}

export class AddRecord implements Action {
  readonly type = RecordsActionTypes.AddRecord;

  constructor(
    public payload: {
      recordUid: string;
      category: "ALL" | "DELETED" | "FAVORITES" | "OWNED" | "SHARED";
      directoryUid?: string;
      data: DocSetNodeDtoModel;
    }
  ) {}
}

export class AddRecordSucceeded implements Action {
  readonly type = RecordsActionTypes.AddRecordSucceeded;

  constructor(
    public payload: {
      category: "ALL" | "DELETED" | "FAVORITES" | "OWNED" | "SHARED";
      directoryUid?: string;
      data: DocSetNodeDtoModel;
    }
  ) {}
}

export class AddRecordFailed implements Action {
  readonly type = RecordsActionTypes.AddRecordFailed;

  constructor(public payload: any) {}
}

export class UpdateRecordStatus implements Action {
  readonly type = RecordsActionTypes.UpdateRecordStatus;

  constructor(public payload: { recordUid: string; status: string }) {}
}

export class UpdateRecordStatusSucceeded implements Action {
  readonly type = RecordsActionTypes.UpdateRecordStatusSucceeded;

  constructor(public payload: { recordUid: string; status: string }) {}
}

export class UpdateRecordStatusFailed implements Action {
  readonly type = RecordsActionTypes.UpdateRecordStatusFailed;

  constructor(public payload: { recordUid: string; status: string }) {}
}

export class UpdateRecord implements Action {
  readonly type = RecordsActionTypes.UpdateRecord;

  constructor(public payload: { recordUid: string }) {}
}

export class UpdateRecordSucceeded implements Action {
  readonly type = RecordsActionTypes.UpdateRecordSucceeded;

  constructor(public payload: any) {}
}

export class UpdateRecordFailed implements Action {
  readonly type = RecordsActionTypes.UpdateRecordFailed;

  constructor(public payload: any) {}
}

export class LoadAdvancedSearchFields implements Action {
  readonly type = RecordsActionTypes.LoadAdvancedSearchFields;
}

export class AdvancedSearchFieldsLoaded implements Action {
  readonly type = RecordsActionTypes.AdvancedSearchFieldsLoaded;

  constructor(
    public payload: {
      columnFields: any;
      filterFields: any;
      fieldTypeToRelations: any;
    }
  ) {}
}

export class AdvancedSearchFieldsError implements Action {
  readonly type = RecordsActionTypes.AdvancedSearchFieldsError;

  constructor(public payload: any) {}
}

export class LoadAdvancedSearchRecords implements Action {
  readonly type = RecordsActionTypes.LoadAdvancedSearchRecords;

  constructor(
    public payload: {
      directoryUid?: string;
      category?: string;
      filters: any;
      columns: string[];
      sorts?: string[];
    }
  ) {}
}

export class AdvancedSearchRecordsLoaded implements Action {
  readonly type = RecordsActionTypes.AdvancedSearchRecordsLoaded;

  constructor(public payload: any) {}
}

export class AdvancedSearchRecordsError implements Action {
  readonly type = RecordsActionTypes.AdvancedSearchRecordsError;
}

export class SortsRecords implements Action {
  readonly type = RecordsActionTypes.SortsRecords;

  constructor(
    public payload: {
      category?: RecordCategoryUppercaseEnum;
      directoryUid?: string;
      page?: number;
      perPage?: number;
      view?: string;
      sorts?: string;
    }
  ) {}
}

export class SortsRecordsSucceeded implements Action {
  readonly type = RecordsActionTypes.SortsRecordsSucceeded;

  constructor(public payload: { items?: any[] }) {}
}

export class SortsRecordsError implements Action {
  readonly type = RecordsActionTypes.SortsRecordsError;
}

export class ChangePage implements Action {
  readonly type = RecordsActionTypes.ChangePage;

  constructor(public payload: number) {}
}

export class ChangePageSucceeded implements Action {
  readonly type = RecordsActionTypes.ChangePageSucceeded;

  constructor(public payload: number) {}
}

export class ChangePageError implements Action {
  readonly type = RecordsActionTypes.ChangePageError;

  constructor(payload: any) {
    //
  }
}

export class LoadAllRecords implements Action {
  readonly type = RecordsActionTypes.LoadAllRecords;

  constructor(
    public payload: {
      category?: RecordCategoryUppercaseEnum;
      directoryUid?: string;
      page?: number;
      perPage?: number;
      view?: string;
      sorts?: string;
    }
  ) {}
}

export class LoadAllRecordsSucceeded implements Action {
  readonly type = RecordsActionTypes.LoadAllRecordsSucceeded;

  constructor(public payload: any) {}
}

export class LoadAllRecordsFailed implements Action {
  readonly type = RecordsActionTypes.LoadAllRecordsFailed;

  constructor(public payload: any) {}
}

export class SetSelectedColumnFields implements Action {
  readonly type = RecordsActionTypes.SetSelectedColumnFields;

  constructor(public payload: SelectedColumnFieldsModel[]) {}
}

export class CurrentSelectedColumnFields implements Action {
  readonly type = RecordsActionTypes.CurrentSelectedColumnFields;

  constructor(public payload: SelectedColumnFieldsModel[]) {}
}

export class SetSelectedFilter implements Action {
  readonly type = RecordsActionTypes.SetSelectedFilter;

  constructor(public payload?: SelectedFilterModel) {}
}

export class ApplyAdvancedSearch implements Action {
  readonly type = RecordsActionTypes.ApplyAdvancedSearch;
}

export class CancelAdvancedSearch implements Action {
  readonly type = RecordsActionTypes.CancelAdvancedSearch;
}

export class CloseAdvancedSearch implements Action {
  readonly type = RecordsActionTypes.CloseAdvancedSearch;
}

export class RenameRecordOrDirectory implements Action {
  readonly type = RecordsActionTypes.RenameRecordOrDirectory;

  constructor(public payload: { row: IRecord; newName: string }) {}
}

export class RenameRecordOrDirectorySucceeded implements Action {
  readonly type = RecordsActionTypes.RenameRecordOrDirectorySucceeded;

  constructor(public payload: MediumNodeDtoModel) {}
}

export class RenameRecordOrDirectoryError implements Action {
  readonly type = RecordsActionTypes.RenameRecordOrDirectoryError;

  constructor(public payload: { oldName: string; uid: string }) {}
}

export class UpdateCustomTags implements Action {
  readonly type = RecordsActionTypes.UpdateCustomTags;

  constructor(
    public payload: {
      recordUid: string;
      body: OApiReqUpdateCustomTagsRequestDtoModel;
    }
  ) {}
}

export class UpdateCustomTagsSucceeded implements Action {
  readonly type = RecordsActionTypes.UpdateCustomTagsSucceeded;

  constructor(
    public payload: {
      recordUid: string;
      body: OApiReqUpdateCustomTagsRequestDtoModel;
    }
  ) {}
}

export class UpdateCustomTagsFailed implements Action {
  readonly type = RecordsActionTypes.UpdateCustomTagsFailed;
}

export class UpdateShareRecordWithUid implements Action {
  readonly type = RecordsActionTypes.UpdateShareRecordWithUid;

  constructor(public recordUid: string, public shared: boolean) {}
}

export type RecordsAction =
  | UpdateRecordStatus
  | UpdateRecordStatusSucceeded
  | UpdateRecordStatusFailed
  | UpdateRecord
  | UpdateRecordSucceeded
  | UpdateRecordFailed
  | AddDirectory
  | AddDirectorySucceeded
  | AddDirectoryFailed
  | AddRecord
  | AddRecordSucceeded
  | AddRecordFailed
  | LoadAllRecords
  | LoadAllRecordsSucceeded
  | LoadAllRecordsFailed
  | LoadAdvancedSearchFields
  | AdvancedSearchFieldsLoaded
  | AdvancedSearchFieldsError
  | LoadAdvancedSearchRecords
  | AdvancedSearchRecordsLoaded
  | AdvancedSearchRecordsError
  | ChangePage
  | ChangePageSucceeded
  | ChangePageError
  | SetSelectedColumnFields
  | CurrentSelectedColumnFields
  | SetSelectedFilter
  | ApplyAdvancedSearch
  | CancelAdvancedSearch
  | CloseAdvancedSearch
  | SortsRecords
  | SortsRecordsSucceeded
  | SortsRecordsError
  | RenameRecordOrDirectory
  | RenameRecordOrDirectorySucceeded
  | RenameRecordOrDirectoryError
  | UpdateCustomTags
  | UpdateCustomTagsSucceeded
  | UpdateCustomTagsFailed
  | UpdateShareRecordWithUid;

export const fromRecordsActions = {
  UpdateRecordStatus,
  UpdateRecordStatusSucceeded,
  UpdateRecordStatusFailed,

  UpdateRecord,
  UpdateRecordSucceeded,
  UpdateRecordFailed,

  AddDirectory,
  AddDirectorySucceeded,
  AddDirectoryFailed,

  AddRecord,
  AddRecordSucceeded,
  AddRecordFailed,

  LoadAllRecords,
  LoadAllRecordsSucceeded,
  LoadAllRecordsFailed,

  LoadAdvancedSearchFields,
  AdvancedSearchFieldsLoaded,
  AdvancedSearchFieldsError,

  LoadAdvancedSearchRecords,
  AdvancedSearchRecordsLoaded,
  AdvancedSearchRecordsError,

  ChangePage,
  ChangePageSucceeded,
  ChangePageError,

  SetSelectedColumnFields,
  CurrentSelectedColumnFields,
  SetSelectedFilter,
  ApplyAdvancedSearch,
  CancelAdvancedSearch,
  CloseAdvancedSearch,

  SortsRecords,
  SortsRecordsSucceeded,
  SortsRecordsError,

  RenameRecordOrDirectory,
  RenameRecordOrDirectorySucceeded,
  RenameRecordOrDirectoryError,

  UpdateCustomTags,
  UpdateCustomTagsSucceeded,
  UpdateCustomTagsFailed,

  UpdateShareRecordWithUid,
};
