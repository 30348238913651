<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 1 auto">
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    [style.margin-right]="'12px'"
  >
    <label
      class="document-set-name-label"
      [ngStyle]="{
        'margin-top': null
      }"
      >Merged document set</label
    >
    <input
      type="text"
      class="name-input"
      [(ngModel)]="mergedDocSet.name"
      (focusout)="onDocSetNameChanged()"
    />
  </div>
  <div
    style="margin-top: 15px"
    [style.overflow-y]="'auto'"
    [style.max-height]="verticalView ? '220px' : '145px'"
  >
    <div>
      <table
        class="table"
        id="selectedFileListTable"
        cdkDropList
        (cdkDropListDropped)="onDropDocument($event)"
        [cdkDropListData]="this.mergedDocSet.docDataFiles"
      >
        <tbody>
          <tr
            *ngFor="let item of this.mergedDocSet.docDataFiles; let i = index"
            cdkDrag
          >
            <td>
              <div class="number-container">
                <span>{{ i + 1 }}</span>
              </div>
            </td>
            <td>
              <div fxLayout="row" fxLayoutAlign="start center">
                <div
                  fxLayout="column"
                  fxLayoutAlign="start stretch"
                  fxFlex="1 1 auto"
                >
                  <input
                    type="text"
                    class="name-input"
                    [(ngModel)]="item.name"
                    (focusout)="onDocumentNameChanged(item)"
                  />
                </div>
              </div>
            </td>
            <td class="text-right" style="width: 24px">
              <itls-icon
                class="icon-remove"
                svgIcon="icon-remove"
                (click)="onRemoveFile(i)"
              ></itls-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
