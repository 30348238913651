import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromDrive from "./state/drive.reducer";
import { DriveEffects } from "./state/drive.effects";
import { DriveFacade } from "./state/drive.facade";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDrive.DRIVE_FEATURE_KEY, fromDrive.reducer),
    EffectsModule.forFeature([DriveEffects]),
  ],
  providers: [DriveFacade],
})
export class AppStateDriveV2Module {}
