<div class="row search align-items-center justify-content-start">
  <div class="col-sm-12 search-input pl-0 pr-0">
    <div class="input-group mr-4">
      <!--                        <ng-template-->
      <!--                            #searchResultTemplate-->
      <!--                            let-model="item"-->
      <!--                            let-index="index"-->
      <!--                        >-->
      <!--                            <div-->
      <!--                                fxLayout="row"-->
      <!--                                fxLayoutAlign="start center"-->
      <!--                                class="search-item-container"-->
      <!--                                [matTooltip]="model.tooltip"-->
      <!--                            >-->
      <!--                                <mat-icon-->
      <!--                                    *ngIf="model.node.type === 'DIRECTORY'"-->
      <!--                                    [svgIcon]="-->
      <!--                                        model.node.shared-->
      <!--                                            ? 'icon-folder-share'-->
      <!--                                            : 'icon-documents'-->
      <!--                                    "-->
      <!--                                    style="margin-right: 10px"-->
      <!--                                ></mat-icon>-->
      <!--                                <mat-icon-->
      <!--                                    *ngIf="model.node.type === 'RECORD'"-->
      <!--                                    [svgIcon]="model.node.shared ? 'icon-documents-share' : 'icon-note'"-->
      <!--                                    style="margin-right: 10px"-->
      <!--                                ></mat-icon>-->
      <!--                                <div fxLayout="column" class="text-content-container">-->
      <!--                                    <span class="node-name">-->
      <!--                                        {{ model.node.name }}-->
      <!--                                    </span>-->
      <!--                                    <span class="owner-name">-->
      <!--                                        {{ model.node.owner?.name }}-->
      <!--                                    </span>-->
      <!--                                </div>-->
      <!--                                <span style="margin-left: auto">-->
      <!--                                    {{ model.node.lastModifiedDate | date: 'YYYY/MM/dd' }}-->
      <!--                                </span>-->
      <!--                            </div>-->
      <!--                            <div-->
      <!--                                *ngIf="-->
      <!--                                    indexOfLastDirectoryNode === index &&-->
      <!--                                    !allSearchResultDirectoryNodes-->
      <!--                                "-->
      <!--                                style="-->
      <!--                                    border-top: 1px solid #d6d6d6;-->
      <!--                                    margin-left: -8px;-->
      <!--                                    margin-right: -8px;-->
      <!--                                "-->
      <!--                            ></div>-->
      <!--                        </ng-template>-->
      <!--                        <input-->
      <!--                            type="text"-->
      <!--                            class="form-control"-->
      <!--                            itlsFocusIn-->
      <!--                            [(ngModel)]="search.input"-->
      <!--                            placeholder="Search all Records and Folders"-->
      <!--                            [typeaheadAsync]="true"-->
      <!--                            [typeahead]="search.result"-->
      <!--                            (typeaheadLoading)="changeSearchLoading($event)"-->
      <!--                            (typeaheadNoResults)="onSearchNoResults($event)"-->
      <!--                            (typeaheadOnSelect)="onSearchItemSelect($event)"-->
      <!--                            typeaheadOptionField="name"-->
      <!--                            [typeaheadItemTemplate]="searchResultTemplate"-->
      <!--                            [typeaheadScrollable]="true"-->
      <!--                            [typeaheadOptionsInScrollableView]="search.scrollCount"-->
      <!--                        />-->
      <!--                        <i class="fa fa-search search-icon"></i>-->
      <!--                        <span class="press-enter-message"-->
      <!--                            >Press enter to see all results</span-->
      <!--                        >-->
      <mat-form-field
        class="drive-searchbar-container"
        [ngClass]="{ dashboard: fromDashboard }"
      >
        <itls-icon matPrefix svgIcon="icon-search"></itls-icon>
        <input
          type="text"
          matInput
          *ngIf="driveFacade.isAdvancedSearchActive$ | async"
          readonly
          [value]="advancedSearchFilterNumber"
        />
        <input
          type="text"
          matInput
          [formControl]="nameQueryCtrl"
          *ngIf="(driveFacade.isAdvancedSearchActive$ | async) === false"
          itlsFocusIn
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          class="drive-search-autocomplete"
        >
          <mat-option
            *ngFor="let enrichedNode of search.result | async; index as index"
            [value]="enrichedNode.node.name"
            (onSelectionChange)="onSearchItemSelect(enrichedNode)"
            [matTooltip]="enrichedNode.tooltip"
            [id]="enrichedNode.node.uid"
            class="search-item-container"
            [style.border-bottom]="
              indexOfLastDirectoryNode === index &&
              !allSearchResultDirectoryNodes
                ? '1px solid #bfbfbf'
                : null
            "
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              [matTooltip]="enrichedNode.tooltip"
            >
              <mat-icon
                *ngIf="enrichedNode.node.type === 'DIRECTORY'"
                [svgIcon]="
                  enrichedNode.node.shared
                    ? 'icon-folder-share'
                    : 'icon-documents'
                "
                style="margin-right: 10px"
              ></mat-icon>
              <mat-icon
                *ngIf="enrichedNode.node.type === 'RECORD'"
                [svgIcon]="
                  enrichedNode.node.shared
                    ? 'icon-documents-share'
                    : 'icon-note'
                "
                style="margin-right: 10px"
              ></mat-icon>
              <div fxLayout="column" class="text-content-container">
                <span class="node-name">
                  {{ enrichedNode.node.name }}
                </span>
                <span class="owner-name">
                  {{ enrichedNode.node.owner?.name }}
                </span>
              </div>
              <span style="margin-left: auto">
                {{ enrichedNode.node.lastModifiedDate | date: "YYYY/MM/dd" }}
              </span>
            </div>
          </mat-option>
          <mat-option
            (onSelectionChange)="onSeeAllResultsClicked()"
            *ngIf="nameQueryCtrl.value && search.totalSize > search.resultSize"
            style="border-top: 1px solid #bfbfbf"
          >
            <div style="font-weight: 500; color: #2979ff; text-align: center">
              See all results
            </div>
          </mat-option>
        </mat-autocomplete>
        <div
          class="advanced-search"
          *ngIf="category === 'OWNED' || category === 'ALL'"
        >
          <div class="vertical-line"></div>
          <mat-icon
            svgIcon="icon-advanced-search"
            matTooltip="Search options"
            (click)="toggleAdvancedSearch()"
          ></mat-icon>
        </div>
      </mat-form-field>
      <div *ngIf="isAdvancedSearchOpen" class="advanced-search-modal">
        <il-advanced-search
          (toggleAdvancedSearch)="toggleAdvancedSearch($event)"
        ></il-advanced-search>
      </div>
      <!--            <div-->
      <!--                class="loading-container"-->
      <!--                *ngIf="search.isLoading"-->
      <!--                style="position: absolute; top: 7px; left: 12px; z-index: 5"-->
      <!--            >-->
      <!--                <itls-pg-progress-->
      <!--                    style="height: 20px; width: 16px; background: transparent"-->
      <!--                    class="progress"-->
      <!--                    [type]="'circle'"-->
      <!--                    [thick]="true"-->
      <!--                    [indeterminate]="true"-->
      <!--                >-->
      <!--                </itls-pg-progress>-->
      <!--            </div>-->
      <!-- <p
                *ngIf="
                    search.noResult && nameQueryCtrl.value && !search.isLoading
                "
                translate="GeneralMessage.NoResult"
                style="
                    position: absolute;
                    bottom: -31px;
                    font-size: 10px;
                    left: 8px;
                "
            ></p> -->
    </div>
  </div>
</div>
