/* tslint:disable */
/* eslint-disable */

import { DriveNodeDtoModel } from "./drive-node-dto-model";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import {
  NodeNavigationResultModel,
  PaginationDataModel,
} from "@@intelease/api-models/adex-api-model-src";

@JsonObject("OApiListWithNavigationAndPaginationResultRecordNodeDtoModel2")
export class OApiListWithNavigationAndPaginationResultRecordNodeDtoModel {
  @JsonProperty("items", [DriveNodeDtoModel], true)
  items?: Array<DriveNodeDtoModel> = [];

  @JsonProperty("nodeNavigation", NodeNavigationResultModel, true)
  nodeNavigation?: NodeNavigationResultModel = undefined;

  @JsonProperty("pagination", PaginationDataModel, true)
  pagination?: PaginationDataModel = undefined;
}
