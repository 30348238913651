import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";
import {
  RECORD_ACTION__DELETE,
  RECORD_ACTION__EXPORT,
  RECORD_ACTION__MOVE,
  RECORD_ACTION__SHARE,
} from "@@intelease/web/ui/src/lib/itls-drive/constants/record-action-buttons.const";

export const MULTI_SELECTION_ACTION_BUTTONS: ActionButtonInterface[] = [
  RECORD_ACTION__SHARE,
  RECORD_ACTION__MOVE,
  RECORD_ACTION__EXPORT,
  RECORD_ACTION__DELETE,
];

export const MULTI_SELECTION_ACTION_BUTTONS_KEYS: string[] =
  MULTI_SELECTION_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);
