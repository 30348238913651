import { Action, createAction, props } from "@ngrx/store";
import { Entity } from "./abstract-review.reducer";
import { FullValMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value";
import {
  CustomTagViewModel,
  DocPdfViewModel,
  DocSetFullDocOutlineViewModel,
  FullValMultiPdfProvViewModel,
  FullValMultiplePdfProvisionViewModel,
  NestedProvisionSubfieldDtoModel,
  OApiRespChangeCustomTagsResponseDtoModel,
  OApiRespDashboardDtoModel,
  OptionsCalculatorDataResponseDtoModel,
  PartialValPdfProvisionViewModel,
  ProvisionInconsistenciesApiDtoModel,
  ProvisionReviewCompletionInfoViewModel,
  RecordLockInfoDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src";
import { SelectedAbstractDetailLoadedPayloadInterface } from "@@intelease/app-models/abstract-review/src";
import { DocumentsMapModel } from "./models/abstract-review.model";
import { DocumentCommentModelWithPosition } from "@@intelease/web/abstraction-page/src/lib/components/web-abstraction-page/web-abstraction-page.component";
import { CommentItemNode } from "@@intelease/web/abstraction-page/src/lib/components/web-abstraction-page-sidebar/components/toc/web-abstraction-page-sidebar-toc.component";
import { RemindersTypeEnum } from "@@intelease/web/abstraction-page/src";

export enum AbstractReviewActionTypes {
  LoadAbstractReview = "[AbstractReview] Load AbstractReview",
  AbstractReviewLoaded = "[AbstractReview] AbstractReview Loaded",
  AbstractReviewLoadError = "[AbstractReview] AbstractReview Load Error",

  LoadSelectedAbstractDetail = "[AbstractReview] Load Selected Abstract Detail",
  SelectedAbstractDetailLoaded = "[AbstractReview] Selected Abstract Detail Loaded",
  SelectedAbstractDetailLoadError = "[AbstractReview] Selected Abstract Detail Load Error",

  SelectProvisionOption = "[AbstractReview] Select Provision Option",
  ProvisionOptionSelected = "[AbstractReview] Provision Option Selected",
  ProvisionOptionSelectError = "[AbstractReview] Provision Option Select Error",

  LoadSelectedAbstractTOC = "[AbstractReview] Load Selected Abstract TOC",
  SelectedAbstractTOCLoaded = "[AbstractReview] Selected Abstract TOC Loaded",
  SelectedAbstractTOCLoadError = "[AbstractReview] Selected Abstract TOC Load Error",

  PickSnippet = "[AbstractReview] Pick Snippet",
  PickSnippetSuccess = "[AbstractReview] Pick Snippet Success",
  PickSnippetError = "[AbstractReview] Pick Snippet Error",

  SelectMention = "[AbstractReview] Select Mention",
  SelectMentionSuccess = "[AbstractReview] Select Mention Success",
  SelectMentionError = "[AbstractReview] Select Mention Error",

  DeSelectMention = "[AbstractReview] DeSelect Mention",
  DeSelectMentionSuccess = "[AbstractReview] DeSelect Mention Success",
  DeSelectMentionError = "[AbstractReview] DeSelect Mention Error",

  CreateManualMention = "[AbstractReview] Create Manual Mention",
  CreateManualMentionSuccess = "[AbstractReview] Create Manual Mention Success",
  CreateManualMentionError = "[AbstractReview] Create Manual Mention Error",

  RemoveSelectedMention = "[AbstractReview] Remove Selected Mention",

  RemoveMention = "[AbstractReview] Remove Mention",
  RemoveMentionSucceeded = "[AbstractReview] Remove Mention Succeeded",

  AddMention = "[AbstractReview] Add Mention",
  AddMentionSucceeded = "[AbstractReview] Add Mention Succeeded",

  LoadProvisionFullDetail = "[AbstractReview] Load Provision Full Detail",
  ProvisionFullDetailLoaded = "[AbstractReview] Provision Full Detail Loaded",
  ProvisionFullDetailLoadError = "[AbstractReview] Provision Full Detail Load Error",

  SetMultiplePdfProvision = "[AbstractReview] Set MultiplePdfProvision",

  AddTemporaryMention = "[AbstractReview] Add Temporary Mention",
  RemoveTemporaryMention = "[AbstractReview] Remove Temporary Mention",

  SaveDocumentsShortName = "[AbstractReview] Save Documents ShortName",
  SaveSelectedDocument = "[AbstractReview] Save Selected Document",

  UpdateSelectedMentionLocally = "[AbstractReview] Update Selected Mention Locally",

  UpdateMention = "[AbstractReview] Update Mention",
  UpdateMentionSucceeded = "[AbstractReview] Update Mention Succeeded",

  CreateProvisionComment = "[AbstractReview] Create Provision Comment",
  CreateProvisionCommentSuccess = "[AbstractReview] Create Provision Comment Success",
  CreateProvisionCommentError = "[AbstractReview] Create Provision Comment Error",

  LoadProvisionComments = "[AbstractReview] Load Provision Comments",
  ProvisionCommentsLoaded = "[AbstractReview] Provision Comments Loaded",
  ProvisionCommentsLoadError = "[AbstractReview] Provision Comments Load Error",

  CreateProvisionNote = "[AbstractReview] Create Provision Note",
  CreateProvisionNoteSuccess = "[AbstractReview] Create Provision Note Success",
  CreateProvisionNoteError = "[AbstractReview] Create Provision Note Error",

  UpdateProvisionNote = "[AbstractReview] Update Selected Provision Note",
  UpdateProvisionNoteSucceeded = "[AbstractReview] Update Selected Provision Note Succeeded",
  UpdateProvisionNoteFailed = "[AbstractReview] Update Selected Provision Note Failed",

  SetSelectedProvision = "[AbstractReview] Set Selected Provision",

  CleanAbstractReviewState = "[AbstractReview] Clean Abstract Review State",

  SelectedDocumentFullyLoaded = "[AbstractReview] Selected Document Fully Loaded",

  SetCommentCountOnRecord = "[AbstractReview] Set Total Comment Count on current Record",
  ToggleCommentPanelSideBar = "[AbstractReview] Toggle Comment Panel Sidebar",

  TogglePromptPlaygroundSideBar = "[AbstractReview] Toggle Prompt Playground",

  UpdateCustomTags = "[AbstractReview] Update Custom Tags",
  UpdateCustomTagsSucceeded = "[AbstractReview] Update Custom Tags Succeeded",
  UpdateCustomTagsFailed = "[AbstractReview] Update Custom Tags Failed",

  UpdateNoteWithProvisionUid = "[AbstractReview] Update Note With Provision Uid",
  UpdateNoteWithProvisionUidSucceeded = "[AbstractReview] Update Note With Provision Uid Succeeded",

  UpdateSelectedProvisionReviewStatus = "[AbstractReview] Update Selected Provision Review Status",
  UpdateSelectedProvisionReviewStatusSucceeded = "[AbstractReview] Update Selected Provision Review Status Succeeded",
  UpdateSelectedProvisionReviewStatusError = "[AbstractReview] Update Selected Provision Review Status Error",

  AddNewlyCreatedProvision = "[AbstractReview] Add Newly Created Provision",
  UpdateProvisionMentionNote = "[AbstractReview] Update Provision Mention Note",
  UpdateProvisionMentionNoteSucceeded = "[AbstractReview] Update Provision Mention Note Succeeded",
  UpdateProvisionMentionNoteError = "[AbstractReview] Update Provision Mention Note Error",

  ProvisionReviewMentionPanelExpand = "[AbstractReview] Provision Review Mention Panel Expand",
  ProvisionReviewMentionPanelHide = "[AbstractReview] Provision Review Mention Panel Hide",

  UpdateSelectedProvisionMentionReviewStatus = "[AbstractReview] Update Selected Provision Mention Review Status",

  UpdateMentionWithMentionIndex = "[AbstractReview] Update Mention With Mention Index",

  FetchDefaultDocumentAnnotation = "[AbstractReview] Fetch Default Document Annotation",
  FetchDefaultDocumentAnnotationSucceeded = "[AbstractReview] Fetch Default Document Annotation Succeeded",

  RenameProvisionMention = "[AbstractReview] Rename Provision Mention",
  RenameProvisionMentionSucceeded = "[AbstractReview] Rename Provision Mention Succeeded",

  AddNestedSubfields = "[AbstractReview] Add Nested Subfields To Record Provision",
  AddNestedSubfieldsSucceeded = "[AbstractReview] Nested Subfields Add To Record Provision Successful",

  MakeAnchorProvision = "[AbstractReview] Make Anchor Provision",
  MakeAnchorProvisionSucceeded = "[AbstractReview] Make Anchor Provision Successful",

  UpdateProvisionReviewCompletionInfoSucceeded = "[AbstractReview] Update Provision Review Completion Info Succeeded",

  LastTouchedMentionUid = "[AbstractReview] Last touched mention Uid",

  LastTouchedNestedSubfieldKey = "[AbstractReview] Last touched nested mention subfield",

  SetAllCommentNodes = "[AbstractReview] Set All Comments Nodes",
  AddCommentNode = "[AbstractReview] Add Comment Node",
  EditCommentNode = "[AbstractReview] Edit Comment Node",
  DeleteCommentNode = "[AbstractReview] Delete Comment Node",

  GetOptionRemindersData = "[AbstractReview] Get Option Reminders Data",
  GetOptionRemindersDataSuccessful = "[AbstractReview] Get Option Reminders Data Successful",
  GetOptionRemindersDataFailed = "[AbstractReview] Get Option Reminders Data Failed",
  ClearOptionReminderData = "[AbstractReview] Clear Option Reminder Data",

  LockRecordSuccessful = "[AbstractReview] Lock Record Successful",
  UnlockRecordSuccessful = "[AbstractReview] Unlock Record Successful",
}

export class SetAllCommentNodes implements Action {
  readonly type = AbstractReviewActionTypes.SetAllCommentNodes;

  constructor(public comments: CommentItemNode) {}
}

export class AddCommentNode implements Action {
  readonly type = AbstractReviewActionTypes.AddCommentNode;

  constructor(public comment: CommentItemNode) {}
}

export class EditCommentNode implements Action {
  readonly type = AbstractReviewActionTypes.EditCommentNode;

  constructor(public commentUid: string, public comment: CommentItemNode) {}
}

export class DeleteCommentNode implements Action {
  readonly type = AbstractReviewActionTypes.DeleteCommentNode;

  constructor(public commentUid: string, public documentUid: string) {}
}

export class LoadAbstractReview implements Action {
  readonly type = AbstractReviewActionTypes.LoadAbstractReview;
}

export class AbstractReviewLoaded implements Action {
  readonly type = AbstractReviewActionTypes.AbstractReviewLoaded;

  constructor(public payload: Entity[]) {}
}

export class AbstractReviewLoadError implements Action {
  readonly type = AbstractReviewActionTypes.AbstractReviewLoadError;

  constructor(public payload) {}
}

export class LoadSelectedAbstractDetail implements Action {
  readonly type = AbstractReviewActionTypes.LoadSelectedAbstractDetail;

  constructor(public payload: { abstractUid: string }) {}
}

export class AddNewlyCreatedProvision implements Action {
  readonly type = AbstractReviewActionTypes.AddNewlyCreatedProvision;

  constructor(
    public payload: {
      provision: FullValMultiPdfProvModel;
      provisionCategoryOrGroupUid: string;
      preProvisionLocationUid?: string;
      postProvisionLocationUid?: string;
    }
  ) {}
}

export class SelectedAbstractDetailLoaded implements Action {
  readonly type = AbstractReviewActionTypes.SelectedAbstractDetailLoaded;

  constructor(public payload: SelectedAbstractDetailLoadedPayloadInterface) {}
}

export class SelectedAbstractDetailLoadError implements Action {
  readonly type = AbstractReviewActionTypes.SelectedAbstractDetailLoadError;

  constructor(public payload: boolean) {}
}

export class SelectProvisionOption implements Action {
  readonly type = AbstractReviewActionTypes.SelectProvisionOption;

  constructor(public payload: { provisionOptionUid: string }) {}
}

export class ProvisionOptionSelected implements Action {
  readonly type = AbstractReviewActionTypes.ProvisionOptionSelected;

  constructor(public payload) {}
}

export class ProvisionOptionSelectError implements Action {
  readonly type = AbstractReviewActionTypes.ProvisionOptionSelectError;

  constructor(public payload) {}
}

export class LoadSelectedAbstractTOC implements Action {
  readonly type = AbstractReviewActionTypes.LoadSelectedAbstractTOC;

  constructor(public payload) {}
}

export class SelectedAbstractTOCLoaded implements Action {
  readonly type = AbstractReviewActionTypes.SelectedAbstractTOCLoaded;

  constructor(public payload: DocSetFullDocOutlineViewModel) {}
}

export class SelectedAbstractTOCLoadError implements Action {
  readonly type = AbstractReviewActionTypes.SelectedAbstractTOCLoadError;
}

export class UpdateMention implements Action {
  readonly type = AbstractReviewActionTypes.UpdateMention;

  constructor(
    public payload: {
      provisionUid: string;
      mention: PartialValPdfProvisionViewModel;
    }
  ) {}
}

export class UpdateMentionSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.UpdateMentionSucceeded;

  constructor(
    public payload: {
      provisionUid: string;
      mention: PartialValPdfProvisionViewModel;
    }
  ) {}
}

export class UpdateSelectedProvisionReviewStatus implements Action {
  readonly type = AbstractReviewActionTypes.UpdateSelectedProvisionReviewStatus;

  constructor(public payload) {}
}

export class UpdateSelectedProvisionMentionReviewStatus implements Action {
  readonly type =
    AbstractReviewActionTypes.UpdateSelectedProvisionMentionReviewStatus;

  constructor(
    public payload: {
      provisionUid: FullValMultiPdfProvViewModel["uid"];
      provisionOptionUid: PartialValPdfProvisionViewModel["uid"];
      reviewStatus: unknown;
    }
  ) {}
}

export class SelectedProvisionReviewStatusUpdated implements Action {
  readonly type =
    AbstractReviewActionTypes.UpdateSelectedProvisionReviewStatusSucceeded;

  constructor(
    public payload: {
      provisionUid: string;
      reviewStatus: string;
    }
  ) {}
}

export class SelectedProvisionReviewStatusError implements Action {
  readonly type =
    AbstractReviewActionTypes.UpdateSelectedProvisionReviewStatusError;

  constructor(
    public payload: {
      provisionUid: string;
      reviewStatus: string;
    }
  ) {}
}

export class PickSnippet implements Action {
  readonly type = AbstractReviewActionTypes.PickSnippet;

  constructor(public payload) {}
}

export class PickSnippetSuccess implements Action {
  readonly type = AbstractReviewActionTypes.PickSnippetSuccess;

  constructor(public payload: FullValMultiProvisionValueModel) {}
}

export class PickSnippetError implements Action {
  readonly type = AbstractReviewActionTypes.PickSnippetError;

  constructor(public payload: OApiRespDashboardDtoModel) {}
}

export class SelectMention implements Action {
  readonly type = AbstractReviewActionTypes.SelectMention;

  constructor(public payload) {}
}

export class SelectMentionSuccess implements Action {
  readonly type = AbstractReviewActionTypes.SelectMentionSuccess;

  constructor(public payload) {}
}

export class SelectMentionError implements Action {
  readonly type = AbstractReviewActionTypes.SelectMentionError;

  constructor(public payload) {}
}

export class DeSelectMention implements Action {
  readonly type = AbstractReviewActionTypes.DeSelectMention;

  constructor(public payload) {}
}

export class DeSelectMentionSuccess implements Action {
  readonly type = AbstractReviewActionTypes.DeSelectMentionSuccess;

  constructor(public payload) {}
}

export class DeSelectMentionError implements Action {
  readonly type = AbstractReviewActionTypes.DeSelectMentionError;

  constructor(public payload) {}
}

export class CreateManualMention implements Action {
  readonly type = AbstractReviewActionTypes.CreateManualMention;

  constructor(public payload) {}
}

export class CreateManualMentionSuccess implements Action {
  readonly type = AbstractReviewActionTypes.CreateManualMentionSuccess;

  constructor(public payload: FullValMultiPdfProvViewModel) {}
}

export class CreateManualMentionError implements Action {
  readonly type = AbstractReviewActionTypes.CreateManualMentionError;

  constructor(public payload) {}
}

export class LoadProvisionFullDetail implements Action {
  readonly type = AbstractReviewActionTypes.LoadProvisionFullDetail;

  constructor(public payload) {}
}

export class RemoveSelectedMention implements Action {
  readonly type = AbstractReviewActionTypes.RemoveSelectedMention;

  constructor(public payload) {}
}

export class AddMention implements Action {
  readonly type = AbstractReviewActionTypes.AddMention;

  constructor(public payload: { provisionUid: string; data }) {}
}

export class AddMentionSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.AddMentionSucceeded;

  constructor(public payload: { provisionUid: string; data }) {}
}

export class RemoveMention implements Action {
  readonly type = AbstractReviewActionTypes.RemoveMention;

  constructor(public payload: { provisionUid: string }) {}
}

export class RemoveMentionSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.RemoveMentionSucceeded;

  constructor(public payload: { provisionUid: string }) {}
}

export class ProvisionFullDetailLoaded implements Action {
  readonly type = AbstractReviewActionTypes.ProvisionFullDetailLoaded;

  constructor(public payload) {}
}

export class SetMultiplePdfProvision implements Action {
  readonly type = AbstractReviewActionTypes.SetMultiplePdfProvision;

  constructor(
    public payload: {
      provisionUid: string;
      provisionDetail;
    }
  ) {}
}

export class ProvisionFullDetailLoadError implements Action {
  readonly type = AbstractReviewActionTypes.ProvisionFullDetailLoadError;

  constructor(public payload) {}
}

export class AddTemporaryMention implements Action {
  readonly type = AbstractReviewActionTypes.AddTemporaryMention;

  constructor(public payload) {}
}

export class RemoveTemporaryMention implements Action {
  readonly type = AbstractReviewActionTypes.RemoveTemporaryMention;

  constructor(public payload) {}
}

export class SaveDocumentsShortName implements Action {
  readonly type = AbstractReviewActionTypes.SaveDocumentsShortName;

  constructor(public payload: { [documentUid: string]: DocumentsMapModel }) {}
}

export class SaveSelectedDocument implements Action {
  readonly type = AbstractReviewActionTypes.SaveSelectedDocument;

  constructor(public payload) {}
}

export class UpdateSelectedMentionLocally implements Action {
  readonly type = AbstractReviewActionTypes.UpdateSelectedMentionLocally;

  constructor(public payload) {}
}

export class CreateProvisionComment implements Action {
  readonly type = AbstractReviewActionTypes.CreateProvisionComment;

  constructor(public payload) {}
}

export class CreateProvisionCommentSuccess implements Action {
  readonly type = AbstractReviewActionTypes.CreateProvisionCommentSuccess;

  constructor(public payload) {}
}

export class CreateProvisionCommentError implements Action {
  readonly type = AbstractReviewActionTypes.CreateProvisionCommentError;

  constructor(public payload: OApiRespDashboardDtoModel) {}
}

export class LoadProvisionComments implements Action {
  readonly type = AbstractReviewActionTypes.LoadProvisionComments;

  constructor(public payload) {}
}

export class ProvisionCommentsLoaded implements Action {
  readonly type = AbstractReviewActionTypes.ProvisionCommentsLoaded;

  constructor(public payload) {}
}

export class ProvisionCommentsLoadError implements Action {
  readonly type = AbstractReviewActionTypes.ProvisionCommentsLoadError;

  constructor(public payload: OApiRespDashboardDtoModel) {}
}

export class CreateProvisionNote implements Action {
  readonly type = AbstractReviewActionTypes.CreateProvisionNote;

  constructor(public payload) {}
}

export class CreateProvisionNoteSuccess implements Action {
  readonly type = AbstractReviewActionTypes.CreateProvisionNoteSuccess;

  constructor(public payload) {}
}

export class CreateProvisionNoteError implements Action {
  readonly type = AbstractReviewActionTypes.CreateProvisionNoteError;

  constructor(public payload: OApiRespDashboardDtoModel) {}
}

export class UpdateProvisionNote implements Action {
  readonly type = AbstractReviewActionTypes.UpdateProvisionNote;

  constructor(public payload) {}
}

export class UpdateProvisionNoteSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.UpdateProvisionNoteSucceeded;

  constructor(public payload) {}
}

export class UpdateProvisionNoteFailed implements Action {
  readonly type = AbstractReviewActionTypes.UpdateProvisionNoteFailed;

  constructor(public payload) {}
}

export class CleanAbstractReviewState implements Action {
  readonly type = AbstractReviewActionTypes.CleanAbstractReviewState;

  constructor(public payload) {}
}

export class SetSelectedProvision implements Action {
  readonly type = AbstractReviewActionTypes.SetSelectedProvision;
  constructor(public payload) {}
}

export class SelectedDocumentFullyLoaded implements Action {
  readonly type = AbstractReviewActionTypes.SelectedDocumentFullyLoaded;

  constructor(public payload) {}
}

export class SetCommentCountOnRecord implements Action {
  readonly type = AbstractReviewActionTypes.SetCommentCountOnRecord;
  constructor(public payload: number) {}
}

export class ToggleCommentPanelSideBar implements Action {
  readonly type = AbstractReviewActionTypes.ToggleCommentPanelSideBar;
  constructor(public payload: boolean) {}
}

export class TogglePromptPlaygroundSideBar implements Action {
  readonly type = AbstractReviewActionTypes.TogglePromptPlaygroundSideBar;
  constructor(public payload: boolean) {}
}

export class UpdateCustomTags implements Action {
  readonly type = AbstractReviewActionTypes.UpdateCustomTags;

  constructor(
    public payload: { recordUid: string; customTags: CustomTagViewModel[] }
  ) {}
}

export class UpdateCustomTagsSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.UpdateCustomTagsSucceeded;

  constructor(public payload: OApiRespChangeCustomTagsResponseDtoModel) {}
}

export class UpdateCustomTagsFailed implements Action {
  readonly type = AbstractReviewActionTypes.UpdateCustomTagsFailed;
}

export class FetchDefaultDocumentAnnotation implements Action {
  readonly type = AbstractReviewActionTypes.FetchDefaultDocumentAnnotation;

  constructor(
    public payload: {
      recordUid: FullValMultiPdfProvViewModel["abstractUid"];
    }
  ) {}
}

export class FetchDefaultDocumentAnnotationSucceeded implements Action {
  readonly type =
    AbstractReviewActionTypes.FetchDefaultDocumentAnnotationSucceeded;

  constructor(public payload: DocPdfViewModel) {}
}

export class UpdateNoteWithProvisionUid implements Action {
  readonly type = AbstractReviewActionTypes.UpdateNoteWithProvisionUid;

  constructor(
    public payload: {
      provisionUid: string;
      notes: string;
    }
  ) {}
}

export class UpdateNoteWithProvisionUidSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.UpdateNoteWithProvisionUidSucceeded;

  constructor(
    public payload: {
      provisionUid: string;
      notes: string;
    }
  ) {}
}

export class UpdateProvisionMentionNoteSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.UpdateProvisionMentionNoteSucceeded;

  constructor(
    public payload: {
      provisionUid: FullValMultiPdfProvViewModel["uid"];
      provisionOption: PartialValPdfProvisionViewModel;
      notes: FullValMultiplePdfProvisionViewModel["notes"];
    }
  ) {}
}

export class UpdateMentionWithMentionIndex implements Action {
  readonly type = AbstractReviewActionTypes.UpdateMentionWithMentionIndex;

  constructor(
    public provisionUid: string,
    public newMention: PartialValPdfProvisionViewModel,
    public mentionIndex: number
  ) {}
}

export const UpdateProvisionMentionNote = createAction(
  AbstractReviewActionTypes.UpdateProvisionMentionNote,
  props<{
    payload: {
      provision: FullValMultiPdfProvViewModel;
      provisionOption: PartialValPdfProvisionViewModel;
      notes: FullValMultiplePdfProvisionViewModel["notes"];
    };
  }>()
);

export const UpdateProvisionMentionNoteError = createAction(
  AbstractReviewActionTypes.UpdateProvisionMentionNoteError
);

export class RenameProvisionMention implements Action {
  readonly type = AbstractReviewActionTypes.RenameProvisionMention;

  constructor(
    public payload: {
      mentionUid: string;
      provisionUid: string;
      newMentionName: string;
    }
  ) {}
}
export class RenameProvisionMentionSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.RenameProvisionMentionSucceeded;

  constructor(
    public payload: {
      mentionUid: string;
      provisionUid: string;
      uiProvisionName: string;
    }
  ) {}
}

export class AddNestedSubfields implements Action {
  readonly type = AbstractReviewActionTypes.AddNestedSubfields;

  constructor(
    public payload: {
      provisionUid: string;
      additionalSubfields: NestedProvisionSubfieldDtoModel[];
    }
  ) {}
}
export class AddNestedSubfieldsSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.AddNestedSubfieldsSucceeded;

  constructor(
    public payload: {
      provisionUid: string;
      updatedProvision: FullValMultiPdfProvViewModel;
    }
  ) {}
}

export class MakeAnchorProvision implements Action {
  readonly type = AbstractReviewActionTypes.MakeAnchorProvision;

  constructor(
    public payload: {
      provisionUid: string;
    }
  ) {}
}
export class MakeAnchorProvisionSucceeded implements Action {
  readonly type = AbstractReviewActionTypes.MakeAnchorProvisionSucceeded;

  constructor(
    public payload: {
      provisionUid: string;
      updatedProvisionInconsistencyDetails: ProvisionInconsistenciesApiDtoModel;
    }
  ) {}
}

export class UpdateProvisionReviewCompletionInfoSucceeded implements Action {
  readonly type =
    AbstractReviewActionTypes.UpdateProvisionReviewCompletionInfoSucceeded;

  constructor(
    public payload: {
      provisionUid: string;
      reviewCompletionInfos: ProvisionReviewCompletionInfoViewModel[];
    }
  ) {}
}

export class LastTouchedMentionUid implements Action {
  readonly type = AbstractReviewActionTypes.LastTouchedMentionUid;
  constructor(public payload: { mentionUid?: string }) {}
}

export class LastTouchedNestedSubfieldKey implements Action {
  readonly type = AbstractReviewActionTypes.LastTouchedNestedSubfieldKey;
  constructor(public payload: { subfieldKey?: string }) {}
}

export class GetOptionRemindersData implements Action {
  readonly type = AbstractReviewActionTypes.GetOptionRemindersData;

  constructor(
    public payload: {
      recordUid: string;
      optionRemindersType: RemindersTypeEnum;
    }
  ) {}
}

export class GetOptionRemindersDataSuccessful implements Action {
  readonly type = AbstractReviewActionTypes.GetOptionRemindersDataSuccessful;

  constructor(
    public optionRemindersCalculatorData: OptionsCalculatorDataResponseDtoModel,
    public optionRemindersType: RemindersTypeEnum
  ) {}
}

export class GetOptionRemindersDataFailed implements Action {
  readonly type = AbstractReviewActionTypes.GetOptionRemindersDataFailed;
}

export class ClearOptionReminderData implements Action {
  readonly type = AbstractReviewActionTypes.ClearOptionReminderData;
}

export class LockRecordSuccessful implements Action {
  readonly type = AbstractReviewActionTypes.LockRecordSuccessful;

  constructor(public payload: { lockInfo: RecordLockInfoDtoModel }) {}
}

export class UnlockRecordSuccessful implements Action {
  readonly type = AbstractReviewActionTypes.UnlockRecordSuccessful;
}

export type AbstractReviewAction =
  | LoadAbstractReview
  | FetchDefaultDocumentAnnotation
  | FetchDefaultDocumentAnnotationSucceeded
  | AbstractReviewLoaded
  | AbstractReviewLoadError
  | LoadSelectedAbstractDetail
  | SelectedAbstractDetailLoaded
  | SelectedAbstractDetailLoadError
  | SelectProvisionOption
  | ProvisionOptionSelected
  | ProvisionOptionSelectError
  | LoadSelectedAbstractTOC
  | SelectedAbstractTOCLoaded
  | SelectedAbstractTOCLoadError
  | PickSnippet
  | PickSnippetSuccess
  | PickSnippetError
  | SelectMention
  | SelectMentionSuccess
  | SelectMentionError
  | DeSelectMention
  | DeSelectMentionSuccess
  | DeSelectMentionError
  | CreateManualMention
  | CreateManualMentionSuccess
  | CreateManualMentionError
  | LoadProvisionFullDetail
  | RemoveSelectedMention
  | SetMultiplePdfProvision
  | ProvisionFullDetailLoaded
  | ProvisionFullDetailLoadError
  | AddTemporaryMention
  | SaveDocumentsShortName
  | SaveSelectedDocument
  | UpdateSelectedMentionLocally
  | CreateProvisionComment
  | CreateProvisionCommentSuccess
  | CreateProvisionCommentError
  | LoadProvisionComments
  | ProvisionCommentsLoaded
  | ProvisionCommentsLoadError
  | CleanAbstractReviewState
  | RemoveTemporaryMention
  | CreateProvisionNote
  | CreateProvisionNoteSuccess
  | CreateProvisionNoteError
  | UpdateProvisionNote
  | UpdateProvisionNoteSucceeded
  | UpdateProvisionNoteFailed
  | SetSelectedProvision
  | SelectedDocumentFullyLoaded
  | UpdateMention
  | UpdateMentionSucceeded
  | RemoveMention
  | RemoveMentionSucceeded
  | AddMention
  | AddMentionSucceeded
  | SetCommentCountOnRecord
  | ToggleCommentPanelSideBar
  | TogglePromptPlaygroundSideBar
  | UpdateCustomTags
  | UpdateCustomTagsSucceeded
  | UpdateCustomTagsFailed
  | UpdateNoteWithProvisionUid
  | UpdateNoteWithProvisionUidSucceeded
  | UpdateSelectedProvisionReviewStatus
  | SelectedProvisionReviewStatusUpdated
  | SelectedProvisionReviewStatusError
  | AddNewlyCreatedProvision
  | UpdateProvisionMentionNoteSucceeded
  | UpdateSelectedProvisionMentionReviewStatus
  | UpdateMentionWithMentionIndex
  | RenameProvisionMention
  | RenameProvisionMentionSucceeded
  | AddNestedSubfields
  | AddNestedSubfieldsSucceeded
  | MakeAnchorProvision
  | MakeAnchorProvisionSucceeded
  | UpdateProvisionReviewCompletionInfoSucceeded
  | LastTouchedMentionUid
  | LastTouchedNestedSubfieldKey
  | SetAllCommentNodes
  | AddCommentNode
  | EditCommentNode
  | DeleteCommentNode
  | GetOptionRemindersData
  | GetOptionRemindersDataSuccessful
  | GetOptionRemindersDataFailed
  | ClearOptionReminderData
  | LockRecordSuccessful
  | UnlockRecordSuccessful;

export const fromAbstractReviewActions = {
  LoadAbstractReview,
  AbstractReviewLoaded,
  AbstractReviewLoadError,
  LoadSelectedAbstractDetail,
  SelectedAbstractDetailLoaded,
  SelectedAbstractDetailLoadError,
  SelectProvisionOption,
  ProvisionOptionSelected,
  ProvisionOptionSelectError,
  LoadSelectedAbstractTOC,
  SelectedAbstractTOCLoaded,
  SelectedAbstractTOCLoadError,
  PickSnippet,
  PickSnippetSuccess,
  PickSnippetError,
  SelectMention,
  SelectMentionSuccess,
  SelectMentionError,
  DeSelectMention,
  DeSelectMentionSuccess,
  DeSelectMentionError,
  CreateManualMention,
  CreateManualMentionSuccess,
  CreateManualMentionError,
  LoadProvisionFullDetail,
  RemoveSelectedMention,
  SetMultiplePdfProvision,
  ProvisionFullDetailLoaded,
  ProvisionFullDetailLoadError,
  AddTemporaryMention,
  SaveDocumentsShortName,
  SaveSelectedDocument,
  UpdateSelectedMentionLocally,
  CreateProvisionComment,
  CreateProvisionCommentSuccess,
  CreateProvisionCommentError,
  LoadProvisionComments,
  ProvisionCommentsLoaded,
  ProvisionCommentsLoadError,
  CleanAbstractReviewState,
  RemoveTemporaryMention,

  CreateProvisionNote,
  CreateProvisionNoteSuccess,
  CreateProvisionNoteError,

  UpdateProvisionNote,
  UpdateProvisionNoteSucceeded,
  UpdateProvisionNoteFailed,

  SetSelectedProvision,
  SelectedDocumentFullyLoaded,

  UpdateMention,
  UpdateMentionSucceeded,

  RemoveMention,
  RemoveMentionSucceeded,

  AddMention,
  AddMentionSucceeded,

  SetCommentCountOnRecord,
  ToggleCommentPanelSideBar,

  TogglePromptPlaygroundSideBar,

  UpdateCustomTags,
  UpdateCustomTagsSucceeded,
  UpdateCustomTagsFailed,

  UpdateNoteWithProvisionUid,
  UpdateNoteWithProvisionUidSucceeded,

  UpdateSelectedProvisionReviewStatus,
  SelectedProvisionReviewStatusUpdated,
  SelectedProvisionReviewStatusError,

  AddNewlyCreatedProvision,
  UpdateProvisionMentionNote,
  UpdateProvisionMentionNoteError,
  UpdateProvisionMentionNoteSucceeded,

  UpdateSelectedProvisionMentionReviewStatus,

  UpdateMentionWithMentionIndex,
  FetchDefaultDocumentAnnotationSucceeded,

  FetchDefaultDocumentAnnotation,

  RenameProvisionMention,
  RenameProvisionMentionSucceeded,

  AddNestedSubfields,
  AddNestedSubfieldsSucceeded,

  LastTouchedMentionUid,
  LastTouchedNestedSubfieldKey,

  MakeAnchorProvision,
  MakeAnchorProvisionSucceeded,

  UpdateProvisionReviewCompletionInfoSucceeded,

  SetAllCommentNodes,
  DeleteCommentNode,
  AddCommentNode,
  EditCommentNode,

  GetOptionRemindersData,
  GetOptionRemindersDataSuccessful,
  GetOptionRemindersDataFailed,
  ClearOptionReminderData,

  LockRecordSuccessful,
  UnlockRecordSuccessful,
};
