import { NewUploadService } from "@@intelease/web/ui/src/lib/itls-new-upload";
import { DocDataFiles } from "./doc-data-files.model";

export interface DocumentReorderingEvent {
  pre: DocDataFiles[];
  post: DocDataFiles[];
}

export class MergedDocSetModel {
  docDataFiles: DocDataFiles[];

  name: string;

  documentReorderingEvents: DocumentReorderingEvent[];

  lastDocumentReorderingEvent: DocumentReorderingEvent;

  constructor(
    docDataFiles: DocDataFiles[],
    name: string,
    documentReorderingEvents: DocumentReorderingEvent[],
    lastDocumentReorderingEvent: DocumentReorderingEvent
  ) {
    this.docDataFiles = docDataFiles;
    this.name = name;
    this.documentReorderingEvents = documentReorderingEvents;
    this.lastDocumentReorderingEvent = lastDocumentReorderingEvent;
  }

  pickDefaultNameIfEmpty() {
    if (!this.name.length && this.docDataFiles.length) {
      const firstFilename = this.getFilenameWithoutExtension(
        this.docDataFiles[0].name
      );
      if (firstFilename && firstFilename.length) {
        this.name = firstFilename;
      }
    }
  }

  private getFilenameWithoutExtension(fileName: string) {
    const lastDotIdx = fileName.lastIndexOf(".");
    let nameWithoutExtension = fileName;
    if (lastDotIdx !== -1) {
      nameWithoutExtension = fileName.substr(0, lastDotIdx);
    }
    return nameWithoutExtension;
  }
}
