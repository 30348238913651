import { JsonObject, JsonProperty } from "json2typescript";
import { FinalAbstractModel } from "./final-abstract.model";
import { ProvisionFormStructureModel } from "@@intelease/app-models/provision";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src/lib/pdf/full-val-multi-pdf-prov.model";
import { NodeNavigationModel } from "@@intelease/web/common/models";
import { AbstractReviewOperationModeModel } from "@@intelease/app-models/abstract-review/src/lib/abstract-review-operation-mode.model";
import {
  CustomTagViewModel,
  DefaultDataFieldUnitsModel,
  QueueInfoDtoModel,
  RecordLockInfoDtoModel,
} from "@@intelease/api-models/adex-api-model-src";
import { Permission } from "@@intelease/app-state/drive/src/lib/models/record.types";

@JsonObject("FinalAbstractCompleteModel")
export class FinalAbstractCompleteModel extends FinalAbstractModel {
  @JsonProperty("docNames", [String])
  docNames: Array<string> = [];

  @JsonProperty("docAbstractUids", [String])
  docAbstractUids: Array<string> = [];

  @JsonProperty("permissions", [String], true)
  permissions: Array<Permission> = [];

  @JsonProperty("status", String)
  status = "";

  @JsonProperty("recordReviewMode", String, true)
  recordReviewMode = "";

  @JsonProperty("defaultDataFieldUnits", DefaultDataFieldUnitsModel, true)
  defaultDataFieldUnits = undefined;

  @JsonProperty("formStructure", ProvisionFormStructureModel)
  formStructure: ProvisionFormStructureModel = new ProvisionFormStructureModel();

  @JsonProperty("provisions", [FullValMultiPdfProvModel])
  provisions: Array<FullValMultiPdfProvModel> = [];

  @JsonProperty("nodeNavigation", NodeNavigationModel, true)
  nodeNavigation: NodeNavigationModel = undefined;

  @JsonProperty("reviewOperationMode", AbstractReviewOperationModeModel)
  reviewOperationMode: AbstractReviewOperationModeModel = undefined;

  @JsonProperty("commentCountInRecord", Number, true)
  commentCountInRecord? = 0;

  @JsonProperty("customTags", [CustomTagViewModel])
  customTags: Array<CustomTagViewModel> = [];

  @JsonProperty("queueInfo", QueueInfoDtoModel, true)
  queueInfo?: QueueInfoDtoModel = undefined;

  @JsonProperty("lockInfo", RecordLockInfoDtoModel, true)
  lockInfo?: RecordLockInfoDtoModel = undefined;

  @JsonProperty("docSetUid", String)
  docSetUid = "";
}
