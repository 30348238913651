/* tslint:disable */
/* eslint-disable */
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";
import { RequestBuilder } from "../request-builder";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { OApiDeleteRequestModel } from "../models/o-api-delete-request-model";
import { OApiReqCloneProvisionFormDtoModel } from "../models/o-api-req-clone-provision-form-dto-model";
import { OApiReqCreateProvisionFormDtoModel } from "../models/o-api-req-create-provision-form-dto-model";
import { OApiReqEditImportProvisionFormDtoModel } from "../models/o-api-req-edit-import-provision-form-dto-model";
import { OApiReqEditProvisionFormDtoModel } from "../models/o-api-req-edit-provision-form-dto-model";
import { OApiReqRenameProvisionFormDtoModel } from "../models/o-api-req-rename-provision-form-dto-model";
import { OApiRespFullProvisionFormViewModel } from "../models/o-api-resp-full-provision-form-view-model";
import { OApiRespFullProvisionGroupViewModel } from "../models/o-api-resp-full-provision-group-view-model";
import { OApiRespGroupsProvisionFormViewModel } from "../models/o-api-resp-groups-provision-form-view-model";
import { OApiRespMinimalProvisionFormViewModel } from "../models/o-api-resp-minimal-provision-form-view-model";
import { OApiRespMinimalProvisionGroupViewModel } from "../models/o-api-resp-minimal-provision-group-view-model";
import { OApiRespMultiFullProvisionFormViewModel } from "../models/o-api-resp-multi-full-provision-form-view-model";
import { OApiRespMultiFullProvisionGroupViewModel } from "../models/o-api-resp-multi-full-provision-group-view-model";
import { OApiRespMultiGroupsProvisionFormViewModel } from "../models/o-api-resp-multi-groups-provision-form-view-model";
import { OApiRespMultiMinimalProvisionFormViewModel } from "../models/o-api-resp-multi-minimal-provision-form-view-model";
import { OApiRespMultiMinimalProvisionGroupViewModel } from "../models/o-api-resp-multi-minimal-provision-group-view-model";
import { OApiSingleEntityNoDataResponseModel } from "../models/o-api-single-entity-no-data-response-model";
import { RequestModel } from "../models/request-model";

@Injectable({
  providedIn: "root",
})
export class ProvisionFormService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation cloneProvisionForm
   */
  static readonly CloneProvisionFormPath = "/api/v1/provisionForms/clone";

  /**
   * Clone a provisionForm from an existing one.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cloneProvisionForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneProvisionForm$Response(params: {
    body: OApiReqCloneProvisionFormDtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullProvisionFormViewModel
      | OApiRespGroupsProvisionFormViewModel
      | OApiRespMinimalProvisionGroupViewModel
      | OApiRespMinimalProvisionFormViewModel
      | OApiRespFullProvisionGroupViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.CloneProvisionFormPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >;
        })
      );
  }

  /**
   * Clone a provisionForm from an existing one.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cloneProvisionForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneProvisionForm(params: {
    body: OApiReqCloneProvisionFormDtoModel;
  }): Observable<
    | OApiRespFullProvisionFormViewModel
    | OApiRespGroupsProvisionFormViewModel
    | OApiRespMinimalProvisionGroupViewModel
    | OApiRespMinimalProvisionFormViewModel
    | OApiRespFullProvisionGroupViewModel
  > {
    return this.cloneProvisionForm$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
      )
    );
  }

  /**
   * Path part for operation createProvisionForm
   */
  static readonly CreateProvisionFormPath = "/api/v1/provisionForms";

  /**
   * Create a provisionForm from scratch. Creates for entire account, for now.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProvisionForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProvisionForm$Response(params: {
    body: OApiReqCreateProvisionFormDtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullProvisionFormViewModel
      | OApiRespGroupsProvisionFormViewModel
      | OApiRespMinimalProvisionGroupViewModel
      | OApiRespMinimalProvisionFormViewModel
      | OApiRespFullProvisionGroupViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.CreateProvisionFormPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >;
        })
      );
  }

  /**
   * Create a provisionForm from scratch. Creates for entire account, for now.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createProvisionForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProvisionForm(params: {
    body: OApiReqCreateProvisionFormDtoModel;
  }): Observable<
    | OApiRespFullProvisionFormViewModel
    | OApiRespGroupsProvisionFormViewModel
    | OApiRespMinimalProvisionGroupViewModel
    | OApiRespMinimalProvisionFormViewModel
    | OApiRespFullProvisionGroupViewModel
  > {
    return this.createProvisionForm$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
      )
    );
  }

  /**
   * Path part for operation getForm
   */
  static readonly GetFormPath = "/api/v1/provisionForms/{provisionFormUid}";

  /**
   * Get a provision form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForm()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForm$Response(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
    provisionFormUid: string;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullProvisionFormViewModel
      | OApiRespGroupsProvisionFormViewModel
      | OApiRespMinimalProvisionGroupViewModel
      | OApiRespMinimalProvisionFormViewModel
      | OApiRespFullProvisionGroupViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.GetFormPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
      rb.path("provisionFormUid", params.provisionFormUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >;
        })
      );
  }

  /**
   * Get a provision form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getForm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForm(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
    provisionFormUid: string;
  }): Observable<
    | OApiRespFullProvisionFormViewModel
    | OApiRespGroupsProvisionFormViewModel
    | OApiRespMinimalProvisionGroupViewModel
    | OApiRespMinimalProvisionFormViewModel
    | OApiRespFullProvisionGroupViewModel
  > {
    return this.getForm$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
      )
    );
  }

  /**
   * Path part for operation deleteForm
   */
  static readonly DeleteFormPath = "/api/v1/provisionForms/{provisionFormUid}";

  /**
   * Delete a Provision Form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteForm()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteForm$Response(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
    provisionFormUid: string;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullProvisionFormViewModel
      | OApiRespGroupsProvisionFormViewModel
      | OApiRespMinimalProvisionGroupViewModel
      | OApiRespMinimalProvisionFormViewModel
      | OApiRespFullProvisionGroupViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.DeleteFormPath,
      "delete"
    );
    if (params) {
      rb.query("view", params.view, {});
      rb.path("provisionFormUid", params.provisionFormUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >;
        })
      );
  }

  /**
   * Delete a Provision Form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteForm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteForm(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
    provisionFormUid: string;
  }): Observable<
    | OApiRespFullProvisionFormViewModel
    | OApiRespGroupsProvisionFormViewModel
    | OApiRespMinimalProvisionGroupViewModel
    | OApiRespMinimalProvisionFormViewModel
    | OApiRespFullProvisionGroupViewModel
  > {
    return this.deleteForm$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
      )
    );
  }

  /**
   * Path part for operation deleteForms
   */
  static readonly DeleteFormsPath = "/api/v1/deleteBatch/provisionForms";

  /**
   * Delete a ProvisionInfoGroup (actually, a provisionForm).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteForms()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteForms$Response(params: {
    body: OApiDeleteRequestModel;
  }): Observable<StrictHttpResponse<OApiSingleEntityNoDataResponseModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.DeleteFormsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiSingleEntityNoDataResponseModel>;
        })
      );
  }

  /**
   * Delete a ProvisionInfoGroup (actually, a provisionForm).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteForms$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteForms(params: {
    body: OApiDeleteRequestModel;
  }): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.deleteForms$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiSingleEntityNoDataResponseModel>) =>
          r.body as OApiSingleEntityNoDataResponseModel
      )
    );
  }

  /**
   * Path part for operation editAbstractProvisionForm
   */
  static readonly EditAbstractProvisionFormPath =
    "/api/v1/finalDocSets/{recordUid}/provisionForms/{provisionFormUid}";

  /**
   * Edit a provision form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editAbstractProvisionForm()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   *
   * @deprecated
   */
  editAbstractProvisionForm$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullProvisionFormViewModel
      | OApiRespGroupsProvisionFormViewModel
      | OApiRespMinimalProvisionGroupViewModel
      | OApiRespMinimalProvisionFormViewModel
      | OApiRespFullProvisionGroupViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.EditAbstractProvisionFormPath,
      "put"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >;
        })
      );
  }

  /**
   * Edit a provision form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editAbstractProvisionForm$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   *
   * @deprecated
   */
  editAbstractProvisionForm(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespFullProvisionFormViewModel
    | OApiRespGroupsProvisionFormViewModel
    | OApiRespMinimalProvisionGroupViewModel
    | OApiRespMinimalProvisionFormViewModel
    | OApiRespFullProvisionGroupViewModel
  > {
    return this.editAbstractProvisionForm$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
      )
    );
  }

  /**
   * Path part for operation editProvisionFormName
   */
  static readonly EditProvisionFormNamePath =
    "/api/v1/provisionForms/{provisionFormUid}/name";

  /**
   * Change name of a provision form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionFormName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionFormName$Response(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
    provisionFormUid: string;
    body: OApiReqRenameProvisionFormDtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullProvisionFormViewModel
      | OApiRespGroupsProvisionFormViewModel
      | OApiRespMinimalProvisionGroupViewModel
      | OApiRespMinimalProvisionFormViewModel
      | OApiRespFullProvisionGroupViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.EditProvisionFormNamePath,
      "put"
    );
    if (params) {
      rb.query("view", params.view, {});
      rb.path("provisionFormUid", params.provisionFormUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >;
        })
      );
  }

  /**
   * Change name of a provision form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionFormName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionFormName(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
    provisionFormUid: string;
    body: OApiReqRenameProvisionFormDtoModel;
  }): Observable<
    | OApiRespFullProvisionFormViewModel
    | OApiRespGroupsProvisionFormViewModel
    | OApiRespMinimalProvisionGroupViewModel
    | OApiRespMinimalProvisionFormViewModel
    | OApiRespFullProvisionGroupViewModel
  > {
    return this.editProvisionFormName$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
      )
    );
  }

  /**
   * Path part for operation editProvisionFormV2
   */
  static readonly EditProvisionFormV2Path =
    "/api/v1/provisionForms/{provisionFormUid}/provisions";

  /**
   * Edit a provision form V2.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProvisionFormV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionFormV2$Response(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
    provisionFormUid: string;
    body: OApiReqEditProvisionFormDtoModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespFullProvisionFormViewModel
      | OApiRespGroupsProvisionFormViewModel
      | OApiRespMinimalProvisionGroupViewModel
      | OApiRespMinimalProvisionFormViewModel
      | OApiRespFullProvisionGroupViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.EditProvisionFormV2Path,
      "put"
    );
    if (params) {
      rb.query("view", params.view, {});
      rb.path("provisionFormUid", params.provisionFormUid, {});
      rb.body(params.body, "application/json");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >;
        })
      );
  }

  /**
   * Edit a provision form V2.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editProvisionFormV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProvisionFormV2(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
    provisionFormUid: string;
    body: OApiReqEditProvisionFormDtoModel;
  }): Observable<
    | OApiRespFullProvisionFormViewModel
    | OApiRespGroupsProvisionFormViewModel
    | OApiRespMinimalProvisionGroupViewModel
    | OApiRespMinimalProvisionFormViewModel
    | OApiRespFullProvisionGroupViewModel
  > {
    return this.editProvisionFormV2$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
          >
        ) =>
          r.body as
            | OApiRespFullProvisionFormViewModel
            | OApiRespGroupsProvisionFormViewModel
            | OApiRespMinimalProvisionGroupViewModel
            | OApiRespMinimalProvisionFormViewModel
            | OApiRespFullProvisionGroupViewModel
      )
    );
  }

  /**
   * Path part for operation exportForm
   */
  static readonly ExportFormPath =
    "/api/v1/provision-forms/{provisionFormUid}/export";

  /**
   * Export a provision form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportForm$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportForm$Pdf$Response(params: {
    exportType:
      | "DOCX"
      | "XLSX"
      | "CSV"
      | "HTML"
      | "PDF"
      | "TSV"
      | "ZIP"
      | "WT_TSV";
    provisionFormUid: string;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.ExportFormPath,
      "get"
    );
    if (params) {
      rb.query("exportType", params.exportType, {});
      rb.path("provisionFormUid", params.provisionFormUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "blob",
          accept: "application/pdf",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export a provision form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportForm$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportForm$Pdf(params: {
    exportType:
      | "DOCX"
      | "XLSX"
      | "CSV"
      | "HTML"
      | "PDF"
      | "TSV"
      | "ZIP"
      | "WT_TSV";
    provisionFormUid: string;
  }): Observable<any> {
    return this.exportForm$Pdf$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Export a provision form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportForm$TabSeparatedValues()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportForm$TabSeparatedValues$Response(params: {
    exportType:
      | "DOCX"
      | "XLSX"
      | "CSV"
      | "HTML"
      | "PDF"
      | "TSV"
      | "ZIP"
      | "WT_TSV";
    provisionFormUid: string;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.ExportFormPath,
      "get"
    );
    if (params) {
      rb.query("exportType", params.exportType, {});
      rb.path("provisionFormUid", params.provisionFormUid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "text",
          accept: "text/tab-separated-values",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Export a provision form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportForm$TabSeparatedValues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportForm$TabSeparatedValues(params: {
    exportType:
      | "DOCX"
      | "XLSX"
      | "CSV"
      | "HTML"
      | "PDF"
      | "TSV"
      | "ZIP"
      | "WT_TSV";
    provisionFormUid: string;
  }): Observable<any> {
    return this.exportForm$TabSeparatedValues$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getRecentlyUploaded
   */
  static readonly GetRecentlyUploadedPath =
    "/api/v1/provisionForms/recentlyUploaded";

  /**
   * Get a provision form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentlyUploaded()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentlyUploaded$Response(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiFullProvisionFormViewModel
      | OApiRespMultiGroupsProvisionFormViewModel
      | OApiRespMultiMinimalProvisionGroupViewModel
      | OApiRespMultiMinimalProvisionFormViewModel
      | OApiRespMultiFullProvisionGroupViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.GetRecentlyUploadedPath,
      "get"
    );
    if (params) {
      rb.query("view", params.view, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiFullProvisionFormViewModel
            | OApiRespMultiGroupsProvisionFormViewModel
            | OApiRespMultiMinimalProvisionGroupViewModel
            | OApiRespMultiMinimalProvisionFormViewModel
            | OApiRespMultiFullProvisionGroupViewModel
          >;
        })
      );
  }

  /**
   * Get a provision form.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecentlyUploaded$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentlyUploaded(params: {
    view:
      | "MINIMAL_FORM"
      | "GROUPS_FORM"
      | "FULL_FORM"
      | "MINIMAL_GROUP"
      | "FULL_GROUP"
      | "NONE";
  }): Observable<
    | OApiRespMultiFullProvisionFormViewModel
    | OApiRespMultiGroupsProvisionFormViewModel
    | OApiRespMultiMinimalProvisionGroupViewModel
    | OApiRespMultiMinimalProvisionFormViewModel
    | OApiRespMultiFullProvisionGroupViewModel
  > {
    return this.getRecentlyUploaded$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiFullProvisionFormViewModel
            | OApiRespMultiGroupsProvisionFormViewModel
            | OApiRespMultiMinimalProvisionGroupViewModel
            | OApiRespMultiMinimalProvisionFormViewModel
            | OApiRespMultiFullProvisionGroupViewModel
          >
        ) =>
          r.body as
            | OApiRespMultiFullProvisionFormViewModel
            | OApiRespMultiGroupsProvisionFormViewModel
            | OApiRespMultiMinimalProvisionGroupViewModel
            | OApiRespMultiMinimalProvisionFormViewModel
            | OApiRespMultiFullProvisionGroupViewModel
      )
    );
  }

  /**
   * Path part for operation importNewForm
   */
  static readonly ImportNewFormPath = "/api/v1/provision-forms/import-new-form";

  /**
   * Import provision form from TSV file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importNewForm()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importNewForm$Response(params: {
    /**
     * RequestBody Payload should be passed as multipart/form-data, The files array should contain exactly one file
     */
    body: OApiReqEditImportProvisionFormDtoModel;
  }): Observable<StrictHttpResponse<OApiRespMinimalProvisionFormViewModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.ImportNewFormPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "multipart/form-data");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OApiRespMinimalProvisionFormViewModel>;
        })
      );
  }

  /**
   * Import provision form from TSV file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importNewForm$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importNewForm(params: {
    /**
     * RequestBody Payload should be passed as multipart/form-data, The files array should contain exactly one file
     */
    body: OApiReqEditImportProvisionFormDtoModel;
  }): Observable<OApiRespMinimalProvisionFormViewModel> {
    return this.importNewForm$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OApiRespMinimalProvisionFormViewModel>) =>
          r.body as OApiRespMinimalProvisionFormViewModel
      )
    );
  }

  /**
   * Path part for operation searchForms
   */
  static readonly SearchFormsPath = "/api/v2/search/provisionForms";

  /**
   * Search the provision forms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchForms()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  searchForms$Response(params?: {
    body?: RequestModel;
  }): Observable<
    StrictHttpResponse<
      | OApiRespMultiFullProvisionFormViewModel
      | OApiRespMultiGroupsProvisionFormViewModel
      | OApiRespMultiMinimalProvisionGroupViewModel
      | OApiRespMultiMinimalProvisionFormViewModel
      | OApiRespMultiFullProvisionGroupViewModel
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProvisionFormService.SearchFormsPath,
      "post"
    );
    if (params) {
      rb.body(params.body, "*/*");
    }

    return this.http
      .request(
        rb.build({
          responseType: "json",
          accept: "application/json",
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            | OApiRespMultiFullProvisionFormViewModel
            | OApiRespMultiGroupsProvisionFormViewModel
            | OApiRespMultiMinimalProvisionGroupViewModel
            | OApiRespMultiMinimalProvisionFormViewModel
            | OApiRespMultiFullProvisionGroupViewModel
          >;
        })
      );
  }

  /**
   * Search the provision forms.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchForms$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  searchForms(params?: {
    body?: RequestModel;
  }): Observable<
    | OApiRespMultiFullProvisionFormViewModel
    | OApiRespMultiGroupsProvisionFormViewModel
    | OApiRespMultiMinimalProvisionGroupViewModel
    | OApiRespMultiMinimalProvisionFormViewModel
    | OApiRespMultiFullProvisionGroupViewModel
  > {
    return this.searchForms$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<
            | OApiRespMultiFullProvisionFormViewModel
            | OApiRespMultiGroupsProvisionFormViewModel
            | OApiRespMultiMinimalProvisionGroupViewModel
            | OApiRespMultiMinimalProvisionFormViewModel
            | OApiRespMultiFullProvisionGroupViewModel
          >
        ) =>
          r.body as
            | OApiRespMultiFullProvisionFormViewModel
            | OApiRespMultiGroupsProvisionFormViewModel
            | OApiRespMultiMinimalProvisionGroupViewModel
            | OApiRespMultiMinimalProvisionFormViewModel
            | OApiRespMultiFullProvisionGroupViewModel
      )
    );
  }
}
