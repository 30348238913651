import { Injectable } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import { ProvisionFormExportModalComponent } from "./provision-form-export-modal.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class ProvisionFormExportModalService {
  constructor(private dialog: MatDialog) {}

  openProvisionFormExportModal(
    modalData: ModalInputModel,
    options: MatDialogConfig = { minWidth: 340 }
  ) {
    return this.dialog.open(ProvisionFormExportModalComponent, {
      data: modalData,
      disableClose: true,
      panelClass: "provision-form-export-modal",
      minWidth: options.minWidth,
    });
  }
}
