import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";
import {
  DOC_SET_ACTION__ADD_TO_QUEUE,
  DOC_SET_ACTION__DELETE,
  DOC_SET_ACTION__DOWNLOAD,
  DOC_SET_ACTION__MOVE,
  DOC_SET_ACTION__VIEW,
  DOC_SET_ACTION__REVIEW,
  DOC_SET_ACTION__RENAME,
  DOC_SET_ACTION__SHARE,
  DOC_SET_ACTION__MERGE,
  DOC_SET_ACTION__FULL_EXPORT,
} from "./doc-set-action-buttons.const";
import {
  RECORD_ACTION__ASSIGN,
  RECORD_ACTION__OPEN_IN_NEW_TAB,
} from "./record-action-buttons.const";

export * from "./folder-table.const";
export * from "./error-record-action-buttons.const";
export * from "./record-action-buttons.const";
export * from "./folder-action-buttons.const";
export * from "./doc-set-action-buttons.const";
export * from "./multi-selection-action-buttons.const";
export * from "./deleted-record-action-buttons.const";
export * from "./drive-search-table.const";

export const COMMON_ACTION_BUTTONS: ActionButtonInterface[] = [
  RECORD_ACTION__OPEN_IN_NEW_TAB,
  DOC_SET_ACTION__VIEW,
  DOC_SET_ACTION__REVIEW,
  DOC_SET_ACTION__RENAME,
  DOC_SET_ACTION__ADD_TO_QUEUE,
  DOC_SET_ACTION__MERGE,
  DOC_SET_ACTION__SHARE,
  RECORD_ACTION__ASSIGN,
  DOC_SET_ACTION__DOWNLOAD,
  DOC_SET_ACTION__MOVE,
  DOC_SET_ACTION__DELETE,
  DOC_SET_ACTION__FULL_EXPORT,
];
