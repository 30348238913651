/* tslint:disable */
/* eslint-disable */

/**
 * the subfields for this nested group provision
 */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";

@JsonObject("NestedGroupProvisionSubfieldModel")
export class NestedGroupProvisionSubfieldModel {
  @JsonProperty("allModelKeys", [String], true)
  allModelKeys?: Array<string> = [];

  /**
   * the key to use for the model when finding a value for this subfield
   */

  @JsonProperty("modelKey", String, true)
  modelKey?: string = "";

  /**
   * the keys that, if found by the model, can correspond to this subfield
   */

  @JsonProperty("modelKeys", [String], true)
  modelKeys?: Array<string> = [];

  /**
   * the user-facing name for this subfield
   */

  @JsonProperty("uiName", String, true)
  uiName?: string = "";
}
