<div class="advanced-search-filter-container">
  <il-filter
    style="min-height: 100px"
    [model]="selectedFilter"
    [filterFields]="filterFields"
    [filterOperators]="driveFacade.advancedSearchFieldsOperators$ | async"
    (modelChange)="onFilterModelChange($event)"
  ></il-filter>
  <div class="footer-buttons">
    <button mat-button (click)="onCancelBtnClick()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onNextBtnClick()">
      Next
    </button>
  </div>
</div>
