import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Subscription } from "rxjs";
import * as _ from "lodash";

import {
  DriveFacade,
  SelectedFilterModel,
} from "@intelease/app-state/drive-v2";
import { ItlsDriveService } from "../../services/itls-drive.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
@Component({
  selector: "il-advanced-search",
  templateUrl: "./itls-advanced-search.component.html",
  styleUrls: ["./itls-advanced-search.component.scss"],
})
export class AdvancedSearchComponent {
  destroyRef = inject(DestroyRef);
  //for filter
  filterModel: SelectedFilterModel;

  //for columns
  selectedColumns: any[] = [];
  columnFieldsModel: any[] = [];

  selectedTabIndex: number;

  @Output() toggleAdvancedSearch: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public driveFacade: DriveFacade,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private driveService: ItlsDriveService
  ) {
    this.initActions();
  }

  initActions() {
    this.selectedTabIndex = 0;
    this.driveFacade.loadAdvancedSearchFields();

    this.driveFacade.getSelectedFilter$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((_filterModel) => {
        this.filterModel = _filterModel;
      });

    this.driveFacade.getCurrentSelectedColumnFields$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedColumnFields) => {
        this.columnFieldsModel = selectedColumnFields;
        this.selectedColumns = selectedColumnFields.map(
          (selectedFilterField) => {
            return `${selectedFilterField?.prop}::${selectedFilterField?.name}::${selectedFilterField?.type}`;
          }
        );
      });
  }

  closeModal() {
    this.toggleAdvancedSearch.emit(false);
    this.selectedTabIndex = 0;
  }

  selectedTabChange(evt: MatTabChangeEvent) {
    this.selectedTabIndex = evt.index;
  }

  onApplyAdvancedSearch() {
    this.driveFacade.applyAdvancedSearch();
    this.driveService.loadAdvancedSearchRecords.next([]);
    this.cdr.detectChanges();
    this.closeModal();
  }

  onChangeTab() {
    this.selectedTabIndex === 0
      ? this.selectedTabIndex++
      : this.selectedTabIndex--;
  }

  onCancelAdvancedSearch() {
    this.driveFacade.cancelAdvancedSearch();
    this.closeModal();
  }
}
