<div class="advanced-search-filter-container">
  <div
    style="position: relative; height: 100%; min-height: 215px"
    (click)="$event.stopPropagation()"
  >
    <mat-progress-spinner
      diameter="50"
      mode="indeterminate"
      style="position: absolute; top: 42%; left: 45%"
      *ngIf="!(driveFacade.getAdvancedSearchFieldsList$ | async)"
    ></mat-progress-spinner>
    <ng-container *ngIf="driveFacade.getAdvancedSearchFieldsList$ | async">
      <div class="p-8" style="margin: 0 1%">
        <mat-form-field style="margin-bottom: -18px">
          <mat-label>Add field</mat-label>
          <input
            #searchInput
            type="text"
            matInput
            [formControl]="recordColumnControl"
            itlsFocusIn
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <div
              class="report-filter-fields-group"
              *ngFor="let group of filterFieldGroups | async"
            >
              <span class="fields-group-label" [matTooltip]="group.title">{{
                group.title
              }}</span>
              <mat-option
                *ngFor="let field of group.fields"
                [value]="field.uiName"
                (onSelectionChange)="onFilterFieldOptionSelected(field)"
                [matTooltip]="field.uiName"
                [matTooltipDisabled]="field?.uiName?.length < 45"
                [id]="field.uiName"
              >
                <itls-icon
                  [svgIcon]="field.fieldType | translateIconType"
                  class="field-icon"
                ></itls-icon>
                {{ field.uiName }}
              </mat-option>
            </div>
          </mat-autocomplete>
        </mat-form-field>
        <ng-scrollbar style="max-height: 100%">
          <div class="filter-fields-list">
            <div class="main-body">
              <mat-list
                class="filter-fields-list-container"
                style="padding-top: 0"
                cdkDropList
                (cdkDropListDropped)="drop($event)"
              >
                <ng-container>
                  <mat-list-item
                    cdkDrag
                    cdkDragBoundary=".filter-fields-list-container"
                    class="my-1 column-list-item"
                    *ngFor="let field of selectedFilterFields; let i = index"
                    style="border-radius: 5px"
                  >
                    <div
                      fxLayout
                      fxLayoutAlign="space-between center"
                      style="width: 100%"
                    >
                      <div fxLayout fxLayoutAlign="space-between center">
                        <mat-icon
                          cdkDragHandle
                          class="mr-3"
                          style="font-size: 21px; cursor: move"
                          >drag_handle</mat-icon
                        >
                        <p
                          mat-line
                          [matTooltip]="field?.uiName"
                          [matTooltipDisabled]="field?.uiName?.length < 45"
                          style="margin-bottom: 0; max-width: 195px"
                        >
                          {{ field?.uiName }}
                        </p>
                      </div>
                      <button
                        style="
                          width: 30px;
                          height: 30px;
                          line-height: 30px;
                          margin-right: 2px;
                        "
                        mat-icon-button
                        matTooltip="Remove"
                        (click)="onRemoveFilterFields(i)"
                      >
                        <itls-icon
                          style="margin-top: -8px !important"
                          [height]="14"
                          mat-list-icon
                          svgIcon="icon-remove"
                        ></itls-icon>
                      </button>
                    </div>
                  </mat-list-item>
                </ng-container>
                <ng-container *ngIf="selectedFilterFields.length === 0">
                  <mat-list-item
                    class="my-1 column-list-item empty"
                    style="border-radius: 5px"
                  >
                    No column found.
                  </mat-list-item>
                </ng-container>
              </mat-list>
            </div>
          </div>
        </ng-scrollbar>
      </div>
    </ng-container>
  </div>
  <div class="footer-buttons">
    <button mat-button (click)="onCancelBtnClick()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onApplyBtnClick()">
      Apply
    </button>
  </div>
</div>
