import { DriveAction, DriveActionTypes } from "./drive.actions";
import {
  cloneDeep,
  findIndex,
  last,
  slice,
  keyBy as _keyBy,
  isEmpty,
} from "lodash";
import {
  SelectedFilterModel,
  SelectedColumnFieldsModel,
  IDriveNode,
  Paginated,
  DriveNodeType,
} from "../models/drive-node.types";
import { ReportLogicalOperatorEnum } from "@@intelease/web/intelease/constants";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";

export const DRIVE_FEATURE_KEY = "drive2";

export interface DriveState {
  driveNodes?: Paginated<IDriveNode>;
  isAllDriveNodesLoading?: boolean;
  loadAllDriveNodesSucceeded?: boolean;
  loadAllDriveNodesError?: boolean;

  advancedSearchFields?: any;
  advancedSearchFieldsLoaded?: boolean;
  advancedSearchFieldsLoadError?: any;
  advancedSearchPossibleColumns?: any;
  advancedSearchLoaded?: boolean;
  isAdvancedSearchActive?: boolean;

  provisionsOperator?: any;
  fieldTypeToOperationAggregations?: any;

  sortDriveNodesLoading?: boolean;
  sortDriveNodesFailed?: any;

  selectedColumnFields: {
    applied: SelectedColumnFieldsModel[];
    current: SelectedColumnFieldsModel[];
  };

  selectedFilter: {
    applied?: SelectedFilterModel;
    current?: SelectedFilterModel;
  };
}

export interface DrivePartialState {
  readonly [DRIVE_FEATURE_KEY]: DriveState;
}

export const initialState: DriveState = {
  driveNodes: {
    items: [],
    pagination: {
      totalResults: 0,
      page: 1,
    },
  },
  isAllDriveNodesLoading: true,
  loadAllDriveNodesSucceeded: false,
  advancedSearchFields: {},
  advancedSearchFieldsLoaded: false,
  advancedSearchLoaded: false,
  advancedSearchFieldsLoadError: undefined,
  isAdvancedSearchActive: false,
  selectedColumnFields: {
    applied: [],
    current: [],
  },
  selectedFilter: {
    applied: {
      operator: ReportLogicalOperatorEnum.AND,
      value: [],
    },
    current: {
      operator: ReportLogicalOperatorEnum.AND,
      value: [],
    },
  },
};

export function reducer(
  state: DriveState = initialState,
  action: DriveAction
): DriveState {
  switch (action.type) {
    case DriveActionTypes.LoadAdvancedSearchFields: {
      state = {
        ...state,
        advancedSearchFieldsLoaded: false,
        advancedSearchFieldsLoadError: undefined,
      };
      break;
    }

    case DriveActionTypes.AdvancedSearchFieldsLoaded: {
      const { columnFields, filterFields, fieldTypeToRelations } =
        action.payload;
      state = {
        ...state,
        advancedSearchFields: {
          generalFields: filterFields,
        },
        advancedSearchPossibleColumns: columnFields,
        provisionsOperator: fieldTypeToRelations,
        advancedSearchFieldsLoaded: true,
        advancedSearchFieldsLoadError: undefined,
      };
      break;
    }

    case DriveActionTypes.AdvancedSearchFieldsError: {
      state = {
        ...state,
        advancedSearchFieldsLoadError: action.payload,
      };
      break;
    }

    case DriveActionTypes.LoadAdvancedSearchDriveNodes: {
      state = {
        ...state,
        advancedSearchLoaded: false,
        isAdvancedSearchActive: true,
        isAllDriveNodesLoading: true,
      };
      break;
    }

    case DriveActionTypes.AdvancedSearchDriveNodesLoaded: {
      const { items, pagination } = action.payload;
      state = {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items,
          pagination,
        },
        advancedSearchLoaded: true,
        isAdvancedSearchActive: true,
        isAllDriveNodesLoading: false,
      };
      break;
    }

    case DriveActionTypes.AdvancedSearchDriveNodesError: {
      state = {
        ...state,
        isAdvancedSearchActive: false,
        isAllDriveNodesLoading: false,
        loadAllDriveNodesSucceeded: false,
        loadAllDriveNodesError: true,
        selectedFilter: {
          applied: {
            operator: ReportLogicalOperatorEnum.AND,
            value: [],
          },
          current: {
            operator: ReportLogicalOperatorEnum.AND,
            value: [],
          },
        },
      };
      break;
    }

    case DriveActionTypes.ChangePage: {
      state = {
        ...state,
      };
      break;
    }

    case DriveActionTypes.ChangePageSucceeded: {
      state = {
        ...state,
        driveNodes: {
          ...state?.driveNodes,
          items: state.driveNodes?.items,
          pagination: {
            ...state.driveNodes?.pagination,
            page: action.payload,
          },
        } as Paginated<IDriveNode>,
      };
      break;
    }

    case DriveActionTypes.ChangePageError: {
      state = {
        ...state,
      };
      break;
    }

    case DriveActionTypes.LoadAllDriveNodes: {
      state = {
        ...state,
        driveNodes: undefined,
        isAllDriveNodesLoading: true,
        loadAllDriveNodesSucceeded: false,
        loadAllDriveNodesError: undefined,
      };
      break;
    }
    case DriveActionTypes.LoadAllDriveNodesSucceeded: {
      state = {
        ...state,
        driveNodes: action.payload,
        isAllDriveNodesLoading: false,
        loadAllDriveNodesSucceeded: true,
        loadAllDriveNodesError: undefined,
        isAdvancedSearchActive: false,
      };
      break;
    }

    case DriveActionTypes.LoadAllDriveNodesFailed: {
      state = {
        ...state,
        driveNodes: undefined,
        isAllDriveNodesLoading: false,
        loadAllDriveNodesSucceeded: false,
        loadAllDriveNodesError: true,
      };
      break;
    }

    case DriveActionTypes.SortDriveNodes: {
      state = {
        ...state,
        sortDriveNodesLoading: true,
        sortDriveNodesFailed: false,
        isAllDriveNodesLoading: true,
      };
      break;
    }

    case DriveActionTypes.SortsRecordsSucceeded: {
      const { items } = action.payload;
      state = {
        ...state,
        driveNodes: {
          ...(state.driveNodes as Paginated<IDriveNode>),
          items: items as IDriveNode[],
        },
        sortDriveNodesLoading: false,
        sortDriveNodesFailed: false,
        isAllDriveNodesLoading: false,
      };
      break;
    }

    case DriveActionTypes.SortsRecordsError: {
      state = {
        ...state,
        sortDriveNodesFailed: true,
        sortDriveNodesLoading: false,
        loadAllDriveNodesSucceeded: false,
        loadAllDriveNodesError: true,
      };
      break;
    }

    case DriveActionTypes.UpdateRecordStatus: {
      return {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items: state.driveNodes?.items.map((item: IDriveNode) => {
            if (item.uid === action.payload.recordUid) {
              return {
                ...item,
                status: action.payload.status,
              };
            } else {
              return item;
            }
          }),
          pagination: state.driveNodes?.pagination,
        } as Paginated<IDriveNode>,
      };
    }

    case DriveActionTypes.UpdateDocument: {
      const { recordUid, queueName, queueStage, queueUid } = action.payload;
      return {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items: state.driveNodes?.items.map((item: IDriveNode) => {
            if (item.recordUid === recordUid) {
              return {
                ...item,
                queueName: queueName || item.queueName,
                queueUid: queueUid || item.queueUid,
                queueStage,
              };
            } else {
              return item;
            }
          }),
          pagination: state.driveNodes?.pagination,
        } as Paginated<IDriveNode>,
      };
    }

    case DriveActionTypes.AddDocSetPartial: {
      if (isEmpty(state.driveNodes?.items)) {
        return { ...state };
      }
      const {
        docSetUid,
        docSetName,
        recordUid,
        directoryUid,
        queueName,
        queueStage,
        queueUid,
        owner,
        permissions,
        documentCount,
      } = action.payload;
      if (
        !isEmpty(state.driveNodes?.items.find((item) => item.uid === docSetUid))
      ) {
        return {
          ...state,
          driveNodes: {
            ...state.driveNodes,
            items: [
              ...(state.driveNodes?.items.filter(
                (item) => item.type === DriveNodeType.DIRECTORY
              ) as IDriveNode[]),
              ...(
                state.driveNodes?.items.filter(
                  (item) =>
                    item.type === DriveNodeType.DOC_SET ||
                    item.type === DriveNodeType.RECORD
                ) as IDriveNode[]
              ).map((item) => {
                if (item.uid === docSetUid) {
                  return {
                    ...item,
                    createdAt: Json2TypescriptHelper.convertToEntity(
                      new Date(),
                      Date
                    ),
                    lastModifiedDate: Json2TypescriptHelper.convertToEntity(
                      new Date(),
                      Date
                    ),
                    name: docSetName,
                    uid: docSetUid,
                    permissions: permissions,
                    type: DriveNodeType.DOC_SET,
                    recordUid,
                    queueName,
                    queueUid,
                    queueStage,
                    owner,
                    documentCount,
                    ownerUid: owner?.uid,
                  };
                }
                return item;
              }),
            ],
          } as Paginated<IDriveNode>,
        };
      }

      const _state: DriveState = {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items: [
            ...(state.driveNodes?.items.filter(
              (item) => item.type === DriveNodeType.DIRECTORY
            ) as IDriveNode[]),
            {
              createdAt: Json2TypescriptHelper.convertToEntity(
                new Date(),
                Date
              ),
              lastModifiedDate: Json2TypescriptHelper.convertToEntity(
                new Date(),
                Date
              ),
              name: docSetName,
              uid: docSetUid,
              permissions: permissions,
              type: DriveNodeType.DOC_SET,
              recordUid,
              queueName,
              queueUid,
              queueStage,
              documentCount,
              owner,
              ownerUid: owner?.uid,
            },
            ...(state.driveNodes?.items.filter(
              (item) =>
                item.type === DriveNodeType.DOC_SET ||
                item.type === DriveNodeType.RECORD
            ) as IDriveNode[]),
          ],
        } as Paginated<IDriveNode>,
      };

      if (!directoryUid && location.pathname.includes("/drive/owned")) {
        return _state;
      }

      if (
        location.pathname.includes("/drive/folders") &&
        last(location.pathname.split("/")) === directoryUid
      ) {
        return _state;
      }

      if (location.pathname.includes("/drive/documents")) {
        return _state;
      }
      break;
    }

    case DriveActionTypes.AddRecord: {
      state = {
        ...state,
      };
      break;
    }

    case DriveActionTypes.AddRecordSucceeded: {
      const { category, directoryUid, data } = action.payload;
      const _state: DriveState = cloneDeep(state);
      const _directoryUid = last(
        _state.driveNodes?.nodeNavigation?.navigations
      )?.uid;
      const _category = _state.driveNodes?.nodeNavigation?.category;

      if (category === _category && directoryUid === _directoryUid) {
        const _index = findIndex(
          _state.driveNodes?.items,
          (item: any) => item.type === DriveNodeType.RECORD
        );
        const _items =
          _index && _index > 0
            ? [
                ...(slice(_state.driveNodes?.items, 0, _index) as IDriveNode[]),
                data,
                ...(slice(_state.driveNodes?.items, _index) as IDriveNode[]),
              ]
            : [...(_state.driveNodes?.items as IDriveNode[]), data];
        state = {
          ...state,
          driveNodes: {
            ...state.driveNodes,
            pagination: {
              ...state.driveNodes?.pagination,
              totalResults:
                (state.driveNodes?.pagination?.totalResults || 0) <= 20
                  ? (state.driveNodes?.pagination?.totalResults || 0) + 1
                  : state.driveNodes?.pagination?.totalResults,
            },
            items: _items.slice(0, 20),
          } as Paginated<IDriveNode>,
        };
      } else {
        state = { ...state };
      }
      break;
    }

    case DriveActionTypes.AddRecordFailed: {
      state = {
        ...state,
      };
      break;
    }

    case DriveActionTypes.AddDocSet: {
      state = {
        ...state,
      };
      break;
    }

    case DriveActionTypes.AddDocSetSucceeded: {
      const { category, directoryUid, data } = action.payload;
      const _state: DriveState = cloneDeep(state);
      const _directoryUid = last(
        _state.driveNodes?.nodeNavigation?.navigations
      )?.uid;
      const _category = _state.driveNodes?.nodeNavigation?.category;
      if (category === _category && directoryUid === _directoryUid) {
        const _index = findIndex(
          _state.driveNodes?.items,
          (item: any) => item.type === DriveNodeType.DOC_SET
        );
        const _items =
          _index && _index > 0
            ? [
                ...(slice(_state.driveNodes?.items, 0, _index) as IDriveNode[]),
                data,
                ...(slice(_state.driveNodes?.items, _index) as IDriveNode[]),
              ]
            : [...(_state.driveNodes?.items as IDriveNode[]), data];
        state = {
          ...state,
          driveNodes: {
            ...state.driveNodes,
            pagination: {
              ...state.driveNodes?.pagination,
              totalResults:
                (state.driveNodes?.pagination?.totalResults || 0) <= 20
                  ? (state.driveNodes?.pagination?.totalResults || 0) + 1
                  : state.driveNodes?.pagination?.totalResults,
            },
            items: _items.slice(0, 20),
          } as Paginated<IDriveNode>,
        };
      } else {
        state = { ...state };
      }
      break;
    }

    case DriveActionTypes.AddDocSetFailed: {
      state = {
        ...state,
      };
      break;
    }

    case DriveActionTypes.AddDirectory: {
      state = {
        ...state,
      };
      break;
    }

    case DriveActionTypes.AddDirectorySucceeded: {
      const { category, directoryUid, data } = action.payload;
      const _state: DriveState = cloneDeep(state);
      const _directoryUid = last(
        _state.driveNodes?.nodeNavigation?.navigations
      )?.uid;
      const _category = _state.driveNodes?.nodeNavigation?.category;

      if (category === _category && directoryUid === _directoryUid) {
        const _items = _state.driveNodes?.items
          ? [data, ..._state.driveNodes?.items]
          : [data];
        state = {
          ...state,
          driveNodes: {
            ...state.driveNodes,
            pagination: {
              ...state.driveNodes?.pagination,
              totalResults:
                (state.driveNodes?.pagination?.totalResults || 0) <= 20
                  ? (state.driveNodes?.pagination?.totalResults || 0) + 1
                  : state.driveNodes?.pagination?.totalResults,
            },
            items: _items.slice(0, 20),
          } as Paginated<IDriveNode>,
        };
      } else {
        state = { ...state };
      }
      break;
    }

    case DriveActionTypes.AddDirectoryFailed: {
      state = {
        ...state,
      };
      break;
    }

    case DriveActionTypes.SetSelectedFilter: {
      state = {
        ...state,
        selectedFilter: {
          ...state.selectedFilter,
          current: action.payload,
        },
      };
      break;
    }

    case DriveActionTypes.CancelAdvancedSearch: {
      state = {
        ...state,
        selectedFilter: {
          ...state.selectedFilter,
          current: state.selectedFilter?.applied,
        },
        selectedColumnFields: {
          ...state.selectedColumnFields,
          current: state.selectedColumnFields?.applied,
        },
      };
      break;
    }

    case DriveActionTypes.ApplyAdvancedSearch: {
      state = {
        ...state,
        selectedColumnFields: {
          ...state.selectedColumnFields,
          applied: state.selectedColumnFields.current,
        },
        selectedFilter: {
          ...state.selectedFilter,
          applied: state.selectedFilter?.current,
        },
      };
      break;
    }

    case DriveActionTypes.SetSelectedColumnFields: {
      state = {
        ...state,
        selectedColumnFields: {
          applied: action.payload,
          current: action.payload,
        },
      };
      break;
    }

    case DriveActionTypes.CurrentSelectedColumnFields: {
      state = {
        ...state,
        selectedColumnFields: {
          ...state.selectedColumnFields,
          current: action.payload,
        },
      };
      break;
    }

    case DriveActionTypes.CloseAdvancedSearch: {
      state = {
        ...state,
        isAdvancedSearchActive: false,
        selectedFilter: {
          applied: {
            operator: ReportLogicalOperatorEnum.AND,
            value: [],
          },
          current: {
            operator: ReportLogicalOperatorEnum.AND,
            value: [],
          },
        },
      };
      break;
    }

    case DriveActionTypes.RenameDriveNode: {
      const {
        row: { uid },
        newName,
      } = action.payload;
      const _records = {
        ...state.driveNodes,
        items: state.driveNodes?.items.map((item: IDriveNode) => {
          if (uid !== item.uid) return item;
          return {
            ...item,
            name: newName,
          };
        }),
      };
      state = {
        ...state,
        driveNodes: _records as Paginated<IDriveNode>,
      };
      break;
    }

    case DriveActionTypes.RenameDriveNodeSucceeded: {
      const { lastModifiedDate, name, uid } = action.payload;
      const _items =
        state.driveNodes?.items
          .map((item: IDriveNode) => {
            if (item.uid !== uid) return item;
            return {
              ...item,
              lastModifiedDate,
              name,
            } as IDriveNode;
          })
          .sort((a: IDriveNode, b: IDriveNode) => {
            if (
              !a?.lastModifiedDate?.getTime() ||
              !b?.lastModifiedDate?.getTime()
            )
              return 0;
            return b.lastModifiedDate.getTime() - a.lastModifiedDate.getTime();
          }) || [];
      state = {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items: [
            ..._items.filter((item) => item.type === DriveNodeType.DIRECTORY),
            ..._items.filter(
              (item) =>
                item.type === DriveNodeType.RECORD ||
                item.type === DriveNodeType.DOC_SET
            ),
          ],
        } as Paginated<IDriveNode>,
        sortDriveNodesFailed: !state.sortDriveNodesLoading,
      };
      break;
    }

    case DriveActionTypes.RenameDriveNodeError: {
      const { oldName, uid } = action.payload;
      const _records = {
        ...state.driveNodes,
        items: state.driveNodes?.items.map((item) => {
          if (item.uid !== uid) return item;
          return {
            ...item,
            name: oldName,
          };
        }),
      };
      state = {
        ...state,
        driveNodes: _records as Paginated<IDriveNode>,
      };
      break;
    }

    case DriveActionTypes.UpdateCustomTagsSucceeded: {
      state = {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items: state.driveNodes?.items.map((_item: any) =>
            _item.map((item: any) => {
              if (item.uid === action.payload.recordUid)
                return {
                  ...item,
                  customTags: action.payload.body.data?.customTags,
                };
              return item;
            })
          ),
        } as Paginated<IDriveNode>,
      };
      break;
    }

    case DriveActionTypes.UpdateShareRecordWithUid: {
      state = {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items: state.driveNodes?.items.map((item) => {
            if (action.recordUid === item.uid)
              return {
                ...item,
                shared: action.shared,
              };
            return item;
          }),
        } as Paginated<IDriveNode>,
      };
      break;
    }

    case DriveActionTypes.AddBatchDocSetsToQueue: {
      const { docSetUids, queueName, queueUid } = action.payload;
      state = {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items: state.driveNodes?.items.map((item) =>
            docSetUids.includes(item.uid)
              ? {
                  ...item,
                  queueName,
                  queueUid,
                }
              : item
          ),
        } as Paginated<IDriveNode>,
      };
      break;
    }
    case DriveActionTypes.AddBatchDocSetToQueueSuccessful: {
      const { docSetUids, ingestionResult } = action.payload;
      const docSetIdToRes = _keyBy(ingestionResult, "docSetUid");
      state = {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items: state.driveNodes?.items.map((item) =>
            docSetUids.includes(item.uid)
              ? {
                  ...item,
                  queueName: docSetIdToRes[item.uid]?.recordUid
                    ? docSetIdToRes[item.uid].queueName
                    : "",
                  queueUid: docSetIdToRes[item.uid]?.recordUid
                    ? docSetIdToRes[item.uid].queueUid
                    : "",
                  recordUid: docSetIdToRes[item.uid]?.recordUid
                    ? docSetIdToRes[item.uid].recordUid
                    : "",
                  queueStage: docSetIdToRes[item.uid]?.recordUid
                    ? docSetIdToRes[item.uid]?.queueStage
                    : "",
                }
              : item
          ),
        } as Paginated<IDriveNode>,
      };
      break;
    }

    case DriveActionTypes.AddBatchDocSetToQueueFailed: {
      const { docSetUids } = action;
      state = {
        ...state,
        driveNodes: {
          ...state.driveNodes,
          items: state.driveNodes?.items.map((item) =>
            docSetUids.includes(item.uid)
              ? {
                  ...item,
                  queueName: "",
                  queueUid: "",
                }
              : item
          ),
        } as Paginated<IDriveNode>,
      };
    }
  }
  return state;
}
