import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MergedDocSetModel } from "../models";

@Component({
  selector: "intelease-select-files",
  templateUrl: "./select-files.component.html",
  styleUrls: [],
})
export class SelectFilesFromDocSetsComponent {
  @Input()
  verticalView = true;
  @Input()
  mergedDocSets: MergedDocSetModel[];
  @Output()
  modelChanged: EventEmitter<MergedDocSetModel[]> = new EventEmitter<
    MergedDocSetModel[]
  >();

  onAllFilesRemoved(uploadedDocSet: MergedDocSetModel) {
    const index = this.mergedDocSets.findIndex(
      (item) => item.name === uploadedDocSet.name
    );
    this.mergedDocSets.splice(index, 1);
    if (!this.mergedDocSets.length) {
      this.mergedDocSets = undefined;
      this.modelChanged.emit(this.mergedDocSets || []);
    } else {
      this.modelChanged.emit([...this.mergedDocSets]);
    }
  }

  getDocumentCounts(mergedDocSets: MergedDocSetModel[]) {
    return mergedDocSets
      .map((item) => item.docDataFiles.length)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
  }

  getSourceDocSetCount(mergedDocSets: MergedDocSetModel[]): number {
    return new Set(
      mergedDocSets
        .flatMap((docSet) => docSet.docDataFiles)
        .map((docDataFile) => docDataFile.docSetUid)
    ).size;
  }
}
