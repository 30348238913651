import { Injectable } from "@angular/core";
import { LoggedInUserModel } from "@@intelease/web/common/models/user/loggedin-user.model";
import { Router } from "@angular/router";
import { CreateApiService } from "@@intelease/web/intelease/services/backend/create";
import { EditApiService } from "@@intelease/web/intelease/services/backend/edit";
import { FetchApiService } from "@@intelease/web/intelease/services";
import { MainDrawerService } from "@@intelease/web/intelease/services/main-drawer.service";
import { EditUserService } from "@@intelease/web/intelease/services/models/user/edit/edit-user.service";
import { LoginTosDtoModel } from "@@intelease/app-models/auth/src/lib/auth/login-tos-dto.model";
import { LoginDtoModel, LogoutDtoModel } from "@@intelease/app-models/auth";
import { Observable, Subject } from "rxjs";
import { LocalStorageService } from "@@intelease/app-services/common";
import {
  LoggedInUserViewModel,
  OApiRespDashboardDtoModel,
  ProfileUserViewModel,
} from "@@intelease/api-models/adex-api-model-src";
import { LocalStorageKey } from "@@intelease/web/common/enums/local-storage.keys";
import { RestClient } from "@@intelease/web/utils";
import { HttpHeaders } from "@angular/common/http";
import { datadogRum } from "@datadog/browser-rum";
import { environment } from "apps/ui/src/environments/environment";

const REDIRECT_URL_EXCEPTIONS: string[] = [
  "/miscellaneous/not-found",
  "/auth/login",
  "/miscellaneous/forbidden",
  "/dashboards/new",
];

interface AccountRoleInfo {
  type: string;
  name: string;
  rank: number;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private createApiService: CreateApiService,
    private editApiService: EditApiService,
    private fetchApiService: FetchApiService,
    private router: Router,
    private editUserService: EditUserService,
    private mainDrawerService: MainDrawerService,
    private restClient: RestClient
  ) {}

  private static readonly API_VERSION_1 = "/v1";
  private static readonly LOGIN_URL = "/login";
  private static readonly FORGOT_PASSWORD_URL = "/forgotPassword";
  private static readonly RESET_PASSWORD_URL = "/resetPassword";
  private static readonly LOGOUT_URL = "/logout";

  private static readonly loggedIn$: Subject<void> = new Subject<void>();
  public static setLoggedIn$: Subject<void> = new Subject<void>();

  static storeAccountRoleInfo(accountRoleInfo: AccountRoleInfo): void {
    localStorage.setItem(LocalStorageKey.ACCOUNT_ROLE, accountRoleInfo.type);
    localStorage.setItem(
      LocalStorageKey.ACCOUNT_ROLE_NAME,
      accountRoleInfo.name
    );
    localStorage.setItem(
      LocalStorageKey.ACCOUNT_ROLE_RANK,
      accountRoleInfo.rank.toString()
    );
  }

  static storeUserInfo(user: LoggedInUserModel): void {
    if (environment.production) {
      datadogRum.setUser({
        id: user.uid,
        email: user.email,
        name: user.name,
      });
    }
    localStorage.setItem(LocalStorageKey.AUTH, user.accessToken);
    localStorage.setItem(LocalStorageKey.USER_UID, user.uid);
    localStorage.setItem(LocalStorageKey.USER_EMAIL, user.email);
    localStorage.setItem(LocalStorageKey.USER_HASH, user.uidHash);
    localStorage.setItem(LocalStorageKey.ACCOUNT_UID, user.accountUid);
    localStorage.setItem(LocalStorageKey.ACCOUNT_ROLE, user.accountRole);
    localStorage.setItem(
      LocalStorageKey.ACCOUNT_ROLE_NAME,
      user.accountRoleName
    );
    localStorage.setItem(
      LocalStorageKey.ACCOUNT_ROLE_RANK,
      user.accountRoleRank.toString()
    );
    localStorage.setItem(
      LocalStorageKey.LBX_ACCOUNT_USER,
      user.lbxAccountUser ? "true" : "false"
    );
    localStorage.setItem(
      LocalStorageKey.LBX_ROLES,
      JSON.stringify(user.lbxRoles ? user.lbxRoles : [])
    );
    localStorage.setItem(
      LocalStorageKey.RECORD_REVIEW_MODE,
      user.recordReviewMode
    );
    localStorage.setItem(
      LocalStorageKey.QUEUE_FEATURE_ENABLED,
      user.queueFeatureEnabled ? "true" : "false"
    );
    if (user.firstName) {
      localStorage.setItem(LocalStorageKey.FIRST_NAME, user.firstName);
    }
    if (user.name) {
      localStorage.setItem(LocalStorageKey.NAME, user.name);
    }
    if (user.profileImageUid) {
      localStorage.setItem(
        LocalStorageKey.PROFILE_IMAGE_UID,
        user.profileImageUid
      );
    }

    if (user.docSetCategories) {
      localStorage.setItem(
        LocalStorageKey.DOC_SET_CATEGORIES,
        JSON.stringify(user.docSetCategories)
      );
    }
    localStorage.setItem(
      LocalStorageKey.SETTING_ITEMS,
      JSON.stringify(user.settingItems)
    );
    this.loggedIn$.next();
  }

  static storeUserRoleAndBasicInfo(user: ProfileUserViewModel): void {
    localStorage.setItem(
      LocalStorageKey.LBX_ROLES,
      JSON.stringify(user.lbxRoles ? user.lbxRoles : [])
    );
    localStorage.setItem(
      LocalStorageKey.LBX_ACCOUNT_USER,
      user.lbxAccountUser ? "true" : "false"
    );
    if (user.firstName) {
      localStorage.setItem(LocalStorageKey.FIRST_NAME, user.firstName);
    }
    if (user.name) {
      localStorage.setItem(LocalStorageKey.NAME, user.name);
    }
  }

  private static createLogoutData(): LogoutDtoModel {
    const logoutDto = new LogoutDtoModel();
    logoutDto.uid = localStorage.getItem(LocalStorageKey.USER_UID);
    logoutDto.accessToken = localStorage.getItem(LocalStorageKey.AUTH);
    return logoutDto;
  }

  public static removeUserInfo(): void {
    if (environment.production) {
      datadogRum.clearUser();
    }
    localStorage.removeItem(LocalStorageKey.AUTH);
    localStorage.removeItem(LocalStorageKey.USER_UID);
    localStorage.removeItem(LocalStorageKey.USER_EMAIL);
    localStorage.removeItem(LocalStorageKey.USER_HASH);
    localStorage.removeItem(LocalStorageKey.ACCOUNT_UID);
    localStorage.removeItem(LocalStorageKey.ACCOUNT_ROLE);
    localStorage.removeItem(LocalStorageKey.ACCOUNT_ROLE_NAME);
    localStorage.removeItem(LocalStorageKey.ACCOUNT_ROLE_RANK);
    localStorage.removeItem(LocalStorageKey.LBX_ACCOUNT_USER);
    localStorage.removeItem(LocalStorageKey.LBX_ROLES);
    localStorage.removeItem(LocalStorageKey.RECORD_REVIEW_MODE);
    localStorage.removeItem(LocalStorageKey.QUEUE_FEATURE_ENABLED);
    localStorage.removeItem(LocalStorageKey.FIRST_NAME);
    localStorage.removeItem(LocalStorageKey.NAME);
    localStorage.removeItem(LocalStorageKey.PROFILE_IMAGE_UID);
    localStorage.removeItem(LocalStorageKey.DOC_SET_CATEGORIES);
    localStorage.removeItem(LocalStorageKey.GOOGLE_DRIVE_INFO_VIEW);
    localStorage.removeItem(LocalStorageKey.SETTING_ITEMS);
  }

  isNotValidRedirectUrl(url: string) {
    let isUrlException = false;
    if (url) {
      REDIRECT_URL_EXCEPTIONS.forEach((exception) => {
        if (url.includes(exception)) {
          isUrlException = true;
        }
      });
    }
    return isUrlException;
  }

  forgotPassword(
    emailData: LoginDtoModel
  ): Observable<OApiRespDashboardDtoModel> {
    return this.createApiService.sendRequestNoView(
      AuthService.API_VERSION_1,
      AuthService.FORGOT_PASSWORD_URL,
      emailData
    );
  }

  checkResetPassword(resetId: string): Observable<any> {
    return this.fetchApiService.sendUnknownEntityRequest(
      AuthService.API_VERSION_1,
      AuthService.RESET_PASSWORD_URL,
      resetId
    );
  }

  resetPassword(resetId: string, password: string): Observable<any> {
    const data = {
      password: password,
    };
    return this.editApiService.sendRequestNoView(
      AuthService.API_VERSION_1,
      AuthService.RESET_PASSWORD_URL,
      resetId,
      data,
      false
    );
  }

  getHomePageLink(): string {
    return this.isLoggedIn() ? "/dashboards" : "/auth/login";
  }

  isLoggedIn(): boolean {
    const accessToken = localStorage.getItem(LocalStorageKey.AUTH);
    return accessToken && accessToken.length > 0;
  }

  login(loginData: LoginDtoModel) {
    return this.createApiService.sendRequest(
      AuthService.API_VERSION_1,
      AuthService.LOGIN_URL,
      loginData,
      LoggedInUserModel.view,
      LoggedInUserModel
    );
  }

  onLogin(): Observable<void> {
    return AuthService.loggedIn$.asObservable();
  }

  loginToS(loginData: LoginTosDtoModel) {
    this.editUserService
      .editUserToS(
        loginData.userUid,
        loginData,
        LoggedInUserModel.view,
        LoggedInUserModel
      )
      .subscribe((loggedInUser) => {
        AuthService.storeUserInfo(loggedInUser);
        this.router.navigateByUrl("/dashboards").then(() => {
          this.mainDrawerService.enableToolbar();
          this.mainDrawerService.enableSidebar();
        });
      });
  }

  logout(shouldNotNavigate?: boolean): void {
    const logoutData = AuthService.createLogoutData();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${logoutData.accessToken}`,
    });
    const options = {
      headers,
    };
    AuthService.removeUserInfo();
    LocalStorageService.clear();
    this.restClient
      .sendPostRequestNoView(
        AuthService.API_VERSION_1,
        AuthService.LOGOUT_URL,
        logoutData,
        options
      )
      .subscribe(
        () => {
          AuthService.setLoggedIn$.next();
          // TODO(apoorv): Need this stupid extra reload here b/c, otherwise, for some reason, the following problem exists:
          // If user logs out and then immediately logs in as a user in PRE_TOS status,
          // the form for terms-of-service behaves incorrectly. Specifically, the "Approve" checkbox
          // does not update on the UI. So this extra reload sidesteps that issue.
          if (!shouldNotNavigate) {
            this.router.navigateByUrl("/auth/login");
          }
        },
        (err) => {
          if (err?.status === 401) {
            this.router
              .navigateByUrl("/auth/login")
              .then(() => window.location.reload());
          }
        }
      );
  }
}
