import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { cloneDeep, find, first as _first, isEqual, uniqBy } from "lodash";
import { WebAbstractionProvisionService } from "@@intelease/web/abstraction-page/src/lib/services/web-abstraction-provision.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import {
  PartialValProvisionValueModel,
  RelatedDocModel,
} from "@@intelease/web/common/models";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { distinct, take } from "rxjs/operators";
import {
  ModalInputModel,
  PermissionCheckResponseModel,
} from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { CommonModalService } from "@@intelease/web/common/services";
import { ProvisionBoxActionTypeEnum } from "@@intelease/web/abstraction-page/src/lib/enums";
import {
  InteleaseNotificationService,
  PermissionApiService,
} from "@@intelease/web/intelease/services";
import {
  FinalAbstractModel,
  PROVISION_BOX_TABS_CONST,
  PROVISION_BOX_TABS_KEY,
  ProvisionBoxInitTypesEnum,
} from "@@intelease/app-models/abstract-review";
import {
  AbstractTextHighlightsService,
  WebAbstractionPageService,
} from "@@intelease/web/abstraction-page/src/lib/services";
import { PdfReaderService } from "@@intelease/web/ui";
import { toggleFormSchemaMode } from "@@intelease/web/intelease/utils";
import { EditedComment } from "@@intelease/web/ui/src/lib/itls-comment";
import { PermissionTypeGroupEnum } from "@@intelease/web/ui/src/lib/new-share-entity/models/permission-type-group.enum";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { AbstractReviewService } from "@@intelease/app-services/abstract-review";
import { ProfileUserModel } from "@@intelease/web/common/models/user";
import { NewComment } from "@@intelease/web/ui/src/lib/itls-comment/itls-comment.component";
import { BaseEntityPermissionComponent } from "@@intelease/web/common/components";
import { ItlsShareDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-share-drive.service";
import { ProvisionBoxHelperService } from "@@intelease/web/abstraction-page/src/lib/services/provision-box-helper.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

enum PROVISIONS_SOURCE_TYPE {
  TEMP_NEW_TAB = "TEMP_NEW_TAB",
}

@Component({
  selector: "il-provision",
  templateUrl: "./provision.component.html",
  styleUrls: ["./provision.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProvisionComponent
  extends BaseEntityPermissionComponent
  implements OnInit, ComponentStateHooksInterface
{
  destroyRef = inject(DestroyRef);
  @Input() isMinimized: boolean;
  @Input() selectedAbstractUid: string;
  @Input() selectedMentionUid: string;
  @Input() selectedProvisionUid: string;
  @Input() selectedProvision;
  @Input() abstractDocument: FinalAbstractModel;
  @Input() selectedDocument: RelatedDocModel;
  @Input() isInNewBrowserTab: boolean;
  @Input() isInNewBrowserTabMode: boolean;

  @Input()
  set provision(value) {
    this.initProvision(value);
  }

  get provision() {
    return this._provision;
  }

  _provision = undefined;
  selectedMention = undefined;
  @Input() documentsShortNameMap?: { [documentUid: string]: any } = {};
  @Input() provisionBoxInitTypes: ProvisionBoxInitTypesEnum;
  @Input() editable: boolean;
  @Input() provisionIndex: number;
  @Input() canComment: boolean;
  provisionBoxInitTypesEnum = ProvisionBoxInitTypesEnum;
  provisionTabs = PROVISION_BOX_TABS_CONST;
  @Input() selectedProvisionBoxTab: PROVISION_BOX_TABS_KEY;
  @Input() selectedProvisionTab = _first(this.provisionTabs);
  isProvisionCommentActionDispatched: boolean;
  isUpdateProvisionNoteActionDispatched: boolean;
  @Input() accountUsers: ProfileUserModel[];
  @Output() mentionRemoved: EventEmitter<any> = new EventEmitter<any>();

  isLoading = false;

  constructor(
    private webAbstractionProvisionService: WebAbstractionProvisionService,
    private cdr: ChangeDetectorRef,
    public readonly abstractReviewFacade: AbstractReviewFacade,
    private commonModalService: CommonModalService,
    private webAbstractionPageService: WebAbstractionPageService,
    private pdfReaderService: PdfReaderService,
    private inteleaseNotificationService: InteleaseNotificationService,
    private abstractReviewService: AbstractReviewService,
    private permissionApiService: PermissionApiService,
    private itlsShareDriveService: ItlsShareDriveService,
    @Optional() @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
    this.initListeners();
  }

  private initProvision(value) {
    if (value) {
      if (!isEqual(this._provision, value)) {
        this.selectedProvisionTab = _first(this.provisionTabs);
        this._provision = {
          ...value,
          multiplePdfProvision: {
            ...value.multiplePdfProvision,
            options: value.multiplePdfProvision.options.filter(
              (option) => option.source !== "TEMP_LOCAL"
            ),
          },
        };
        if (
          this._provision.multiplePdfProvision.options.length === 0 &&
          this.editable
        ) {
          this.onAddMentionClick();
        }
        this.prepareProvisionMentions(this.editable);
        this.initState();
        this.setSelectedProvisionTab(value);
      }
    }
  }

  ngOnInit(): void {
    ProvisionBoxHelperService.removeMention$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        const {
          mentionIndex,
          mentionUid,
          isTempMention,
          provisionUid,
          isReloadDisabled,
          cb,
        } = res;
        if (provisionUid === this._provision?.provisionInfo?.uid) {
          if (!isReloadDisabled) {
            this.removeMention(mentionIndex, mentionUid, isTempMention, cb);
          } else {
            this.provision.multiplePdfProvision.options.splice(mentionIndex, 1);
          }
        }
      });
  }

  private setSelectedProvisionTab(value) {
    setTimeout(() => {
      if (
        this.selectedProvisionBoxTab &&
        this.selectedProvision &&
        value &&
        this.selectedProvisionUid === value.provisionInfo.uid &&
        this.provisionBoxInitTypes === ProvisionBoxInitTypesEnum.PROVISION
      ) {
        this.selectedProvisionTab = this.provisionTabs.find(
          (item) => item.key === this.selectedProvisionBoxTab
        );
        this.onSelectedProvisionTabChange(this.selectedProvisionTab);
      }
    }, 1);
  }

  private initState() {
    if (!this.selectedMentionUid) {
      this.selectedMention = this._provision.multiplePdfProvision.options.find(
        (o) => o.uid === this.modalData?.mentionData?.uid
      );
      this.selectedMentionUid = this.selectedMention?.uid;
    }

    if (
      (!this.selectedProvisionUid && !this.selectedMentionUid) ||
      (this.selectedProvisionUid &&
        this.selectedProvisionUid !== this._provision.provisionInfo.uid) ||
      (this.selectedProvisionUid &&
        !this.selectedMention &&
        !this.selectedMentionUid &&
        this.selectedProvisionUid === this._provision.provisionInfo.uid)
    ) {
      if (
        !this.selectedMention ||
        this.selectedMention.source === PROVISIONS_SOURCE_TYPE.TEMP_NEW_TAB
      ) {
        this.selectedMention = _first(
          this._provision.multiplePdfProvision.options
        );
        this.onChangeSelectedMentionClick(this.selectedMention);
      }
    } else if (
      this.selectedProvisionUid &&
      this.selectedMentionUid &&
      this.selectedProvisionUid === this._provision.provisionInfo.uid
    ) {
      this.selectedMention = this._provision.multiplePdfProvision.options.find(
        (mention) => mention.uid === this.selectedMentionUid
      );
      this.onChangeSelectedMentionClick(this.selectedMention);
    }
  }

  // eslint-disable-next-line
  initActions(): void {}

  private prepareProvisionMentions(isProvisionEditable: boolean) {
    this._provision.multiplePdfProvision.options = uniqBy(
      this._provision.multiplePdfProvision.options,
      "uid"
    );
    this._provision.multiplePdfProvision.options.forEach((mention) => {
      const { valueType } = mention;
      if (this.isTableProvision(valueType)) {
        mention.model = this.webAbstractionProvisionService
          .prepareProvisionValue(valueType)
          .prepareProvisionOptionModel(mention);
      } else {
        this.getFormSchemaByType((provisionFormSchema) => {
          mention.model = this.webAbstractionProvisionService
            .prepareProvisionValue(valueType)
            .prepareProvisionOptionModel(mention);
          mention.formSchema = toggleFormSchemaMode(
            provisionFormSchema,
            !isProvisionEditable
          );
        }, mention);
      }
    });
  }

  public reloadProvisionData(
    cb?,
    shouldNotMutateProvision?: boolean,
    isReloadLocalProvisionsDisabled?: boolean,
    type?: "ADD" | "DELETE" | "ADD_MENTION_FORM_READER"
  ) {
    const _payload = {
      abstractUid: this.selectedAbstractUid,
      provisionUid: this._provision.provisionInfo.uid,
    };
    this.abstractReviewFacade.loadProvisionFullDetail(_payload);
    let tempHighlight = this.provision.multiplePdfProvision.options.find(
      (item) => item.isTempHighlight
    );
    this.toggleProvisionLoading(true);
    let updatedCount = 0;
    this.abstractReviewFacade
      .getSelectedAbstractProvisionByProvisionUid$(
        this._provision.provisionInfo.uid
      )
      .pipe(take(2))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((provision) => {
        if (provision) {
          if (!shouldNotMutateProvision) {
            updatedCount++;
            this.provision = cloneDeep(provision);
            if (updatedCount === 2) {
              if (type === "ADD_MENTION_FORM_READER") {
                if (tempHighlight) {
                  this.provision.multiplePdfProvision.options.splice(0, 1);
                  tempHighlight = undefined;
                }
              } else {
                if (tempHighlight) {
                  this.provision.multiplePdfProvision.options.unshift(
                    tempHighlight
                  );
                  this.selectedProvision.multiplePdfProvision.options.unshift(
                    tempHighlight
                  );
                  tempHighlight = undefined;
                }
              }
              const payload = {
                provisionUid: this.provision.provisionInfo.uid,
                selectedProvision: this.provision,
              };
              this.toggleProvisionLoading(false);
              updatedCount = 0;
              this.toggleProvisionLoading(true);
              ProvisionBoxHelperService.reinitializeSelectedSelectedProvision.next(
                payload
              );
              this.selectedMention = _first(
                this.provision.multiplePdfProvision.options
              );
              this.toggleProvisionLoading(false);
            }
          }
          if (cb) {
            cb();
          }
          this.toggleProvisionLoading(true);
          this.prepareProvisionMentions(this.editable);
          this.toggleProvisionLoading(false);
          this.cdr.detectChanges();
        }
      });
  }

  onMentionCreated() {
    this.reloadProvisionData(() => {
      this.selectedMention = _first(
        this._provision.multiplePdfProvision.options
      );
    });
  }

  initListeners(): void {
    this.abstractReviewFacade.createProvisionCommentSucceeded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          if (
            this.isProvisionCommentActionDispatched &&
            this.selectedProvision.provisionInfo.uid ===
              this._provision.provisionInfo.uid
          ) {
            this.abstractReviewFacade.loadProvisionComments(
              this.selectedAbstractUid,
              this._provision.provisionInfo.uid
            );
          }
          this.isProvisionCommentActionDispatched = false;
        }
      });
    this.abstractReviewFacade.isUpdateProvisionNoteSucceeded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res) {
          if (this.isUpdateProvisionNoteActionDispatched) {
            this.abstractReviewFacade.cleanAbstractReviewState({
              isUpdateProvisionNoteSucceeded: false,
            });
            this.reloadProvisionData(() => undefined, true);
          }
          this.isUpdateProvisionNoteActionDispatched = false;
        }
      });
    AbstractTextHighlightsService.onTextHighlightActionToProvisionBox
      .pipe(distinct())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: any) => {
        this.textHighlightActionToProvisionBoxHandler(res);
      });
  }

  private textHighlightActionToProvisionBoxHandler(res: {
    type: ProvisionBoxActionTypeEnum;
    payload?;
  }) {
    const { type, payload } = res;
    const { provisionUid, mentionUid } = payload;
    switch (type) {
      case ProvisionBoxActionTypeEnum.INITIATE_DELETE_MENTION_FROM_VIEWER: {
        if (
          this._provision.provisionInfo.uid === provisionUid &&
          this.selectedMention.uid === mentionUid
        ) {
          const mention = this._provision.multiplePdfProvision.options.find(
            (_mention) => _mention.uid === mentionUid
          );
          if (mention) {
            const mentionIndex =
              this._provision.multiplePdfProvision.options.findIndex(
                (_mention) => _mention.uid === mentionUid
              );
            this.onDeleteMentionConfirmed(mentionUid, mentionIndex, () => {
              this.removeMention(mentionIndex, mentionUid);
            });
          }
        }
        break;
      }
    }
  }

  private triggerChangeDetection() {
    setTimeout(() => {
      try {
        this.cdr.detectChanges();
      } catch (e) {} // eslint-disable-line
    }, 3);
  }

  private setProvisionMentionFormSchema(provisionMention) {
    this.webAbstractionProvisionService
      .getProvisionFormSchemaByType(
        provisionMention.valueType,
        this._provision.multiplePdfProvision
      )
      .subscribe((provisionFormSchema) => {
        provisionMention.formSchema = cloneDeep(provisionFormSchema);
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this._provision.multiplePdfProvision.options,
      event.previousIndex,
      event.currentIndex
    );
    if (event.previousIndex !== event.currentIndex) {
      this.onReorderClick();
    }
  }

  private onReorderClick() {
    this.toggleProvisionLoading(true);
    this.webAbstractionProvisionService
      .updateMultiProvisionOrder(
        this.selectedAbstractUid,
        this._provision.provisionInfo.uid,
        this._provision.multiplePdfProvision.options
          .filter(
            (item) => item.source !== "TEMP_NEW_TAB" && !item.isTempHighlight
          )
          .map((item) => item.uid)
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        () => {
          this.reloadProvisionData(() => undefined, true);
        },
        () => {
          this.toggleProvisionLoading(false);
        }
      );
  }

  onChangeSelectedMentionClick(mention) {
    this.selectedMention = mention;
    this.triggerChangeDetection();
  }

  generateTempMention(
    type: PROVISIONS_SOURCE_TYPE,
    formSchema
  ): PartialValProvisionValueModel {
    const mention = new PartialValProvisionValueModel();
    mention.uid = type + "__" + new Date().getTime().toString();
    mention.source = type;
    mention.valueType = this._provision.provisionInfo.type;
    if (this.isTableProvision(mention.valueType)) {
      mention.value = this.webAbstractionProvisionService.getEmptyTableValue();
      mention.model = this.webAbstractionProvisionService
        .prepareProvisionValue(PROVISIONS_DATA_CONST.TABLE.name)
        .prepareProvisionOptionModel(mention);
    } else {
      mention.model = {};
    }
    mention.formSchema = formSchema;
    return mention;
  }

  getFormSchemaByType(cb, mention?) {
    this.webAbstractionProvisionService
      .getProvisionFormSchemaByType(
        mention
          ? mention.valueType || mention.type
          : this._provision.provisionInfo.type,
        this._provision.multiplePdfProvision
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((provisionFormSchema) => {
        cb(provisionFormSchema);
      });
  }

  onAddMentionClick() {
    this.getFormSchemaByType((provisionFormSchema) => {
      this._provision.multiplePdfProvision.options.unshift(
        this.generateTempMention(
          PROVISIONS_SOURCE_TYPE.TEMP_NEW_TAB,
          provisionFormSchema
        )
      );
      this.selectedMention = _first(
        this._provision.multiplePdfProvision.options
      );
      this.triggerChangeDetection();
    });
  }

  private isTableProvision(type: string): boolean {
    return type === PROVISIONS_DATA_CONST.TABLE.name;
  }

  isTempMentionAdded() {
    return this._provision.multiplePdfProvision.options.find(
      (mention) =>
        mention.source === PROVISIONS_SOURCE_TYPE.TEMP_NEW_TAB ||
        mention.isTempHighlight
    );
  }

  onRemoveMentionClick(
    evt: MouseEvent,
    mentionIndex: number,
    mentionUid: string,
    mentionSource: string,
    isTempHighlight: boolean
  ) {
    evt.stopPropagation();
    this.onChangeSelectedMentionClick(
      this._provision.multiplePdfProvision.options[mentionIndex]
    );
    if (
      mentionSource === PROVISIONS_SOURCE_TYPE.TEMP_NEW_TAB ||
      isTempHighlight
    ) {
      this.removeMention(mentionIndex, mentionUid, isTempHighlight);
    } else {
      this.showDeleteModal(() => {
        this.onDeleteMentionConfirmed(mentionUid, mentionIndex, () => {
          this.removeMention(mentionIndex, mentionUid);
        });
      });
    }
  }

  private removeMention(
    mentionIndex: number,
    mentionUid: string,
    isTempHighlight?: boolean,
    cb?: () => void,
    type?
  ) {
    this.toggleProvisionLoading(true);
    const selectedMention =
      this._provision.multiplePdfProvision.options[mentionIndex];
    if (isTempHighlight) {
      AbstractTextHighlightsService.removeTempMentionTextHighlightCreatedFromReader(
        selectedMention
      );
      this.abstractReviewFacade.removeTemporaryMention({
        provisionUid: this._provision.provisionInfo.uid,
        mentionUid,
      });
      this.provision.multiplePdfProvision.options.splice(0, 1);
      // ProvisionBoxHelperService.removeTempHighlightedMention$.next()
    } else {
      this.abstractReviewFacade.removeSelectedMention({
        provisionUid: this._provision.provisionInfo.uid,
        mentionUid,
      });
    }
    // this.mentionRemoved.emit({ mentionUid })
    // if (this._provision.multiplePdfProvision.options.length) {
    //     const mention = first(
    //         this._provision.multiplePdfProvision.options,
    //     )
    //     this.onChangeSelectedMentionClick(mention)
    // }
    this.toggleProvisionLoading(false);
    let updatedCount = 0;
    // const tempHighlight = this.provision.multiplePdfProvision.options.find(
    //     (item) => item.isTempHighlight,
    // )
    this.reloadProvisionData(
      () => {
        if (this._provision.multiplePdfProvision.options.length) {
          const mention = _first(this._provision.multiplePdfProvision.options);
          this.onChangeSelectedMentionClick(mention);
        }
        updatedCount++;
        if (cb && updatedCount === 2) {
          cb();
        }
        // else if(updatedCount === 2){
        //   if(tempHighlight){
        //     console.log('update tempHighlight', tempHighlight)
        //     this._provision.multiplePdfProvision.options.unshift(tempHighlight)
        //     this.onChangeSelectedMentionClick(tempHighlight)
        //   }
        // }
        this.toggleProvisionLoading(false);
      },
      false,
      true,
      type
    );
  }

  /**
   * fires when user confirmed to delete selected provision option
   * @param mentionUid
   * @param mentionIndex
   * @param cb
   */
  private onDeleteMentionConfirmed(
    mentionUid: string,
    mentionIndex: number,
    cb?: () => void
  ) {
    this.toggleProvisionLoading(true);
    this.webAbstractionProvisionService
      .deleteProvisionValueOption(
        this.selectedAbstractUid,
        this._provision.provisionInfo.uid,
        mentionUid
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const selectedMention =
          this._provision.multiplePdfProvision.options[mentionIndex];
        const { pdfHighlightIds } = selectedMention;
        if (pdfHighlightIds && pdfHighlightIds.length) {
          AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next(
            {
              type: ProvisionBoxActionTypeEnum.DELETE,
              provision: this._provision.multiplePdfProvision,
              provisionValue: selectedMention,
            }
          );
        }
        if (cb) {
          cb();
        }
      });
  }

  private showDeleteModal(cb) {
    const modalData = new ModalInputModel();
    modalData.mode = ComponentModeEnum.REMOVE;
    modalData.payload = {
      customMessage: false,
      message: "Are you sure you want to delete?",
      title: "Delete",
    };
    this.commonModalService
      .openGenericOkCancelModal(modalData)
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        if (res.data.exitType === ModalsResponseTypeEnum.CLOSE) {
          cb();
        } else {
          this.toggleProvisionLoading(false);
        }
      });
  }

  toggleProvisionLoading(isShow: boolean) {
    this.isLoading = isShow;
    this.triggerChangeDetection();
  }

  onPickSnippetClick(snippet: PartialValProvisionValueModel) {
    this.toggleProvisionLoading(true);
    const { uid: snippetUid } = snippet;
    const payload = {
      abstractUid: this.selectedAbstractUid,
      provisionUid: this._provision.provisionInfo.uid,
      snippetUid,
    };
    this.abstractReviewFacade.pickSnippet(payload);
    this.abstractReviewFacade.onPickSnippetSuccess$
      .pipe(distinct())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((multiPdfProv) => {
        if (multiPdfProv) {
          const { uid: provisionUid } = this._provision.provisionInfo;
          if (this.selectedProvision.provisionInfo.uid === provisionUid) {
            let newOptionFound = false;
            for (const option of multiPdfProv.options) {
              if (option.relatedOptionUids && option.relatedOptionUids.length) {
                if (
                  find(
                    option.relatedOptionUids,
                    (relatedOptionUid) => relatedOptionUid === snippetUid
                  )
                ) {
                  this.reloadProvisionData(() => {
                    AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next(
                      {
                        type: ProvisionBoxActionTypeEnum.SELECT_DOCUMENT_MENTION,
                        provisionValue: option,
                        provision: this.provision.multiplePdfProvision,
                      }
                    );
                    this.selectedMention = _first(
                      this._provision.multiplePdfProvision.options
                    );
                  });
                  newOptionFound = true;
                  break;
                }
              }
            }
            if (!newOptionFound) {
              this.inteleaseNotificationService.openSnackBar(
                "Sorry an error occurred! [code: 100]"
              );
            }
          }
        }
      });
    //TODO(mohammad-haji) New Provision Box
    // this.onSearchProvision((multiProvisionValue: FullValMultiProvisionValueModel) => {
    //   this.removeSnippetHighlightFromReader(snippet, multiProvisionValue, HighlightTypeEnum.NONE);
    //   this.addSnippetRelatedOptionToAbstractHighlights(uid, page, multiProvisionValue);
    // });
  }

  onGotoMentionByUid(uid: string) {
    this.selectedMention = this._provision.multiplePdfProvision.options.find(
      (option) => option.uid === uid
    );
    this.cdr.detectChanges();
  }

  onMentionReloadProvisionData(evt: {
    mention?;
    shouldNotMutateProvision?;
    type?;
  }) {
    if (evt && evt.type === "UPDATE" && evt.mention) {
      this.reloadProvisionData(() => {
        this.selectedMention = evt.mention;
        this.selectedMentionUid = evt.mention.uid;
        this.selectedProvisionUid = this.selectedProvision.provisionInfo.uid;
      }, true);
    } else {
      this.reloadProvisionData(
        () => undefined,
        evt && evt.shouldNotMutateProvision,
        false,
        evt.type
      );
    }
  }

  onSaveProvisionCommentClick(newComment: NewComment) {
    if (newComment.elements.mentions && newComment.elements.mentions.length) {
      this.permissionApiService
        .checkPermission(
          newComment.elements.mentions.map((mentionedUser) => ({
            uid: mentionedUser.uid,
            type: "USER",
          })),
          [
            {
              uid: this.selectedAbstractUid,
              type: "FINAL_ABSTRACT",
            },
          ],
          [AppAuthorities.READ, AppAuthorities.COMMENT]
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((checkPermissionsResp) => {
          if (checkPermissionsResp.accessibleToAll) {
            this.saveProvisionComment(newComment);
          } else {
            this.checkCurrentUserAccessForSharingRecordAndSaveComment(
              newComment,
              checkPermissionsResp
            );
          }
        });
    } else {
      this.saveProvisionComment(newComment);
    }
  }

  private saveProvisionComment(newComment: NewComment) {
    this.isProvisionCommentActionDispatched = true;
    const payload = {
      comment: newComment,
      provisionUid: this._provision.provisionInfo.uid,
      abstractUid: this.selectedAbstractUid,
    };
    this.abstractReviewFacade.createProvisionComment(payload);
  }

  private checkCurrentUserAccessForSharingRecordAndSaveComment(
    comment: any,
    checkPermissionsResp: PermissionCheckResponseModel
  ) {
    if (this.emptyGivenOrHasPermissions(this.AUTH.SHARE)) {
      const modalData = new ModalInputModel();
      modalData.mode = ComponentModeEnum.SHARE;
      modalData.payload = {
        customMessage: false,
        message: `Some of the mentioned users don't have access to this record, do you want to share this record with them?`,
        title: "No access for mentioned user(s)",
        showDismissIcon: false,
      };
      this.commonModalService
        .openGenericOkCancelModal(modalData)
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res) => {
          if (res.data.exitType === ModalsResponseTypeEnum.CLOSE) {
            const actorsToShareWith = checkPermissionsResp.items
              .filter((item) => !item.hasAccess)
              .map((item) => ({
                permissionTypeGroup: PermissionTypeGroupEnum.COMMENTER,
                domainType: item.domainType,
                domainUid: item.domainUid,
              }));
            this.itlsShareDriveService
              .share(
                {
                  entityUid: this.selectedAbstractUid,
                  entityType: "RECORD",
                },
                actorsToShareWith
              )
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(() => {
                this.saveProvisionComment(comment);
              });
          } else {
            this.saveProvisionComment(comment);
          }
        });
    } else {
      const modalData = new ModalInputModel();
      modalData.mode = ComponentModeEnum.SHARE;
      modalData.payload = {
        customMessage: false,
        message: `Some of the mentioned users don't have access to this record (they will be notified about the comment), do you want to continue?`,
        title: "No access for mentioned user(s)",
        showDismissIcon: false,
      };
      this.commonModalService
        .openGenericOkCancelModal(modalData)
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res) => {
          if (res.data.exitType === ModalsResponseTypeEnum.CLOSE) {
            this.saveProvisionComment(comment);
          }
        });
    }
  }

  onDeleteProvisionCommentClick(comment) {
    this.abstractReviewService
      .deleteProvisionComment(
        this.selectedAbstractUid,
        this._provision.provisionInfo.uid,
        comment.uid
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  onEditProvisionCommentClick(editedComment: EditedComment) {
    if (
      editedComment.elements.mentions &&
      editedComment.elements.mentions.length
    ) {
      this.permissionApiService
        .checkPermission(
          editedComment.elements.mentions.map((mentionedUser) => ({
            uid: mentionedUser.uid,
            type: "USER",
          })),
          [
            {
              uid: this.selectedAbstractUid,
              type: "FINAL_ABSTRACT",
            },
          ],
          [AppAuthorities.READ, AppAuthorities.COMMENT]
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((checkPermissionsResp) => {
          if (checkPermissionsResp.accessibleToAll) {
            this.editProvisionComment(editedComment);
          } else {
            this.checkCurrentUserAccessForSharingRecordAndEditComment(
              editedComment,
              checkPermissionsResp
            );
          }
        });
    } else {
      this.editProvisionComment(editedComment);
    }
  }

  private checkCurrentUserAccessForSharingRecordAndEditComment(
    comment: any,
    checkPermissionsResp: PermissionCheckResponseModel
  ) {
    if (this.emptyGivenOrHasPermissions(this.AUTH.SHARE)) {
      const modalData = new ModalInputModel();
      modalData.mode = ComponentModeEnum.SHARE;
      modalData.payload = {
        customMessage: false,
        message: `Some of the mentioned users don't have access to this record, do you want to share this record with them?`,
        title: "No access for mentioned user(s)",
        showDismissIcon: false,
      };
      this.commonModalService
        .openGenericOkCancelModal(modalData)
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res) => {
          if (res.data.exitType === ModalsResponseTypeEnum.CLOSE) {
            const actorsToShareWith = checkPermissionsResp.items
              .filter((item) => !item.hasAccess)
              .map((item) => ({
                permissionTypeGroup: PermissionTypeGroupEnum.COMMENTER,
                domainType: item.domainType,
                domainUid: item.domainUid,
              }));
            this.itlsShareDriveService
              .share(
                {
                  entityUid: this.selectedAbstractUid,
                  entityType: "RECORD",
                },
                actorsToShareWith
              )
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(() => {
                this.editProvisionComment(comment);
              });
          } else {
            this.editProvisionComment(comment);
          }
        });
    } else {
      const modalData = new ModalInputModel();
      modalData.mode = ComponentModeEnum.SHARE;
      modalData.payload = {
        customMessage: false,
        message: `Some of the mentioned users don't have access to this record (they will be notified about the comment), do you want to continue?`,
        title: "No access for mentioned user(s)",
        showDismissIcon: false,
      };
      this.commonModalService
        .openGenericOkCancelModal(modalData)
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res) => {
          if (res.data.exitType === ModalsResponseTypeEnum.CLOSE) {
            this.editProvisionComment(comment);
          }
        });
    }
  }

  private editProvisionComment(editedComment: EditedComment) {
    this.abstractReviewService
      .updateProvisionComment(
        this.selectedAbstractUid,
        this._provision.provisionInfo.uid,
        editedComment
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  onGotoMentionHighlightOnViewer(mention, isSnippet = false) {
    if (this.selectedDocument.uid === mention.docAbstractUid) {
      const highlight: any =
        this.webAbstractionPageService.getHighlightMetaByProvisionOptionUid(
          mention.uid
        );
      if (highlight) {
        if (!this.isInNewBrowserTab) {
          this.toggleProvisionLoading(true);
        }
        const payload = {
          annotationId: highlight.id,
          page: highlight.page,
        };
        if (isSnippet) {
          AbstractTextHighlightsService.onTextHighlightActionFromProvisionBox.next(
            {
              type: ProvisionBoxActionTypeEnum.ADD_DOCUMENT_MENTION_TEXT_HIGHLIGHT_TO_VIEWER,
              payload: {
                page: highlight.page,
                textHighlightId: highlight.id,
                textHighlight: highlight,
              },
            }
          );
        }
        setTimeout(
          () =>
            this.webAbstractionPageService.onScrollToAnnotationClick$.next(
              payload
            ),
          200
        );
      }
    } else {
      if (!this.isInNewBrowserTab) {
        this.toggleProvisionLoading(true);
      }
      this.webAbstractionPageService.onGoToAnnotationByDocumentUidClick$.next({
        ...mention,
      });
    }
    // this.goToMentionTextHighlight.emit(provisionOption); // when provision box is opened in new browser window
    if (!this.isInNewBrowserTabMode) {
      this.pdfReaderService.onScrolledToTextHighlight$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          () => {
            this.toggleProvisionLoading(false);
          },
          () => this.toggleProvisionLoading(false)
        );
    } else {
      setTimeout(() => {
        this.toggleProvisionLoading(false);
      }, 1500);
    }
  }

  onSelectedProvisionTabChange(provisionTab) {
    const { key } = provisionTab;
    this.selectedProvisionTab = provisionTab;
    if (key === PROVISION_BOX_TABS_KEY.COMMENTS) {
      this.abstractReviewFacade.loadProvisionComments(
        this.selectedAbstractUid,
        this._provision.provisionInfo.uid
      );
      this.isProvisionCommentActionDispatched = true;
    }
    this.triggerChangeDetection();
  }

  onSaveProvisionNoteClick(note: string) {
    if (this._provision.multiplePdfProvision.notes !== note) {
      const payload = {
        note,
        provisionUid: this._provision.provisionInfo.uid,
        abstractUid: this.selectedAbstractUid,
      };
      this.abstractReviewFacade.updateProvisionNote(payload);
      this.isUpdateProvisionNoteActionDispatched = true;
    }
  }
}
