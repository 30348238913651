import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { QueueDtoModel } from "@@intelease/api-models/adex-api-model-src";
import {
  ModalInputModel,
  ModalResponseModel,
} from "@@intelease/web/intelease/models";
import { ModalsResponseTypeEnum } from "@@intelease/web/intelease/enums";
import { DriveFacade } from "@@intelease/app-state/drive-v2/src";

export class AddToQueueModalInput extends ModalInputModel {
  payload: {
    docSetUids: string[];
  };
}

@Component({
  selector: "intelease-add-to-queue",
  templateUrl: "./add-to-queue.component.html",
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToQueueComponent {
  selectedQueue: QueueDtoModel = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: AddToQueueModalInput,
    private dialogRef: MatDialogRef<AddToQueueComponent>,
    private driveFacade: DriveFacade
  ) {}

  onSelectQueue(queue: QueueDtoModel) {
    this.selectedQueue = queue;
  }

  onAdd() {
    if (!this.selectedQueue) {
      return;
    }

    this.driveFacade.addBatchDocSetsToQueue({
      queueUid: this.selectedQueue.uid,
      queueName: this.selectedQueue.name,
      docSetUids: this.modalData.payload.docSetUids,
    });

    const modalOutput = new ModalResponseModel();
    modalOutput.type = ModalsResponseTypeEnum.DISMISS;
    this.dialogRef.close(modalOutput);
  }
}
