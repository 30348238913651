import {
  CollectionViewer,
  DataSource,
  SelectionChange,
} from "@angular/cdk/collections";
import { INode } from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-drive-tree.service";
import { BehaviorSubject, merge, Observable, Subscription } from "rxjs";
import { FlatTreeControl } from "@angular/cdk/tree";
import { finalize, map } from "rxjs/operators";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive-v2/services/itls-drive.service";
import { FullNodeModel } from "@@intelease/web/common/models";
import { cloneDeep, remove } from "lodash";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DestroyRef, inject } from "@angular/core";

interface ParentLevelAndChildren {
  children: INode[];
  level: number;
}

export class ItlsDriveDynamicDatasource implements DataSource<INode> {
  private subscriptions: Subscription = new Subscription();
  dataChange = new BehaviorSubject<INode[]>([]);
  private nodeIdToChildren = new Map<string, ParentLevelAndChildren>();
  nodeSelected?: INode;

  get data(): INode[] {
    return this.dataChange.value;
  }
  set data(value: INode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  destroyRef = inject(DestroyRef);

  constructor(
    private _treeControl: FlatTreeControl<INode>,
    private driveService: ItlsDriveService
  ) {
    this.subscriptions.add(
      this.driveService.renameFolder$.subscribe((item) => {
        this.data.forEach((_item) => {
          if (_item.id === item.uid && _item.title !== item.name) {
            _item.title = item.name;
          }
        });
        this.nodeIdToChildren.forEach((nodeIdToChild) => {
          nodeIdToChild.children.forEach((node) => {
            if (node.id === item.uid) node.title = item.name;
          });
        });
        this.dataChange.next(this.data);
      })
    );

    this.subscriptions.add(
      this.driveService.deleteFolders$.subscribe((ids) => {
        ids.forEach((id) => {
          if (this.nodeIdToChildren.get(ItlsDriveService.ROOT_NODE_ID)) {
            this.nodeIdToChildren
              .get(ItlsDriveService.ROOT_NODE_ID)
              .children.forEach((node) => {
                this.getNodeFromMapWithId(node, id);
                if (this.nodeSelected?.id === id) {
                  this.deleteNode(this.nodeSelected);
                  this.nodeSelected = undefined;
                }
              });
          }
        });
        this.dataChange.next(this.data);
      })
    );

    this.subscriptions.add(
      this.driveService.newFolderCreated$.subscribe((item) => {
        if (item.parentDirectoryUid) {
          const parentNode = this.data.find(
            (node) => node.id === item.parentDirectoryUid
          );
          const parentLevelAndChildren = this.nodeIdToChildren.get(
            item.parentDirectoryUid
          );
          if (parentLevelAndChildren) {
            const newNode = this.fullNodeToINode(
              parentLevelAndChildren.level + 1,
              item.node
            );
            parentLevelAndChildren.children = [
              newNode,
              ...parentLevelAndChildren.children,
            ];
            const parentNodeIndex = this.data.findIndex(
              (node) => node.id === item.parentDirectoryUid
            );
            if (
              parentNodeIndex !== -1 &&
              this._treeControl.isExpanded(parentNode)
            ) {
              this.data.splice(parentNodeIndex + 1, 0, newNode);
              this.dataChange.next(this.data);
            }
          }
        } else {
          const parentNode = this.data.find(
            (node) => node.id === ItlsDriveService.ROOT_NODE_ID
          );
          const parentLevelAndChildren = this.nodeIdToChildren.get(
            ItlsDriveService.ROOT_NODE_ID
          );
          if (parentLevelAndChildren) {
            const newNode = this.fullNodeToINode(
              parentLevelAndChildren.level + 1,
              item.node
            );
            parentLevelAndChildren.children = [
              newNode,
              ...parentLevelAndChildren.children,
            ];
            const parentNodeIndex = this.data.findIndex(
              (node) => node.id === ItlsDriveService.ROOT_NODE_ID
            );
            if (
              parentNodeIndex !== -1 &&
              this._treeControl.isExpanded(parentNode)
            ) {
              this.data.splice(parentNodeIndex + 1, 0, newNode);
              this.dataChange.next(this.data);
            }
          }
        }
      })
    );
  }

  close() {
    this.subscriptions.unsubscribe();
  }

  connect(collectionViewer: CollectionViewer): Observable<INode[]> {
    this._treeControl.expansionModel.changed
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        if (
          (change as SelectionChange<INode>).added ||
          (change as SelectionChange<INode>).removed
        ) {
          this.handleTreeControl(change as SelectionChange<INode>);
        }
      });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(
      map(() => this.data)
    );
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<INode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: INode, expand: boolean) {
    const index = this.data.indexOf(node);
    if (index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }

    if (expand) {
      const children = this.nodeIdToChildren.get(node.id)?.children;
      if (!children) {
        const directoryUid = ItlsDriveService.getNonRootDirectoryUid(node.id);
        node.isLoading = true;
        this.subscriptions.add(
          this.driveService
            .getChildrenDirectories(directoryUid)
            .pipe(
              finalize(() => {
                node.isLoading = false;
                // notify the change
                this.dataChange.next(this.data);
              })
            )
            .subscribe((resp) => {
              const items: INode[] = resp.items.map((item) =>
                this.fullNodeToINode(node.level + 1, item)
              );
              this.nodeIdToChildren.set(node.id, {
                children: items,
                level: node.level,
              });
              if (items.length) {
                this.data.splice(index + 1, 0, ...items);
              }
            })
        );
      } else if (children.length) {
        this.data.splice(index + 1, 0, ...this.expandRecursively(children));
      }
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > node.level;
        i++, count++
      ) {}
      this.data.splice(index + 1, count);
    }
    // notify the change
    this.dataChange.next(this.data);
  }

  private fullNodeToINode(level: number, fullNode: FullNodeModel): INode {
    return {
      id: fullNode.uid,
      title: fullNode.name,
      value: "",
      icon: fullNode.shared ? "icon-folder-share" : "icon-documents",
      level,
    };
  }

  private expandRecursively(nodes: INode[]) {
    let _data = [];
    nodes.forEach((node, index) => {
      _data = [..._data, node];
      // check if children also need to be expanded
      const children = this.nodeIdToChildren.get(node.id)?.children;
      if (children?.length > 0 && this._treeControl.isExpanded(node)) {
        _data = [..._data, ...this.expandRecursively(children)];
      }
    });
    return _data;
  }

  private deleteNode(node: INode) {
    const children = cloneDeep(
      this.nodeIdToChildren?.get(node?.id)
        ? this.nodeIdToChildren?.get(node.id)?.children
        : undefined
    );

    if (this.data.find((item) => item?.id === node?.id))
      remove(this.data, (item) => item?.id === node?.id);

    this.nodeIdToChildren.forEach((nodeIdToChild) => {
      remove(nodeIdToChild.children, (item) => item.id === node?.id);
    });

    if (children?.length > 0) {
      children.forEach((_node) => {
        this.deleteNode(_node);
      });
    }
  }

  private getNodeFromMapWithId = (node: INode, id: string) => {
    if (node.id === id) this.nodeSelected = node;
    else if (this.nodeIdToChildren.get(node.id)?.children) {
      this.nodeIdToChildren.get(node.id).children.forEach((_node) => {
        this.getNodeFromMapWithId(_node, id);
      });
    }
  };
}
