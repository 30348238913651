import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";

export const RECORD_ACTION__OPEN_IN_NEW_TAB: ActionButtonInterface = {
  title: "Open in New Tab",
  actionKey: "OPEN_IN_NEW_TAB",
  icon: "icon-export",
};
export const RECORD_ACTION__EDIT: ActionButtonInterface = {
  title: "Review",
  actionKey: "EDIT",
  icon: "icon-take-me-there",
  children: [],
};
export const RECORD_ACTION__RENAME: ActionButtonInterface = {
  title: "Rename",
  actionKey: "RENAME",
  icon: "icon-rename",
  children: [],
};
export const RECORD_ACTION__ADD_TO_QUEUE: ActionButtonInterface = {
  title: "Add to Queue",
  actionKey: "ADD_TO_QUEUE",
  icon: "icon-move",
  children: [],
};
export const RECORD_ACTION__SHARE: ActionButtonInterface = {
  title: "Share",
  actionKey: "SHARE",
  icon: "icon-share",
  children: [],
};
export const RECORD_ACTION__MOVE: ActionButtonInterface = {
  title: "Move",
  actionKey: "MOVE",
  icon: "icon-move",
  children: [],
};
export const RECORD_ACTION__ASSIGN: ActionButtonInterface = {
  title: "Assign",
  actionKey: "ASSIGN",
  icon: "icon-assigned-to-me",
  children: [],
};
export const RECORD_ACTION__EXPORT: ActionButtonInterface = {
  title: "Export",
  actionKey: "EXPORT",
  icon: "icon-download",
};
export const RECORD_ACTION__FULL_EXPORT: ActionButtonInterface = {
  title: "Full Export",
  actionKey: "FULL_EXPORT",
  icon: "icon-download",
};
export const RECORD_ACTION__HISTORY_EXPORT: ActionButtonInterface = {
  title: "History Export",
  actionKey: "HISTORY_EXPORT",
  icon: "icon-download",
};
export const RECORD_ACTION__DELETE: ActionButtonInterface = {
  title: "Delete",
  actionKey: "DELETE",
  icon: "icon-remove",
  children: [],
};

export const ALL_RECORD_ACTION_BUTTONS: ActionButtonInterface[] = [
  RECORD_ACTION__OPEN_IN_NEW_TAB,
  RECORD_ACTION__EDIT,
  RECORD_ACTION__RENAME,
  RECORD_ACTION__ADD_TO_QUEUE,
  RECORD_ACTION__SHARE,
  RECORD_ACTION__ASSIGN,
  RECORD_ACTION__EXPORT,
  RECORD_ACTION__MOVE,
  RECORD_ACTION__DELETE,
  RECORD_ACTION__FULL_EXPORT,
  RECORD_ACTION__HISTORY_EXPORT,
];

export const ALL_RECORD_ACTION_BUTTONS_KEYS: string[] =
  ALL_RECORD_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);

export const BATCH_RECORD_ACTION_BUTTONS: ActionButtonInterface[] = [
  RECORD_ACTION__SHARE,
  RECORD_ACTION__ADD_TO_QUEUE,
  RECORD_ACTION__EXPORT,
  RECORD_ACTION__MOVE,
  RECORD_ACTION__DELETE,
];

export const BATCH_RECORD_ACTION_BUTTONS_KEYS: string[] =
  BATCH_RECORD_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);
