import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PgSelectModule } from "../select/select.module";
import { PaginationComponent } from "./pagination.component";

@NgModule({
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
  imports: [CommonModule, FormsModule, PgSelectModule],
})
export class PgPaginationModule {}
