import { NgModule } from "@angular/core";
import { ItlsDriveV2RoutingModule } from "@@intelease/web/ui/src/lib/itls-drive-v2/itls-drive-routing.module";
import { ItlsDriveV2NoRoutingModule } from "@@intelease/web/ui/src/lib/itls-drive-v2/itls-drive-no-routing.module";
import { PermissionsGuard } from "@@intelease/web/intelease/guards";
import { ItlsCustomIconModule } from "@@intelease/web/common/components/itls-custom-icon";

@NgModule({
  imports: [
    ItlsDriveV2NoRoutingModule,
    ItlsDriveV2RoutingModule,
    ItlsCustomIconModule,
  ],
  providers: [PermissionsGuard],
})
export class ItlsDriveV2Module {}
