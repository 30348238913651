import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  EntityFieldModel,
  ModalInputModel,
  PaginationModel,
} from "@@intelease/web/intelease/models";
import {
  ColumnMode,
  ContextmenuType,
  DatatableComponent,
  SelectionType,
  SortType,
} from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CommonModalService,
  UploadingBoxDialogService,
} from "@@intelease/web/common/services";
import {
  InteleaseNotificationService,
  MainDrawerService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { MatDialog } from "@angular/material/dialog";
import { openInNewWindow } from "@@intelease/web/intelease/utils";
import { NewShareEntityComponent } from "@@intelease/web/ui/src/lib/new-share-entity/new-share-entity.component";
import { ResizedEvent } from "@@intelease/web/ui/src/lib/itls-angular-resize-event";
import { combineLatest } from "rxjs";
import {
  ItlsDriveService,
  NavigationType,
} from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";
import {
  ALL_RECORD_ACTION_BUTTONS,
  ALL_RECORD_ACTION_BUTTONS_KEYS,
  BATCH_FOLDER_ACTION_BUTTONS_KEYS,
  BATCH_RECORD_ACTION_BUTTONS_KEYS,
  EDIT_ACTION_BUTTONS_KEYS,
  EXPORT_ACTION_BUTTONS_KEYS,
  FOLDER_ACTION_BUTTONS_KEYS,
  FOLDER_TABLE,
  MULTI_SELECTION_ACTION_BUTTONS_KEYS,
  READ_ACTION_BUTTONS,
  READ_ACTION_BUTTONS_KEYS,
  RECORD_ACTION__ADD_TO_QUEUE,
  RECORD_ACTION__ASSIGN,
  RECORD_ACTION__DELETE,
  RECORD_ACTION__EDIT,
  RECORD_ACTION__EXPORT,
  RECORD_ACTION__FULL_EXPORT,
  RECORD_ACTION__HISTORY_EXPORT,
  RECORD_ACTION__MOVE,
  RECORD_ACTION__OPEN_IN_NEW_TAB,
  RECORD_ACTION__RENAME,
  RECORD_ACTION__SHARE,
} from "@@intelease/web/ui/src/lib/itls-drive/constants";
import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";
import { MatMenuTrigger } from "@angular/material/menu";
import { NavigationItemService } from "@@intelease/web/intelease/constants/navigation-item.service";
import {
  AddFolderComponent,
  SelectFolderComponent,
} from "@@intelease/web/ui/src/lib/itls-drive/modals";
import { ItlsBreadcrumbService } from "@@intelease/web/intelease/components/toolbar/breadcrumb";
import {
  FullNodeModel,
  LightNodeNameModel,
} from "@@intelease/web/common/models";
import { AbstractExportModalService } from "@@intelease/web/intelease/modal";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { TaskModalService } from "@@intelease/web/tasks";
import {
  SortDirectionEnum,
  SortDirectionLowcaseEnum,
} from "@@intelease/web/common/enums/sort-direction.enum";
import { FormModalService } from "@@intelease/web/ui";
import {
  APP_ENUMS_CONST,
  ENTITY_FORM_SCHEMA_ONLY_NAME_CONST,
} from "@@intelease/web/intelease/constants";
import { cloneDeep, forEach, isEqual, remove } from "lodash";
import { ItlsShareDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-share-drive.service";
import { CentrifugeService } from "@@intelease/app-services/notifications/src";
import { NotificationTypesEnum } from "@@intelease/app-models/notifications/src";
import { DriveFacade } from "@@intelease/app-state/drive/src";
import { environment } from "../../../../../../../apps/ui/src/environments/environment";
import { ItlsModifyCustomTagComponent } from "../../itls-modify-custom-tag/itls-modify-custom-tag.component";
import { RecordReviewModeEnum } from "@@intelease/app-models/common/src";
import { filter } from "rxjs/operators";
import {
  IRecord,
  Permission,
  RecordCategory,
  RecordCategoryUppercaseEnum,
  RecordStatus,
  RecordType,
  SelectedColumnFieldsModel,
} from "@@intelease/app-state/drive/src/lib/models/record.types";
import { LocalStorageKey } from "@@intelease/web/common/enums/local-storage.keys";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

interface LoadDirectoriesOptions {
  resetPage?: boolean;
  targetNodeOffset?: number;
}

export const PAGINATION_SIZE = 20;

@Component({
  selector: "il-folder",
  templateUrl: "./itls-folder.component.html",
  styleUrls: ["./itls-folder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItlsFolderComponent implements OnInit {
  @Output() newTask: EventEmitter<any> = new EventEmitter();
  @Input() tableColumns: EntityFieldModel[] = [];
  @ViewChild("table") table: DatatableComponent;
  actionMaps: { [key: string]: any };
  tableUpdated = false;

  // custom fields (remove anything above this)
  columnModeSetting: ColumnMode | keyof typeof ColumnMode =
    window.innerWidth < 960 ? "standard" : "force";
  scrollBarHorizontal = window.innerWidth < 960;
  SelectionType = SelectionType;
  pagination: PaginationModel = new PaginationModel(1, PAGINATION_SIZE);
  basicRows: IRecord[] = [];
  columns: any[] = [];
  selectedItems: any[] = [];
  isTableLoaded = false;
  isRecordsLoaded = false;
  isAdvancedSearchActive = false;
  nodes: FullNodeModel[] = [];
  @ViewChild("contextMenuTrigger", { static: false })
  contextMenu: MatMenuTrigger;
  contextMenuContent: any;
  contextMenuEvent: MouseEvent;
  contextMenuPos: { x: number; y: number };
  folderUid: string;
  category: "favorite" | "shared" | "owned" | "deleted" | "all";
  navigationType: NavigationType;
  sorts: {
    sorts: {
      prop: string;
      dir: SortDirectionLowcaseEnum;
    }[];
    sort: string;
  };
  contextMenuActionButtons: ActionButtonInterface[] = [];
  isShiftHold: boolean;
  sortType = SortType;
  reviewSection: "/record-review/" | "/abstract-review/";

  destroyRef = inject(DestroyRef);

  isTestBed = environment.testbed;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonModalService: CommonModalService,
    private inteleaseNotificationService: InteleaseNotificationService,
    public mainDrawerService: MainDrawerService,
    private itlsShareDriveService: ItlsShareDriveService,
    private dialog: MatDialog,
    private driveService: ItlsDriveService,
    private cdr: ChangeDetectorRef,
    private itlsBreadcrumbService: ItlsBreadcrumbService,
    private taskModalService: TaskModalService,
    private abstractExportModalService: AbstractExportModalService,
    private formModalService: FormModalService,
    public uploadingBoxDialogService: UploadingBoxDialogService,
    private driveFacade: DriveFacade
  ) {
    this.initTableColumns();
    this.initActions();
  }

  ngOnInit() {
    this.initListener();
  }

  initListener() {
    this.route.params
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        const queryParams = this.route.snapshot.queryParams;
        if (
          params.category !== this.category ||
          params.folderUid !== this.folderUid
        ) {
          this.driveFacade.closeAdvancedSearch();
        }
        if (params.category) {
          this.setSorts();
          this.category = params.category;
          this.folderUid = undefined;
          this.loadDirectories({
            resetPage: true,
            targetNodeOffset: queryParams?.targetNodeOffset,
          });
        } else if (params.folderUid) {
          this.setSorts();
          this.category = undefined;
          this.folderUid = params.folderUid;
          this.loadDirectories({
            resetPage: true,
            targetNodeOffset: queryParams?.targetNodeOffset,
          });
        }
      });

    this.driveFacade.getAppliedSelectedColumnFields$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedColumnFields) => {
        this.columns = cloneDeep(selectedColumnFields);
      });

    NavigationItemService.openAddFolder$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.openCreateFolderDialog());

    this.driveService.newFolderCreated$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((item) => {
        if (item.refreshDirectories) this.loadDirectories();
      });

    this.driveFacade.getPagination$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pagination) => {
        this.pagination = {
          page: pagination?.page,
          size: PAGINATION_SIZE,
          totalResults: pagination?.totalResults,
        };
      });

    combineLatest([
      this.driveFacade.isLoadAllRecordsLoading$,
      this.driveFacade.loadAllRecordsSucceeded$,
      this.driveFacade.isAdvancedSearchActive$,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([
          isLoadAllRecordsLoading,
          loadAllRecordsSucceeded,
          isAdvancedSearchActive,
        ]) => {
          this.isTableLoaded = loadAllRecordsSucceeded;
          this.isRecordsLoaded = !isLoadAllRecordsLoading;
          this.isAdvancedSearchActive = isAdvancedSearchActive;
          this.cdr.markForCheck();
        }
      );

    this.driveService.loadAdvancedSearchRecords
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((sort) => {
        if (sort)
          this.driveFacade.loadAdvancedSearchRecords({
            sorts: sort,
          });
      });

    combineLatest([this.driveFacade.isAdvancedSearchActive$])
      .pipe(filter(([isAdvancedSearchActive]) => !isAdvancedSearchActive))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isAdvancedSearchActive]) => {
        if (this.isTableLoaded) {
          this.setSorts();
          this.loadDirectories();
        }
      });

    this.driveFacade.isLoadAllRecordsLoading$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((isLoadAllRecordsLoading) => !isLoadAllRecordsLoading)
      )
      .subscribe((isLoadAllRecordsLoading) => {
        this.driveFacade
          .getRecords$(this.category as RecordCategory)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((resp) => {
            this.basicRows = resp.items;
            this.cdr.markForCheck();
          });
      });

    this.onRecordStatusUpdate();

    switch (localStorage.getItem(LocalStorageKey.RECORD_REVIEW_MODE)) {
      case RecordReviewModeEnum.V2:
        this.reviewSection = "/record-review/";
        break;

      case RecordReviewModeEnum.V1:
      default:
        this.reviewSection = "/abstract-review/";
        break;
    }

    this.driveFacade.isSortRecordFailed$.pipe(filter(Boolean)).subscribe(() => {
      this.setSorts();
    });
  }

  initTableColumns() {
    this.driveFacade.isAdvancedSearchActive$
      .pipe(filter((value) => !value))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.navigationType === "OWNED") {
          this.driveFacade.setSelectedColumnFields(
            cloneDeep(FOLDER_TABLE.ownedColumns) as SelectedColumnFieldsModel[]
          );
        } else {
          this.driveFacade.setSelectedColumnFields(
            cloneDeep(
              FOLDER_TABLE.defaultColumns
            ) as SelectedColumnFieldsModel[]
          );
        }
      });
    // this.columns.unshift(_.first(ROOT_FOLDER_TABLE_CONST.fixedColumns))
    // this.columns.push(_.last(ROOT_FOLDER_TABLE_CONST.fixedColumns))
  }

  onPageChange(evt) {
    this.driveFacade.isAdvancedSearchActive$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isAdvancedSearchActive) => {
        isAdvancedSearchActive
          ? this.driveFacade.changePage(evt?.page)
          : this.table.onFooterPage(evt);
      });
    // this.driveFacade.changePage(evt?.page)
    // this.table.onFooterPage(evt)
  }

  onButtonActionClick(actionKey: string, row) {
    this.actionMaps[actionKey](row);
  }

  changePage(pageInfo): void {
    this.pagination.page = ++pageInfo.offset;
    this.loadDirectories();
  }

  /**
   * This is used by the html table.
   */
  getUid(row: IRecord): string {
    return row?.uid;
  }

  private getCurrentPageSelectedItemsLen() {
    const currentPageUidList = this.basicRows.map((row: any) => row.uid);
    return this.selectedItems.filter(
      (selectedItem: any) => currentPageUidList.indexOf(selectedItem.uid) !== -1
    ).length;
  }

  onSortClick(evt: any) {
    if (evt.newValue) {
      this.setSorts(evt);
      if (this.sorts.sort) {
        const payload = {
          category: this.category?.toUpperCase() as
            | RecordCategoryUppercaseEnum
            | undefined,
          directoryUid: this.folderUid,
          page: this.pagination.page,
          perPage: PAGINATION_SIZE,
          sorts: this.sorts.sort,
        };
        this.isAdvancedSearchActive
          ? this.driveService.loadAdvancedSearchRecords.next([this.sorts.sort])
          : this.driveFacade.sortsRecords(payload);
      }
    }
  }

  initActions() {
    this.actionMaps = {
      OPEN_IN_NEW_TAB: () => this.onOpenInNewTAbClick(this.selectedItems[0]),
      EDIT: () => this.onEdit(this.selectedItems[0]),
      MOVE: () =>
        this.selectedItems.length > 1
          ? this.onBatchMove(this.selectedItems)
          : this.onMove(this.selectedItems[0]),
      EXPORT: () =>
        this.selectedItems.length > 1
          ? this.onBatchExport(this.selectedItems)
          : this.onExport(this.selectedItems[0]),
      FULL_EXPORT: () =>
        this.selectedItems.length > 1
          ? this.onBatchExport(this.selectedItems)
          : this.onExport(this.selectedItems[0]),
      SHARE: () =>
        this.selectedItems.length > 1
          ? this.onBatchShare(this.selectedItems)
          : this.onShare(this.selectedItems[0]),
      RENAME: () => this.onRename(this.selectedItems[0]),
      DELETE: () =>
        this.selectedItems.length > 1
          ? this.onBatchDelete(this.selectedItems)
          : this.onDelete(this.selectedItems[0]),
      RESTORE: () => this.onRestore(this.selectedItems[0]),
      ASSIGN: () =>
        this.selectedItems.length > 1
          ? this.onBatchAssign(this.selectedItems)
          : this.onAssign(this.selectedItems[0]),
    };
  }

  private onExport(thisRow: IRecord) {
    const { uid, name, type } = thisRow;
    const data = new ModalInputModel();
    data.payload = {
      type,
      fileNode: new LightNodeNameModel({
        name,
        type,
        uid,
      }),
    };
    this.abstractExportModalService
      .openAbstractExportModal(data)
      .afterClosed()
      .subscribe((res) => {
        //
      });
  }

  private onBatchExport(rows: IRecord[]) {
    const data = new ModalInputModel();
    data.payload = {
      type: rows[0].type,
      fileNodes: rows.map(
        (row) =>
          new LightNodeNameModel({
            name: row.name,
            type: row.type,
            uid: row.uid,
          })
      ),
    };
    this.abstractExportModalService
      .openAbstractExportModal(data)
      .afterClosed()
      .subscribe((res) => {
        //
      });
  }

  private onOpenInNewTAbClick(selectedItem: IRecord) {
    if (
      this.equalsAnyIgnoreCase(
        selectedItem.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UI_DELETED
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this record, as it is deleted."
      );
      return;
    }
    if (
      this.equalsAnyIgnoreCase(
        selectedItem.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.PROCESSING,
        APP_ENUMS_CONST.DOCUMENT_STATUS.ERROR,
        APP_ENUMS_CONST.DOCUMENT_STATUS.QUEUED
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this record, as it is processing. Please wait and refresh the page, till the processing is complete."
      );
      return;
    }
    if (
      this.equalsAnyIgnoreCase(
        selectedItem.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UPLOADING
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this record, as its files are still uploading."
      );
      return;
    }
    const { uid } = selectedItem;
    const url = `/individual-abstract/${uid}/related-documents`;
    openInNewWindow(url);
  }

  modifyCustomTag(row: IRecord, value, actionType: string) {
    const { uid } = row;
    const modalInput = new ModalInputModel();
    modalInput.mode = ComponentModeEnum.ADD;
    modalInput.payload = {
      customMessage: false,
      customTags: value,
    };
    this.dialog
      .open(ItlsModifyCustomTagComponent, {
        data: {
          abstractUid: uid,
          ...modalInput,
        },
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse.data.customTags) {
          this.driveFacade.updateCustomTags(uid, modalResponse.data.customTags);
        }
      });
  }

  onRowClick(row: IRecord, value, evt) {
    //
  }

  onRowDbClick(row: IRecord, evt): void {
    if (row.status === RecordStatus.UI_DELETED) {
      return;
    }

    if (evt.ctrlKey || evt.shiftKey || evt.metaKey) return;
    if (row.type === "DIRECTORY") {
      const foldersUrl = `/drive/folders/${row.uid}`;
      this.router.navigate([foldersUrl]);
    } else {
      if (
        this.equalsAnyIgnoreCase(
          row.status,
          APP_ENUMS_CONST.DOCUMENT_STATUS.UI_DELETED
        )
      ) {
        return;
      }
      if (
        this.equalsAnyIgnoreCase(
          row.status,
          APP_ENUMS_CONST.DOCUMENT_STATUS.PROCESSING,
          APP_ENUMS_CONST.DOCUMENT_STATUS.QUEUED
        )
      ) {
        this.inteleaseNotificationService.openSnackBar(
          "Cannot navigate to this record, as it is processing. Please wait and refresh the page, till the processing is complete."
        );
        return;
      }
      if (
        this.equalsAnyIgnoreCase(
          row.status,
          APP_ENUMS_CONST.DOCUMENT_STATUS.UPLOADING
        )
      ) {
        this.inteleaseNotificationService.openSnackBar(
          "Cannot navigate to this record, as its files are still uploading."
        );
        return;
      }
      if (
        this.equalsAnyIgnoreCase(
          row.status,
          APP_ENUMS_CONST.DOCUMENT_STATUS.ERROR
        )
      ) {
        this.inteleaseNotificationService.openSnackBar(
          "Cannot navigate to this record. Please contact an ADEx administrator."
        );
        return;
      }
      const detailsUrl = `/individual-abstract/${row.uid}/related-documents`;
      this.router.navigate([detailsUrl], {
        queryParams: {
          abstractTitle: row.name,
        },
      });
    }
  }

  private equalsAnyIgnoreCase(status: string, ...expectedStatuses: string[]) {
    return (
      expectedStatuses?.findIndex(
        (expectedStatus) =>
          status?.toLowerCase() === expectedStatus.toLowerCase()
      ) !== -1
    );
  }

  private createDeleteModal(rows: IRecord[]) {
    const distinctTypes = new Set(rows.map((row) => row.type));
    const deleteModalInput = new ModalInputModel();
    deleteModalInput.mode = ComponentModeEnum.REMOVE;
    deleteModalInput.payload = {
      customMessage: true,
    };
    if (distinctTypes.size === 1 && distinctTypes.has(RecordType.RECORD)) {
      // only records
      deleteModalInput.payload.title = "Delete Record(s)";
      deleteModalInput.payload.message = `This will permanently delete ${rows.length} record(s). Are you sure?`;
      deleteModalInput.payload.params = {
        deletedRecordsNum: rows.length,
      };
    } else if (
      distinctTypes.size === 1 &&
      distinctTypes.has(RecordType.DIRECTORY)
    ) {
      // only folders
      deleteModalInput.payload.title = "Delete Folder(s)";
      deleteModalInput.payload.message = `This will permanently delete ${rows.length} folder(s). Are you sure?`;
      deleteModalInput.payload.params = {
        deletedFoldersNum: rows.length,
      };
    } else {
      const deletedRecordsNum = rows.filter(
        (row) => row.type === "RECORD"
      ).length;
      const deletedFoldersNum = rows.filter(
        (row) => row.type === "DIRECTORY"
      ).length;
      deleteModalInput.payload.title = "Delete Folder(s) / Record(s)";
      deleteModalInput.payload.message = `This will permanently delete ${deletedFoldersNum} folder(s) and ${deletedRecordsNum} record(s). Are you sure?`;
      deleteModalInput.payload.params = {
        deletedRecordsNum,
        deletedFoldersNum,
      };
    }
    return deleteModalInput;
  }

  private onRestore(row: IRecord) {
    //
  }

  private onDelete(row: IRecord) {
    this.onBatchDelete([row]);
  }

  private onBatchDelete(rows: IRecord[]) {
    this.commonModalService
      .openDeleteConfirmModal(this.createDeleteModal(rows))
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse?.type === ModalsResponseTypeEnum.CLOSE) {
          const payload = rows.map((row) => ({
            type: row.type,
            uid: row.uid,
          }));

          this.driveService
            .deleteNodes(payload)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((resp) => {
              this.driveService.deleteFolders$.next(
                payload.map(
                  (item) => item.type === RecordType.DIRECTORY && item.uid
                )
              );
              this.loadDirectories();
            });
        }
      });
  }

  private onShare(row: IRecord): void {
    this.dialog.open(NewShareEntityComponent, {
      width: "350px",
      height: "420px",
      panelClass: "no-padding-modal-panel",
      data: {
        shareEntityService: this.itlsShareDriveService,
        entity: {
          entityUid: row.uid,
          entityType: row.type,
        },
      },
    });
  }

  private onBatchShare(rows: IRecord[]): void {
    this.dialog.open(NewShareEntityComponent, {
      width: "350px",
      height: "420px",
      panelClass: "no-padding-modal-panel",
      data: {
        shareEntityService: this.itlsShareDriveService,
        entities: rows.map((row) => ({
          entityUid: row.uid,
          entityType: row.type,
        })),
      },
    });
  }

  private onRename(row: IRecord): void {
    let saveButtonTooltip;
    let editTitle;
    if (row.type === "RECORD") {
      editTitle = "Edit Record";
    } else if (row.type === "DIRECTORY") {
      editTitle = "Edit Folder";
    } else {
      throw new Error("unexpected value: " + row.type);
    }
    const modalData = new ModalInputModel();
    modalData.mode = ComponentModeEnum.EDIT;
    modalData.payload = {
      editTitle,
      formSchema: { ...ENTITY_FORM_SCHEMA_ONLY_NAME_CONST },
      model: { name: row.name },
    };
    switch (modalData.payload.editTitle) {
      case "Edit Folder":
        saveButtonTooltip = {
          emptyField: "You must enter a folder name!",
          notValidField: "No change detected in the folder name!",
          firstMessage: "No change detected in the folder name!",
        };
        break;
      case "Edit Record":
        saveButtonTooltip = {
          emptyField: "You must enter a record name!",
          notValidField: "No change detected in the record name!",
          firstMessage: "No change detected in the record name!",
        };
        break;

      default:
        break;
    }
    const modalRef = this.formModalService.openNewFormModal(
      modalData,
      {
        minWidth: 340,
      },
      saveButtonTooltip
    );
    modalRef.afterClosed().subscribe((res) => {
      if (res && res?.data && res?.data?.name) {
        const { name } = res.data;
        this.driveService.renameFolder$.next({
          uid: row.uid,
          name: name,
        });
        this.driveFacade.renameRecordOrDirectory({ newName: name, row });
      }
    });
  }

  private onEdit(thisRow: IRecord): void {
    if (
      this.equalsAnyIgnoreCase(
        thisRow.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UI_DELETED
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this record, as it is deleted."
      );
      return;
    }
    if (
      this.equalsAnyIgnoreCase(
        thisRow.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.ERROR
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this record, as it is processing. Please wait and refresh the page, till the processing is complete."
      );
      return;
    }
    if (
      this.equalsAnyIgnoreCase(
        thisRow.status,
        APP_ENUMS_CONST.DOCUMENT_STATUS.UPLOADING
      )
    ) {
      this.inteleaseNotificationService.openSnackBar(
        "Cannot navigate to this record, as its files are still uploading."
      );
      return;
    }

    const abstractionUrl = `${this.reviewSection}${thisRow.uid}`;
    openInNewWindow(abstractionUrl);
  }

  onResized(event: ResizedEvent) {
    if (!this.tableUpdated) {
      this.tableUpdated = true;
      setTimeout(() => {
        combineLatest([
          this.driveFacade.getRecords$(this.category as RecordCategory),
          this.driveFacade.loadAllRecordsSucceeded$,
        ])
          .pipe(
            filter(([resp, isLoaded]) => isLoaded && !isEqual(resp.items, [])),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe(([resp, isLoaded]) => {
            this.basicRows = resp.items;
            this.cdr.detectChanges();
          });
        try {
          this.table.ngDoCheck();
        } catch (e) {
          //
        }
        this.tableUpdated = false;
      }, 150);
    }
  }

  getPosition(e: MouseEvent) {
    const leftMenu = document.querySelector("left-menu")?.clientWidth || 0;
    let posx = 0;
    let posy = 0;

    if (e.pageX || e.pageY) {
      posx = e.pageX - leftMenu;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }

    return {
      x: this.mainDrawerService.isOpen ? posx - 270 : posx - 25,
      y: posy - 55,
    };
  }

  private reloadContextMenuActionButtons() {
    this.contextMenuActionButtons = this.getContextMenuActionButtons();
  }

  private getContextMenuActionButtons(): ActionButtonInterface[] {
    if (this.selectedItems.length === 0) {
      return [];
    }
    if (this.category === "deleted") {
      return [];
    }

    const userUid = UserInfoService.getUserUid();
    const allActionButtons = [...ALL_RECORD_ACTION_BUTTONS];

    this.filterActionButtonsBasedOnAppEnv(allActionButtons);

    this.filterActionButtonsBasedOnSelectedItemsPermissions(
      allActionButtons,
      this.selectedItems
    );

    this.filterActionButtonsBasedOnRowDetails(
      allActionButtons,
      this.selectedItems,
      userUid
    );

    this.filterActionButtonsBasedOnMixedSelectionOfFoldersAndRecords(
      allActionButtons,
      this.selectedItems
    );

    this.filterActionButtonsBasedOnRecordOwner(
      allActionButtons,
      this.selectedItems,
      userUid
    );

    this.filterActionButtonsBasedOnRecordStatus(
      allActionButtons,
      this.selectedItems
    );

    return allActionButtons;
  }

  private filterActionButtonsBasedOnAppEnv(
    allActionButtons: ActionButtonInterface[]
  ) {
    if (!this.isTestBed) {
      remove(
        allActionButtons,
        (actionButton) =>
          actionButton.actionKey === RECORD_ACTION__ADD_TO_QUEUE.actionKey
      );
    }
  }

  private filterActionButtonsBasedOnSelectedItemsPermissions(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IRecord[]
  ) {
    for (const selectedItem of selectedItems) {
      // stop if we don't have any actions to filter
      if (allActionButtons.length === 0) {
        return;
      }
      if (!selectedItem.permissions?.includes(Permission.READ)) {
        remove(allActionButtons, (actionButton) =>
          READ_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
      if (!selectedItem.permissions?.includes(Permission.EDIT)) {
        remove(allActionButtons, (actionButton) =>
          EDIT_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
      if (!selectedItem.permissions?.includes(Permission.SHARE)) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === RECORD_ACTION__SHARE.actionKey
        );
      }
      if (!selectedItem.permissions?.includes(Permission.EXPORT)) {
        remove(allActionButtons, (actionButton) =>
          EXPORT_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
      if (!selectedItem.permissions?.includes(Permission.DELETE)) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === RECORD_ACTION__DELETE.actionKey
        );
      }
    }
  }

  private filterActionButtonsBasedOnRecordStatus(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IRecord[]
  ) {
    const actionsShouldRemoveForUploading = [
      RECORD_ACTION__ASSIGN,
      RECORD_ACTION__DELETE,
      RECORD_ACTION__EDIT,
      RECORD_ACTION__EXPORT,
      RECORD_ACTION__FULL_EXPORT,
      RECORD_ACTION__HISTORY_EXPORT,
      RECORD_ACTION__OPEN_IN_NEW_TAB,
    ];
    const actionsShouldRemoveForProcessing = [
      RECORD_ACTION__ASSIGN,
      RECORD_ACTION__DELETE,
      RECORD_ACTION__EXPORT,
      RECORD_ACTION__FULL_EXPORT,
      RECORD_ACTION__HISTORY_EXPORT,
      RECORD_ACTION__OPEN_IN_NEW_TAB,
    ];
    for (const selectedItem of selectedItems) {
      if (allActionButtons.length === 0) {
        return;
      }
      if (selectedItem.status === RecordStatus.UPLOADING) {
        forEach(actionsShouldRemoveForUploading, (action) => {
          remove(
            allActionButtons,
            (actionButton) => actionButton.actionKey === action.actionKey
          );
        });
      } else if (selectedItem.status === RecordStatus.PROCESSING) {
        forEach(actionsShouldRemoveForProcessing, (action) => {
          remove(
            allActionButtons,
            (actionButton) => actionButton.actionKey === action.actionKey
          );
        });
      }
    }
  }

  private filterActionButtonsBasedOnRowDetails(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IRecord[],
    userUid: string
  ) {
    for (const selectedItem of selectedItems) {
      // stop if we don't have any actions to filter
      if (allActionButtons.length === 0) {
        return;
      }
      // only allow DELETE action for records with ERROR status
      if (selectedItem.status === "ERROR") {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey !== RECORD_ACTION__DELETE.actionKey
        );
      }
      // remove MOVE action if there is a folder/record that is NOT owned by the current user
      if (selectedItem.ownerUid !== userUid) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === RECORD_ACTION__MOVE.actionKey
        );
      }
    }
  }

  private filterActionButtonsBasedOnMixedSelectionOfFoldersAndRecords(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IRecord[]
  ) {
    if (allActionButtons.length === 0) {
      return;
    }
    const isBatchSelection = selectedItems.length > 1;
    const allFolders = selectedItems.every((item) => item.type === "DIRECTORY");
    const allRecords = selectedItems.every((item) => item.type === "RECORD");
    if (allFolders) {
      // only folders
      if (isBatchSelection) {
        remove(
          allActionButtons,
          (actionButton) =>
            !BATCH_FOLDER_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      } else {
        remove(
          allActionButtons,
          (actionButton) =>
            !FOLDER_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
    } else if (allRecords) {
      // only records
      if (isBatchSelection) {
        remove(
          allActionButtons,
          (actionButton) =>
            !BATCH_RECORD_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      } else {
        remove(
          allActionButtons,
          (actionButton) =>
            !ALL_RECORD_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
        );
      }
    } else {
      // mixed of folders and records
      remove(
        allActionButtons,
        (actionButton) =>
          !MULTI_SELECTION_ACTION_BUTTONS_KEYS.includes(actionButton.actionKey)
      );
    }
  }

  private filterActionButtonsBasedOnRecordOwner(
    allActionButtons: ActionButtonInterface[],
    selectedItems: IRecord[],
    userUid: string
  ) {
    for (const selectedItem of selectedItems) {
      if (allActionButtons.length === 0) {
        return;
      }
      if (selectedItem.ownerUid !== userUid) {
        remove(
          allActionButtons,
          (actionButton) =>
            actionButton.actionKey === RECORD_ACTION__ASSIGN.actionKey
        );
      }
    }
  }

  onTableContextMenu(contextMenuEvent: {
    event: MouseEvent;
    type: ContextmenuType;
    content: any;
  }) {
    this.contextMenu.closeMenu();
    if (contextMenuEvent.type === "body") {
      if (!this.isSelectedBefore(contextMenuEvent.content)) {
        this.selectedItems = [contextMenuEvent.content];
      }
      this.contextMenuContent = contextMenuEvent.content;
      this.contextMenuEvent = contextMenuEvent.event;
      this.contextMenuPos = this.getPosition(this.contextMenuEvent);
    } else {
      this.contextMenuContent = undefined;
      this.contextMenuEvent = undefined;
      this.contextMenuPos = undefined;
      this.selectedItems = [];
    }

    this.reloadContextMenuActionButtons();
    if (this.contextMenuActionButtons.length === 0) {
      this.contextMenu.closeMenu();
    } else {
      setTimeout(() => {
        this.contextMenu.openMenu();
      }, 150);
    }

    contextMenuEvent.event.preventDefault();
    contextMenuEvent.event.stopPropagation();
  }

  private isSelectedBefore(item): boolean {
    return (
      this.selectedItems.length > 0 &&
      this.selectedItems.find((selectedItem) => selectedItem.uid === item.uid)
    );
  }

  private loadDirectories(options?: LoadDirectoriesOptions) {
    let shouldHighlightTargetNode = false;

    if (options?.resetPage) {
      if (options?.targetNodeOffset > 0) {
        this.pagination.page =
          options.targetNodeOffset % PAGINATION_SIZE === 0
            ? Math.floor(options.targetNodeOffset / PAGINATION_SIZE)
            : Math.floor(options.targetNodeOffset / PAGINATION_SIZE) + 1;
        shouldHighlightTargetNode = true;
      } else {
        this.pagination.page = 1;
      }
    }

    if (!this.isAdvancedSearchActive) {
      const payload = {
        category: this.category?.toUpperCase() as RecordCategoryUppercaseEnum,
        directoryUid: this.folderUid,
        page: this.pagination.page,
        perPage: this.pagination.size,
        sorts: this.sorts.sort,
      };
      this.driveFacade.loadAllRecords(payload);
    }

    combineLatest([
      this.driveFacade.loadAllRecordsSucceeded$,
      this.driveFacade.getRecords$(this.category as RecordCategory),
    ])
      .pipe(filter(([loadAllRecordsSucceeded]) => loadAllRecordsSucceeded))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([loadAllRecordsSucceeded, resp]) => {
        if (
          this.isNavigationTypeChanged(
            resp?.nodeNavigation?.category
              ? resp?.nodeNavigation?.category
              : "OWNED"
          )
        ) {
          this.navigationType = resp?.nodeNavigation?.category
            ? resp?.nodeNavigation?.category
            : "OWNED";
          this.initTableColumns();
        } else {
          this.selectedItems = [];
        }
        this.pagination = new PaginationModel(
          this.pagination.page,
          PAGINATION_SIZE,
          resp?.pagination?.totalResults
        );
        this.itlsBreadcrumbService.recreateBreadcrumbByNodeNavigation(
          resp?.nodeNavigation
        );
        // this.basicRows = resp.items
        if (shouldHighlightTargetNode) {
          const highlightRowIndex =
            options.targetNodeOffset -
            (this.pagination.page - 1) * PAGINATION_SIZE;
          this.selectedItems = [this.basicRows[highlightRowIndex - 1]];
        }
        this.cdr.detectChanges();
      });
  }

  onRecordStatusUpdate() {
    CentrifugeService.onRecordStatusUpdate
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        const { type, abstractUid } = data;
        if (type === NotificationTypesEnum.ABSTRACTION_FINISHED) {
          if (window.location.pathname.includes("/drive/")) {
            this.driveFacade.updateRecordStatus({
              recordUid: abstractUid,
              status: "PROCESSING_COMPLETED",
            });
            this.cdr.detectChanges();
          }
        }
      });
  }

  private openCreateFolderDialog() {
    this.dialog.open(AddFolderComponent, {
      width: "275px",
      data: {
        parentFolderUid: this.folderUid,
      },
    });
  }

  @HostListener("document:click", ["$event"])
  clickedOutside($event) {
    this.contextMenu?.closeMenu();
    this.contextMenuEvent = undefined;
    this.contextMenuPos = undefined;
    this.contextMenuContent = undefined;
  }

  private onMove(row: IRecord) {
    this.onBatchMove([row]);
  }

  private onBatchMove(rows: IRecord[]) {
    const dialogRef = this.dialog.open(SelectFolderComponent, {
      height: "640px",
      width: "480px",
      data: {
        source: {
          uids: rows.map((row) => row.uid),
          parentDirectoryUid: this.folderUid,
        },
        title: "Select destination folder",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.selectedFolder) {
        this.driveService
          .moveNodes(
            rows.map((row) => ({
              uid: row.uid,
              type: row.type,
            })),
            result.selectedFolder.id
          )
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((resp) => {
            this.loadDirectories();
          });
      }
    });
  }

  private onAssign(row: IRecord) {
    const taskData = new ModalInputModel();
    taskData.mode = ComponentModeEnum.ADD;
    taskData.payload = { abstractUid: row.uid };
    this.taskModalService
      .openNewTaskModal(taskData)
      .afterClosed()
      .subscribe((res) => {
        //
      });
  }

  private onBatchAssign(rows: IRecord[]) {
    const taskData = new ModalInputModel();
    taskData.mode = ComponentModeEnum.ADD;
    taskData.payload = { abstractUids: rows.map((row) => row.uid) };
    this.taskModalService
      .openNewTaskModal(taskData)
      .afterClosed()
      .subscribe((res) => {
        //
      });
  }

  private isNavigationTypeChanged(navigationType: NavigationType): boolean {
    return !this.navigationType || this.navigationType !== navigationType;
  }

  private setSorts(evt?) {
    if (!evt) {
      this.sorts = {
        sort: undefined,
        sorts: [],
      };
    } else {
      const column = this.columns.find(
        (inColumn) => inColumn.prop === evt.column.prop
      );
      const direction = evt.newValue.toUpperCase();
      this.sorts = {
        sort: `${column.prop}::${column.name}::${column.type}::${SortDirectionEnum[direction]}`,
        sorts: evt.sorts,
      };
    }
    this.cdr.detectChanges();
  }

  checkShiftHold(event) {
    this.isShiftHold = event.shiftKey;
  }
}
