import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { DateConvertService } from "@common/services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { combineLatest, merge, Subject } from "rxjs";
import { distinct, first, map } from "rxjs/operators";
import { cloneDeep } from "lodash";

import { ModalResponseModel } from "@@intelease/web/intelease/models";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { CalculatorService } from "@@intelease/web/abstraction-page/src/lib/services/calculator.service";
import { DateUtil } from "@@intelease/web/utils";
import { MessageService } from "@@intelease/web/intelease/components/message/message.service";
import {
  ChangeEventInterface,
  EventSourceEnum,
} from "@@intelease/web/intelease/components";
import {
  PartialValProvisionValueModel,
  RelatedDocModel,
} from "@@intelease/web/common/models";
import { CalculatorNamesEnum } from "@@intelease/web/abstraction-page/src/lib/enums";
import { AbstractReviewFacade } from "@@intelease/app-state/abstract-review";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import {
  OApiReqTermCalculatorInputsV2DtoModel,
  OApiRespTermCalculatorInputsResponseModel,
  PartialValPdfProvisionViewModel,
  ProvisionMentionService,
  TermCalculatorInputsApiV2DtoModel,
  TermService,
} from "@@intelease/api-models/adex-api-model-src";
import { RecordReviewModeEnum } from "@@intelease/app-models/common";
import { FullValMultiPdfProvModel } from "@@intelease/app-models/provision/src";
import { ProvisionOptionSourceEnum } from "@@intelease/web/abstract-review/src/lib/models/provision-review.model";
import { firstNotNill } from "@@intelease/web/utils/rxjs-operators";
import {
  RelevantMentions,
  SelectMentionConfirmModalService,
} from "../../select-mention-confirm-modal";
import { CalculatorUtilsService } from "../calculator-utils.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

enum dateFields {
  COMMENCEMENT_DATE = "COMMENCEMENT_DATE",
  EXPIRATION_DATE = "EXPIRATION_DATE",
  TERM = "TERM",
}

interface TermResultInterface {
  years?: any;
  months?: any;
  days?: any;
}

@Component({
  selector: "il-term-calculator-modal",
  templateUrl: "./term-calculator-modal.component.html",
  styleUrls: ["./term-calculator-modal-component.scss"],
})
export class TermCalculatorModalComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  onClose: Subject<any> = new Subject<any>();
  onDismiss: Subject<any> = new Subject<any>();
  imported: Subject<any> = new Subject<any>();
  modalRes: ModalResponseModel = new ModalResponseModel();
  mode: ComponentModeEnum = ComponentModeEnum.ADD;
  payload: any;
  modalTitle = "Save Abstract";
  abstractName = "";
  deselectIncomplete = false;
  isCustomDate: any = {
    COMMENCEMENT_DATE: false,
    EXPIRATION_DATE: false,
    TERM: false,
  };
  termModel: { input: any; dropdown: "YEARS" | "MONTHS" | "DAYS" } = {
    dropdown: undefined,
    input: undefined,
  };
  termResult: TermResultInterface;
  datePickerValue = new Date();
  commencementDate: any[] = [
    {
      label: "test 1",
      value: new Date(),
    },
    {
      label: "test 2",
      value: new Date(new Date().getDay() + 1),
    },
    {
      label: "New",
      value: "New",
    },
  ];
  expirationDate: any[] = [
    {
      label: "test 1",
      value: new Date(),
    },
    {
      label: "test 2",
      value: new Date(new Date().getDay() + 1),
    },
    {
      label: "New",
      value: "New",
    },
  ];
  items: any[] = [
    {
      label: "Days",
      value: "DAYS",
    },
    {
      label: "Months",
      value: "MONTHS",
    },
    {
      label: "Years",
      value: "YEARS",
    },
  ];
  selectedDate: dateFields;
  dateFieldsEnum = dateFields;
  selectedField: "commencementDate" | "expirationDate" | "term" = "term";
  provisionFormUid: string;
  selectedDocument: RelatedDocModel;
  currentPage: number;
  abstractUid: string;
  listItems: any = {
    expiryDate: [],
    commencementDate: [],
    leaseTerm: [],
  };
  calculatedItem: any = {
    field: "term",
    value: {},
  };
  formData: any = {
    commencementDate: undefined,
    expirationDate: undefined,
    term: undefined,
  };
  recordReviewMode: RecordReviewModeEnum;

  provisionsMap = {
    commencementDate: {},
    expirationDate: {},
    term: {},
  };

  leaseTermArray: { id: number; value: string; mentionuid?: string }[];
  commencementDateArray: { id: number; value: string; mentionuid?: string }[];
  expirationDateArray: { id: number; value: string; mentionuid?: string }[];
  selectedLeaseTerm: number;
  leaseTermProvision: FullValMultiPdfProvModel;
  expiryDateProvision: FullValMultiPdfProvModel;
  commencementDateProvision: FullValMultiPdfProvModel;

  private selectedUnit: "YEARS" | "MONTHS" | "DAYS";

  constructor(
    public bsModalRef: BsModalRef,
    private calculatorService: CalculatorService,
    private termService: TermService,
    private _notification: MessageService,
    private datePipe: DatePipe,
    private provisionMentionService: ProvisionMentionService,
    public readonly abstractReviewFacade: AbstractReviewFacade,
    private readonly calculatorUtilsService: CalculatorUtilsService,
    private readonly selectMentionConfirmModalService: SelectMentionConfirmModalService
  ) {}

  ngOnInit() {
    this.initLeaseTermArray();
    const {
      abstractUid,
      provisionFormUid,
      selectedDocument,
      currentPage,
      abstractName,
      recordReviewMode,
    } = this.payload;
    this.abstractUid = abstractUid;
    this.abstractName = abstractName;
    this.provisionFormUid = provisionFormUid;
    this.selectedDocument = selectedDocument;
    this.currentPage = currentPage;
    this.recordReviewMode = recordReviewMode;
    switch (recordReviewMode) {
      case RecordReviewModeEnum.V1:
        this.getTermCalculatorData(abstractUid, provisionFormUid);
        break;
      case RecordReviewModeEnum.V2:
        this.getTermCalculatorDataV2(abstractUid, provisionFormUid);
        break;
      default:
        throw new Error(
          "Cannot handle recordReviewMode: " + recordReviewMode + "!"
        );
    }
  }

  initLeaseTermArray() {
    combineLatest([
      merge(
        this.abstractReviewFacade
          .getProvisionWithhtmlName$("leaseTerm_DOUBLE")
          .pipe(firstNotNill()),
        this.abstractReviewFacade
          .getProvisionWithhtmlName$("leaseTerm")
          .pipe(firstNotNill())
      ),
      merge(
        this.abstractReviewFacade
          .getProvisionWithhtmlName$("expiryDate_LOCAL_DATE")
          .pipe(firstNotNill()),
        this.abstractReviewFacade
          .getProvisionWithhtmlName$("expiryDate")
          .pipe(firstNotNill())
      ),
      merge(
        this.abstractReviewFacade
          .getProvisionWithhtmlName$("commencementDate_LOCAL_DATE")
          .pipe(firstNotNill()),
        this.abstractReviewFacade
          .getProvisionWithhtmlName$("commencementDate")
          .pipe(firstNotNill())
      ),
    ])
      .pipe(
        map(
          ([
            leaseTermProvision,
            expirationDateProvision,
            commencementDateProvision,
          ]) => {
            return {
              leaseTermProvision: {
                provision: leaseTermProvision,
                provisionOptions:
                  leaseTermProvision?.multiplePdfProvision?.options,
              },
              expirationDateProvision: {
                provision: expirationDateProvision,
                provisionOptions:
                  expirationDateProvision?.multiplePdfProvision?.options,
              },
              commencementDateProvision: {
                provision: commencementDateProvision,
                provisionOptions:
                  commencementDateProvision?.multiplePdfProvision?.options,
              },
            };
          }
        ),
        first()
      )
      .subscribe(
        ({
          leaseTermProvision,
          commencementDateProvision,
          expirationDateProvision,
        }) => {
          this.leaseTermProvision =
            leaseTermProvision.provision as FullValMultiPdfProvModel;
          this.expiryDateProvision =
            expirationDateProvision.provision as FullValMultiPdfProvModel;
          this.commencementDateProvision =
            commencementDateProvision.provision as FullValMultiPdfProvModel;

          const newLeaseTermMention = {
            id: leaseTermProvision.provisionOptions.length,
            value: "New",
            mentionuid: null,
          };

          const newCommencementDateMention = {
            id: commencementDateProvision.provisionOptions.length,
            value: "New",
            mentionuid: null,
          };

          const newExpirationDateMention = {
            id: expirationDateProvision.provisionOptions.length,
            value: "New",
            mentionuid: null,
          };

          this.leaseTermArray = [
            ...leaseTermProvision.provisionOptions.map((option, index) => ({
              id: index,
              value: `Lease Term ${index ? index + 1 : ""}`,
              mentionuid: option.uid,
            })),
          ];

          this.commencementDateArray = [
            ...commencementDateProvision.provisionOptions.map(
              (option, index) => ({
                id: index,
                value: `Commencement Date ${index ? index + 1 : ""}`,
                mentionuid: option.uid,
              })
            ),
          ];

          this.expirationDateArray = [
            ...expirationDateProvision.provisionOptions.map(
              (option, index) => ({
                id: index,
                value: `Expiration Date ${index ? index + 1 : ""}`,
                mentionuid: option.uid,
              })
            ),
          ];

          if (!this.hasLocalMention("term")) {
            this.leaseTermArray = [...this.leaseTermArray, newLeaseTermMention];
          }

          if (!this.hasLocalMention("commencementDate")) {
            this.commencementDateArray = [
              ...this.commencementDateArray,
              newCommencementDateMention,
            ];
          }

          if (!this.hasLocalMention("expirationDate")) {
            this.expirationDateArray = [
              ...this.expirationDateArray,
              newExpirationDateMention,
            ];
          }

          this.selectedLeaseTerm = this.leaseTermArray[0].id;
        }
      );
  }

  private getTermCalculatorDataV2(recordUid: string, provisionFormUid: string) {
    const data: TermCalculatorInputsApiV2DtoModel = {
      provisionFormUid,
      recordUid,
    };
    const body: OApiReqTermCalculatorInputsV2DtoModel = {
      data,
    };
    this.termService
      .retrieveTermInputsV2({ body })
      .subscribe((res: OApiRespTermCalculatorInputsResponseModel) => {
        const htmlNameToFullVal: any = res.data.htmlNameToFullVal;
        this.listItems = {
          expiryDate: [],
          commencementDate: [],
          leaseTerm: [],
        };

        // expiry date
        const expiryDateProvision = htmlNameToFullVal["expiryDate_LOCAL_DATE"];
        this.provisionsMap.expirationDate = expiryDateProvision;
        expiryDateProvision?.views?.forEach((item) => {
          if (
            item.provisionValue?.value &&
            item.provisionType === "LOCAL_DATE"
          ) {
            this.listItems.expiryDate.push({
              label: this.transformDate(
                DateConvertService.deserializeDate(
                  cloneDeep(item.provisionValue.value)
                )
              ),
              value: this.transformDate(
                DateConvertService.deserializeDate(
                  cloneDeep(item.provisionValue.value)
                )
              ),
            });
          }
        });
        this.listItems.expiryDate.push({
          label: "New",
          value: "New",
        });

        // commencement date
        const commencementDateProvision =
          htmlNameToFullVal["commencementDate_LOCAL_DATE"];
        this.provisionsMap.commencementDate = commencementDateProvision;
        commencementDateProvision?.views?.forEach((item) => {
          if (
            item.provisionValue?.value &&
            item.provisionType === "LOCAL_DATE"
          ) {
            this.listItems.commencementDate.push({
              label: this.transformDate(
                DateConvertService.deserializeDate(
                  cloneDeep(item.provisionValue.value)
                )
              ),
              value: this.transformDate(
                DateConvertService.deserializeDate(
                  cloneDeep(item.provisionValue.value)
                )
              ),
            });
          }
        });
        this.listItems.commencementDate.push({
          label: "New",
          value: "New",
        });

        // lease term
        const leaseTermProvision = htmlNameToFullVal["leaseTerm_DOUBLE"];
        this.provisionsMap.term = leaseTermProvision;
        leaseTermProvision?.views?.forEach((item) => {
          if (
            item?.provisionType === leaseTermProvision?.provisionType &&
            item?.provisionValue
          ) {
            this.listItems.leaseTerm.push({
              label: item.provisionValue.value + " " + item.provisionValue.unit,
              value: item.provisionValue.value,
              unit: item.provisionValue.unit.toUpperCase(),
            });
          }
        });
        this.listItems.leaseTerm.push({
          label: "New",
          value: "New",
        });
      });
  }

  private getTermCalculatorData(abstractUid: string, provisionFormUid: string) {
    this.calculatorService
      .getTermCalculatorData({ abstractUid, provisionFormUid })
      .subscribe((res: any) => {
        const htmlNameToFullVal: any = res.data.htmlNameToFullVal;
        this.listItems = {
          expiryDate: [],
          commencementDate: [],
          leaseTerm: [],
        };

        // expiry date
        const expiryDateProvision = htmlNameToFullVal["expiryDate"];
        this.provisionsMap.expirationDate = expiryDateProvision;
        expiryDateProvision?.views?.forEach((item) => {
          if (
            item.provisionValue?.value &&
            item.provisionType === "LOCAL_DATE"
          ) {
            this.listItems.expiryDate.push({
              label: this.transformDate(
                DateConvertService.deserializeDate(
                  cloneDeep(item.provisionValue.value)
                )
              ),
              value: this.transformDate(
                DateConvertService.deserializeDate(
                  cloneDeep(item.provisionValue.value)
                )
              ),
            });
          }
        });
        this.listItems.expiryDate.push({
          label: "New",
          value: "New",
        });

        // commencement date
        const commencementDateProvision = htmlNameToFullVal["commencementDate"];
        this.provisionsMap.commencementDate = commencementDateProvision;
        commencementDateProvision?.views?.forEach((item) => {
          if (
            item.provisionValue?.value &&
            item.provisionType === "LOCAL_DATE"
          ) {
            this.listItems.commencementDate.push({
              label: this.transformDate(
                DateConvertService.deserializeDate(
                  cloneDeep(item.provisionValue.value)
                )
              ),
              value: this.transformDate(
                DateConvertService.deserializeDate(
                  cloneDeep(item.provisionValue.value)
                )
              ),
            });
          }
        });
        this.listItems.commencementDate.push({
          label: "New",
          value: "New",
        });

        // lease term
        const leaseTermProvision = htmlNameToFullVal["leaseTerm"];
        this.provisionsMap.term = leaseTermProvision;
        leaseTermProvision?.views?.forEach((item) => {
          if (item.doubleValue) {
            this.listItems.leaseTerm.push({
              label: item.doubleValue.value + " " + item.doubleValue.unit,
              value: item.doubleValue.value,
              unit: item.doubleValue.unit,
            });
          }
        });
        this.listItems.leaseTerm.push({
          label: "New",
          value: "New",
        });
      });
  }

  toggleDatePicker(field: dateFields, formField: string) {
    this.selectedDate = field;
    if (formField === "commencementDate" || formField === "expirationDate") {
      this.datePickerValue = this.formData[formField].customValue;
    }
  }

  changeSelectedField(
    formField: "commencementDate" | "expirationDate" | "term"
  ) {
    this.selectedField = formField;
    this.calculatedItem.value = formField === "term" ? {} : undefined;
    this.calculatedItem.field = formField;
    this.formData[formField] = undefined;
  }

  onDismissModal() {
    this.bsModalRef.hide();
    this.onDismiss.next(this.modalRes);
  }

  onTermChange(evt: ChangeEventInterface, source: string) {
    if (source === "New") {
      if (evt.model && evt.model.dropdown && evt.model.dropdown.label) {
        const obj = this.formData.term;
        obj["customValue"] = evt.model;
        obj.label = evt.model.input + " " + evt.model.dropdown.label + " (New)";
        this.formData.term = { ...obj };
      }
    } else {
      if (evt.model.dropdown) {
        const { value } = evt.model.dropdown;

        if (evt.eventSource === EventSourceEnum.SELECT) {
          this.selectedUnit = value;
        }

        if (evt.eventSource === EventSourceEnum.SELECT && this.termResult) {
          this.calculatedItem.value.input = this.termResult[value];
          // if (this.selectedField === 'term') {
          //   this.calculatedItem.value.input = undefined;
          // }
        } else {
          this.calculatedItem.value.input = evt.model.input;
        }
      }
    }
  }

  onDateValueChange(evt, field: string, formField: string) {
    const { value } = evt;
    if (value === "New") {
      this.selectedDate = dateFields[field];
      if (field !== "TERM") {
        this.datePickerValue =
          this.formData[formField].customValue || new Date();
        this.formData[formField] = {
          value: "New",
          label: this.transformDate(this.datePickerValue) + " (New)",
        };
      } else {
        this.formData[formField] = { value: "New", label: "New" };
      }
    } else {
      if (formField === "term") {
        this.formData[formField] = {
          ...{ value: value, label: evt.label, unit: evt.unit },
        };
      } else {
        this.formData[formField] = {
          ...{ value: value, label: evt.label },
        };
      }
      this.selectedDate = undefined;
    }
  }

  private transformDate(date) {
    return this.datePipe.transform(date, "MMM-d-y");
  }

  onDateChange(date, formField: string) {
    const value = this.formData[formField] || {};
    value["customValue"] = date;
    value["label"] = this.transformDate(date) + " (New)";
    this.formData[formField] = { ...value };
  }

  private calculateCommencementDate(termValue: {
    unit: "MONTHS" | "DAYS" | "YEARS";
    value: number;
  }) {
    const expirationDate: Date =
      this.formData.expirationDate.value === "New"
        ? this.formData.expirationDate.customValue
        : new Date(cloneDeep(this.formData.expirationDate).value);

    this.termService
      .calculateTerm({
        body: {
          data: {
            calculatedField: "COMMENCE_DATE",
            expirationDate: DateConvertService.serializeDate(expirationDate),
            termNumber: termValue.value,
            termUnit: termValue.unit,
          },
        },
      })
      .subscribe((response) => {
        this.calculatedItem.value = DateUtil.deserializeSimpleDate(
          response.data.startDate
        );
        this.calculatedItem.field = "commencementDate";
      });
  }

  private calculateExpirationDate(termValue: {
    unit: "MONTHS" | "DAYS" | "YEARS";
    value: number;
  }) {
    const commencementDate =
      this.formData.commencementDate.value === "New"
        ? this.formData.commencementDate.customValue
        : new Date(cloneDeep(this.formData.commencementDate).value);

    this.termService
      .calculateTerm({
        body: {
          data: {
            calculatedField: "EXPIRY_DATE",
            commencementDate:
              DateConvertService.serializeDate(commencementDate),
            termNumber: termValue.value,
            termUnit: termValue.unit,
          },
        },
      })
      .subscribe((response) => {
        this.calculatedItem.value = DateUtil.deserializeSimpleDate(
          response.data.endDate
        );
        this.calculatedItem.field = "expirationDate";
      });
  }

  private calculateTerm() {
    const expirationDate =
      this.formData.expirationDate.value === "New"
        ? this.formData.expirationDate.customValue
        : new Date(cloneDeep(this.formData.expirationDate).value);
    const commencementDate: Date =
      this.formData.commencementDate.value === "New"
        ? this.formData.commencementDate.customValue
        : new Date(cloneDeep(this.formData.commencementDate).value);

    this.termService
      .calculateTerm({
        body: {
          data: {
            termUnit: this.selectedUnit,
            calculatedField: "TERM",
            commencementDate:
              DateConvertService.serializeDate(commencementDate),
            expirationDate: DateConvertService.serializeDate(expirationDate),
          },
        },
      })
      .subscribe((response) => {
        this.termResult = {
          [response.data.term.unit]: response.data.term.value,
        } as TermResultInterface;
        if (this.termResult[response.data.term.unit] <= 0) {
          this._notification.error(
            "Expiration Date should be after Commencement Date !"
          );
        } else {
          if (!this.calculatedItem.value.dropdown) {
            this.calculatedItem.value["input"] =
              this.termResult[response.data.term.unit];
            this.calculatedItem.value["dropdown"] = this.items[0];
          } else {
            this.calculatedItem.value["input"] =
              this.termResult[this.calculatedItem.value["dropdown"]["value"]];
          }
        }
      });
  }

  private getTermValue() {
    const obj: { unit: "MONTHS" | "DAYS" | "YEARS"; value: number } = {
      unit: "DAYS",
      value: 0,
    };
    if (this.formData.term.value === "New") {
      obj.unit =
        this.formData.term.customValue &&
        this.formData.term.customValue.dropdown &&
        this.formData.term.customValue.dropdown.value
          ? this.formData.term.customValue.dropdown.value
          : "DAYS";
      obj.value =
        this.formData.term.customValue && this.formData.term.customValue.input
          ? this.formData.term.customValue.input
          : 0;
    } else {
      obj.unit = this.formData.term.unit;
      obj.value = this.formData.term.value;
    }
    return obj;
  }

  onCalculateClick() {
    if (this.isValidForCalculation()) {
      this.selectedDate = undefined;
      if (this.selectedField === "term") {
        this.calculateTerm();
      } else if (this.selectedField === "commencementDate") {
        this.calculateCommencementDate(this.getTermValue());
      } else if (this.selectedField === "expirationDate") {
        this.calculateExpirationDate(this.getTermValue());
      }
    } else {
      this._notification.error("Please set value for two field");
    }
    const provision: any = this.provisionsMap[this.calculatedItem.field];
  }

  private hasLocalMention(
    field: "term" | "commencementDate" | "expirationDate"
  ): boolean {
    switch (field) {
      case "term":
        return this.leaseTermProvision.multiplePdfProvision.options.some(
          this.isLocalMention
        );
      case "commencementDate":
        return this.commencementDateProvision.multiplePdfProvision.options.some(
          this.isLocalMention
        );
      case "expirationDate":
        return this.expiryDateProvision.multiplePdfProvision.options.some(
          this.isLocalMention
        );
    }
  }

  private isLocalMention(mention: PartialValProvisionValueModel): boolean {
    return mention.source === ProvisionOptionSourceEnum.TEMP_LOCAL;
  }

  private isNewMention(mentionIndex: number): boolean {
    switch (this.selectedField) {
      case "term":
        return this.leaseTermArray?.[mentionIndex].value === "New";
      case "commencementDate":
        return this.commencementDateArray?.[mentionIndex].value === "New";
      case "expirationDate":
        return this.expirationDateArray?.[mentionIndex].value === "New";
    }
  }

  private importTerm(
    position: RelevantMentions,
    selectedMentionIndex: number,
    mentionName?: string
  ): void {
    let value: any = { unit: "", value: 0 };
    let format;
    if (this.calculatedItem.field === "term") {
      const { input, dropdown } = this.calculatedItem.value;
      value.unit = dropdown.value;
      value.value = input;
      format = PROVISIONS_DATA_CONST.DOUBLE.name;
    } else {
      value = {
        value: DateUtil.serializeToExactDate(this.calculatedItem.value),
      };
      format = PROVISIONS_DATA_CONST.LOCAL_DATE.name;
    }
    const provision: any = this.provisionsMap[this.calculatedItem.field];

    const body = {
      data: {
        docAbstractUid: this.selectedDocument.uid,
        page: this.currentPage,
        mentionName,
        provisionUid: provision.provisionUid,
        format,
        provisionValue: value,
        ...position,
      },
    };

    this.provisionMentionService
      .calculateCreateProvisionValueV2({
        recordUid: this.abstractUid,
        body,
      })
      .subscribe(({ data: { multiPdfProv, modifiedProvisionMentionUids } }) => {
        const provisionDetail = multiPdfProv;

        this.reloadProvisionData(
          provisionDetail as any,
          provision.provisionUid,
          selectedMentionIndex,
          () => {
            this.getTermCalculatorData(this.abstractUid, this.provisionFormUid);

            this.imported.next({
              type: CalculatorNamesEnum.TermCalculator,
              provisionUid: provision.provisionUid,
            });

            this.onDismissModal();
          }
        );

        this.calculatorUtilsService.updateViewAfterChangeInProvisions(
          provisionDetail,
          this.getSelectedProvision() as any,
          modifiedProvisionMentionUids?.newlyCreated?.[0],
          this.isNewMention(selectedMentionIndex)
            ? undefined
            : selectedMentionIndex
        );
      });
  }

  onImportClick() {
    const selectedProvision = this.getSelectedProvision();
    this.selectMentionConfirmModalService
      .openModalAndAskRelevantMentionUidToFillIn(selectedProvision)
      .subscribe((res) => {
        if (!res.isCanceled) {
          this.importTerm(
            res.relevantMentions,
            res.selectedMentionIndex === undefined
              ? selectedProvision.multiplePdfProvision.options.length
              : res.selectedMentionIndex,
            res?.mentionName
          );
        }
      });
  }

  private getSelectedProvision(): FullValMultiPdfProvModel {
    switch (this.selectedField) {
      case "commencementDate":
        return this.commencementDateProvision;
      case "expirationDate":
        return this.expiryDateProvision;
      default:
      case "term":
        return this.leaseTermProvision;
    }
  }

  public reloadProvisionData(
    provisionDetail: FullValMultiPdfProvModel,
    provisionUid: string,
    selectedMentionIndex: number,
    cb?
  ) {
    this.abstractReviewFacade.updateMentionWithMentionIndex(
      provisionUid,
      this.getOptionDetail(
        provisionDetail.multiplePdfProvision.options,
        selectedMentionIndex
      ),
      selectedMentionIndex
    );
    this.abstractReviewFacade
      .getSelectedAbstractProvisionByProvisionUid$(provisionUid)
      .pipe(distinct())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((provision) => {
        if (provision) {
          if (cb) {
            cb(provision);
          }
        }
      });
  }

  private getOptionDetail(
    options: PartialValPdfProvisionViewModel[],
    selectedMentionIndex: number
  ): PartialValPdfProvisionViewModel {
    let mentionIndex = selectedMentionIndex;
    const oldOptions =
      this.leaseTermProvision.multiplePdfProvision.options.slice(
        0,
        selectedMentionIndex
      );
    oldOptions.forEach((_option) => {
      if (_option.source === ProvisionOptionSourceEnum.TEMP_LOCAL)
        mentionIndex--;
    });
    return options[mentionIndex];
  }

  isValidForCalculation() {
    let formItemWithValue = 0;
    if (this.formData.term) {
      formItemWithValue++;
    }
    if (this.formData.commencementDate) {
      formItemWithValue++;
    }
    if (this.formData.expirationDate) {
      formItemWithValue++;
    }
    // const expirationDate = this.formData.expirationDate.value == 'New' ? this.formData.expirationDate.customValue : new Date(_.cloneDeep(this.formData.expirationDate).value);
    // const commencementDate = this.formData.commencementDate.value == 'New' ? this.formData.commencementDate.customValue : new Date(_.cloneDeep(this.formData.commencementDate).value);
    return formItemWithValue === 2;
    // && DateUtil.subtractDate(expirationDate, commencementDate, 'days') >= 1)
  }

  onResetClick() {
    this.formData = {
      commencementDate: undefined,
      expirationDate: undefined,
      term: undefined,
      termCustom: undefined,
    };
    this.termModel = {
      dropdown: undefined,
      input: undefined,
    };
    this.calculatedItem.value = this.selectedField === "term" ? {} : undefined;
    this.isCustomDate = {
      COMMENCEMENT_DATE: false,
      EXPIRATION_DATE: false,
    };
    this.selectedDate = undefined;
  }
}
