import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { RestClient } from "@@intelease/web/utils";

@Injectable()
export class HttpContextRemoverInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.params.has(RestClient.HTTP_CONTEXT_PARAM_KEY)) {
      return next.handle(
        req.clone({
          params: req.params.delete(RestClient.HTTP_CONTEXT_PARAM_KEY),
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
