import { NodeNavigationModel } from "@@intelease/web/common/models";
import { ReportLogicalOperatorEnum } from "@@intelease/web/intelease/constants";
import { TableColumnTypeEnum } from "@@intelease/app-models/common";

export interface User {
  uid: string;
  name: string;
  email: string;
}

export enum Permission {
  EXPORT = "EXPORT",
  FULL_EXPORT = "FULL_EXPORT",
  LOCK = "LOCK",
  EXPLORE = "EXPLORE",
  COMMENT = "COMMENT",
  EDIT = "EDIT",
  COMPLETE = "COMPLETE",
  MOVE = "MOVE",
  SHARE = "SHARE",
  READ = "READ",
  DELETE = "DELETE",
}

export enum RecordStatus {
  UI_DELETED = "UI_DELETED",
  PROCESSING_COMPLETED = "PROCESSING_COMPLETED",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  UPLOADING = "UPLOADING",
  PROCESSING = "PROCESSING",
}

export enum RecordType {
  RECORD = "RECORD",
  DIRECTORY = "DIRECTORY",
  DOC_SET = "DOC_SET",
}

export interface IRecord {
  createdAt: Date;
  lastModifiedDate: Date;
  name: string;
  owner: User | User["name"] /* overridden in facade */;
  permissions: Permission[];
  shared: boolean;
  size: number;
  status: RecordStatus;
  type: RecordType;
  uid: string;

  //types added on the fly
  docSetCategory?: DocSetCategory["value"];
  ownerUid?: User["uid"];
}

export interface Paginated<T> {
  pagination: {
    page: number;
    totalResults: number;
  };
  nodeNavigation?: NodeNavigationModel;
  items: T[];
}

export interface DocSetCategory {
  uiName: string;
  value: string;
}

export interface SelectedFilterModel {
  operator: ReportLogicalOperatorEnum;
  value: SelectedFilterValueModel[];
}

export interface SelectedFilterValueModel {
  field?: {
    allowedTypes?: { label: string; value: string }[];
    category?: string;
    docSetCategories?: string[];
    fieldName?: string;
    fieldType?: string;
    uiName?: string;
  };
  relation?: string;
  value?: any;
}

export interface SelectedColumnFieldsModel {
  name: string;
  prop: string;
  type: TableColumnTypeEnum;
  sortable: boolean;
}

export enum RecordCategory {
  favorite = "favorite",
  shared = "shared",
  owned = "owned",
  deleted = "deleted",
  all = "all",
}

export enum RecordCategoryUppercaseEnum {
  ALL = "ALL",
  DELETED = "DELETED",
  FAVORITES = "FAVORITES",
  OWNED = "OWNED",
  SHARED = "SHARED",
}
