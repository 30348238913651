import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";

export const DOC_SET_ACTION__VIEW: ActionButtonInterface = {
  title: "View",
  actionKey: "DOC_SET_VIEW",
  icon: "icon-take-me-there",
  children: [],
};
export const DOC_SET_ACTION__REVIEW: ActionButtonInterface = {
  title: "Review",
  actionKey: "DOC_SET_REVIEW",
  icon: "icon-take-me-there",
  children: [],
};
export const DOC_SET_ACTION__RENAME: ActionButtonInterface = {
  title: "Rename",
  actionKey: "RENAME",
  icon: "icon-rename",
  children: [],
};
export const DOC_SET_ACTION__ADD_TO_QUEUE: ActionButtonInterface = {
  title: "Add to Queue",
  actionKey: "ADD_TO_QUEUE",
  icon: "icon-move",
  children: [],
};
export const DOC_SET_ACTION__SHARE: ActionButtonInterface = {
  title: "Share",
  actionKey: "SHARE",
  icon: "icon-share",
  children: [],
};
export const DOC_SET_ACTION__DOWNLOAD: ActionButtonInterface = {
  title: "Download",
  actionKey: "DOWNLOAD",
  icon: "icon-download",
};
export const DOC_SET_ACTION__FULL_EXPORT: ActionButtonInterface = {
  title: "Full Export",
  actionKey: "FULL_EXPORT",
  icon: "icon-download",
};
export const DOC_SET_ACTION__MOVE: ActionButtonInterface = {
  title: "Move",
  actionKey: "MOVE",
  icon: "icon-move",
  children: [],
};

export const DOC_SET_ACTION__DELETE: ActionButtonInterface = {
  title: "Delete",
  actionKey: "DELETE",
  icon: "icon-remove",
  children: [],
};

export const DOC_SET_ACTION__MERGE: ActionButtonInterface = {
  title: "Merge",
  actionKey: "DOC_SET_MERGE",
  icon: "icon-take-me-there",
  children: [],
};

export const ALL_DOC_SET_ACTION_BUTTONS: ActionButtonInterface[] = [
  DOC_SET_ACTION__VIEW,
  DOC_SET_ACTION__REVIEW,
  DOC_SET_ACTION__RENAME,
  DOC_SET_ACTION__ADD_TO_QUEUE,
  DOC_SET_ACTION__SHARE,
  DOC_SET_ACTION__DOWNLOAD,
  DOC_SET_ACTION__MOVE,
  DOC_SET_ACTION__DELETE,
  DOC_SET_ACTION__FULL_EXPORT,
];

export const ALL_DOC_SET_ACTION_BUTTONS_KEYS: string[] =
  ALL_DOC_SET_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);

export const BATCH_DOC_SET_ACTION_BUTTONS: ActionButtonInterface[] = [
  DOC_SET_ACTION__SHARE,
  DOC_SET_ACTION__ADD_TO_QUEUE,
  DOC_SET_ACTION__MERGE,
  DOC_SET_ACTION__DOWNLOAD,
  DOC_SET_ACTION__MOVE,
  DOC_SET_ACTION__DELETE,
];

export const BATCH_DOC_SET_ACTION_BUTTONS_KEYS: string[] =
  BATCH_DOC_SET_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);

export const DELETE_DOC_SET_ACTION_BUTTONS: ActionButtonInterface[] = [
  DOC_SET_ACTION__DELETE,
  DOC_SET_ACTION__MERGE,
];

export const DELETE_DOC_SET_ACTION_BUTTONS_KEYS: string[] =
  DELETE_DOC_SET_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);

export const EXPORT_DOC_SET_ACTION_BUTTONS: ActionButtonInterface[] = [
  DOC_SET_ACTION__DOWNLOAD,
  DOC_SET_ACTION__FULL_EXPORT,
];

export const EXPORT_DOC_SET_ACTION_BUTTONS_KEYS: string[] =
  EXPORT_DOC_SET_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);
