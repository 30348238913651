export function downloadFile(
  fileName: string,
  res: any,
  contentType: string,
  cb: any
) {
  const fileUrl = (window.URL || window["webkitURL"]).createObjectURL(
    new Blob([res], { type: contentType })
  );
  const anchor: any = document.createElement("a");
  anchor.download = fileName;
  anchor.href = fileUrl;
  anchor.click();
  cb();
  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(fileUrl);
}
