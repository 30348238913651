import { NodeNavigationModel } from "@@intelease/web/common/models";
import { ReportLogicalOperatorEnum } from "@@intelease/web/intelease/constants";
import { TableColumnTypeEnum } from "@@intelease/app-models/common";
import { DocSetNodeDtoModel } from "@@intelease/api-models/adex-api-model-src";

export interface User {
  uid: string;
  name: string;
  email: string;
}

export enum Permission {
  EXPORT = "EXPORT",
  COMMENT = "COMMENT",
  EDIT = "EDIT",
  MOVE = "MOVE",
  SHARE = "SHARE",
  READ = "READ",
  DELETE = "DELETE",
}

export enum DriveNodeStatus {
  UI_DELETED = "UI_DELETED",
  PROCESSING_COMPLETED = "PROCESSING_COMPLETED",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  UPLOADING = "UPLOADING",
  PROCESSING = "PROCESSING",
}

export enum DriveNodeType {
  RECORD = "RECORD",

  DOC_SET = "DOC_SET",

  DIRECTORY = "DIRECTORY",
}

export interface IDriveNode {
  createdAt: Date;
  lastModifiedDate: Date;
  name: string;
  owner: User | User["name"] /* overridden in facade */;
  permissions: Permission[];
  shared: boolean;
  size: number;
  status: DriveNodeStatus;
  type: DriveNodeType;
  uid: string;

  //types added on the fly
  docSetCategory?: DocSetCategory["value"];
  ownerUid?: User["uid"];
  documentCount?: number;
  queueUid?: string;
  queueName?: string;
  queueStage?: string;
  recordUid?: string;
  googleDriveFileId?: string;
  viewer?: DocSetNodeDtoModel["viewer"];
}

export interface Paginated<T> {
  pagination: {
    page: number;
    totalResults: number;
  };
  nodeNavigation?: NodeNavigationModel;
  items: T[];
}

export interface DocSetCategory {
  uiName: string;
  value: string;
}

export interface SelectedFilterModel {
  operator: ReportLogicalOperatorEnum;
  value: SelectedFilterValueModel[];
}

export interface SelectedFilterValueModel {
  field?: {
    allowedTypes?: { label: string; value: string }[];
    category?: string;
    docSetCategories?: string[];
    fieldName?: string;
    fieldType?: string;
    uiName?: string;
  };
  relation?: string;
  value?: any;
}

export interface SelectedColumnFieldsModel {
  name: string;
  prop: string;
  type: TableColumnTypeEnum;
  sortable: boolean;
}

export enum DriveNodeCategory {
  favorite = "favorite",
  shared = "shared",
  owned = "owned",
  deleted = "deleted",
  all = "all",
  documents = "documents",
}

export enum DriveNodeCategoryUppercaseEnum {
  ALL = "ALL",
  DELETED = "DELETED",
  FAVORITES = "FAVORITES",
  OWNED = "OWNED",
  SHARED = "SHARED",
  DOCUMENTS = "DOCUMENTS",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
}
