<div class="toolbar">
  <button
    *ngIf="canAddFolder"
    mat-button
    color="primary"
    class="mr-2"
    (click)="onCreateNewFolderClicked()"
    matTooltip="Create folder"
    style="color: #2979ff; background-color: #f5f6f7; border-radius: 4px"
  >
    <mat-icon style="margin-top: -2px; margin-right: 11px; color: #2979ff"
      >add</mat-icon
    >
    <span>NewFolder</span>
  </button>
  <button
    mat-button
    disableRipple
    class="mr-2"
    *ngIf="!isShowingSearch"
    (click)="onShowSearchBarClicked()"
  >
    <div>
      <span>
        <itls-icon
          style="margin-top: -5px; margin-right: 6px"
          svgIcon="icon-search"
        ></itls-icon>
      </span>
      <span> Search </span>
    </div>
  </button>
  <div class="search-container" [hidden]="!isShowingSearch">
    <il-drive-search
      (searchItemClicked)="onSearchItemClicked($event)"
    ></il-drive-search>
  </div>
  <button
    matTooltip="Close search box"
    mat-icon-button
    *ngIf="isShowingSearch"
    class="mr-2"
    (click)="onCloseSearchBox()"
  >
    <itls-icon style="line-height: 0" svgIcon="icon-cancel"></itls-icon>
  </button>
</div>
<div class="itls-drive">
  <router-outlet></router-outlet>
</div>
