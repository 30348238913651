import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { ModalInputModel } from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { openInNewWindow } from "@@intelease/web/intelease/utils";
import { ComponentStateHooksInterface } from "@@intelease/app-models/common";
import { ENTITY_FORM_SCHEMA_CONST } from "@@intelease/web/intelease/constants";
import { BaseEntityPermissionComponent } from "@@intelease/web/common/components/entity-permissions/base-entity-permission.component";

@Component({
  template: "",
})
export class BaseEntityListComponent
  extends BaseEntityPermissionComponent
  implements ComponentStateHooksInterface, OnInit
{
  public renameFormTitle: string;
  public formSchema = ENTITY_FORM_SCHEMA_CONST;
  pagination: any = {
    totalResults: 0,
    page: 0,
    size: 20,
  };
  rows: any[] = [];
  loadingIndicator = false;
  actionMaps: { [key: string]: any };
  destroyRef = inject(DestroyRef);
  columns: any[];
  rowMenu: any[];
  rowBatchMenu: any[];
  selectedItems = [];
  formModalService;
  commonModalService;

  constructor() {
    super();
  }

  public static onOpenInNewTAbClick(url: string): void {
    openInNewWindow(url);
  }

  ngOnInit(): void {
    this.initListeners();
  }

  initActions(): void {
    //
  }

  initListeners(): void {
    //
  }

  private openEditModal(
    selectedRow: { name: string; description: string; uid: string },
    selectedItem?
  ): void {
    let saveButtonTooltip;
    const modalData = new ModalInputModel();
    const {
      name: originalName,
      description: originalDescription,
      uid,
    } = selectedRow;
    modalData.mode = ComponentModeEnum.EDIT;
    modalData.payload = {
      editTitle: this.renameFormTitle,
      formSchema: { ...this.formSchema },
      model: { name: originalName, description: originalDescription },
    };
    switch (modalData.payload.editTitle) {
      case "Edit Report":
        saveButtonTooltip = {
          emptyField: "You must enter a report name!",
          notValidField: "No change detected in the report name!",
          firstMessage: "No change detected in the report name!",
        };
        break;
      case "Edit Project":
        saveButtonTooltip = {
          emptyField: "You must enter a project name!",
          notValidField: "No change detected in the project name!",
          firstMessage: "No change detected in the project name!",
        };
        break;
      case "Edit Form":
        saveButtonTooltip = {
          emptyField: "You must enter a provision form name!",
          notValidField: "No change detected in the provision form name!",
          firstMessage: "No change detected in the provision form name!",
        };
        break;

      default:
        break;
    }
    const modalRef = this.formModalService.openNewFormModal(
      modalData,
      {
        minWidth: 340,
      },
      saveButtonTooltip
    );
    modalRef.afterClosed().subscribe((res) => {
      if (res && res.data) {
        const { name, description } = res.data;
        if (name) {
          this.onRenameModalClose({ name, description, uid }, selectedItem);
        }
      }
    });
  }

  protected onRenameModalClose(
    modalRes: { name: string; description: string; uid: string },
    selectedItem?
  ): void {
    //
  }

  protected onRenameClick(
    selectedRow: { name: string; description: string; uid: string },
    selectedItem?
  ): void {
    this.openEditModal(selectedRow, selectedItem);
  }

  onRowMenuItemClick(evt: { menu: any; row: any }): void {
    const { key } = evt.menu;
    this.actionMaps[key](evt.row);
  }

  protected onRowClick(evt): void {
    //
  }

  protected onRemoveClick(evt): void {
    //
  }

  protected onBatchRemoveClick(evt): void {
    //
  }

  protected onAddClick(): void {
    //
  }

  protected showDeleteDialog(cb: () => void): void {
    const modalData: ModalInputModel = new ModalInputModel();
    this.commonModalService
      .openDeleteConfirmModal(modalData)
      .afterClosed()
      .subscribe((res) => {
        const { type } = res;
        if (type === ModalsResponseTypeEnum.CLOSE) {
          cb();
        }
      });
  }
}
