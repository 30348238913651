import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Observable } from "rxjs";
import { DriveFacade } from "@intelease/app-state/drive-v2";
import { ReportFilterFieldsGroupModel } from "@@intelease/app-models/reports/src";
import { FormControl } from "@angular/forms";
import { filter, first, map, startWith } from "rxjs/operators";
import { ReportsService } from "@@intelease/app-services/reports/src";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { ProvisionTypeEnum } from "@@intelease/app-models/provision";
import { TableColumnTypeEnum } from "@@intelease/app-models/common/src";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "il-advanced-search-columns",
  templateUrl: "./itls-advanced-search-columns.component.html",
  styleUrls: ["./itls-advanced-search-columns.component.scss"],
})
export class AdvancedSearchColumnsComponent implements OnInit {
  recordColumnControl = new FormControl();
  filterFieldGroups: Observable<ReportFilterFieldsGroupModel[]>;
  filterFields: {
    fieldName: string;
    fieldType: string;
    uiName: string;
    category: string;
    docSetCategories: string[];
  }[] = [];
  selectedFilterFields: {
    fieldName: string;
    uiName: string;
    fieldType: TableColumnTypeEnum;
    docSetCategories: string;
    allowedTypes: {
      label: string;
      value: string;
    }[];
  }[] = [];
  notSortableColumnsName = [];

  provisionTypeEnum = ProvisionTypeEnum;

  @Output() cancelAdvancedSearch = new EventEmitter();
  @Output() applyAdvancedSearch = new EventEmitter();

  @ViewChild("searchInput", { static: false })
  searchInput: ElementRef<HTMLInputElement>;

  constructor(
    private destroyRef: DestroyRef,
    public driveFacade: DriveFacade,
    private reportsService: ReportsService,
    private cdr: ChangeDetectorRef
  ) {
    this.initListeners();
  }

  ngOnInit() {
    this.filterFieldGroups = this.recordColumnControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    this.initAction();
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    const filteredFields = this.filterFields.filter((option) =>
      option.uiName.toLowerCase().includes(filterValue)
    );
    return this.reportsService.convertToFilterFieldsGroups(filteredFields);
  }

  initAction(): void {
    this.driveFacade.isAdvancedSearchActive$
      .pipe(
        filter((value) => !value),
        first()
      )
      .subscribe(() => {
        this.selectedFilterFields = [];
      });

    this.driveFacade.getCurrentSelectedColumnFields$
      .pipe(first())
      .subscribe((selectedColumnFields) => {
        const _columnFields = selectedColumnFields.map((item) => ({
          fieldName: item.prop,
          uiName: item.name,
          fieldType: item.type,
          docSetCategories: [ReportsService.GENERAL_DOC_SET_CATEGORY__VALUE],
          allowedTypes: [
            {
              label: PROVISIONS_DATA_CONST[item.type].uiName,
              value: item.type,
            },
          ],
        }));
        this.selectedFilterFields = [];
        _columnFields.forEach((item) => {
          this.onFilterFieldOptionSelected(item);
        });
      });
  }

  initListeners(): void {
    this.driveFacade.advancedSearchPossibleColumns$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((advancedSearchPossibleColumns) => {
        if (advancedSearchPossibleColumns) {
          this.filterFields = advancedSearchPossibleColumns;
        }
      });
  }

  private setCurrentSelectedColumnFields() {
    this.driveFacade.setCurrentSelectedColumnFields(
      this.selectedFilterFields.map((item) => {
        return this.notSortableColumnsName.includes(item.fieldName)
          ? {
              name: item.uiName,
              prop: item.fieldName,
              type: item.fieldType,
              sortable: false,
            }
          : {
              name: item.uiName,
              prop: item.fieldName,
              type: item.fieldType,
              sortable: true,
            };
      })
    );
  }

  onFilterFieldOptionSelected(field) {
    this.selectedFilterFields.push(field);
    this.setCurrentSelectedColumnFields();
    setTimeout(() => {
      this.searchInput.nativeElement.blur();
      this.recordColumnControl.setValue("");
      this.cdr.detectChanges();
    });
  }

  onRemoveFilterFields(index: number) {
    this.selectedFilterFields.splice(index, 1);
    this.setCurrentSelectedColumnFields();
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.selectedFilterFields,
      event.previousIndex,
      event.currentIndex
    );
    this.setCurrentSelectedColumnFields();
  }

  onApplyBtnClick() {
    this.applyAdvancedSearch.emit();
  }

  onCancelBtnClick() {
    this.cancelAdvancedSearch.emit();
  }
}
