<div fxLayout="column">
  <div
    *ngIf="mergedDocSets?.length"
    style="font-size: 12px; margin-bottom: 10px"
  >
    Merging
    <strong>{{ getSourceDocSetCount(mergedDocSets) }}</strong>
    {{
      getSourceDocSetCount(mergedDocSets) > 1 ? "document sets" : "document set"
    }}
    ( with
    <strong>{{ getDocumentCounts(mergedDocSets) }}</strong>
    {{ getDocumentCounts(mergedDocSets) > 1 ? "documents" : "document" }})
  </div>
  <div
    fxLayout="column"
    [style.max-height]="'290px'"
    [style.overflow-y]="'auto'"
  >
    <intelease-doc-set-doc-data-item
      *ngFor="let mergedDocSet of mergedDocSets; let i = index"
      [verticalView]="verticalView"
      [mergedDocSet]="mergedDocSet"
      (allFilesRemoved)="onAllFilesRemoved(mergedDocSet)"
    ></intelease-doc-set-doc-data-item>
  </div>
</div>
