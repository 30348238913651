/* tslint:disable */
/* eslint-disable */
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { DateConvertService } from "@@intelease/web/common/services/date-convert.service";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { NotificationValueConvertService } from "@@intelease/web/common/services/notification-value-convert.service";
import { UserDtoModel } from "@@intelease/api-models/adex-api-model-src";

@JsonObject("RecordNodeDtoModel2")
export class RecordNodeDtoModel {
  @JsonProperty("createdAt", DateTimeConvertService, true)
  createdAt?: Date | undefined = undefined;

  @JsonProperty("creationDate", DateTimeConvertService, true)
  creationDate?: Date | undefined = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService, true)
  lastModifiedDate?: Date | undefined = undefined;

  @JsonProperty("name", String, true)
  name?: string = "";

  @JsonProperty("docSetCategory", String, true)
  docSetCategory?: string = "";

  @JsonProperty("owner", UserDtoModel, true)
  owner?: UserDtoModel = undefined;

  @JsonProperty("permissions", Any, true)
  permissions?: Array<
    "READ" | "EDIT" | "DELETE" | "EXPORT" | "MOVE" | "COMMENT" | "SHARE"
  > = [];

  @JsonProperty("shared", Boolean, true)
  shared?: boolean = undefined;

  @JsonProperty("size", Number, true)
  size?: number = undefined;

  @JsonProperty("type", Any, true)
  type?: "ACCOUNT" | "DIRECTORY" | "DOC_SET" | "ROLE" | "USER" = undefined;

  @JsonProperty("uid", String, true)
  uid?: string = "";

  @JsonProperty("customTags", Any, true)
  customTags?: any = [];
}
