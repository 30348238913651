import { Component, OnInit } from "@angular/core";

import { ControlWidget } from "../../widget";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

@Component({
  selector: "sf-string-widget",
  template: `
    <input
      *ngIf="this.schema.widget.id === 'hidden'; else notHiddenFieldBlock"
      [attr.name]="name"
      type="hidden"
      [formControl]="control"
    />
    <ng-template #notHiddenFieldBlock>
      <div class="widget form-group">
        <label
          *ngIf="schema.title"
          [attr.for]="id"
          class="horizontal control-label"
        >
          {{ schema.title }}
        </label>
        <span *ngIf="schema.description" class="formHelp">{{
          schema.description
        }}</span>
        <input
          *ngIf="schema.mask"
          [name]="name"
          [attr.readonly]="
            schema.widget.id !== 'color' && schema.readOnly ? true : null
          "
          class="text-widget.id textline-widget form-control form-widget"
          [attr.type]="
            !this.schema.widget.id || this.schema.widget.id === 'string'
              ? 'text'
              : this.schema.widget.id
          "
          [attr.id]="id"
          [formControl]="control"
          [attr.placeholder]="schema.placeholder"
          [attr.maxLength]="schema.maxLength || null"
          [attr.minLength]="schema.minLength || null"
          [attr.required]="schema.isRequired || null"
          [attr.disabled]="
            schema.widget.id === 'color' && schema.readOnly ? true : null
          "
        />
        <input
          [itlsFocusIn]="schema.autoFocus"
          *ngIf="!schema.mask"
          [name]="name"
          [attr.readonly]="
            schema.widget.id !== 'color' && schema.readOnly ? true : null
          "
          class="text-widget.id textline-widget form-control form-widget"
          [attr.type]="
            !this.schema.widget.id || this.schema.widget.id === 'string'
              ? 'text'
              : this.schema.widget.id
          "
          [attr.id]="id"
          [formControl]="control"
          [attr.placeholder]="schema.placeholder"
          [attr.maxLength]="schema.maxLength || null"
          [attr.minLength]="schema.minLength || null"
          [attr.required]="schema.isRequired || null"
          [attr.disabled]="
            schema.widget.id === 'color' && schema.readOnly ? true : null
          "
        />
        <input
          *ngIf="schema.widget.id === 'color' && schema.readOnly"
          [attr.name]="name"
          type="hidden"
          [formControl]="control"
        />
      </div>
    </ng-template>
  `,
})
export class StringWidgetComponent extends ControlWidget implements OnInit {
  mask: any;
  getInputType() {
    if (!this.schema.widget.id || this.schema.widget.id === "string") {
      return "text";
    } else {
      return this.schema.widget.id;
    }
  }

  ngOnInit() {
    if (this.schema.mask) {
      switch (this.schema.mask) {
        case "NUMBER_WITH_DECIMAL":
          this.mask = createNumberMask({
            prefix: "",
            allowDecimal: true,
            allowNegative: true,
          });
          break;
        default:
          break;
      }
    }
  }
}
