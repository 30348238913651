import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Inject,
  Injector,
  Input,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import {
  InteleaseNotificationService,
  UserInfoService,
} from "@@intelease/web/intelease/services";
import { combineLatest, Observable, of } from "rxjs";
import { finalize } from "rxjs/operators";
import { NewShareEntityService } from "@@intelease/web/ui/src/lib/new-share-entity/services/new-share-entity.service";
import { PermissionTypeGroupEnum } from "@@intelease/web/ui/src/lib/new-share-entity/models/permission-type-group.enum";
import { ActorModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor.model";
import { DomainsService } from "@@intelease/web/ui/src/lib/new-share-entity/services/domains.service";
import { isEqual, cloneDeep, remove } from "lodash";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ComponentModeEnum } from "@@intelease/web/intelease/enums";
import { REGULAR_EXPRESSION_CONST } from "@@intelease/web/utils";
import { EntityShareModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/entity-share.model";
import { EntityActorsPermissionsModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/entity-actors-permissions.model";
import { ActorPermissionModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor-permission.model";
import { ActorShareModel } from "@@intelease/web/ui/src/lib/new-share-entity/models/actor-share.model";
import { DriveFacade as DriveFacadeV2 } from "@@intelease/app-state/drive-v2/src";
import { DriveFacade } from "@@intelease/app-state/drive/src";
import { environment } from "apps/ui/src/environments/environment";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

interface EnrichedPermissionTypeGroup {
  value: PermissionTypeGroupEnum | "VARIES" | "VARIES_DISABLED";
  title: string;
  titleDescription: string;
  description: string;
}

const VARIES_ENRICHED_PERMISSION_TYPE_GROUP: EnrichedPermissionTypeGroup = {
  title: "Varies",
  value: "VARIES",
  description: "",
  titleDescription: "",
};

const VARIES_DISABLED_ENRICHED_PERMISSION_TYPE_GROUP: EnrichedPermissionTypeGroup =
  {
    title: "Varies",
    value: "VARIES_DISABLED",
    description: "",
    titleDescription: "",
  };

class ActorWithPermissionGroupModel {
  actor: ActorModel;
  permissionGroup: EnrichedPermissionTypeGroup;

  constructor(actor: ActorModel, permissionGroup: EnrichedPermissionTypeGroup) {
    this.actor = actor;
    this.permissionGroup = permissionGroup;
  }
}

@Component({
  selector: "il-new-share-entity",
  templateUrl: "./new-share-entity.component.html",
  styleUrls: ["./new-share-entity.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewShareEntityComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  isLoadingActors: boolean;
  isLoadingPermissions: boolean;
  isCallingShareApi: boolean;
  searchInput = "";
  /**
   * all actors which we can share with
   */
  actors: ActorModel[] = [];
  /**
   * actors we shared with before making any changes
   */
  originalSelectedActors: ActorWithPermissionGroupModel[] = [];
  /**
   * actors we shared with after changes
   */
  selectedActors: ActorWithPermissionGroupModel[] = [];
  @Input() newShareEntityService: NewShareEntityService;
  @Input() withShareableLink = false;
  @Input() entity: EntityShareModel;
  @Input() entities: EntityShareModel[];
  @Output() shared: EventEmitter<boolean> = new EventEmitter<boolean>();
  private readonly matDialogRef: MatDialogRef<NewShareEntityComponent>;
  isDialogMode: boolean;
  PermissionTypeGroupEnum = PermissionTypeGroupEnum;
  componentMode = ComponentModeEnum.EDIT;
  ComponentModeEnum = ComponentModeEnum;
  addedActors: ActorModel[] = [];
  /**
   * tracks changes to existing permissions for User / Team
   */
  pendingChanges = false;
  addActorsPermissionTypeGroup: EnrichedPermissionTypeGroup;
  userSelectablePermissionTypeGroups: EnrichedPermissionTypeGroup[];
  permissionTypeGroups: EnrichedPermissionTypeGroup[];
  shareWithEmail: string;

  driveFacade: DriveFacade | DriveFacadeV2;

  constructor(
    private cdr: ChangeDetectorRef,
    private domainsService: DomainsService,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private injector: Injector,
    private inteleaseNotificationService: InteleaseNotificationService,
    private driveFacadeV1: DriveFacade,
    private driveFacadeV2: DriveFacadeV2
  ) {
    this.driveFacade = environment ? this.driveFacadeV2 : this.driveFacadeV1;
    this.matDialogRef = injector.get(MatDialogRef, null);
    const { allowedPermissionTypeGroups } = this.initialDataInDialogMode();
    this.initPermissionTypeGroups(allowedPermissionTypeGroups);
  }

  ngOnInit(): void {
    this.initActorsAndPermissions();
  }

  private initActorsAndPermissions() {
    this.isLoadingActors = true;
    this.isLoadingPermissions = true;
    combineLatest([
      this.domainsService.getActors(),
      this.newShareEntityService.getPermissions(this.getEntities()),
    ])
      .pipe(
        finalize(() => {
          this.isLoadingActors = false;
          this.isLoadingPermissions = false;
          this.cdr.detectChanges();
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([actors, entityPermissions]) => {
        this.originalSelectedActors =
          this.createActorPermissions(entityPermissions);
        this.selectedActors = cloneDeep(this.originalSelectedActors);

        const currentUserUid = UserInfoService.getUserUid();
        this.actors = actors.filter((actor) => actor.uid !== currentUserUid);

        setTimeout(() => {
          cloneDeep(this.createActorPermissions(entityPermissions)).forEach(
            (item) => {
              this.actors = this.actors.filter(
                (actor) => actor.uid !== item.actor.uid
              );
            }
          );
        });

        if (this.shareWithEmail) {
          const foundActor = this.actors.find(
            (item) => item?.email === this.shareWithEmail
          );
          if (foundActor) {
            this.addedActors = [foundActor];
          } else {
            this.addedActors = [
              new ActorModel({
                email: this.shareWithEmail,
                type: "UNREGISTERED_USER",
                name: this.shareWithEmail,
                uid: this.shareWithEmail,
              }),
            ];
          }
          this.onActorSelectionChanged(this.addedActors);
          this.shareWithEmail = undefined;
        }
      });
  }

  onCancelClick() {
    if (this.isDialogMode) {
      this.matDialogRef.close(false);
    }
  }

  onShareClick() {
    if (this.pendingChanges) {
      this.updateChangesToExistingPermissions();
    } else {
      this.saveNewAddedActors();
    }
  }

  private updateChangesToExistingPermissions() {
    const selectedActorsUids = this.selectedActors.map(
      (item) => item.actor.uid
    );
    // those that are not in selectedActors but are within originalActors are deleted
    const deletedActors = this.originalSelectedActors.filter(
      (item) =>
        item.permissionGroup.value !== PermissionTypeGroupEnum.OWNER &&
        !selectedActorsUids.includes(item.actor.uid)
    );
    const changedActors = this.selectedActors.filter((item) => {
      const origActor = this.originalSelectedActors.find(
        (origItem) => origItem.actor.uid === item.actor.uid
      );
      return !isEqual(item, origActor);
    });
    this.isCallingShareApi = true;
    const shareSubscription = changedActors.length
      ? this.shareSingleOrMulti(
          changedActors.map((item) => ({
            domainUid: item.actor.uid,
            domainType: item.actor.type,
            permissionTypeGroup: item.permissionGroup.value,
          }))
        )
      : of({});
    shareSubscription.subscribe({
      next: (shareResp) => {
        const unshareSubscription = deletedActors.length
          ? this.newShareEntityService.removeShared(
              this.getEntities(),
              deletedActors.map((item) => item.actor)
            )
          : of({});
        unshareSubscription
          .pipe(
            finalize(() => {
              this.isCallingShareApi = false;
              this.cdr.detectChanges();
            })
          )
          .subscribe((unshareResp) => {
            changedActors.length
              ? this.getEntities().forEach((item) => {
                  this.driveFacade.updateShareRecordWithUid(
                    item.entityUid,
                    true
                  );
                })
              : deletedActors.length &&
                this.getEntities().forEach((item) => {
                  if (
                    this.originalSelectedActors.length - 1 ===
                    deletedActors.length
                  )
                    this.driveFacade.updateShareRecordWithUid(
                      item.entityUid,
                      false
                    );
                });
            this.originalSelectedActors = cloneDeep(changedActors);
            this.shared.emit(true);
            if (this.isDialogMode) {
              this.matDialogRef.close(true);
            }
          });
      },
      error: (error) => {
        this.isCallingShareApi = false;
        this.cdr.detectChanges();
      },
    });
  }

  removeFromShared(removedActor: ActorWithPermissionGroupModel) {
    remove(
      this.selectedActors,
      (item) => item.actor.uid === removedActor.actor.uid
    );
    this.checkForPendingChanges();
    this.cdr.detectChanges();
  }

  private initialDataInDialogMode(): {
    allowedPermissionTypeGroups: PermissionTypeGroupEnum[];
  } {
    const result = {
      allowedPermissionTypeGroups: null,
    };
    this.isDialogMode = this.matDialogRef !== null;
    if (this.isDialogMode) {
      this.newShareEntityService = this.dialogData.shareEntityService;
      this.entity = this.dialogData.entity;
      this.entities = this.dialogData.entities;
      this.shareWithEmail = this.dialogData.shareWithEmail;
      result.allowedPermissionTypeGroups =
        this.dialogData?.allowedPermissionTypeGroups;
    }
    return result;
  }

  onActorSelectionChanged(actors: any[]) {
    if (actors.length) {
      this.componentMode = ComponentModeEnum.ADD;
      const newAddedActor = actors[actors.length - 1];
      if (newAddedActor.type == null) {
        newAddedActor.type = "UNREGISTERED_USER";
        newAddedActor.uid = newAddedActor.name;
        newAddedActor.email = newAddedActor.name;
      }
    } else {
      this.componentMode = ComponentModeEnum.EDIT;
    }
  }

  onChangePermissionTypeGroupForSelectedActor(
    selectedActor: ActorWithPermissionGroupModel,
    otherPermissionTypeGroup: EnrichedPermissionTypeGroup
  ) {
    selectedActor.permissionGroup = otherPermissionTypeGroup;
    this.checkForPendingChanges();
    this.cdr.detectChanges();
  }

  private checkForPendingChanges() {
    this.pendingChanges = !isEqual(
      this.selectedActors,
      this.originalSelectedActors
    );
  }

  private saveNewAddedActors() {
    if (!this.addedActors.length) {
      this.shared.emit(false);
      if (this.isDialogMode) {
        this.matDialogRef.close(false);
      }
      return;
    }
    const addedActors = this.addedActors.filter(
      (item) =>
        !this.originalSelectedActors.find(
          (origActor) =>
            origActor.actor.uid === item.uid ||
            // in case it is 'shareWithEmail' we don't have 'uid', so also check for 'email'
            origActor.actor?.email === item?.email
        )
    );
    if (addedActors.length) {
      const invalidAddedActors = addedActors.filter(
        (addedActor) =>
          addedActor.type === "UNREGISTERED_USER" &&
          !REGULAR_EXPRESSION_CONST.EMAIL.test(addedActor.name)
      );
      if (invalidAddedActors.length) {
        this.inteleaseNotificationService.openSnackBar(
          "Sorry, could not understand the email address starting at " +
            invalidAddedActors.map((item) => `"${item.name}"`).join(", ")
        );
        this.cdr.detectChanges();
        return;
      }
      const addedActorsPermissionTypeGroup = this.addActorsPermissionTypeGroup;
      this.isCallingShareApi = true;
      this.shareSingleOrMulti(
        addedActors.map((item) => ({
          domainUid: item.uid,
          domainType: item.type,
          permissionTypeGroup: addedActorsPermissionTypeGroup.value,
        }))
      )
        .pipe(
          finalize(() => {
            this.isCallingShareApi = false;
            this.cdr.detectChanges();
          })
        )
        .subscribe((shareResp) => {
          this.getEntities().forEach((item) => {
            this.driveFacade.updateShareRecordWithUid(item.entityUid, true);
          });
          this.shared.emit(true);
          if (this.isDialogMode) {
            this.matDialogRef.close(true);
          }
        });
    } else {
      this.shared.emit(false);
      if (this.isDialogMode) {
        this.matDialogRef.close(false);
      }
    }
  }

  private getEntities(): EntityShareModel[] {
    return this.entity ? [this.entity] : this.entities;
  }

  private findEnrichedPermissionTypeGroup(
    permissionTypeGroup: PermissionTypeGroupEnum
  ): EnrichedPermissionTypeGroup {
    return this.permissionTypeGroups.find(
      (item) => item.value === permissionTypeGroup
    );
  }

  private initPermissionTypeGroups(
    allowedPermissionTypeGroups?: PermissionTypeGroupEnum[]
  ) {
    this.permissionTypeGroups = PermissionTypeGroupEnum.getValues().map(
      (permissionTypeGroup) => ({
        value: permissionTypeGroup,
        title: PermissionTypeGroupEnum.getTitle(permissionTypeGroup),
        titleDescription:
          PermissionTypeGroupEnum.getTitleDescription(permissionTypeGroup),
        description:
          PermissionTypeGroupEnum.getDescription(permissionTypeGroup),
      })
    );
    let selectablePermissionTypeGroups =
      PermissionTypeGroupEnum.getUserSelectableValues();
    if (allowedPermissionTypeGroups) {
      selectablePermissionTypeGroups = selectablePermissionTypeGroups.filter(
        (item) => allowedPermissionTypeGroups.includes(item)
      );
    }
    this.userSelectablePermissionTypeGroups =
      selectablePermissionTypeGroups.map((permissionTypeGroup) => ({
        value: permissionTypeGroup,
        title: PermissionTypeGroupEnum.getTitle(permissionTypeGroup),
        titleDescription:
          PermissionTypeGroupEnum.getTitleDescription(permissionTypeGroup),
        description:
          PermissionTypeGroupEnum.getDescription(permissionTypeGroup),
      }));

    this.addActorsPermissionTypeGroup = this.findEnrichedPermissionTypeGroup(
      PermissionTypeGroupEnum.VIEWER
    );
  }

  /**
   *  we're trying to aggregate permissions of each actor for each entity,
   *      if permission WRITER exists for actor 1 for both entities of A and B then we show:
   *          Actor 1 with permission WRITER
   *     if permission WRITER exists for actor 2 for entity A then we show:
   *          Actor 2 with permission VARIES
   *     if permission READER exists for actor 3 for entity A and permission WRITER exists for actor 3 for entity B then we show:
   *          Actor 3 with permission VARIES
   *     if permission OWNER exists for actor 4 for entity A and permission READER exists for actor 4 for entity B then we show:
   *          Actor 4 with permission VARIES (user is NOT able to change it)
   *     if permission OWNER exists for actor 5 for entity A then we show:
   *          Actor 5 with permission VARIES (user is NOT able to change it)
   */
  private createActorPermissions(
    entityPermissions: EntityActorsPermissionsModel[]
  ): ActorWithPermissionGroupModel[] {
    // group permissions by actors
    //TODO(reza) apply sorting so we have consistent list each time e.g: based on actor names
    const actorIdToPermissions = new Map<string, ActorPermissionModel[]>();
    for (const entityPermission of entityPermissions) {
      for (const permission of entityPermission.permissions) {
        let actorPermissions = actorIdToPermissions.get(permission.actor.uid);
        if (!actorPermissions) {
          actorPermissions = [];
          actorIdToPermissions.set(permission.actor.uid, actorPermissions);
        }
        actorPermissions.push(permission);
      }
    }

    const actorWithPermissionGroupModel: ActorWithPermissionGroupModel[] = [];
    for (const actorPermissions of actorIdToPermissions.values()) {
      if (
        actorPermissions.length === entityPermissions.length &&
        new Set(actorPermissions.map((item) => item.permissionTypeGroup))
          .size === 1
      ) {
        // we have actor permission for each entity and all PermissionTypeGroups are equal
        actorWithPermissionGroupModel.push({
          actor: actorPermissions[0].actor,
          permissionGroup: this.findEnrichedPermissionTypeGroup(
            PermissionTypeGroupEnum[actorPermissions[0].permissionTypeGroup]
          ),
        });
      } else {
        if (
          actorPermissions.every(
            (item) => item.permissionTypeGroup !== PermissionTypeGroupEnum.OWNER
          )
        ) {
          actorWithPermissionGroupModel.push({
            actor: actorPermissions[0].actor,
            permissionGroup: VARIES_ENRICHED_PERMISSION_TYPE_GROUP,
          });
        } else {
          // even if user is OWNER of just 1 entity, we don't allow changing PermissionTypeGroup
          actorWithPermissionGroupModel.push({
            actor: actorPermissions[0].actor,
            permissionGroup: VARIES_DISABLED_ENRICHED_PERMISSION_TYPE_GROUP,
          });
        }
      }
    }
    return actorWithPermissionGroupModel;
  }

  private shareSingleOrMulti(
    actorsToShareWith: ActorShareModel[]
  ): Observable<any> {
    return this.entity
      ? this.newShareEntityService.share(this.entity, actorsToShareWith)
      : this.newShareEntityService.shareMulti(this.entities, actorsToShareWith);
  }
}
