import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";

export const DRIVE_SEARCH_CONST = {
  columns: [
    {
      name: "Name",
      prop: "name",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Status",
      prop: "status",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Owner",
      prop: "owner",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Last Modified",
      prop: "lastModifiedDate",
      type: PROVISIONS_DATA_CONST.DATE.name,
      sortable: true,
    },
  ],
  fixedColumns: [
    { name: "checkbox", prop: "checkbox", type: "CHECKBOX" },
    { name: "", prop: "action", sortable: false, type: "ACTION" },
  ],
};
