import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { DriveFacade } from "@intelease/app-state/drive-v2";
import { ReportLogicalOperatorEnum } from "@intelease/constants";
import { cloneDeep } from "lodash";
import { filter, first } from "rxjs/operators";
import { isEqual } from "lodash";

@Component({
  selector: "il-advanced-search-filters",
  templateUrl: "./itls-advanced-search-filters.component.html",
  styleUrls: ["./itls-advanced-search-filters.component.scss"],
})
export class AdvancedSearchFiltersComponent implements OnInit {
  subscriptions: Subscription = new Subscription();

  selectedFilter: {
    operator: ReportLogicalOperatorEnum;
    value: any[];
  } = {
    operator: ReportLogicalOperatorEnum.AND,
    value: [],
  };
  filterFields: {
    fieldName: string;
    fieldType: string;
    uiName: string;
    category: "GENERAL" | "PROVISION";
    docSetCategories: string[];
  }[] = [];

  @Output() nextStep = new EventEmitter();
  @Output() cancelAdvancedSearch = new EventEmitter();

  constructor(public driveFacade: DriveFacade) {}

  ngOnInit() {
    this.initActions();
  }

  initActions() {
    this.subscriptions.add(
      this.driveFacade.getAdvancedSearchFieldsList$.subscribe(
        (advancedSearchFields) => {
          if (advancedSearchFields) {
            this.filterFields = advancedSearchFields;
          }
        }
      )
    );

    this.driveFacade.getSelectedFilter$
      .pipe(
        filter(
          (advancedSearchFilterModel) =>
            !isEqual(advancedSearchFilterModel, {
              operator: ReportLogicalOperatorEnum.AND,
              value: [],
            })
        ),
        first()
      )
      .subscribe((advancedSearchFilterModel) => {
        this.selectedFilter = cloneDeep(advancedSearchFilterModel);
      });
  }

  onFilterModelChange(evt) {
    this.driveFacade.setSelectedFilter(cloneDeep(evt));
  }

  onNextBtnClick() {
    this.nextStep.emit();
  }

  onCancelBtnClick() {
    this.cancelAdvancedSearch.emit();
  }
}
