import { Component, Inject, OnInit } from "@angular/core";
import {
  ModalInputModel,
  ModalResponseModel,
  ServerResponseModel,
} from "@@intelease/web/intelease/models";
import {
  ComponentModeEnum,
  ModalsResponseTypeEnum,
} from "@@intelease/web/intelease/enums";
import { InputValidationService } from "@@intelease/web/intelease/services/input-validation.service";
import { UserInfoService } from "@@intelease/web/intelease/services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { UploadingDocSetBoxService } from "@@intelease/web/ui/src/lib/itls-new-upload";
import { map } from "rxjs/operators";
import { Json2TypescriptHelper } from "@@intelease/web/intelease/utils";
import { MinimalProvisionFormViewModel } from "@@intelease/api-models/adex-api-model-src";
import { Router } from "@angular/router";

@Component({
  selector: "itls-import-tsv-form-modal",
  templateUrl: "./import-tsv-form-modal.component.html",
  styleUrls: [],
})
export class ImportTsvFormModalComponent implements OnInit {
  modalRes: ModalResponseModel = new ModalResponseModel();
  name = "";
  description = "";
  selectedDocumentType: string;
  files: File[] = [];
  isValidForm = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModalInputModel,
    private httpClient: HttpClient,
    private router: Router,
    public dialogRef: MatDialogRef<ImportTsvFormModalComponent>
  ) {}

  ngOnInit() {
    this.name = this.dialogData.payload.name;
    this.description = this.dialogData.payload.description;
    this.selectedDocumentType = this.dialogData.payload.docSetCategory;
  }

  onImportClick() {
    if (this.files && this.files.length === 1) {
      const formData = new FormData();
      formData.append(
        "files",
        this.files[0],
        encodeURIComponent(this.files[0].name)
      );
      formData.set("name", this.name);
      formData.set("description", this.description);
      formData.set("docSetCategory", this.selectedDocumentType);

      return this.httpClient
        .post<ServerResponseModel>(
          "/v1/provision-forms/import-new-form",
          formData
        )
        .pipe(
          map((resp) => {
            return Json2TypescriptHelper.convertToEntity(
              resp.data,
              MinimalProvisionFormViewModel
            );
          })
        )
        .subscribe((res) => {
          this.dialogRef.close({
            ...this.modalRes,
            type: ModalsResponseTypeEnum.CLOSE,
          });
          this.router.navigate([`/individual-form/${res.uid}`]);
        });
    }
  }

  onFileChange(event) {
    this.files = event.target.files;
    if (this.files && this.files.length === 1) {
      this.isValidForm = true;
    }
  }

  onDismissModalClick() {
    this.dialogRef.close({
      ...this.modalRes,
      type: ModalsResponseTypeEnum.DISMISS,
    });
  }
}
