import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";
import { QueueFeatureService } from "@@intelease/web/intelease/utils";
import { environment } from "apps/ui/src/environments/environment";

const FOLDER_TABLE_TEMP = {
  defaultColumns: [
    {
      name: "Name",
      prop: "name",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Status",
      prop: "status",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Owner",
      prop: "owner",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Queue",
      prop: "queueName",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: false,
    },
    {
      name: "Queue Stage",
      prop: "queueStage",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: false,
    },
    {
      name: "Last Modified",
      prop: "lastModifiedDate",
      type: PROVISIONS_DATA_CONST.DATE.name,
      sortable: true,
    },
  ],

  ownedColumns: [
    {
      name: "Name",
      prop: "name",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Status",
      prop: "status",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Queue",
      prop: "queueName",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: false,
    },
    {
      name: "Queue Stage",
      prop: "queueStage",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: false,
    },
    {
      name: "Created At",
      prop: "createdAt",
      type: PROVISIONS_DATA_CONST.DATE.name,
      sortable: true,
    },
    {
      name: "Last Modified Date",
      prop: "lastModifiedDate",
      type: PROVISIONS_DATA_CONST.DATE.name,
      sortable: true,
    },
  ],
};

const queueFeatureService = new QueueFeatureService();

if (queueFeatureService.isFeatureEnabled()) {
  FOLDER_TABLE_TEMP.defaultColumns = FOLDER_TABLE_TEMP.defaultColumns.filter(
    (item) => item.prop !== "status" && item.prop !== "lastModifiedDate"
  );
  FOLDER_TABLE_TEMP.defaultColumns.push({
    name: "Created At",
    prop: "createdAt",
    type: PROVISIONS_DATA_CONST.DATE.name,
    sortable: true,
  });
  FOLDER_TABLE_TEMP.ownedColumns = FOLDER_TABLE_TEMP.ownedColumns.filter(
    (item) => item.prop !== "status" && item.prop !== "lastModifiedDate"
  );
}

export const FOLDER_TABLE = FOLDER_TABLE_TEMP;
