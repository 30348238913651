import { PROVISIONS_DATA_CONST } from "@@intelease/web/common/enums/provision-data.const";

export const FOLDER_TABLE = {
  defaultColumns: [
    {
      name: "Name",
      prop: "name",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Status",
      prop: "status",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Owner",
      prop: "owner",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Last Modified",
      prop: "lastModifiedDate",
      type: PROVISIONS_DATA_CONST.DATE.name,
      sortable: true,
    },
  ],

  ownedColumns: [
    {
      name: "Name",
      prop: "name",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Status",
      prop: "status",
      type: PROVISIONS_DATA_CONST.STRING.name,
      sortable: true,
    },
    {
      name: "Created At",
      prop: "createdAt",
      type: PROVISIONS_DATA_CONST.DATE.name,
      sortable: true,
    },
    {
      name: "Last Modified Date",
      prop: "lastModifiedDate",
      type: PROVISIONS_DATA_CONST.DATE.name,
      sortable: true,
    },
  ],
};
