<div fxLayout="column" style="height: 100%">
  <div class="select-files-title-container">
    <div
      style="width: 24px; height: 24px; margin-right: 10px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <itls-icon svgIcon="icon-documents"> </itls-icon>
    </div>
    <div>Merge multiple Document Sets</div>
  </div>
  <mat-accordion
    displayMode="flat"
    id="selectDocumentFilesModalAccordion"
    [class]="
      mergedDocSets?.length
        ? 'selectFilesModalAccordionWithFiles'
        : 'selectFilesModalAccordionWithoutFiles'
    "
  >
    <mat-expansion-panel
      #selectFilesPanel
      expanded="true"
      (opened)="onPanelOpened(PanelView.SELECT_FILES)"
      (closed)="onPanelClosed(PanelView.SELECT_FILES)"
      [ngClass]="
        selectedView === PanelView.SELECT_FILES
          ? 'panel-opened'
          : 'panel-closed'
      "
    >
      <mat-expansion-panel-header
        [class.disabled-pointer]="!mergedDocSets?.length"
      >
        <mat-panel-title> Select Documents </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div *ngIf="isLoaded">
          <intelease-select-files
            #selectFilesFromDocSetsComponent
            [mergedDocSets]="mergedDocSets"
            (modelChanged)="onModelChanged($event)"
          ></intelease-select-files>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel
      #selectDestFolderPanel
      class="select-dest-folder-panel"
      style="border-top: solid 1px #dfe1e6 !important"
      (opened)="onPanelOpened(PanelView.SELECT_DEST_FOLDER)"
      (closed)="onPanelClosed(PanelView.SELECT_DEST_FOLDER)"
      [ngClass]="
        selectedView === PanelView.SELECT_DEST_FOLDER
          ? 'panel-opened'
          : 'panel-closed'
      "
    >
      <mat-expansion-panel-header
        [class.disabled-pointer]="!mergedDocSets?.length || !selectedFolderUid"
      >
        <mat-panel-title> Select destination folder </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <il-drive-tree
          [contentMaxHeight]="346"
          [selectedNodeUid]="selectedFolderUid"
          (nodeClicked)="selectedFolderUid = $event.id"
          [showContextMenu]="true"
        ></il-drive-tree>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel
      #selectQueuePanel
      class="select-dest-folder-panel"
      style="border-top: solid 1px #dfe1e6 !important"
      (opened)="onPanelOpened(PanelView.SELECT_QUEUE)"
      (closed)="onPanelClosed(PanelView.SELECT_QUEUE)"
      [ngClass]="
        selectedView === PanelView.SELECT_QUEUE
          ? 'panel-opened'
          : 'panel-closed'
      "
    >
      <mat-expansion-panel-header
        [class.disabled-pointer]="!mergedDocSets?.length || !selectedFolderUid"
      >
        <mat-panel-title>
          Select queue
          {{ (isQueueRequired$ | async) ? "" : "(Optional)" }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <il-choose-queue-dropdown
          [clearable]="true"
          (select)="onSelectQueue($event)"
        >
        </il-choose-queue-dropdown>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

  <div
    *ngIf="mergedDocSets?.length"
    class="action-btn-container"
    fxLayout="row"
    fxLayoutAlign="end"
  >
    <button
      *ngIf="selectedView !== PanelView.SELECT_FILES"
      [disabled]="isUploading"
      translate
      type="button"
      class="btn btn-default upload-action-btn back-btn"
      (click)="onBackClicked()"
    >
      <span> Back </span>
    </button>
    <button
      type="button"
      *ngIf="selectedView !== PanelView.SELECT_QUEUE"
      class="btn btn-primary upload-action-btn next-btn"
      (click)="onNextClicked()"
    >
      <div>
        <span> Next </span>
        <span>
          <itls-icon svgIcon="icon-next"></itls-icon>
        </span>
      </div>
    </button>
    <button
      type="button"
      *ngIf="selectedView === PanelView.SELECT_QUEUE"
      [disabled]="
        isUploading ||
        !this.selectedFolderUid ||
        !this.mergedDocSets?.length ||
        ((isQueueRequired$ | async) ? !this.selectedQueueUid : false)
      "
      class="btn btn-primary upload-action-btn"
      (click)="onMerge()"
    >
      Merge
      <i class="fa fa-spinner fa-spin" *ngIf="isUploading"></i>
    </button>
  </div>
</div>
