import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItlsModifyCustomTagComponent } from "./itls-modify-custom-tag.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { InteleaseCommonModule } from "@@intelease/web/common";
import { FormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { CommonDirectivesModule } from "@@intelease/web/common/directives";

const NGX_BOOTSTRAP = [ModalModule.forRoot()];

const MATERIAL_MODULES = [MatDialogModule, MatButtonModule];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommonDirectivesModule,
    InteleaseCommonModule,
    ...NGX_BOOTSTRAP,
    ...MATERIAL_MODULES,
  ],
  declarations: [ItlsModifyCustomTagComponent],
  exports: [ItlsModifyCustomTagComponent],
})
export class ItlsModifyCustomTagModule {}
