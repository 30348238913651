import { Any, JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConvertService } from "@@intelease/web/common/services/date-time-convert.service";
import { OwnerModel } from "@@intelease/web/common/models/drive/owner-model";

@JsonObject("FullNodeModel")
export class FullNodeModel {
  static view = "full";

  @JsonProperty("uid", String)
  uid = "";

  @JsonProperty("name", String)
  name = "";

  @JsonProperty("status", String, true)
  status = "";

  @JsonProperty("owner", OwnerModel)
  owner: OwnerModel = undefined;

  @JsonProperty("lastModifiedDate", DateTimeConvertService)
  lastModifiedDate: Date = undefined;

  @JsonProperty("createdAt", DateTimeConvertService)
  createdAt: Date = undefined;

  @JsonProperty("size", Number)
  size = 0;

  /**
   * Possible values:
   *  "DELETE",
   *  "COMMENT",
   *  "MOVE",
   *  "EXPORT",
   *  "EDIT",
   *  "READ"
   */
  @JsonProperty("permissions", [String])
  permissions: (
    | "SHARE"
    | "DELETE"
    | "COMMENT"
    | "MOVE"
    | "EXPORT"
    | "FULL_EXPORT"
    | "EXPLORE"
    | "LOCK"
    | "EDIT"
    | "COMPLETE"
    | "READ"
  )[] = [];

  /**
   * Possible values:
   *  "RECORD",
   *  "DIRECTORY"
   */
  @JsonProperty("type", String)
  type: "RECORD" | "DIRECTORY" | undefined = undefined;

  @JsonProperty("shared", Boolean, true)
  shared = false;
}
