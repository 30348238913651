import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";

export const RECORD_ACTION__OPEN_IN_NEW_TAB: ActionButtonInterface = {
  title: "Open in New Tab",
  actionKey: "OPEN_IN_NEW_TAB",
  icon: "icon-export",
};
export const RECORD_ACTION__ASSIGN: ActionButtonInterface = {
  title: "Assign",
  actionKey: "ASSIGN",
  icon: "icon-assigned-to-me",
  children: [],
};
