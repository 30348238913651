import { RecordsAction, RecordsActionTypes } from "./drive.actions";
import { cloneDeep, findIndex, last, slice } from "lodash";
import {
  SelectedFilterModel,
  SelectedColumnFieldsModel,
  IRecord,
  Paginated,
  RecordType,
} from "../models/record.types";
import { ReportLogicalOperatorEnum } from "@@intelease/web/intelease/constants";

export const RECORDS_FEATURE_KEY = "drive";
/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface DriveState {
  records?: Paginated<IRecord>;
  isLoadAllRecordsLoading?: boolean;
  loadAllRecordsSucceeded?: boolean;
  loadAllRecordsError?: boolean;

  advancedSearchFields?: any;
  advancedSearchFieldsLoaded?: boolean;
  advancedSearchFieldsLoadError?: any;
  advancedSearchPossibleColumns?: any;
  advancedSearchLoaded?: boolean;
  isAdvancedSearchActive?: boolean;

  provisionsOperator?: any;
  fieldTypeToOperationAggregations?: any;

  sortRecordsLoading?: boolean;
  sortRecordsFailed?: any;

  selectedColumnFields: {
    applied: SelectedColumnFieldsModel[];
    current: SelectedColumnFieldsModel[];
  };

  selectedFilter: {
    applied?: SelectedFilterModel;
    current?: SelectedFilterModel;
  };
}

export interface RecordsPartialState {
  readonly [RECORDS_FEATURE_KEY]: DriveState;
}

export const initialState: DriveState = {
  records: {
    items: [],
    pagination: {
      totalResults: 0,
      page: 1,
    },
  },
  isLoadAllRecordsLoading: true,
  loadAllRecordsSucceeded: false,
  advancedSearchFields: {},
  advancedSearchFieldsLoaded: false,
  advancedSearchLoaded: false,
  advancedSearchFieldsLoadError: undefined,
  isAdvancedSearchActive: false,
  selectedColumnFields: {
    applied: [],
    current: [],
  },
  selectedFilter: {
    applied: {
      operator: ReportLogicalOperatorEnum.AND,
      value: [],
    },
    current: {
      operator: ReportLogicalOperatorEnum.AND,
      value: [],
    },
  },
};

export function reducer(
  state: DriveState = initialState,
  action: RecordsAction
): DriveState {
  switch (action.type) {
    case RecordsActionTypes.LoadAdvancedSearchFields: {
      state = {
        ...state,
        advancedSearchFieldsLoaded: false,
        advancedSearchFieldsLoadError: undefined,
      };
      break;
    }

    case RecordsActionTypes.AdvancedSearchFieldsLoaded: {
      const { columnFields, filterFields, fieldTypeToRelations } =
        action.payload;
      state = {
        ...state,
        advancedSearchFields: {
          generalFields: filterFields,
        },
        advancedSearchPossibleColumns: columnFields,
        provisionsOperator: fieldTypeToRelations,
        advancedSearchFieldsLoaded: true,
        advancedSearchFieldsLoadError: undefined,
      };
      break;
    }

    case RecordsActionTypes.AdvancedSearchFieldsError: {
      state = {
        ...state,
        advancedSearchFieldsLoadError: action.payload,
      };
      break;
    }

    case RecordsActionTypes.LoadAdvancedSearchRecords: {
      state = {
        ...state,
        advancedSearchLoaded: false,
        isAdvancedSearchActive: true,
        isLoadAllRecordsLoading: true,
      };
      break;
    }

    case RecordsActionTypes.AdvancedSearchRecordsLoaded: {
      const { items, pagination } = action.payload;
      state = {
        ...state,
        records: {
          ...state.records,
          items,
          pagination,
        },
        advancedSearchLoaded: true,
        isAdvancedSearchActive: true,
        isLoadAllRecordsLoading: false,
      };
      break;
    }

    case RecordsActionTypes.AdvancedSearchRecordsError: {
      state = {
        ...state,
        isAdvancedSearchActive: false,
        isLoadAllRecordsLoading: false,
        loadAllRecordsSucceeded: false,
        loadAllRecordsError: true,
        selectedFilter: {
          applied: {
            operator: ReportLogicalOperatorEnum.AND,
            value: [],
          },
          current: {
            operator: ReportLogicalOperatorEnum.AND,
            value: [],
          },
        },
      };
      break;
    }

    case RecordsActionTypes.ChangePage: {
      state = {
        ...state,
      };
      break;
    }

    case RecordsActionTypes.ChangePageSucceeded: {
      state = {
        ...state,
        records: {
          ...state?.records,
          items: state.records?.items,
          pagination: {
            ...state.records?.pagination,
            page: action.payload,
          },
        } as Paginated<IRecord>,
      };
      break;
    }

    case RecordsActionTypes.ChangePageError: {
      state = {
        ...state,
      };
      break;
    }

    case RecordsActionTypes.LoadAllRecords: {
      state = {
        ...state,
        records: undefined,
        isLoadAllRecordsLoading: true,
        loadAllRecordsSucceeded: false,
        loadAllRecordsError: undefined,
      };
      break;
    }
    case RecordsActionTypes.LoadAllRecordsSucceeded: {
      state = {
        ...state,
        records: action.payload,
        isLoadAllRecordsLoading: false,
        loadAllRecordsSucceeded: true,
        loadAllRecordsError: undefined,
        isAdvancedSearchActive: false,
      };
      break;
    }

    case RecordsActionTypes.LoadAllRecordsFailed: {
      state = {
        ...state,
        records: undefined,
        isLoadAllRecordsLoading: false,
        loadAllRecordsSucceeded: false,
        loadAllRecordsError: true,
      };
      break;
    }

    case RecordsActionTypes.SortsRecords: {
      state = {
        ...state,
        sortRecordsLoading: true,
        sortRecordsFailed: false,
        isLoadAllRecordsLoading: true,
      };
      break;
    }

    case RecordsActionTypes.SortsRecordsSucceeded: {
      const { items } = action.payload;
      state = {
        ...state,
        records: {
          ...(state.records as Paginated<IRecord>),
          items: items as IRecord[],
        },
        sortRecordsLoading: false,
        sortRecordsFailed: false,
        isLoadAllRecordsLoading: false,
      };
      break;
    }

    case RecordsActionTypes.SortsRecordsError: {
      state = {
        ...state,
        sortRecordsFailed: true,
        sortRecordsLoading: false,
        loadAllRecordsSucceeded: false,
        loadAllRecordsError: true,
      };
      break;
    }

    case RecordsActionTypes.UpdateRecordStatus: {
      state = {
        ...state,
      };
      break;
    }

    case RecordsActionTypes.UpdateRecordStatusSucceeded: {
      state = {
        ...state,
        records: {
          ...state.records,
          items: state.records?.items.map((item: IRecord) => {
            if (item.uid === action.payload.recordUid) {
              return {
                ...item,
                status: action.payload.status,
              };
            } else {
              return item;
            }
          }),
          pagination: state.records?.pagination,
        } as Paginated<IRecord>,
      };
      break;
    }

    case RecordsActionTypes.UpdateRecordStatusFailed: {
      state = {
        ...state,
      };
      break;
    }

    case RecordsActionTypes.AddRecord: {
      state = {
        ...state,
      };
      break;
    }

    case RecordsActionTypes.AddRecordSucceeded: {
      const { category, directoryUid, data } = action.payload;
      const _state: DriveState = cloneDeep(state);
      const _directoryUid = last(
        _state.records?.nodeNavigation?.navigations
      )?.uid;
      const _category = _state.records?.nodeNavigation?.category;

      if (category === _category && directoryUid === _directoryUid) {
        const _index = findIndex(
          _state.records?.items,
          (item: any) => item.type === "RECORD"
        );
        const _items =
          _index && _index >= 0
            ? [
                ...(slice(_state.records?.items, 0, _index) as IRecord[]),
                data,
                ...(slice(_state.records?.items, _index) as IRecord[]),
              ]
            : [...(_state.records?.items as IRecord[]), data];
        state = {
          ...state,
          records: {
            ...state.records,
            pagination: {
              ...state.records?.pagination,
              totalResults:
                (state.records?.pagination?.totalResults || 0) <= 20
                  ? (state.records?.pagination?.totalResults || 0) + 1
                  : state.records?.pagination?.totalResults,
            },
            items: _items.slice(0, 20),
          } as Paginated<IRecord>,
        };
      } else {
        state = { ...state };
      }
      break;
    }

    case RecordsActionTypes.AddRecordFailed: {
      state = {
        ...state,
      };
      break;
    }

    case RecordsActionTypes.AddDirectory: {
      state = {
        ...state,
      };
      break;
    }

    case RecordsActionTypes.AddDirectorySucceeded: {
      const { category, directoryUid, data } = action.payload;
      const _state: DriveState = cloneDeep(state);
      const _directoryUid = last(
        _state.records?.nodeNavigation?.navigations
      )?.uid;
      const _category = _state.records?.nodeNavigation?.category;

      if (category === _category && directoryUid === _directoryUid) {
        const _items = _state.records?.items
          ? [data, ..._state.records?.items]
          : [data];
        state = {
          ...state,
          records: {
            ...state.records,
            pagination: {
              ...state.records?.pagination,
              totalResults:
                (state.records?.pagination?.totalResults || 0) <= 20
                  ? (state.records?.pagination?.totalResults || 0) + 1
                  : state.records?.pagination?.totalResults,
            },
            items: _items.slice(0, 20),
          } as Paginated<IRecord>,
        };
      } else {
        state = { ...state };
      }
      break;
    }

    case RecordsActionTypes.AddDirectoryFailed: {
      state = {
        ...state,
      };
      break;
    }

    case RecordsActionTypes.SetSelectedFilter: {
      state = {
        ...state,
        selectedFilter: {
          ...state.selectedFilter,
          current: action.payload,
        },
      };
      break;
    }

    case RecordsActionTypes.CancelAdvancedSearch: {
      state = {
        ...state,
        selectedFilter: {
          ...state.selectedFilter,
          current: state.selectedFilter?.applied,
        },
        selectedColumnFields: {
          ...state.selectedColumnFields,
          current: state.selectedColumnFields?.applied,
        },
      };
      break;
    }

    case RecordsActionTypes.ApplyAdvancedSearch: {
      state = {
        ...state,
        selectedColumnFields: {
          ...state.selectedColumnFields,
          applied: state.selectedColumnFields.current,
        },
        selectedFilter: {
          ...state.selectedFilter,
          applied: state.selectedFilter?.current,
        },
      };
      break;
    }

    case RecordsActionTypes.SetSelectedColumnFields: {
      state = {
        ...state,
        selectedColumnFields: {
          applied: action.payload,
          current: action.payload,
        },
      };
      break;
    }

    case RecordsActionTypes.CurrentSelectedColumnFields: {
      state = {
        ...state,
        selectedColumnFields: {
          ...state.selectedColumnFields,
          current: action.payload,
        },
      };
      break;
    }

    case RecordsActionTypes.CloseAdvancedSearch: {
      state = {
        ...state,
        isAdvancedSearchActive: false,
        selectedFilter: {
          applied: {
            operator: ReportLogicalOperatorEnum.AND,
            value: [],
          },
          current: {
            operator: ReportLogicalOperatorEnum.AND,
            value: [],
          },
        },
      };
      break;
    }

    case RecordsActionTypes.RenameRecordOrDirectory: {
      const {
        row: { uid },
        newName,
      } = action.payload;
      const _records = {
        ...state.records,
        items: state.records?.items.map((item: IRecord) => {
          if (uid !== item.uid) return item;
          return {
            ...item,
            name: newName,
          };
        }),
      };
      state = {
        ...state,
        records: _records as Paginated<IRecord>,
      };
      break;
    }

    case RecordsActionTypes.RenameRecordOrDirectorySucceeded: {
      const { lastModifiedDate, name, uid } = action.payload;
      const _items =
        state.records?.items
          .map((item: IRecord) => {
            if (item.uid !== uid) return item;
            return {
              ...item,
              lastModifiedDate,
              name,
            } as IRecord;
          })
          .sort((a: IRecord, b: IRecord) => {
            if (
              !a?.lastModifiedDate?.getTime() ||
              !b?.lastModifiedDate?.getTime()
            )
              return 0;
            return b.lastModifiedDate.getTime() - a.lastModifiedDate.getTime();
          }) || [];
      state = {
        ...state,
        records: {
          ...state.records,
          items: [
            ..._items.filter((item) => item.type === RecordType.DIRECTORY),
            ..._items.filter(
              (item) =>
                item.type === RecordType.RECORD ||
                item.type === RecordType.DOC_SET
            ),
          ],
        } as Paginated<IRecord>,
        sortRecordsFailed: !state.sortRecordsLoading,
      };
      break;
    }

    case RecordsActionTypes.RenameRecordOrDirectoryError: {
      const { oldName, uid } = action.payload;
      const _records = {
        ...state.records,
        items: state.records?.items.map((item) => {
          if (item.uid !== uid) return item;
          return {
            ...item,
            name: oldName,
          };
        }),
      };
      state = {
        ...state,
        records: _records as Paginated<IRecord>,
      };
      break;
    }

    case RecordsActionTypes.UpdateCustomTagsSucceeded: {
      state = {
        ...state,
        records: {
          ...state.records,
          items: state.records?.items.map((_item: any) =>
            _item.map((item: any) => {
              if (item.uid === action.payload.recordUid)
                return {
                  ...item,
                  customTags: action.payload.body.data?.customTags,
                };
              else return item;
            })
          ),
        } as Paginated<IRecord>,
      };
      break;
    }

    case RecordsActionTypes.UpdateShareRecordWithUid: {
      state = {
        ...state,
        records: {
          ...state.records,
          items: state.records?.items.map((item) => {
            if (action.recordUid === item.uid)
              return {
                ...item,
                shared: action.shared,
              };
            return item;
          }),
        } as Paginated<IRecord>,
      };
      break;
    }
  }
  return state;
}
