import { Component } from "@angular/core";

import { ControlWidget } from "../../widget";

@Component({
  selector: "sf-integer-widget",
  template: `
    <div class="widget form-group">
      <label
        *ngIf="schema.title"
        [attr.for]="id"
        class="horizontal control-label"
      >
        {{ schema.title }}
      </label>
      <span *ngIf="schema.description" class="formHelp">{{
        schema.description
      }}</span>
      <input
        [attr.readonly]="schema.readOnly ? true : null"
        [name]="name"
        class="text-widget integer-widget form-control form-widget"
        [formControl]="control"
        [attr.type]="'number'"
        [attr.min]="schema.minimum"
        [attr.max]="schema.maximum"
        [attr.placeholder]="schema.placeholder"
        [attr.maxLength]="schema.maxLength || null"
        [attr.minLength]="schema.minLength || null"
        (focus)="onFocus($event, schema.modelKey)"
      />
    </div>
  `,
})
export class IntegerWidgetComponent extends ControlWidget {
  onFocus(ev: FocusEvent, modelKey: string) {
    ev.preventDefault();
    this.schema.onTouched && this.schema.onTouched(modelKey);
  }
}
