import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

const animationDuration = 200;

export const SEARCH_BAR_ANIMATION = [
  trigger("expand", [
    state(
      "void",
      style({
        width: "80px",
      })
    ),
    transition(
      ":enter",
      [
        animate(
          "{{animationDuration}}ms ease-in-out",
          style({
            width: "400px",
          })
        ),
      ],
      { params: { animationDuration: animationDuration } }
    ),
    transition(
      ":leave",
      [
        animate(
          "{{animationDuration}}ms ease-in-out",
          style({
            width: "80px",
          })
        ),
      ],
      { params: { animationDuration: animationDuration } }
    ),
  ]),
  trigger("showDelay", [
    state(
      "void",
      style({
        display: "none",
      })
    ),
    state(
      "*",
      style({
        display: "block",
      })
    ),
    transition("void => *", animate("{{animationDuration}}ms"), {
      params: { animationDuration: animationDuration },
    }),
  ]),
];
