<div class="bottom-container" #resizable id="bottom-table-container">
  <div #fullBody id="full-body"></div>
  <div class="drag-icon" id="resizer" (mousedown)="mouseDown()">
    <mat-icon id="resize">keyboard_arrow_down</mat-icon>
  </div>
  <div class="table-container" id="bottom-sheet-container">
    <il-table-detail
      #tableDetailComponent
      [tableDetail]="tableDetail"
      [readOnly]="readOnly"
      [createModel]="data.createDataModel"
      (bottomTableDismiss)="onBottomTableDismiss($event)"
    ></il-table-detail>
    <!-- <il-table-detail
            [tableDetail]="tableDetail"
            (reduceHeight)="reduceHeight()"
            (bottomTableDismiss)="onBottomTableDismiss($event)"
        ></il-table-detail> -->
  </div>
</div>
