import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ItlsDriveComponent } from "@@intelease/web/ui/src/lib/itls-drive-v2/drive/itls-drive.component";
import { ItlsFolderComponent } from "@@intelease/web/ui/src/lib/itls-drive-v2/folders/itls-folder.component";
import { ItlsDriveSearchResultsComponent } from "@@intelease/web/ui/src/lib/itls-drive-v2/search-results/itls-drive-search-results.component";
import { AppAuthorities } from "@@intelease/web/intelease/constants";
import { PermissionsGuard } from "@@intelease/web/intelease/guards";

const routes: Routes = [
  {
    path: "",
    component: ItlsDriveComponent,
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: [AppAuthorities.READ_RECORD],
        redirectTo: "/miscellaneous/not-found",
      },
    },
    children: [
      {
        path: "search",
        component: ItlsDriveSearchResultsComponent,
        canActivate: [PermissionsGuard],
        data: {
          breadcrumbs: [
            {
              key: "DRIVE_SEARCH",
            },
          ],
          permissions: {
            only: [AppAuthorities.READ_RECORD],
            redirectTo: "/miscellaneous/not-found",
          },
        },
      },
      {
        path: ":category",
        component: ItlsFolderComponent,
        canActivate: [PermissionsGuard],
        data: {
          breadcrumbs: [
            {
              routeParamsKey: ":category",
              keys: {
                owned: "DRIVE_OWNED",
                shared: "DRIVE_SHARED",
                favorite: "DRIVE_FAVORITE",
                deleted: "DRIVE_DELETED",
                all: "DRIVE_ALL",
                documents: "DRIVE_DOCUMENTS",
                google_drive: "GOOGLE_DRIVE",
              },
            },
          ],
          permissions: {
            only: [AppAuthorities.READ_RECORD],
            redirectTo: "/miscellaneous/not-found",
          },
        },
      },
      {
        path: "folders/:folderUid",
        component: ItlsFolderComponent,
        canActivate: [PermissionsGuard],
        data: {
          permissions: {
            only: [AppAuthorities.READ_RECORD],
            redirectTo: "/miscellaneous/not-found",
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ItlsDriveV2RoutingModule {}
