<div
  class="share-entity"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  [style.minWidth]="isDialogMode ? '350px' : '280px'"
>
  <div class="header" fxLayout fxLayoutAlign="space-between center">
    <div>
      <mat-icon svgIcon="icon-share" class="mr-2"></mat-icon>
      <span class="title">Share</span>
    </div>
    <!--        <div>-->
    <!--            <mat-slide-toggle-->
    <!--                [checked]="isShared"-->
    <!--                [(ngModel)]="isShared"-->
    <!--                [disabled]="!isShared && !removedAllActors.length"-->
    <!--                (change)="onRemoveAllSharedAccesses($event)"-->
    <!--                [matTooltip]="isShared ? 'Remove all shares' : null"-->
    <!--            >-->
    <!--            </mat-slide-toggle>-->
    <!--        </div>-->
  </div>
  <div class="divider" *ngIf="withShareableLink"></div>
  <div
    class="share-link"
    fxLayout
    fxLayoutAlign="center center"
    *ngIf="withShareableLink"
  >
    <mat-icon>link</mat-icon>
    <div class="address"></div>
    <button mat-button color="primary" ngxClipboard [cbContent]="">Copy</button>
  </div>
  <div class="divider"></div>
  <div
    class="search-input-container"
    fxLayout="row"
    fxLayoutAlign="stretch"
    *ngIf="!pendingChanges"
  >
    <div class="spinner" *ngIf="isLoadingActors || isLoadingPermissions">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="50"
      ></mat-progress-spinner>
    </div>
    <ng-container *ngIf="!isLoadingActors && !isLoadingPermissions">
      <ng-select
        style="width: 100%; display: flex; align-items: center"
        [style.maxWidth]="
          componentMode === ComponentModeEnum.ADD ? '248px' : null
        "
        [items]="actors"
        [addTag]="true"
        [multiple]="true"
        appendTo="body"
        bindLabel="name"
        [(ngModel)]="addedActors"
        (change)="onActorSelectionChanged($event)"
      >
        <ng-template ng-label-tmp let-item="item">
          <div
            style="width: 100%; display: flex; align-items: center"
            [matTooltip]="
              'Name: ' +
              item.name +
              (item.type === 'USER' || item.type === 'UNREGISTERED_USER'
                ? '\n Email: ' + item.email
                : '')
            "
            matTooltipClass="multi-line-tooltip"
          >
            <itls-icon
              matRipple
              [svgIcon]="item.type | translateIconType"
              [height]="14"
              style="margin-right: 7px"
            ></itls-icon>
            <p class="selected-option">{{ item.name }}</p>
          </div>
        </ng-template>
        <ng-template
          ng-option-tmp
          let-item="item"
          appendTo="body"
          let-index="index"
        >
          <div style="width: 100%; display: flex; align-items: center">
            <div style="display: flex; flex-direction: column">
              <span>
                <itls-icon
                  matRipple
                  [svgIcon]="item.type | translateIconType"
                  [height]="14"
                  style="margin-right: 7px"
                ></itls-icon>
                <b>
                  {{ item.name }}
                </b>
              </span>
              <p
                *ngIf="
                  item.type === 'USER' || item.type === 'UNREGISTERED_USER'
                "
                style="margin-left: 24px"
              >
                {{ item.email }}
              </p>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <button
        *ngIf="componentMode === ComponentModeEnum.ADD"
        mat-button
        fxFlexAlign="flex-start"
        class="permission-type-group-btn"
        [matMenuTriggerFor]="newSharesPermissionGroupTypeMenu"
      >
        {{ addActorsPermissionTypeGroup.title }}
        <mat-icon svgIcon="icon-arrow-down"></mat-icon>
      </button>
      <mat-menu
        #newSharesPermissionGroupTypeMenu="matMenu"
        class="permission-type-group-selection-container"
      >
        <button
          (click)="addActorsPermissionTypeGroup = otherPermissionTypeGroup"
          mat-menu-item
          *ngFor="
            let otherPermissionTypeGroup of userSelectablePermissionTypeGroups
          "
        >
          {{ otherPermissionTypeGroup.title }}
        </button>
        <!--            <div-->
        <!--                (click)="-->
        <!--                    addActorsPermissionTypeGroup = otherPermissionTypeGroup-->
        <!--                "-->
        <!--                mat-menu-item-->
        <!--                fxLayout="column"-->
        <!--                fxLayoutAlign="center flex-start"-->
        <!--                *ngFor="-->
        <!--                    let otherPermissionTypeGroup of userSelectablePermissionTypeGroups-->
        <!--                "-->
        <!--            >-->
        <!--                <span>{{ otherPermissionTypeGroup.titleDescription }}</span>-->
        <!--                <span>{{ otherPermissionTypeGroup.description }}</span>-->
        <!--            </div>-->
      </mat-menu>
    </ng-container>
  </div>
  <mat-list
    class="permissions-container"
    *ngIf="componentMode === ComponentModeEnum.EDIT && !isLoadingActors"
  >
    <mat-list-item
      *ngFor="let selectedActor of selectedActors"
      class="shared-actor-item"
      [matTooltip]="
        'Name: ' +
        selectedActor.actor.name +
        (selectedActor.actor.type === 'USER' ||
        selectedActor.actor.type === 'UNREGISTERED_USER'
          ? '\nEmail: ' + selectedActor.actor.email
          : '')
      "
      matTooltipClass="multi-line-tooltip"
    >
      <itls-icon
        matRipple
        [svgIcon]="selectedActor.actor.type | translateIconType"
        [height]="24"
        style="margin-right: 7px"
        mat-list-icon
      ></itls-icon>
      <h4
        *ngIf="
          selectedActor.actor.type === 'ROLE' ||
          selectedActor.actor.type === 'ACCOUNT'
        "
        style="font-weight: 400"
      >
        {{ selectedActor.actor.name | shorten: 27:"..." }}
      </h4>
      <h4
        *ngIf="
          selectedActor.actor.type === 'USER' ||
          selectedActor.actor.type === 'UNREGISTERED_USER'
        "
        mat-line
      >
        {{ selectedActor.actor.name | shorten: 27:"..." }}
      </h4>
      <p
        *ngIf="
          selectedActor.actor.type === 'USER' ||
          selectedActor.actor.type === 'UNREGISTERED_USER'
        "
        mat-line
      >
        {{ selectedActor.actor.email | shorten: 27:"..." }}
      </p>
      <ng-container
        *ngIf="
          selectedActor.permissionGroup.value ===
            PermissionTypeGroupEnum.OWNER ||
            selectedActor.permissionGroup.value === 'VARIES_DISABLED';
          else elseBlock
        "
      >
        <div
          class="permission-type-group-container"
          fxLayout="row"
          fxLayoutAlign="center"
        >
          <label>
            {{ selectedActor.permissionGroup.title }}
          </label>
        </div>
      </ng-container>
      <ng-template #elseBlock>
        <button
          mat-button
          class="permission-type-group-btn"
          [matMenuTriggerFor]="permissionGroupTypeMenu"
        >
          {{ selectedActor.permissionGroup.title }}
          <mat-icon svgIcon="icon-arrow-down"></mat-icon>
        </button>
        <mat-menu #permissionGroupTypeMenu="matMenu">
          <button
            (click)="
              onChangePermissionTypeGroupForSelectedActor(
                selectedActor,
                otherPermissionTypeGroup
              )
            "
            mat-menu-item
            *ngFor="
              let otherPermissionTypeGroup of userSelectablePermissionTypeGroups
            "
          >
            {{ otherPermissionTypeGroup.title }}
          </button>
          <button mat-menu-item (click)="removeFromShared(selectedActor)">
            Remove
          </button>
        </mat-menu>
      </ng-template>
    </mat-list-item>
  </mat-list>
  <mat-card-actions class="action-btns-container">
    <span
      *ngIf="pendingChanges"
      style="color: #5f6368; font-style: italic; margin-right: 12px"
      >Pending changes</span
    >
    <button
      mat-flat-button
      color="accent"
      [disabled]="isCallingShareApi"
      *ngIf="isDialogMode"
      class="cancel-btn"
      [class.dialog-btn]="isDialogMode"
      (click)="onCancelClick()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="isCallingShareApi"
      class="done-btn"
      [class.dialog-btn]="isDialogMode"
      (click)="onShareClick()"
    >
      Done
    </button>
  </mat-card-actions>
</div>
