import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

import { ControlWidget } from "../../widget";
import { FormControl } from "@angular/forms";

@Component({
  selector: "sf-date-picker-widget",
  template: `
    <div class="widget form-group">
      <label
        *ngIf="schema.title"
        [attr.for]="id"
        class="horizontal control-label"
      >
        {{ schema.title }}
      </label>
      <span *ngIf="schema.description" class="formHelp">{{
        schema.description
      }}</span>
      <input
        type="text"
        #input
        (click)="position = getPosition()"
        [placeholder]="schema.placeholder"
        class="form-control form-widget"
        [formControl]="control"
        [isDisabled]="schema.readOnly"
        [bsValue]="control.value"
        [placement]="position"
        [bsConfig]="{
          containerClass: 'theme-dark-blue',
          customTodayClass: 'custom-today-class'
        }"
        bsDatepicker
      />
    </div>
  `,
})
export class DatePickerWidgetComponent extends ControlWidget {
  @ViewChild("input")
  input: ElementRef;

  public position: "top" | "bottom" = this.getPosition();

  getPosition(): "top" | "bottom" {
    if (!this.input) {
      return "bottom";
    }

    const body = document.body,
      html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    return height - this.input.nativeElement.getBoundingClientRect().bottom <
      330 //height of date picker
      ? "top"
      : "bottom";
  }

  onFormPropertyValueChange(control: FormControl, newValue) {
    if (control.value !== newValue) {
      control.setValue(newValue, {
        emitEvent: false,
        emitModelToViewChange: true,
        emitViewToModelChange: false,
      });
    }
  }
}
