import { ActionButtonInterface } from "@@intelease/web/intelease/interfaces";

export const FOLDER_ACTION__RENAME = {
  title: "Rename",
  actionKey: "RENAME",
  icon: "icon-rename",
  children: [],
};
export const FOLDER_ACTION__SHARE = {
  title: "Share",
  actionKey: "SHARE",
  icon: "icon-share",
  children: [],
};
export const FOLDER_ACTION__MOVE = {
  title: "Move",
  actionKey: "MOVE",
  icon: "icon-move",
  children: [],
};

export const FOLDER_ACTION__DOWNLOAD = {
  title: "Download",
  actionKey: "DOWNLOAD",
  icon: "icon-download",
};
export const FOLDER_ACTION__DELETE = {
  title: "Delete",
  actionKey: "DELETE",
  icon: "icon-remove",
  children: [],
};

export const FOLDER_ACTION_BUTTONS: ActionButtonInterface[] = [
  FOLDER_ACTION__SHARE,
  FOLDER_ACTION__MOVE,
  FOLDER_ACTION__DOWNLOAD,
  FOLDER_ACTION__RENAME,
  FOLDER_ACTION__DELETE,
];

export const BATCH_FOLDER_ACTION_BUTTONS: ActionButtonInterface[] = [
  FOLDER_ACTION__SHARE,
  FOLDER_ACTION__MOVE,
  FOLDER_ACTION__DOWNLOAD,
  FOLDER_ACTION__DELETE,
];

export const FOLDER_ACTION_BUTTONS_KEYS: string[] = FOLDER_ACTION_BUTTONS.map(
  (actionButton) => actionButton.actionKey
);

export const BATCH_FOLDER_ACTION_BUTTONS_KEYS: string[] =
  BATCH_FOLDER_ACTION_BUTTONS.map((actionButton) => actionButton.actionKey);
