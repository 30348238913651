import { createFeatureSelector, createSelector } from "@ngrx/store";
import { RECORDS_FEATURE_KEY, DriveState } from "./drive.reducer";
import { values } from "lodash";
import { ReportLogicalOperatorEnum } from "@@intelease/web/intelease/constants";
import { SelectedFilterModel } from "../models/record.types";

// Lookup the 'Drive' feature state managed by NgRx
const getRecordsState = createFeatureSelector<DriveState>(RECORDS_FEATURE_KEY);

const loadAllRecordsSucceeded = createSelector(
  getRecordsState,
  (state: DriveState) => state.loadAllRecordsSucceeded
);

const loadAllRecordsError = createSelector(
  getRecordsState,
  (state: DriveState) => state.loadAllRecordsError
);

const isLoadAllRecordsLoading = createSelector(
  getRecordsState,
  (state: DriveState) => state.isLoadAllRecordsLoading
);

const getAllRecordsList = createSelector(
  getRecordsState,
  loadAllRecordsSucceeded,
  (state: DriveState, isLoaded) =>
    isLoaded
      ? state.isAdvancedSearchActive && state.advancedSearchLoaded
        ? {
            ...state.records,
            items: state.records?.items[state.records?.pagination.page - 1],
          }
        : state.records
      : undefined
);

const getAdvancedSearchFieldsList = createSelector(
  getRecordsState,
  (state: DriveState) => [
    ...values(state.advancedSearchFields.generalFields).map((item: any) => ({
      ...item,
      category: "GENERAL",
    })),
    // ..._.values(state.advancedSearchFields.provisionFields).map(
    //     (item: any) => ({
    //         ...item,
    //         category: 'PROVISION',
    //     }),
    // ),
  ]
);

const getProvisionsOperator = createSelector(
  getRecordsState,
  (state: DriveState) => state.provisionsOperator
);

const getAppliedSelectedColumnFields = createSelector(
  getRecordsState,
  (state: DriveState) => state.selectedColumnFields.applied
);

const getCurrentSelectedColumnFields = createSelector(
  getRecordsState,
  (state: DriveState) => state.selectedColumnFields.current
);

const advancedSearchPossibleColumns = createSelector(
  getRecordsState,
  (state: DriveState) => state.advancedSearchPossibleColumns
);

const isAdvancedSearchActive = createSelector(
  getRecordsState,
  (state: DriveState) => state.isAdvancedSearchActive
);

const getPagination = createSelector(
  getRecordsState,
  (state: DriveState) => state.records?.pagination
);

const getCurrentSelectedFilter = createSelector(
  getRecordsState,
  (state: DriveState) =>
    state.selectedFilter?.current || {
      operator: ReportLogicalOperatorEnum.AND,
      value: [],
    }
);

const getAppliedSelectedFilter = createSelector(
  getRecordsState,
  (state: DriveState) =>
    state.selectedFilter?.applied || {
      operator: ReportLogicalOperatorEnum.AND,
      value: [],
    }
);

const getNumberOfFilters = createSelector(
  getRecordsState,
  getAppliedSelectedFilter,
  (state: DriveState, selectedFilter: SelectedFilterModel) =>
    selectedFilter.value.length || 0
);

const isSortRecordFailed = createSelector(
  getRecordsState,
  (state: DriveState) => state.sortRecordsFailed && !state.sortRecordsLoading
);

export const recordsQuery = {
  isLoadAllRecordsLoading,
  loadAllRecordsSucceeded,
  loadAllRecordsError,
  getAllRecordsList,

  getAdvancedSearchFieldsList,
  getProvisionsOperator,

  getAppliedSelectedColumnFields,
  getCurrentSelectedColumnFields,
  advancedSearchPossibleColumns,

  isAdvancedSearchActive,

  getPagination,
  getNumberOfFilters,
  getAppliedSelectedFilter,
  getCurrentSelectedFilter,

  isSortRecordFailed,
};
