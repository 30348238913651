import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { INode } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive-tree.service";
import { ItlsDriveService } from "@@intelease/web/ui/src/lib/itls-drive/services/itls-drive.service";

@Component({
  selector: "il-select-folder",
  templateUrl: "./select-folder.component.html",
  styleUrls: ["./select-folder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFolderComponent implements OnInit {
  name: string;
  title: string;
  selectedFolder: INode;
  nonSelectableNodeUids: string[];
  disabledNodeUids: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dialogRef: MatDialogRef<SelectFolderComponent>,
    private cdr: ChangeDetectorRef
  ) {
    this.title = modalData.title;
    const source = modalData.source;
    if (source) {
      this.nonSelectableNodeUids = [
        source.parentDirectoryUid
          ? source.parentDirectoryUid
          : ItlsDriveService.ROOT_NODE_ID,
      ];
      this.disabledNodeUids = source.uid ? [source.uid] : [];
      this.disabledNodeUids = source.uids
        ? [...source.uids]
        : this.disabledNodeUids;
    }
  }

  ngOnInit() {}

  onSelectClicked() {
    this.dialogRef.close({ selectedFolder: this.selectedFolder });
  }

  onNodeClicked(node: INode) {
    this.selectedFolder = node;
    this.cdr.detectChanges();
  }
}
