<button
  mat-button
  disableRipple
  class="mr-2"
  *ngIf="!isShowingSearch && !fromDashboard"
  (click)="toggleSearch()"
  [@showDelay]
>
  <div>
    <span>
      <itls-icon
        style="margin-top: -5px; margin-right: 6px"
        svgIcon="icon-search"
      ></itls-icon>
    </span>
    <span> Search </span>
  </div>
</button>

<div
  class="field-container"
  *ngIf="isShowingSearch || fromDashboard"
  [@expand]="!fromDashboard ? 'expand' : null"
>
  <itls-icon svgIcon="icon-search" class="icon search"></itls-icon>

  <input
    #searchInput
    class="form-control"
    type="text"
    placeholder="Search ..."
    [itlsFocusIn]="!fromDashboard"
    [itlsFocusInDelay]="200"
    [matAutocomplete]="auto"
    [formControl]="nameQueryCtrl"
  />

  <itls-icon
    svgIcon="icon-cancel"
    class="icon cancel"
    (click)="toggleSearch()"
    *ngIf="!fromDashboard"
  ></itls-icon>
</div>

<mat-autocomplete #auto="matAutocomplete" class="drive-search-autocomplete">
  <mat-option
    *ngFor="let enrichedNode of search.result | async; index as index"
    [value]="enrichedNode.node.name"
    (onSelectionChange)="onSearchItemSelect(enrichedNode)"
    [matTooltip]="enrichedNode.tooltip"
    [id]="enrichedNode.node.uid"
    class="search-item-container"
    [style.border-bottom]="
      indexOfLastDirectoryNode === index && !allSearchResultDirectoryNodes
        ? '1px solid #bfbfbf'
        : null
    "
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      [matTooltip]="enrichedNode.tooltip"
    >
      <mat-icon
        *ngIf="enrichedNode.node.type === 'DIRECTORY'"
        [svgIcon]="
          enrichedNode.node.shared ? 'icon-folder-share' : 'icon-documents'
        "
        style="margin-right: 10px"
      ></mat-icon>
      <mat-icon
        *ngIf="enrichedNode.node.type === 'RECORD'"
        [svgIcon]="
          enrichedNode.node.shared ? 'icon-documents-share' : 'icon-note'
        "
        style="margin-right: 10px"
      ></mat-icon>
      <div fxLayout="column" class="text-content-container">
        <span class="node-name">
          {{ enrichedNode.node.name }}
        </span>
        <span class="owner-name">
          {{ enrichedNode.node.owner?.name }}
        </span>
      </div>
      <span style="margin-left: auto">
        {{ enrichedNode.node.lastModifiedDate | date: "YYYY/MM/dd" }}
      </span>
    </div>
  </mat-option>
  <mat-option
    (onSelectionChange)="onSeeAllResultsClicked()"
    *ngIf="nameQueryCtrl.value && search.totalSize > search.resultSize"
    style="border-top: 1px solid #bfbfbf"
  >
    <div style="font-weight: 500; color: #2979ff; text-align: center">
      See all results
    </div>
  </mat-option>
</mat-autocomplete>
