<div class="toolbar">
  <button
    *ngIf="canAddFolder"
    mat-button
    color="primary"
    class="mr-2"
    (click)="onCreateNewFolderClicked()"
    matTooltip="Create folder"
    style="color: #2979ff; background-color: #f5f6f7; border-radius: 4px"
  >
    <mat-icon style="margin-top: -2px; margin-right: 11px; color: #2979ff"
      >add</mat-icon
    >
    <span>NewFolder</span>
  </button>
  <div class="search-container">
    <il-drive-search
      (searchItemClicked)="onSearchItemClicked($event)"
    ></il-drive-search>
  </div>
</div>
<div class="itls-drive">
  <router-outlet></router-outlet>
</div>
